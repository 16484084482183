import React, { useState, useEffect, useRef } from 'react';
import './file_uploader.css'; // Asegúrate de que la ruta sea correcta para tus estilos CSS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import { send_file_post_data, get_send_file_post_data } from '../../services/portal/portal';
import MODAL_FORECAST from '../../main_components/modal/modal_forecast';
import { delete_table_forecast } from '../../services/portal/portal';
import { Box, Button } from "@mui/material";
import UploadFileIcon from '@mui/icons-material/UploadFile';


const FORECAST = () => {
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [error, setError] = useState('');
    console.log(uploadedFiles)
    const [socket, setsocket] = useState(null);
    const [msj_total_lotes, setmsj_total_lotes] = useState(0);
    const [msj_total_archivos, setmsj_total_archivos] = useState(0);
    const [msj_count_archivos, setmsj_count_archivos] = useState(0);
    const [msj_aux, setmsj_aux] = useState("Iniciando....");
    const [num_lote, setnum_lote] = useState("Iniciando....");
    const [num_confirmacion, setnum_confirmacion] = useState(0);
    const [progress, setProgress] = useState(0); // Progreso del total de lotes
    const [progressMessage, setProgressMessage] = useState('Preparando para subir archivos...'); // Mensaje de progreso
    const [lotes_reponse, setlotes_reponse] = useState(100); // Progreso del total de lotes

    const [modalT, setmodalT] = useState(false);
    const [flag_dt, setflag_dt] = useState(false);

    const confirmationReceived = useRef(false);
    // console.log(confirmationReceived?.current)

    useEffect(() => {

        const sockkets = () => {
            const newsocket = new WebSocket(`wss://vk9xy2pvtc.execute-api.us-east-1.amazonaws.com/prod`);

            newsocket.addEventListener('open', (event) => {
                console.log('WebSocket connection opened');
            });
            newsocket.addEventListener("message", event => {
                console.log("Mensaje recibido:", event.data);
                handleReceiveMessage(event.data);
            });
            newsocket.addEventListener("close", event => {
                console.log("Conexión cerrada:", event);
                newsocket.close();
                setTimeout(() => {
                    sockkets();
                }, 1500);
            });
            setsocket(newsocket);

            const handleBeforeUnload = () => {
                newsocket.close();
            };
            window.addEventListener('beforeunload', handleBeforeUnload);
            return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
            };
        }

        sockkets();
    }, []);


    useEffect(() => {
        console.log(num_confirmacion)

        if (num_confirmacion === lotes_reponse) {
            setTimeout(() => {
                setmodalT(false)
                setflag_dt(true)
            }, 100);
        }

    }, [num_confirmacion, lotes_reponse])

    const handleReceiveMessage = (message) => {
        console.log(message);
        if (message == 'next_batch') {
            setnum_confirmacion(prev => prev + 1);
            confirmationReceived.current = true;
        }
    };

    async function procesarPociones() {
        const lotes = dividirEnLotes(uploadedFiles, 10);
        setlotes_reponse(lotes?.length);
        console.log(`Total de archivos a procesar: ${lotes.length}`);
        setmsj_total_archivos(`Total de archivos a procesar: ${uploadedFiles?.length}`);
        setmsj_total_lotes(`Total de lotes de 10 archivos a procesar: ${lotes?.length}`);
        setmodalT(true);
        await delete_table_forecast();
        for (let i = 0; i < lotes.length; i++) {
            setProgress((i / lotes.length) * 100); // Actualiza la barra de progreso
            setProgressMessage(`Subiendo lote ${i + 1} de ${lotes.length}`); // Actualiza el mensaje de progreso

            await esperarCincoSegundos(); // Espera antes de enviar el siguiente lote
            await enviarLote(lotes[i]);
            setProgressMessage(`Analizando lote ${i + 1} de ${lotes.length}`); // Actualiza el mensaje a "Analizando"

            await esperarveintesegundos(); // Espera para la confirmación
            handle_get_send_file_post_data(); // Invoca la función para el análisis del lote
        }

        setProgress(100); // Asegúrate de que la barra de progreso se complete al final
        setProgressMessage('Todos los lotes han sido enviados y se estarán procesando para su tratamiento...'); // Mensaje final
    }


    function esperarCincoSegundos() {
        return new Promise(resolve => {
            setTimeout(resolve, 10000);
        });
    }


    function esperarveintesegundos() {
        return new Promise(resolve => {
            setTimeout(resolve, 20000);
        });
    }


    function dividirEnLotes(arr, tamanoLote) {
        let resultado = [];
        for (let i = 0; i < arr.length; i += tamanoLote) {
            let lote = arr.slice(i, i + tamanoLote);
            resultado.push(lote);
        }
        return resultado;
    }

    // Función para enviar datos mediante POST
    async function enviarLote(lote) {
        for (const item of lote) {
            try {
                console.log(item)
                await send_file_post_data(item); // Asumiendo que `send_file_post_data` es tu función para enviar datos
            } catch (err) {
                console.error('Error al enviar el archivo:', item?.fileName, err);
                // Maneja el error según sea necesario
            }
        }
        console.log('Confirmación recibida, procediendo con el siguiente lote');
        return true
    }

    const handle_process = () => {
        procesarPociones().then(() => console.log('Todos los lotes han sido enviados.'));
    }

    const handle_redirect = () => {
        window.location="/FORECAST_TABLE"
    }

    const handle_get_send_file_post_data = async () => {

        try {
            await get_send_file_post_data();
        } catch (err) {
        }
    }


    const handleDirectoryChange = async () => {
        if (!window.showDirectoryPicker) {
            setError('Tu navegador no soporta la selección de directorios.');
            return;
        }

        try {
            const directoryHandle = await window.showDirectoryPicker();
            const files = [];
            for await (const entry of directoryHandle.values()) {
                if (entry.kind === 'file') {
                    const file = await entry.getFile();
                    if (file.type === 'text/xml' || file.name.endsWith('.xml')) {
                        const base64 = await toBase64(file);
                        files.push({
                            fileName: file.name,
                            file64: base64.replace("data:text/xml;base64,", ""),
                        });
                    }
                }
            }
            setUploadedFiles(files);
            if (files.length === 0) {
                setError('No se encontraron archivos XML en la carpeta seleccionada.');
            } else {
                setError('');
            }
        } catch (error) {
            console.error(error);
            setError('Ocurrió un error al seleccionar la carpeta.');
        }
    };

    const toBase64 = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });




    return (
        <div className='container-documents-pdf'>
            <MODAL_FORECAST modalGeneral={modalT} modalT={modalT} message={progressMessage} progress={progress}></MODAL_FORECAST>

            <div className="wrapper">
                {/* <button onClick={() => handle_send_file_post_data()} > Subir archivos</button>
                <button onClick={() => handle_get_send_file_post_data()} > Construir Tabla</button> */}

                <header>CARGAR ARCHIVOS XML</header>

                <div className="form" onClick={handleDirectoryChange}>
                    <FontAwesomeIcon icon={faFolderOpen} />
                    <p>Seleccionar Carpeta</p>
                </div>

                {error && <p className="error">{error}</p>}

                <p>Archivos cargados: {uploadedFiles?.length}</p>

                <Button
                    style={{ "backgroundColor": "green", display:flag_dt===false?"":"none" }}
                    sx={{ height: '35px', marginRight: "5px", width: "65%", color: "white" }}
                    onClick={() => handle_process()}
                >
                    <UploadFileIcon></UploadFileIcon>
                    Iniciar Proceso
                </Button>

                <Button
                    style={{ "backgroundColor": "green", display:flag_dt===false?"none":"" }}
                    sx={{ height: '35px', marginRight: "5px", width: "65%", color: "white" }}
                    onClick={() => handle_redirect()}
                >
                    Ingresar al FORECAST
                </Button>
                

            </div>
        </div>
    );
};

export default FORECAST;
