import React from "react";
import "./warbibg.css";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

const WARNING = ({ msj, view }) => {
  const containerClass = view ? "notification-container" : "notification-container-warning";
  console.log(msj);

  return (
    <div className={containerClass}>
      <div className="notification-title">
        <ReportProblemIcon sx={{ color: 'orange', fontSize: 50 }} />
        <span className="alert-text">¡ADVERTENCIA!</span>
      </div>
      <div className="notification-content">
        <p>{msj}</p>
      </div>
    </div>
  );
};

export default WARNING;
