//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
import Box from "@mui/material/Box";
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import GroupSharpIcon from '@mui/icons-material/GroupSharp';
import MonetizationOnSharpIcon from '@mui/icons-material/MonetizationOnSharp';
import IsoSharpIcon from '@mui/icons-material/IsoSharp';
import ProductionQuantityLimitsSharpIcon from '@mui/icons-material/ProductionQuantityLimitsSharp';
//COMPONENTES

import Addenda_PMT from "./addenda_PMT";
import Addenda_PSV from "./addenda_PSV";
import Marely from "../Marely";
//ESTILOS
// import "../componentsCFDI/estilo.css";
// import "../componentsCFDI/estilo.css";
// import "../componentsCFDI/modal.css";
// import "./dashboar.css";


const Addendas = () => {
  const [value, setValue] = React.useState(0);
  const [clients, setclients] = React.useState("none");
  const [detailSale, setdetailSale] = React.useState("none");
  const [bonus, setbonus] = React.useState("none");
  const [product, setproduct] = React.useState("none");
  const [noSale, setnoSale] = React.useState("none");



  const handelClient = () =>{
    setclients("");
    setdetailSale("none");
    setbonus("none");
    setproduct("none");
    setnoSale("none");
  }

  const handelDetailSales = () =>{
    setclients("none");
    setdetailSale("");
    setbonus("none");
    setproduct("none");
    setnoSale("none");
  }

  const handelBonus = () =>{
    setclients("none");
    setdetailSale("none");
    setbonus("");
    setproduct("none");
    setnoSale("none");
  }

  const handelProduct = () =>{
    setclients("none");
    setdetailSale("none");
    setbonus("none");
    setproduct("");
    setnoSale("none");
  }

  const handelNoSale = () =>{
    setclients("none");
    setdetailSale("none");
    setbonus("none");
    setproduct("none");
    setnoSale("");
  }

  useEffect(() => {
    setclients(clients);
    setdetailSale(detailSale);
    setbonus(bonus);
    setproduct(product);
    setnoSale(noSale);
  }, [setclients,setdetailSale,setbonus,setproduct,setnoSale]);



  
  return (
    <div className="">
      <Box sx={{ margin: "0 auto" ,width: 300 }}>
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        <BottomNavigationAction style={{ display: sessionStorage.getItem("ROLE_ADDENDAS_MARELLI_HELP") }} label="Addenda Marelli" icon={<GroupSharpIcon onClick={handelClient} />}  onClick={handelClient}/>
        <BottomNavigationAction style={{ display: sessionStorage.getItem("ROLE_ADDENDAS_VOLKSWAGEN") }} label="Addenda Volkswagen PMT" icon={<IsoSharpIcon onClick={handelDetailSales}/>} onClick={handelDetailSales}/>
        <BottomNavigationAction label="Addenda Volkswagen PSV" icon={<IsoSharpIcon onClick={handelBonus} />} onClick={handelBonus}/>
        

      </BottomNavigation>
    </Box>

    <div  style={{display:clients}}>
      <Marely></Marely>
    </div>
    <div  style={{display:detailSale}}>
      <Addenda_PMT></Addenda_PMT>
    </div>
    <div  style={{display:bonus}}>
      <Addenda_PSV></Addenda_PSV>
    </div>
    <div  style={{display:product}}>
    </div>
    <div  style={{display:noSale}}>
    </div>

    </div>
  );
};

export default Addendas;
