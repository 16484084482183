import "./chat.css";
import "./chatmini.css";
import { CHATMINI } from './chatmini/chatmini';

export const CHAT = (props) => {
  return (
    <>
      <CHATMINI></CHATMINI>
    </>
  )
}