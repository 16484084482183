//PAQUETERIAS
import { useEffect, useState, useMemo, useRef } from "react";
import axios from "axios";
import { StickyTable, Row, Cell } from "react-sticky-table";
import {CustomDialog, Confirm} from "react-st-modal";
import NotificationSystem from 'react-notification-system';
//COMPONENTES
import AlertResponse from "../../main_components/alerts/alertResponse";
import Modal_Table from "../../main_components/modal/modal_Table";

//ESTILOS
import "./mps.css";
//IMAGENES/ICONOS
import Delete from "../../imgComponents/borrar.png";
import CREATE from "../../imgComponents/create.png";
import Edit from "../../imgComponents/save.png";
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";
import { refresh_token } from "../../main_components/tokens/tokenrefresh";
import { get_ihs } from "../../services/IHS/ihs";
import { NEW_RECORD } from "./new_record";
import datamp from "./datamps.json";
import { SelectColumnFilter } from "../../componentsCFDI/filters";
import TableContainer from "../../main_components/Table/TableContainer";

const MPS= () => {
  
  const notificationSystemRef = useRef();
  const [data, setData] = useState([]);
  const [open, setopen]=useState(false);
  var entrada=0;

  const [modalT, setmodalT] = useState(false);
  const [menssage, setmenssage] = useState("");
  const [modalGeneral, setmodalGeneral] = useState(false);
 
  const URL_BASE = "https://lxwr6pudd9.execute-api.us-east-1.amazonaws.com";
  
useEffect(()=>{
  // handleSubmit();
},[]);

function alertas(msj,status){

  if(status==true){
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={true}></AlertResponse>,
      level: 'success',
      position: 'br', 
      autoDismiss: 10, 
    });  
  }else{
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={false}></AlertResponse>,
      level: 'error',
      position: 'br', 
      autoDismiss: 60, 
    });
  }  
}
const reconsultar=(n)=>{
  if(n==true){
    setmodalT(true);  
  }else{
    setmodalT(false);
  } 
}


//proceso de consultar todos los ROLES
const handleSubmit =  () => {
   
    
    
    if(entrada==0){
      entrada=entrada+1;
      setmenssage("Consultando Roles...");
      setmodalGeneral(true);
      setmodalT(true);
    }else{
      setmodalT(true);
    }
    setopen(true);
    setData([]);

    setTimeout(() => {
      axios
        .get(
          `${URL_BASE}/development/roles`,
          {
            headers: {
              Authorization: sessionStorage.getItem("getIdTokenRefresh"),
              "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
              "Content-Type": "application/json",
            },
          } 
        )
        .then((response) => {
          console.log(response.data);
          const data = response.data.roles;
          setData(data);
          setopen(false);
          setmodalGeneral(false);
          setmodalT(false);
        })
        .catch((err) => {
          console.log("error", err);
          setData([]);
          setopen(false);
          setmodalGeneral(false);
          setmodalT(false);
        });
    }, 2000);
};

const columns = useMemo(
  () => [
    {
      Header:()=>(
        <div>
          <img
            id="crearRol"
            src={CREATE}
            onClick={async () => {
              const result = await CustomDialog(               
             <div>
                <NEW_RECORD handleSubmit={handleSubmit} alertas={alertas} reconsultar={reconsultar}></NEW_RECORD>
                {/* <CreateRole handleSubmit={handleSubmit} alertas={alertas} reconsultar={reconsultar}></CreateRole> */}
             </div>,
           {
             className: "modalish",
             title:"New Record",
             showCloseIcon: true,
             isCanClose:false,
           }
       );
     }}
            title="New Record"
            className="cancel"
          ></img>
        </div>
      ),
      
      id: "Acciones",
      Cell: ({ valor, row }) => {
        return (
          <div>
            <img
              src={Edit}
              onClick={async () => {
                  const result = await CustomDialog(               
                  <div>
                      <NEW_RECORD operation={"EDIT"} handleSubmit={handleSubmit} alertas={alertas} reconsultar={reconsultar} row={row}></NEW_RECORD>
                      
                  </div>,
                  {
                    className: "modalish",
                    title:"New Record",
                    showCloseIcon: true,
                    isCanClose:false,
                  }
                );
                }         
                }
              className="centerText, cancel"
              title="Eliminar Rol"
            ></img>
          </div>
        );
      },
    },
    {
      Header: "COUNTRY",
      id: "COUNTRY",
      accessor: "CTRY",
      Filter: SelectColumnFilter
    },
    {
      Header: "BRAND",
      id: "BRAND",
      accessor: "BRAND"
    },
    {
      Header: "PLATFORM",
      id: "PLATFORM",
      accessor: "PLTFRM"
    },
    {
      Header: "PROGRAM",
      id: "PROGRAM",
      accessor: "PRGRM"
    },
    {
      Header: "VEHICLE",
      id: "VEHICLE",
      accessor: "VEHICLE"
    },
    {
      Header: "SAP ITEM",
      id: "SAP ITEM",
      accessor: "SAP ITEM"
    },
    {
      Header: "MPG",
      id: "MPG",
      accessor: "MPG"
    },
    {
      Header: "APP",
      id: "APP",
      accessor: "APP"
    },
    {
      Header: "CUST",
      id: "CUST",
      accessor: "CUST"
    },
    {
      Header: "SOP",
      id: "SOP",
      accessor: "SOP"
    },
    {
      Header: "EOP",
      id: "EOP",
      accessor: "EOP"
    },
    {
      Header: "PROJECT STATUS",
      id: "PROJECT STATUS",
      accessor: "Project Status",
      Filter: SelectColumnFilter
    },
    {
      Header: "DIV",
      id: "DIV",
      accessor: "Div",
      Filter: SelectColumnFilter,
    },
    {
      Header: "ADJUST FACTOR (%)",
      id: "ADJUST",
      accessor: "Adjust factor (%)"
    },
    {
      Header: "NOTES",
      id:"NOTES",
      accessor: "NOTES"
    },
    {
      Header: "MIN SERVICE",
      id: "MINSERV",
      accessor: "Min Servicio",
      Filter: SelectColumnFilter,
    },
    {
      Header: "BOM",
      id: "BOM",
      accessor: "BOM"
    },
    {
      Header: "2023",
      id: "2023",
      accessor: "2023"
    },
    {
      Header: "2024",
      id: "2024",
      accessor: "2024"
    },
    {
      Header: "2025",
      id: "2025",
      accessor: "2025"
    },
    {
      Header: "Q 2023",
      id: "Q2023",
      accessor: "Q 2023"
    },
    {
      Header: "Q 2024",
      id: "Q2024",
      accessor: "Q 2024"
    },
    {
      Header: "Q 2025",
      id: "Q2025",
      accessor: "Q 2025"
    },
    {
      Header: "Mth 2023",
      id: "mth2023",
      accessor: "Mth 2023"
    },
    {
      Header: "Mth 2024",
      id: "mth2024",
      accessor: "Mth 2024"
    },
    {
      Header: "Mth 2025",
      id :"mth2025",
      accessor: "Mth 2025"
    },
    {
      Header: "MQ 2023",
      id: "MQ2023",
      accessor: "MQ 2023"
    },
    {
      Header: "MQ 2024",
      id: "MQ2024",
      accessor: "MQ 2024"
    },
    {
      Header: "MQ 2025",
      id: "MQ2025",
      accessor: "MQ 2025"
    }
    
    ],
    []
);
  return (
    <>

      <BREADCRUMBS
        niveles={[
          { label: "MPS", path: null },
          { label: "Minimos y FCST", path: "min_fcst"}
        ]}
      ></BREADCRUMBS>

  <div className="">         
  <NotificationSystem  ref={notificationSystemRef} ></NotificationSystem>
  <Modal_Table
    open={true}
    message={menssage}
    modalGeneral={modalGeneral}
    modalT={modalT}
  ></Modal_Table>
          <div className="tablemps">
 
          <StickyTable>
            <TableContainer
              paginado={""}
              consultas={""}
              exportar={true}
              columns={columns}
              data={datamp}
              style={{ overflowx: 10 }}
              Gmodal={open}
            />
          </StickyTable>
          </div>

          </div>
    </>
  );
}

export default MPS;
