import AWS from 'aws-sdk';
import { useState } from "react";
import { Storage, Auth } from 'aws-amplify';
import { FileUploader } from "react-drag-drop-files";
//yarn add xlsx
import * as XLSX from 'xlsx';
const S3_BUCKET_NAME = 'pruebaisrael2';


const Upload_file = (props) => {
 
  const [fileName, setFileName] = useState(null);
  const fileTypes = ["XLSX"];
  const [file, setFile] = useState(null);
  const [mensaje, setmensaje]=useState("No file selected");
  const [upload, setupload]=useState(false);

const handleFileUpload = async (event) => {
    setFileName(null);
    setmensaje("Verifying file...");

    const year = new Date().getFullYear();

    const requiredHeaders = 
            [ 
              'Country/Territory',  
              'Production Plant', 
              'Production Brand',
              'Platform',
              'Program',
              'Production Nameplate',
              // 'SOP',
              // 'EOP',
              'Lifecycle (Time)',
              'Vehicle',
              'Assembly Type',
              'Strategic Group',
              'Sales Group',
              'Global Nameplate',
              'Primary Design Center',
              'Primary Design Country/Territory',
              'Primary Design Region',
              'Secondary Design Center',
              'Secondary Design Region',
              'GVW Rating',
              'GVW Class',
              'Car/Truck',
              'Production Type',
              'Global Production Segment',
              'Regional Sales Segment',
              'Global Production Price Class',
              'Global Sales Segment',
              'Global Sales Sub-Segment',
              'Global Sales Price Class',
              'Short-Term Risk Rating',
              'Long-Term Risk Rating',
              `CY ${year}`,
              `CY ${year+1}`,
              `CY ${year+2}`,
              `CY ${year+3}`,
            ]; 
    let headersFound = {};
    const file = event;

    const reader = new FileReader();
    reader.onload = (e) => {
    const data = e.target.result;
    const workbook = XLSX.read(data, { type: 'binary' });
    workbook.SheetNames.forEach(sheetName => {
      const worksheet = workbook.Sheets[sheetName];
      const jsonWorksheet = XLSX.utils.sheet_to_json(worksheet);
    
      if (jsonWorksheet.length > 0) {
        // Verifica que las cabeceras existan en la hoja de cálculo
      
        for (let rowNum = 4; rowNum <= 6; rowNum++) { //filas
          for (let colNum = 8; colNum <= 200; colNum++) { //columnas
            const cellAddress = XLSX.utils.encode_cell({ r: rowNum, c: colNum });
            const cell = worksheet[cellAddress];
            const cellValue = (cell ? cell.v : null);
    
            if (requiredHeaders.includes(cellValue)) {
              headersFound[cellValue] = true;
            }        
          }
        }
        console.log(headersFound);
      }
    });

    const headersExist = requiredHeaders.every(header => Object.keys(headersFound).includes(header));
    const missingHeaders = requiredHeaders.filter(header => !Object.keys(headersFound).includes(header));
    if (headersExist) {    
      console.log("Full File");
      setupload(true);
      setFileName(event);
      setFile(file);
    } else {
      setmensaje("The file does not contain the required headers:"+missingHeaders);
      setupload(false);
      event=null;
      setFileName(null);
    }
  }
  reader.readAsBinaryString(file);
}



const subiras3 = async ()=> {

  const user = await Auth.currentAuthenticatedUser();

  if (!file) {
    alert('Please select a file');
    return;
  }
  //lee el archivo y lo sube a s3
  try{
    const key = `prueba/${file.name}`;

    Storage.put(key, file, {
      bucket: S3_BUCKET_NAME,
      contentType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    })
      .then(result => {
        console.log(result);
        credenciales();
      })
      .catch((err) => {
        console.log(err);
        alert('Failed to upload file to S3');
      });
  }catch(e){
    alert('Failed to upload file to S3');
  }
 
  };

async function credenciales(){

  
  const user = await Auth.currentAuthenticatedUser();
  const textContent = "name: "+user.attributes.name+"\nid_user:"+user.attributes['custom:id_user']+"\nemail:"+user.attributes.email
  +"\nid_company:"+user.attributes['custom:id_company'];
  const blob = new Blob([textContent], { type: "text/plain" });
  const file = new File([blob], "credenciales.txt");
  const key = `prueba/${file.name}`;
Storage.put(key, file, {
  bucket: S3_BUCKET_NAME,
  contentType: "text/plain",
})
  .then(result => {
    console.log('Archivo subido correctamente a S3', result);
    alert('Archivo subido correctamente a S3');
  })
  .catch(error => {
    console.log('Error al subir archivo a S3', error);
    alert('Error al subir archivo a S3');
  });
}


  return (
    <div className="upload-file-modal">
          <div className="container-drag">
            <FileUploader
              handleChange={handleFileUpload}
              name="file"
              types={fileTypes}
            >
              <div className="drangAndDropExpense">
                <p>Drag or select the File to send</p>
              </div>
            </FileUploader>
            <div className="upload-area">
              {fileName===null?<p>{mensaje}</p>:<p className="filename">{fileName.name + "\nVerifying file"}</p>}
            </div>
            <br />
          </div>
          {
            upload===true?<div>
            <button 
              onClick={
                 ()=>subiras3()
                // ()=>credenciales()  
              }>Upload file to S3</button> 
            </div>:<></>
          }
          
        
  
    </div>
  );
};

export default Upload_file;