import { useState} from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useDialog} from "react-st-modal";
import { catchErrorModul } from "../../main_components/catchErr/catchError";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import datapaymethod from "../data/paymentmethod.json";
import dataregimen from "../data/regimen.json";
import datamon from "../data/currency.json";
import dataaudan from "../data/aduana.json";
import dataclaves from "../data/claves.json";
import datarecibido from "../data/recibido.json";
import datatasa from "../data/tasa.json";
import dataunit from "../data/unit.json";
import datacountry from "../data/country.json";
import dataicoterm from "../data/iconterm.json";
import dayjs from "dayjs";
import { date_current } from "../../main_components/date/date";
import { post_layout, put_layout } from "../../services/petitions/petitions";
import { refresh_token } from "../../main_components/tokens/tokenrefresh";

function OPERATION(props) {
  
  const dialog = useDialog();
  var dateff = ""
  var invoicedateff = ""
  var entrydateff = ""
  console.log(props)

  if(props.operation==="EDIT"){
    if(props?.row?.original?.date === ""){
      console.log("");
    }else{
       dateff = convertirFecha(props?.row?.original?.date);
    }

    if(props?.row?.original?.invoiceDate === ""){
      console.log("");
    }else{
      invoicedateff = convertirFecha(props?.row?.original?.invoiceDate);
    }

    if(props?.row?.original?.entryDate === ""){
      console.log("");
    }else{
      entrydateff = convertirFecha(props?.row?.original?.entryDate);
    }
  }
  
  function convertirFecha(fecha) {
    const partes = fecha.split('/');
    const fechaFormateada = `${partes[2]}-${partes[1]}-${partes[0]}`;
    return fechaFormateada;
  }

  const [entrySummary, setEntrySummary] = useState(props.operation==="EDIT"?props?.row?.original?.entrySummary:"");
  const [customs, setCustoms] = useState(props.operation==="EDIT"?props?.row?.original?.customs:"");
  const [date, setDate] = useState(props.operation==="EDIT"? dayjs(dateff):dayjs(date_current));
  const [exchangeRate, setExchangeRate] = useState(props.operation==="EDIT"?props?.row?.original?.exchangeRate:null);
  const [vat, setVat] = useState(props.operation==="EDIT"?props?.row?.original?.vat:null);
  const [entrySummaryKey, setEntrySummaryKey] = useState(props.operation==="EDIT"?props?.row?.original?.entrySummaryKey:"");
  const [freight, setFreight] = useState(props.operation==="EDIT"?props?.row?.original?.freight:0);
  const [insurance, setInsurance] = useState(props.operation==="EDIT"?props?.row?.original?.insurance:0);
  const [packaging, setPackaging] = useState(props.operation==="EDIT"?props?.row?.original?.packaging:0);
  const [others, setOthers] = useState(props.operation==="EDIT"?props?.row?.original?.others:0);
  const [dta, setDta] = useState(props.operation==="EDIT"?props?.row?.original?.dta:0);
  const [commercialValueLocalCurrencyEntrySummary, setCommercialValueLocalCurrencyEntrySummary] = useState(props.operation==="EDIT"?props?.row?.original?.commercialValueLocalCurrencyEntrySummary:null);
  const [customsValueLocalCurrencyEntrySummary, setCustomsValueLocalCurrencyEntrySummary] = useState(props.operation==="EDIT"?props?.row?.original?.customsValueLocalCurrencyEntrySummary:null);
  const [remarks, setRemarks] = useState(props.operation==="EDIT"?props?.row?.original?.remarks:"");
  const [dischargeByInvoiceDate, setDischargeByInvoiceDate] = useState(props.operation==="EDIT"?props?.row?.original?.dischargeByInvoiceDate:"");
  const [receivedFrom, setReceivedFrom] = useState(props.operation==="EDIT"?props?.row?.original?.receivedFrom:"X");
  const [internalNote1EntrySummaryHeader, setInternalNote1EntrySummaryHeader] = useState(props.operation==="EDIT"?props?.row?.original?.internalNote1EntrySummaryHeader:"");
  const [prevalidation, setPrevalidation] = useState(props.operation==="EDIT"?props?.row?.original?.prevalidation:0);
  const [invoice, setInvoice] = useState(props.operation==="EDIT"?props?.row?.original?.invoice:"");
  const [supplierCode, setSupplierCode] = useState(props.operation==="EDIT"?props?.row?.original?.supplierCode:"");
  const [invoiceDate, setInvoiceDate] = useState(props.operation==="EDIT"?dayjs(invoicedateff):dayjs(date_current));
  const [currencyFactor, setCurrencyFactor] = useState(props.operation==="EDIT"?props?.row?.original?.currencyFactor:1);
  const [partNumber, setPartNumber] = useState(props.operation==="EDIT"?props?.row?.original?.partNumber:"");
  const [tariffFractionEntrySummary, setTariffFractionEntrySummary] = useState(props.operation==="EDIT"?props?.row?.original?.tariffFractionEntrySummary:"");
  const [rate, setRate] = useState(props.operation==="EDIT"?props?.row?.original?.rate:0);
  const [rateType, setRateType] = useState(props.operation==="EDIT"?props?.row?.original?.rateType:"TG");
  const [unit, setUnit] = useState(props.operation==="EDIT"?props?.row?.original?.unit:"");
  const [unitPrice, setUnitPrice] = useState(props.operation==="EDIT"?props?.row?.original?.unitPrice:null);
  const [quantity, setQuantity] = useState(props.operation==="EDIT"?props?.row?.original?.quantity:null);
  const [origin, setOrigin] = useState(props.operation==="EDIT"?props?.row?.original?.origin:"USA");
  const [seller, setSeller] = useState(props.operation==="EDIT"?props?.row?.original?.seller:"USA");
  const [longNote, setLongNote] = useState(props.operation==="EDIT"?props?.row?.original?.longNote:"");
  const [igiPaymentMethod, setIgiPaymentMethod] = useState(props.operation==="EDIT"?props?.row?.original?.igiPaymentMethod:5);
  const [incoterm, setIncoterm] = useState(props.operation==="EDIT"?props?.row?.original?.incoterm:"");
  const [treaty, setTreaty] = useState(props.operation==="EDIT"?props?.row?.original?.treaty:"");
  const [ebId, setEbId] = useState(props.operation==="EDIT"?props?.row?.original?.ebId:"No");
  const [onConsignment, setOnConsignment] = useState(props.operation==="EDIT"?props?.row?.original?.onConsignment:"No");
  const [note2Detail, setNote2Detail] = useState(props.operation==="EDIT"?props?.row?.original?.note2Detail:"");
  const [cove, setCove] = useState(props.operation==="EDIT"?props?.row?.original?.cove:"");
  const [fines, setFines] = useState(props.operation==="EDIT"?props?.row?.original?.fines:0);
  const [surcharges, setSurcharges] = useState(props.operation==="EDIT"?props?.row?.original?.surcharges:0);
  const [internalNote2EntrySummaryHeader, setInternalNote2EntrySummaryHeader] = useState(props.operation==="EDIT"?props?.row?.original?.internalNote2EntrySummaryHeader:"");
  const [cnt, setCnt] = useState(props.operation==="EDIT"?props?.row?.original?.cnt:0);
  const [sequence, setSequence] = useState(props.operation==="EDIT"?props?.row?.original?.sequence:null);
  const [conversionFactor, setConversionFactor] = useState(props.operation==="EDIT"?props?.row?.original?.conversionFactor:1);
  const [tariffUnit, setTariffUnit] = useState(props.operation==="EDIT"?props?.row?.original?.tariffUnit:"");
  const [tariffQuantity, setTariffQuantity] = useState(props.operation==="EDIT"?props?.row?.original?.tariffQuantity:0);
  const [vatPaymentMethodItem, setVatPaymentMethodItem] = useState(props.operation==="EDIT"?props?.row?.original?.vatPaymentMethodItem:0);
  const [entryDate, setEntryDate] = useState(props.operation==="EDIT"?dayjs(entrydateff):dayjs(date_current));
  const [regime, setRegime] = useState(props.operation==="EDIT"?props?.row?.original?.regime:"");
  const [operationType, setOperationType] = useState(props.operation==="EDIT"?props?.row?.original?.operationType:"IMP");
  const [pcIdentifier, setPcIdentifier] = useState(props.operation==="EDIT"?props?.row?.original?.pcIdentifier:"No");
  const [imIdentifier, setImIdentifier] = useState(props.operation==="EDIT"?props?.row?.original?.imIdentifier:"No");
  const [ieps, setIeps] = useState(props.operation==="EDIT"?props?.row?.original?.ieps:0);
  const [iepsPaymentMethod, setIepsPaymentMethod] = useState(props.operation==="EDIT"?props?.row?.original?.iepsPaymentMethod:0);
  const [vatPrv, setVatPrv] = useState(props.operation==="EDIT"?props?.row?.original?.vatPrv:0);
  const [currency, setCurrency] = useState(props.operation==="EDIT"?props?.row?.original?.currency:"");
  const [billingCurrencyValue, setBillingCurrencyValue] = useState(props.operation==="EDIT"?props?.row?.original?.billingCurrencyValue:0);
  const [dollarValue, setDollarValue] = useState(props.operation==="EDIT"?props?.row?.original?.dollarValue : null);
  const [igiLocalCurrencyEntrySummary, setIgiLocalCurrencyEntrySummary] = useState(props.operation==="EDIT"?props?.row?.original?.igiLocalCurrencyEntrySummary:0);
  const [msIdentifier, setMsIdentifier] = useState(props.operation==="EDIT"?props?.row?.original?.msIdentifier:"No");
  const [decreasingTransport, setDecreasingTransport] = useState(props.operation==="EDIT"?props?.row?.original?.decreasingTransport:0);
  const [decreasingInsurance, setDecreasingInsurance] = useState(props.operation==="EDIT"?props?.row?.original?.decreasingInsurance:0);
  const [load, setLoad] = useState(props.operation==="EDIT"?props?.row?.original?.load:0);
  const [unload, setUnload] = useState(props.operation==="EDIT"?props?.row?.original?.unload:0);
  const [otherDecrements, setOtherDecrements] = useState(props.operation==="EDIT"?props?.row?.original?.otherDecrements:0);
  const [reference, setReference] = useState(props.operation==="EDIT"?props?.row?.original?.reference:"");


const create = async () => {

  const d = validaterequiredwindow4();
  if(d=== false){
  props.setmodalT(true);
  var data = {
    entrySummary: entrySummary,
    customs: customs,
    date: date.format("DD/MM/YYYY"),
    exchangeRate: exchangeRate,
    vat: vat,
    entrySummaryKey: entrySummaryKey,
    freight: freight,
    insurance: insurance,
    packaging: packaging,
    others: others,
    dta: dta,
    commercialValueLocalCurrencyEntrySummary: commercialValueLocalCurrencyEntrySummary,
    customsValueLocalCurrencyEntrySummary: customsValueLocalCurrencyEntrySummary,
    remarks: remarks,
    dischargeByInvoiceDate: dischargeByInvoiceDate,
    receivedFrom: receivedFrom,
    internalNote1EntrySummaryHeader: internalNote1EntrySummaryHeader,
    prevalidation: prevalidation,
    invoice: invoice,
    supplierCode: supplierCode,
    invoiceDate: invoiceDate.format("DD/MM/YYYY"),
    currencyFactor: currencyFactor,
    partNumber: partNumber,
    tariffFractionEntrySummary: tariffFractionEntrySummary,
    rate: rate,
    rateType: rateType,
    unit: unit,
    unitPrice: unitPrice,
    quantity: quantity,
    origin: origin,
    seller: seller,
    longNote: longNote,
    igiPaymentMethod: igiPaymentMethod,
    incoterm: incoterm,
    treaty: treaty,
    ebId: ebId,
    onConsignment: onConsignment,
    note2Detail: note2Detail,
    cove: cove,
    fines: fines,
    surcharges: surcharges,
    internalNote2EntrySummaryHeader: internalNote2EntrySummaryHeader,
    cnt: cnt,
    sequence: sequence,
    conversionFactor: conversionFactor,
    tariffUnit: tariffUnit,
    tariffQuantity: tariffQuantity,
    vatPaymentMethodItem: vatPaymentMethodItem,
    entryDate: entryDate.format("DD/MM/YYYY"),
    regime: regime,
    operationType: operationType,
    pcIdentifier: pcIdentifier,
    imIdentifier: imIdentifier,
    ieps: ieps,
    iepsPaymentMethod: iepsPaymentMethod,
    vatPrv: vatPrv,
    currency: currency,
    billingCurrencyValue: billingCurrencyValue,
    dollarValue: dollarValue,
    igiLocalCurrencyEntrySummary: igiLocalCurrencyEntrySummary,
    msIdentifier: msIdentifier,
    decreasingTransport: decreasingTransport,
    decreasingInsurance: decreasingInsurance,
    load: load,
    unload: unload,
    otherDecrements: otherDecrements,
    reference: reference
  }
  console.log(data);
  try{
    var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async() => {
        try{
          const d = await post_layout(data);
          console.log(d);
          props.alertas("Fila agregada con éxito", true);
          props.setmodalT(false);
          dialog.close();
          props.handleSubmit();
        }catch(err){
          console.log(err);
          props.setmodalT(false);
        }
      }, 1500);
    }else{}
  }catch(err){
    console.log(err);
  }
}
}

const updateAccounting = async() => {
  const d = validaterequiredwindow4();
  if(d=== false){
    props.setmodalT(true);
    var data = {
      entrySummary: entrySummary,
      customs: customs,
      date: date.format("DD/MM/YYYY"),
      exchangeRate: exchangeRate,
      vat: vat,
      entrySummaryKey: entrySummaryKey,
      freight: freight,
      insurance: insurance,
      packaging: packaging,
      others: others,
      dta: dta,
      commercialValueLocalCurrencyEntrySummary: commercialValueLocalCurrencyEntrySummary,
      customsValueLocalCurrencyEntrySummary: customsValueLocalCurrencyEntrySummary === "" ? null : customsValueLocalCurrencyEntrySummary,
      remarks: remarks,
      dischargeByInvoiceDate: dischargeByInvoiceDate,
      receivedFrom: receivedFrom,
      internalNote1EntrySummaryHeader: internalNote1EntrySummaryHeader,
      prevalidation: prevalidation,
      invoice: invoice,
      supplierCode: supplierCode,
      invoiceDate: invoiceDate.format("DD/MM/YYYY"),
      currencyFactor: currencyFactor,
      partNumber: partNumber,
      tariffFractionEntrySummary: tariffFractionEntrySummary,
      rate: rate,
      rateType: rateType,
      unit: unit,
      unitPrice: unitPrice,
      quantity: quantity,
      origin: origin,
      seller: seller,
      longNote: longNote,
      igiPaymentMethod: igiPaymentMethod,
      incoterm: incoterm,
      treaty: treaty,
      ebId: ebId,
      onConsignment: onConsignment,
      note2Detail: note2Detail,
      cove: cove,
      fines: fines,
      surcharges: surcharges,
      internalNote2EntrySummaryHeader: internalNote2EntrySummaryHeader,
      cnt: cnt,
      sequence: sequence,
      conversionFactor: conversionFactor,
      tariffUnit: tariffUnit,
      tariffQuantity: tariffQuantity,
      vatPaymentMethodItem: vatPaymentMethodItem,
      entryDate: entryDate.format("DD/MM/YYYY"),
      regime: regime,
      operationType: operationType,
      pcIdentifier: pcIdentifier,
      imIdentifier: imIdentifier,
      ieps: ieps,
      iepsPaymentMethod: iepsPaymentMethod,
      vatPrv: vatPrv,
      currency: currency,
      billingCurrencyValue: billingCurrencyValue,
      dollarValue: dollarValue,
      igiLocalCurrencyEntrySummary: igiLocalCurrencyEntrySummary,
      msIdentifier: msIdentifier,
      decreasingTransport: decreasingTransport,
      decreasingInsurance: decreasingInsurance,
      load: load,
      unload: unload,
      otherDecrements: otherDecrements,
      reference: reference
    }
    console.log(data);
    try{
      var rf_token = await refresh_token();
        if (rf_token === true) {
          setTimeout(async() => {
          try{
            const d = await put_layout(props?.row?.original?.uuid,data, props?.date_c);
            console.log(d);
            props.alertas("Fila actualizada con éxito", true);
            props.setmodalT(false);
            dialog.close();
            props.handleSubmit();
          }catch(err){
            console.log(err);
            props.setmodalT(false);
          }
        }, 1500);
      }else{}
    }catch(err){
      console.log(err);
    }
}
 
} 

const window1 = ()=>{
  setop1(true); 
  setop2(false); 
  setop3(false); 
  setop4(false);
}
const window2 = ()=>{
  const d = validaterequiredwindow1();
  if(d===false){
    setop1(false); 
    setop2(true); 
    setop3(false); 
    setop4(false)
  }
}

const window3 = ()=>{
  const d = validaterequiredwindow2();
  if(d === false){
    setop1(false); 
    setop2(false); 
    setop3(true); 
    setop4(false);
  }
}
const window4 = ()=>{
  const d = validaterequiredwindow3();
  if(d === false){
    setop1(false); 
    setop2(false); 
    setop3(false); 
    setop4(true);
  }
}

  const validaterequiredwindow1 = () =>{
    var cadena = "Valores faltantes en los campos: ";
    if(entrySummary?.trim() === "" || entrySummary === ""){
      cadena = cadena + "*Pedimento ";
    }
    if(exchangeRate === null || exchangeRate === ""){
      cadena = cadena + "*Tipo de Cambio ";
    }
    if(date === ""){
      cadena = cadena + "*Fecha ";
    }
    if(commercialValueLocalCurrencyEntrySummary === null || commercialValueLocalCurrencyEntrySummary === ""){
      cadena = cadena + "*Valor Comercial MN Pedimento ";
    }
    if(commercialValueLocalCurrencyEntrySummary < 0.00001){
      props?.alertas("El Valor Comercial MN Pedimento debe ser mayor a 0.00001", "warning");
    }
    if(entrySummaryKey === "" || entrySummaryKey === "9999999999"){
      cadena = cadena + "*Clave ";
    }
    if(cadena.length === 33){
      return false
    }else{
      props?.alertas(cadena, false);
      return true;
    }
  }

  const validaterequiredwindow2 = () =>{
    var cadena = "Valores faltantes en los campos: ";
    if(invoice === "" || invoice?.trim() === ""){
      cadena = cadena + "*Factura ";
    }
    if(supplierCode === "" || supplierCode?.trim() === ""){
      cadena = cadena + "*Código Proveedor ";
    }
    if(invoiceDate === ""){
      cadena = cadena + "*Fecha Factura ";
    }
    if(partNumber === "" || partNumber?.trim() === ""){
      cadena = cadena + "*Número de parte ";
    }
    if(tariffFractionEntrySummary === "" || tariffFractionEntrySummary.trim() === ""){
      cadena = cadena + "*Fracción partida pedimento ";
    }
    if(unitPrice === null || unitPrice === ""){
      cadena = cadena + "*Precio Unitario ";
    }
    if(quantity === null || quantity === ""){
      cadena = cadena + "*Cantidad ";
    }
    if(unit === null || unit === "9999999999" || unit === ""){
      cadena = cadena + "*Unidad ";
    }
    if(unitPrice < 0.00001){
      props?.alertas("El precio unitario debe ser mayor a 0.00001", "warning");
    }
    if(quantity < 0.00001){
      props?.alertas("La cantidad debe ser mayor a 0.00001", "warning");
    }

    if(cadena.length === 33){
      return false
    }else{
      props?.alertas(cadena, false);
      return true;
    }
  }
  
  const validaterequiredwindow3 = () =>{
    var cadena = "Valores faltantes en los campos: ";
    if(sequence === null || sequence===""){
      cadena = cadena + "*Secuencia ";
    }
    if(entryDate === ""){
      cadena = cadena + "*Fecha entrada ";
    }
    if(cadena.length === 33){
      return false
    }else{
      props?.alertas(cadena, false);
      return true;
    }

  }
  const validaterequiredwindow4 = () =>{
    var cadena = "Valores faltantes en los campos: ";
    if(dollarValue === null || dollarValue === ""){
      cadena = cadena + "*Valor Dólares Pedimento ";
    }
    if(dollarValue < 0.00001){
      props?.alertas("El Valor Dólares Pedimento debe ser mayor a 0.00001", "warning");
    }
    if(cadena.length === 33){
      return false
    }else{
      props?.alertas(cadena, false);
      return true;
    }
  }

  const [op1, setop1]=useState(true);
  const [op2, setop2]=useState(false);
  const [op3, setop3]=useState(false);
  const [op4, setop4]=useState(false);

  return (
    <div>
      <div className="petitions-container">
        <div className="windows-opc-forms">
            <div onClick={()=>window1()} className={"item" + (op1 ? " item-select" : "")}>Página 1</div>
            <div onClick={()=>window2()} className={"item" + (op2 ? " item-select" : "")}>Página 2</div>
            <div onClick={()=>window3()} className={"item" + (op3 ? " item-select" : "")}>Página 3</div>
            <div onClick={()=>window4()} className={"item" + (op4 ? " item-select" : "")}>Página 4</div>
          </div>  
        {
          op1 === true?
        <div className="window1-pett">
            <div className="pett-grid-1">
                <div className="pett1">
                    <TextField
                        label="Pedimento"
                        required
                        value={entrySummary}
                        sx={{ m: 1.5, width: "95%" }}
                        onChange={(event) =>{
                            if (event.target.value.length <= 19) {
                                setEntrySummary(event.target.value)
                            }
                        }}
                        InputLabelProps={{ shrink: true }}
                    ></TextField>
                </div>
                <div className="pett2">
                  <TextField
                    select
                    label="Aduana"
                    value={customs}
                    defaultValue={""}
                    InputLabelProps={{shrink: true}}
                    SelectProps={{native: true}}
                    sx={{ m: 1.5, width: "95%"}}
                    onChange={(event) => setCustoms(event.target.value)}  
                  >
                    <option key={"999999999"} value={"9999999999"}>Selecciona Aduana</option>
                  {
                    dataaudan?.map(item=>(
                      <option key={item.code} value={item?.code}>{item.code} - {item.description}</option>
                    ))  
                  }
                    
                </TextField>
                </div>
                <div className="pett3">
                  <LocalizationProvider 
                    dateAdapter={AdapterDayjs}
                  >
                    <DatePicker
                      sx={{ width: "95%"}}
                      label="Fecha *"
                      required
                      value={date}
                      onChange={(newValue) => setDate(newValue)}
                    />
                  </LocalizationProvider>
                </div>
                <div className="pett4">
                  <TextField
                        label="Tipo de Cambio"
                        type="number"
                        required
                        value={exchangeRate}
                        sx={{ m: 1.5, width: "95%" }}
                        onChange={(event) =>{
                            if (event.target.value <= 30) {
                                setExchangeRate(event.target.value)
                            }
                        }}
                        InputLabelProps={{ shrink: true }}
                    ></TextField>
                </div>
                <div className="pett5">
                  <TextField
                        label="IVA"
                        type="number"
                        value={vat}
                        sx={{ m: 1.5, width: "95%" }}
                        onChange={(event) =>{
                          setVat(event.target.value)
                        }}
                        InputLabelProps={{ shrink: true }}
                    ></TextField>
                </div>
            </div>
            <div className="pett-grid-2">
              <div className="pett6">
                  <TextField
                    select
                    required
                    label="Clave"
                    value={entrySummaryKey}
                    defaultValue={""}
                    InputLabelProps={{shrink: true}}
                    SelectProps={{native: true}}
                    sx={{ m: 1.5, width: "95%"}}
                    onChange={(event) => setEntrySummaryKey(event.target.value)}  
                  >
                    <option key={"999999999"} value={"9999999999"}>Selecciona Clave</option>
                  {
                    dataclaves?.map((item, index)=>(
                      <option key={index} value={item?.value}>{item.value}</option>
                    ))  
                  }
                  </TextField>
              </div>
              <div className="pett7">
                <TextField
                    label="Fletes"
                    type="number"
                    value={freight}
                    sx={{ m: 1.5, width: "95%" }}
                    onChange={(event) =>{
                      setFreight(event.target.value)
                    }}
                    InputLabelProps={{ shrink: true }}
                ></TextField>
              </div>
              <div className="pett8">
                <TextField
                    label="Seguros"
                    type="number"
                    value={insurance}
                    sx={{ m: 1.5, width: "95%" }}
                    onChange={(event) =>{
                      setInsurance(event.target.value)
                    }}
                    InputLabelProps={{ shrink: true }}
                ></TextField>
              </div>
              <div className="pett9">
                <TextField
                    label="Embalajes"
                    type="number"
                    value={packaging}
                    sx={{ m: 1.5, width: "95%" }}
                    onChange={(event) =>{
                      setPackaging(event.target.value)
                    }}
                    InputLabelProps={{ shrink: true }}
                ></TextField>
              </div>
            </div>
            <div className="pett-grid-3">
              <div className="pett10">
                <TextField
                    label="Otros"
                    type="number"
                    value={others}
                    sx={{ m: 1.5, width: "95%" }}
                    onChange={(event) =>{
                      setOthers(event.target.value)
                    }}
                    InputLabelProps={{ shrink: true }}
                ></TextField>
              </div>
              <div className="pett11">
                <TextField
                    label="DTA"
                    type="number"
                    value={dta}
                    sx={{ m: 1.5, width: "95%" }}
                    onChange={(event) =>{
                      setDta(event.target.value)
                    }}
                    InputLabelProps={{ shrink: true }}
                ></TextField>
              </div>
              <div className="pett12">
                <TextField
                    label="Valor Comercial MN Pedimento"
                    required
                    type="number"
                    value={commercialValueLocalCurrencyEntrySummary}
                    sx={{ m: 1.5, width: "95%" }}
                    onChange={(event) =>{
                      setCommercialValueLocalCurrencyEntrySummary(event.target.value)
                    }}
                    InputLabelProps={{ shrink: true }}
                ></TextField>
              </div>
              <div className="pett13">
                <TextField
                    label="Valor Aduana MN Pedimento"
                    type="number"
                    value={customsValueLocalCurrencyEntrySummary}
                    sx={{ m: 1.5, width: "95%" }}
                    onChange={(event) =>{
                      setCustomsValueLocalCurrencyEntrySummary(event.target.value)
                    }}
                    InputLabelProps={{ shrink: true }}
                ></TextField>
              </div>
            </div>
            <div className="pett-grid-4">
              <div className="pett14">
                <TextField
                  label="Observaciones"
                  value={remarks}
                  sx={{ m: 1.5, width: "99%" }}
                  onChange={(event) =>{
                    if(event.target.value.length <= 500){
                      setRemarks(event.target.value)
                    }
                  }}
                  InputLabelProps={{ shrink: true }}
                ></TextField>
              </div>
            </div>
            <div className="pett-grid-5">
              <div className="pett15">
                <TextField
                  select
                  label="Descargo x Fecha Factura"
                  value={dischargeByInvoiceDate}
                  defaultValue={""}
                  InputLabelProps={{shrink: true}}
                  SelectProps={{native: true}}
                  sx={{ m: 1.5, width: "95%"}}
                  onChange={(event) => setDischargeByInvoiceDate(event.target.value)}  
                  >
                    <option key={"999999999"} value={"9999999999"}>Selecciona Opción</option>
                    <option key={"1"} value={"No"}>No</option>
                    <option key={"2"} value={"Si"}>Si</option>
                </TextField>
              </div>
              <div className="pett16">
                <TextField
                  select
                  label="Recibida De"
                  value={receivedFrom}
                  defaultValue={""}
                  InputLabelProps={{shrink: true}}
                  SelectProps={{native: true}}
                  sx={{ m: 1.5, width: "95%"}}
                  onChange={(event) => setReceivedFrom(event.target.value)}    
                >
                  <option key={"999999999"} value={"9999999999"}>Selecciona Opción</option>
                  {
                    datarecibido?.map((item, index)=>(
                      <option key={index} value={item.value}>{item.value} - {item.label}</option>
                    ))
                  }
                </TextField>
              </div>
              <div className="pett17">
                <TextField
                  label="Nota Interna 1 Pedimento (Encabezado)"
                  value={internalNote1EntrySummaryHeader}
                  sx={{ m: 1.5, width: "95%" }}
                  onChange={(event) =>{
                    if(event.target.value.length <= 80){
                      setInternalNote1EntrySummaryHeader(event.target.value)
                    }
                  }}
                  InputLabelProps={{ shrink: true }}
                ></TextField>
              </div>
              <div className="pett18">
                <TextField
                  label="Prevalidación"
                  type="number"
                  value={prevalidation}
                  sx={{ m: 1.5, width: "95%" }}
                  onChange={(event) =>{
                      setPrevalidation(event.target.value)
                  }}
                  InputLabelProps={{ shrink: true }}
                ></TextField>
              </div>
            </div>
            <Box>
            <Button
              variant="contained"
              style={{"backgroundColor":"green"}}
              sx={{ m: 1.5, width: "45%" }}
              onClick={()=>window2()}
            >
              Siguiente
            </Button>
            <Button
              style={{"backgroundColor":"red"}}
              variant="contained"
              sx={{ m: 1.5, width: "45%" }}
              onClick={() => {
                  dialog.close();
                } 
              }
            >
            cancelar
          </Button>
          </Box>
          
        </div>:<></>
        }
        {
          op2 === true?
          <div className="window1-pett">
            <div className="pett-grid-6">
              <div className="pett19">
                <TextField
                    label="Factura"
                    required
                    value={invoice}
                    sx={{ m: 1.5, width: "95%" }}
                    onChange={(event) =>{
                        if (event.target.value.length <= 50) {
                            setInvoice(event.target.value)
                        }
                    }}
                    InputLabelProps={{ shrink: true }}
                ></TextField>
                </div>
                <div className="pett20">
                <TextField
                    label="Código Proveedor"
                    required
                    value={supplierCode}
                    sx={{ m: 1.5, width: "95%" }}
                    onChange={(event) =>{
                        if (event.target.value.length <= 20) {
                            setSupplierCode(event.target.value)
                        }
                    }}
                    InputLabelProps={{ shrink: true }}
                ></TextField>
                </div>
                <div className="pett21">
                  <LocalizationProvider 
                    dateAdapter={AdapterDayjs}  
                  >
                    <DatePicker
                      sx={{ width: "95%"}}
                      label="Fecha Factura *"
                      required
                      value={invoiceDate}
                      onChange={(newValue) => setInvoiceDate(newValue)}
                    />
                  </LocalizationProvider>
                </div>
                <div className="pett22">
                  <TextField
                    label="Factor Moneda"
                    type="number"
                    value={currencyFactor}
                    sx={{ m: 1.5, width: "95%" }}
                    onChange={(event) =>{
                      setCurrencyFactor(event.target.value)  
                    }}
                    InputLabelProps={{ shrink: true }}
                ></TextField>
                </div>
            </div>
            <div className="pett-grid-7">
              <div className="pett23">
                <TextField
                  label="Número de Parte"
                  required
                  value={partNumber}
                  sx={{ m: 1.5, width: "95%" }}
                  onChange={(event) =>{
                      if (event.target.value.length <= 80) {
                          setPartNumber(event.target.value)
                      }
                  }}
                  InputLabelProps={{ shrink: true }}
                ></TextField>
              </div>
              <div className="pett24">
                <TextField
                  label="Fracción partida Pedimento"
                  required
                  value={tariffFractionEntrySummary}
                  sx={{ m: 1.5, width: "95%" }}
                  onChange={(event) =>{
                      if (event.target.value.length <= 10) {
                          setTariffFractionEntrySummary(event.target.value)
                      }
                  }}
                  InputLabelProps={{ shrink: true }}
                ></TextField>
              </div>
              <div className="pett25">
                <TextField
                  label="Tasa"
                  type="number"
                  value={rate}
                  sx={{ m: 1.5, width: "95%" }}
                  onChange={(event) =>{
                      if (event.target.value <= 0.99999) {
                          setRate(event.target.value)
                      }
                  }}
                  InputLabelProps={{ shrink: true }}
                ></TextField>
              </div>
              <div className="pett26">
                <TextField
                  select
                  label="Tipo Tasa"
                  value={rateType}
                  defaultValue={""}
                  InputLabelProps={{shrink: true}}
                  SelectProps={{native: true}}
                  sx={{ m: 1.5, width: "95%"}}
                  onChange={(event) => setRateType(event.target.value)}  
                  >
                    <option key={"999999999"} value={"9999999999"}>Selecciona Tipo Tasa</option>
                  {
                    datatasa?.map(item=>(
                      <option key={item.code} value={item?.code}>{item.code} - {item.label}</option>
                    ))  
                  }
                </TextField>
              </div>
            </div>
            <div className="pett-grid-8">
              <div className="pett27">
                <TextField
                  select
                  label="Unidad"
                  value={unit}
                  required
                  defaultValue={""}
                  InputLabelProps={{shrink: true}}
                  SelectProps={{native: true}}
                  sx={{ m: 1.5, width: "95%"}}
                  onChange={(event) => setUnit(event.target.value)}  
                  >
                    <option key={"999999999"} value={"9999999999"}>Selecciona Unidad</option>
                  {
                    dataunit?.map(item=>(
                      <option key={item.code} value={item?.code}>{item.description}</option>
                    ))  
                  }
                </TextField>
              </div>
              <div className="pett28">
                <TextField
                  label="Precio Unitario"
                  required
                  type="number"
                  value={unitPrice}
                  sx={{ m: 1.5, width: "95%" }}
                  onChange={(event) =>{
                    setUnitPrice(event.target.value)
                  }}
                  InputLabelProps={{ shrink: true }}
                ></TextField>
              </div>
              <div className="pett29">
                <TextField
                  label="Cantidad"
                  required
                  type="number"
                  value={quantity}
                  sx={{ m: 1.5, width: "95%" }}
                  onChange={(event) =>{
                    setQuantity(event.target.value)
                  }}
                  InputLabelProps={{ shrink: true }}
                ></TextField>
              </div>
              <div className="pett30">
                <TextField
                  select
                  label="Origen"
                  value={origin}
                  defaultValue={""}
                  InputLabelProps={{shrink: true}}
                  SelectProps={{native: true}}
                  sx={{ m: 1.5, width: "95%"}}
                  onChange={(event) => setOrigin(event.target.value)}  
                  >
                    <option key={"999999999"} value={"9999999999"}>Selecciona Origen</option>
                  {
                    datacountry?.map((item, index)=>(
                      <option key={index} value={item?.code}>{item.code}</option>
                    ))  
                  }
                </TextField>
              </div>
            </div>
            <div className="étt-grid-8b">
              <div className="pett32">
                <TextField
                  label="Nota Larga"
                  value={longNote}
                  sx={{ m: 1.5, width: "99%" }}
                  onChange={(event) =>{
                    if (event.target.value.length <= 500) {
                      setLongNote(event.target.value)
                    }
                  }}
                  InputLabelProps={{ shrink: true }}
                ></TextField>
              </div>
            </div>
            <div className="pett-grid-9">
              <div className="pett31">
                <TextField
                  select
                  label="Vendedor"
                  value={seller}
                  defaultValue={""}
                  InputLabelProps={{shrink: true}}
                  SelectProps={{native: true}}
                  sx={{ m: 1.5, width: "95%"}}
                  onChange={(event) => setSeller(event.target.value)}  
                  >
                    <option key={"999999999"} value={"9999999999"}>Selecciona Vendedor</option>
                  {
                    datacountry?.map((item, index)=>(
                      <option key={index} value={item?.code}>{item.code}</option>
                    ))  
                  }
                </TextField>
              </div>
              
              <div className="pett33">
                <TextField
                  select
                  label="Forma de Pago IGI"
                  value={igiPaymentMethod}
                  defaultValue={""}
                  InputLabelProps={{shrink: true}}
                  SelectProps={{native: true}}
                  sx={{ m: 1.5, width: "95%"}}
                  onChange={(event) => setIgiPaymentMethod(event.target.value)}  
                  >
                    <option key={"999999999"} value={"9999999999"}>Selecciona Forma Pago</option>
                  {
                    datapaymethod?.map((item, index)=>(
                      <option key={index} value={item?.code}>{item.value}</option>
                    ))  
                  }
                </TextField>
              </div>
              <div className="pett34">
                <TextField
                  select
                  label="Incoterm"
                  value={incoterm}
                  defaultValue={""}
                  InputLabelProps={{shrink: true}}
                  SelectProps={{native: true}}
                  sx={{ m: 1.5, width: "95%"}}
                  onChange={(event) => setIncoterm(event.target.value)}  
                  >
                    <option key={"999999999"} value={"9999999999"}>Selecciona Opción</option>
                  {
                    dataicoterm?.map((item, index)=>(
                      <option key={index} value={item?.code}>{item.value}</option>
                    ))  
                  }
                </TextField>
              </div>
              <div className="pett35">
                <TextField
                  select
                  label="Tratado"
                  value={treaty}
                  defaultValue={""}
                  InputLabelProps={{shrink: true}}
                  SelectProps={{native: true}}
                  sx={{ m: 1.5, width: "95%"}}
                  onChange={(event) => setTreaty(event.target.value)}  
                  >
                    <option key={"0"} value={"99999"}>Selecciona Tratado</option>
                    <option key={"1"} value={"AL"}>{"ALADI"}</option>
                    <option key={"2"} value={"TL"}>{"TLC"}</option>
                </TextField>
              </div>
            </div>
            <Box>
            <Button
              variant="contained"
              style={{"backgroundColor":"green"}}
              sx={{ m: 1.5, width: "45%" }}
              onClick={()=>window3()}
            >
              Siguiente
            </Button>
            <Button
              style={{"backgroundColor":"red"}}
              variant="contained"
              sx={{ m: 1.5, width: "45%" }}
              onClick={() => {
                  dialog.close();
                } 
              }
            >
            cancelar
          </Button>
          </Box>
          </div>:<></>
        }
        {
          op3 === true ? 
          <div className="window1-pett">
            <div className="pett-grid-10">  
              <div className="pett36">
                <TextField
                  select
                  label="Id. EB"
                  value={ebId}
                  defaultValue={""}
                  InputLabelProps={{shrink: true}}
                  SelectProps={{native: true}}
                  sx={{ m: 1.5, width: "95%"}}
                  onChange={(event) => setEbId(event.target.value)}  
                  >
                    <option key={"999999999"} value={"9999999999"}>Selecciona Opción</option>
                    <option key={"1"} value={"No"}>No</option>
                    <option key={"2"} value={"Si"}>Si</option>
                </TextField>
              </div>
              <div className="pett37">
                <TextField
                  select
                  label="En Consignación"
                  value={onConsignment}
                  defaultValue={""}
                  InputLabelProps={{shrink: true}}
                  SelectProps={{native: true}}
                  sx={{ m: 1.5, width: "95%"}}
                  onChange={(event) => setOnConsignment(event.target.value)}  
                  >
                    <option key={"999999999"} value={"9999999999"}>Selecciona Opción</option>
                    <option key={"1"} value={"No"}>No</option>
                    <option key={"2"} value={"Si"}>Si</option>
                </TextField>
              </div>
              <div className="pett38">
                <TextField
                  label="Nota 2 (Det)"
                  value={note2Detail}
                  sx={{ m: 1.5, width: "100%" }}
                  onChange={(event) =>{
                    if (event.target.value.length <= 250) {
                      setNote2Detail(event.target.value)
                    }
                  }}
                  InputLabelProps={{ shrink: true }}
                ></TextField>
              </div>
            </div>
            <div className="pett-grid-11">  
              <div className="pett39">
                <TextField
                  label="Cove"
                  value={cove}
                  sx={{ m: 1.5, width: "95%" }}
                  onChange={(event) =>{
                    if (event.target.value.length <= 30) {
                      setCove(event.target.value)
                    }
                  }}
                  InputLabelProps={{ shrink: true }}
                ></TextField>
              </div>
              <div className="pett40">
                <TextField
                  label="Multas"
                  value={fines}
                  type="number"
                  sx={{ m: 1.5, width: "95%" }}
                  onChange={(event) =>{
                    setFines(event.target.value)
                  }}
                  InputLabelProps={{ shrink: true }}
                ></TextField>
              </div>
              <div className="pett41">
                <TextField
                  label="Recargos"
                  value={surcharges}
                  type="number"
                  sx={{ m: 1.5, width: "100%" }}
                  onChange={(event) =>{
                    setSurcharges(event.target.value)
                  }}
                  InputLabelProps={{ shrink: true }}
                ></TextField>
              </div>
            </div>
            <div className="pett-grid-12">
              <div className="pett42">
                <TextField
                  label="Nota Interna 2 Pedimento (Encabezado)"
                  value={internalNote2EntrySummaryHeader}
                  type="number"
                  sx={{ m: 1.5, width: "100%" }}
                  onChange={(event) =>{
                    setInternalNote2EntrySummaryHeader(event.target.value)
                  }}
                  InputLabelProps={{ shrink: true }}
                ></TextField>
              </div>
            </div>
            <div className="pett-grid-13">
              <div className="pett43">
                <TextField
                  label="CNT"
                  value={cnt}
                  type="number"
                  sx={{ m: 1.5, width: "95%" }}
                  onChange={(event) =>{
                    setCnt(event.target.value)
                  }}
                  InputLabelProps={{ shrink: true }}
                ></TextField>
              </div>
              <div className="pett44">
                <TextField
                  label="Secuencia"
                  value={sequence}
                  required
                  type="number"
                  sx={{ m: 1.5, width: "95%" }}
                  onChange={(event) =>{
                    if (event.target.value > 0){
                      setSequence(event.target.value)
                    }
                  }}
                  InputLabelProps={{ shrink: true }}
                ></TextField>
              </div>
              <div className="pett45">
                <TextField
                  label="Factor de Conversión"
                  value={conversionFactor}
                  type="number"
                  sx={{ m: 1.5, width: "95%" }}
                  onChange={(event) =>{
                      setConversionFactor(event.target.value)
                  }}
                  InputLabelProps={{ shrink: true }}
                ></TextField>
              </div>
              <div className="pett46">
                <TextField
                  select
                  label="Unidad Tarifa"
                  value={tariffUnit}
                  defaultValue={""}
                  InputLabelProps={{shrink: true}}
                  SelectProps={{native: true}}
                  sx={{ m: 1.5, width: "95%"}}
                  onChange={(event) => setTariffUnit(event.target.value)}  
                  >
                    <option key={"99999"} value={"99999"}>Selecciona Unidad</option>
                  {
                    dataunit?.map((item, index)=>(
                      <option key={index} value={item.code}>{item.description}</option>
                    ))
                  }
                    
                    
                </TextField>
              </div>
            </div>
            <div className="pett-grid-14">
              <div className="pett47">
                <TextField
                  label="Cantidad Tarifa"
                  value={tariffQuantity}
                  type="number"
                  sx={{ m: 1.5, width: "95%" }}
                  onChange={(event) =>{
                    setTariffQuantity(event.target.value)
                  }}
                  InputLabelProps={{ shrink: true }}
                ></TextField>
              </div>
              <div className="pett48">
                <TextField
                  select
                  label="Forma de Pago IVA Partida"
                  value={vatPaymentMethodItem}
                  defaultValue={""}
                  InputLabelProps={{shrink: true}}
                  SelectProps={{native: true}}
                  sx={{ m: 1.5, width: "95%"}}
                  onChange={(event) => setVatPaymentMethodItem(event.target.value)}  
                  >
                    <option key={"999999999"} value={"9999999999"}>Selecciona Opción</option>
                  {
                    datapaymethod?.map(item=>(
                      <option key={item.code} value={item?.value}>{item.value}</option>
                    ))  
                  }
                </TextField>
              </div>
              <div className="pett49">
                <LocalizationProvider 
                    dateAdapter={AdapterDayjs}  
                >
                  <DatePicker
                    sx={{ width: "95%"}}
                    label="Fecha Entrada *"
                    required
                    value={entryDate}
                    onChange={(newValue) => setEntryDate(newValue)}
                  />
                </LocalizationProvider>
              </div>
              <div className="pett50">
                <TextField
                  select
                  label="Régimen"
                  value={regime}
                  defaultValue={""}
                  InputLabelProps={{shrink: true}}
                  SelectProps={{native: true}}
                  sx={{ m: 1.5, width: "100%"}}
                  onChange={(event) => setRegime(event.target.value)}  
                  >
                    <option key={"999999999"} value={"9999999999"}>Selecciona Régimen</option>
                  {
                    dataregimen?.map(item=>(
                      <option key={item.code} value={item?.value}>{item.value}</option>
                    ))  
                  }
                </TextField>
              </div>
            </div>
            <Box>
            <Button
              variant="contained"
              style={{"backgroundColor":"green"}}
              sx={{ m: 1.5, width: "45%" }}
              onClick={()=>window4()}
            >
             Siguiente
            </Button>

            <Button
              style={{"backgroundColor":"red"}}
              variant="contained"
              // disabled={!bandera}
              sx={{ m: 1.5, width: "45%" }}
              onClick={() => {
                  dialog.close();
                } 
              }
            >
            cancelar
          </Button>
          </Box>
          </div>:<></>
        }
        {
          op4 === true ? 
          <div className="window1-pett">
            <div className="pett-grid-15">
              <div className="pett51">
                <TextField
                  select
                  label="Tipo de Operación"
                  value={operationType}
                  defaultValue={""}
                  InputLabelProps={{shrink: true}}
                  SelectProps={{native: true}}
                  sx={{ m: 1.5, width: "95%"}}
                  onChange={(event) => setOperationType(event.target.value)}  
                  >
                    <option key={"999999999"} value={"9999999999"}>Selecciona Opción</option>
                    <option key={"1"} value={"IMP"}>IMP</option>
                    <option key={"2"} value={"EXP"}>EXP</option>
                </TextField>
              </div>  
              <div className="pett52">
                <TextField
                  select
                  label="Identificador PC"
                  value={pcIdentifier}
                  defaultValue={""}
                  InputLabelProps={{shrink: true}}
                  SelectProps={{native: true}}
                  sx={{ m: 1.5, width: "95%"}}
                  onChange={(event) => setPcIdentifier(event.target.value)}  
                  >
                    <option key={"999999999"} value={"9999999999"}>Selecciona Opción</option>
                    <option key={"1"} value={"No"}>No</option>
                    <option key={"2"} value={"Si"}>Si</option>
                </TextField>
              </div>  
              <div className="pett53">
                <TextField
                  select
                  label="Identificador IM"
                  value={imIdentifier}
                  defaultValue={""}
                  InputLabelProps={{shrink: true}}
                  SelectProps={{native: true}}
                  sx={{ m: 1.5, width: "95%"}}
                  onChange={(event) => setImIdentifier(event.target.value)}  
                  >
                    <option key={"999999999"} value={"9999999999"}>Selecciona Opción</option>
                    <option key={"1"} value={"No"}>No</option>
                    <option key={"2"} value={"Si"}>Si</option>
                </TextField>
              </div>  
              <div className="pett54">
                <TextField
                  label="IEPS"
                  value={ieps}
                  type="number"
                  sx={{ m: 1.5, width: "100%" }}
                  onChange={(event) =>{
                    setIeps(event.target.value)
                  }}
                  InputLabelProps={{ shrink: true }}
                ></TextField>
              </div>
            </div>
            <div className="pett-grid-16">
              <div className="pett55">
                <TextField
                  select
                  label="Forma de Pago IEPS"
                  value={iepsPaymentMethod}
                  defaultValue={""}
                  InputLabelProps={{shrink: true}}
                  SelectProps={{native: true}}
                  sx={{ m: 1.5, width: "95%"}}
                  onChange={(event) => setIepsPaymentMethod(event.target.value)}  
                  >
                    <option key={"999999999"} value={"9999999999"}>Selecciona Opción</option>
                  {
                    datapaymethod?.map(item=>(
                      <option key={item.code} value={item?.value}>{item.value}</option>
                    ))  
                  }
                    
                </TextField>

              </div>
              <div className="pett56">
                <TextField
                  label="IVA/PRV"
                  value={vatPrv}
                  type="number"
                  sx={{ m: 1.5, width: "95%" }}
                  onChange={(event) =>{
                    setVatPrv(event.target.value)
                  }}
                  InputLabelProps={{ shrink: true }}
                ></TextField>
              </div>
              <div className="pett57">
                <TextField
                 select
                 label="Moneda"
                 value={currency}
                 defaultValue={""}
                 InputLabelProps={{shrink: true}}
                 SelectProps={{native: true}}
                 sx={{ m: 1.5, width: "95%"}}
                 onChange={(event) => setCurrency(event.target.value)}  
                 >
                   <option key={"999999999"} value={"9999999999"}>Selecciona Moneda</option>
                 {
                   datamon?.map((item, index)=>(
                     <option key={item.index} value={item?.code}>{item.code} - {item.value}</option>
                   ))  
                 }
                </TextField>
              </div>
              <div className="pett58">
                <TextField
                 label="Valor Moneda Facturación"
                 value={billingCurrencyValue}
                 type="number"
                 sx={{ m: 1.5, width: "100%" }}
                 onChange={(event) =>{
                   setBillingCurrencyValue(event.target.value)
                 }}
                 InputLabelProps={{ shrink: true }}>
                </TextField>
              </div>
            </div>
            <div className="pett-grid-16">
              <div className="pett59">
                <TextField
                 label="Valor Dólares Pedimento"
                 required
                 value={dollarValue}
                 type="number"
                 sx={{ m: 1.5, width: "95%" }}
                 onChange={(event) =>{
                   setDollarValue(event.target.value)
                 }}
                 InputLabelProps={{ shrink: true }}>
                </TextField>
              </div>
              <div className="pett60">
                <TextField
                 label="IGI MN Pedimento"
                 value={igiLocalCurrencyEntrySummary}
                 type="number"
                 sx={{ m: 1.5, width: "95%" }}
                 onChange={(event) =>{
                   setIgiLocalCurrencyEntrySummary(event.target.value)
                 }}
                 InputLabelProps={{ shrink: true }}>
                </TextField>
              </div>
              <div className="pett61">
                <TextField
                 select
                 label="Identificador MS"
                 value={msIdentifier}
                 defaultValue={""}
                 InputLabelProps={{shrink: true}}
                 SelectProps={{native: true}}
                 sx={{ m: 1.5, width: "95%"}}
                 onChange={(event) => setMsIdentifier(event.target.value)}  
                 >
                  <option key={"999999999"} value={"9999999999"}>Selecciona Opción</option>
                  <option key={"1"} value={"No"}>No</option>
                  <option key={"2"} value={"Si"}>Si</option>
                </TextField>
              </div>
              <div className="pett62">
                <TextField
                 label="Decrementables Transporte"
                 value={decreasingTransport}
                 type="number"
                 sx={{ m: 1.5, width: "100%" }}
                 onChange={(event) =>{
                   setDecreasingTransport(event.target.value)
                 }}
                 InputLabelProps={{ shrink: true }}>
                </TextField>
              </div>
            </div>
            <div className="pett-grid-17">
              <div className="pett63">
                <TextField
                 label="Decrementables Seguro"
                 value={decreasingInsurance}
                 type="number"
                 sx={{ m: 1.5, width: "95%" }}
                 onChange={(event) =>{
                   setDecreasingInsurance(event.target.value)
                 }}
                 InputLabelProps={{ shrink: true }}>
                </TextField>
              </div>
              <div className="pett64">
                <TextField
                 label="Decrementables Carga"
                 value={load}
                 type="number"
                 sx={{ m: 1.5, width: "95%" }}
                 onChange={(event) =>{
                   setLoad(event.target.value)
                 }}
                 InputLabelProps={{ shrink: true }}>
                </TextField>
              </div>
              <div className="pett65">
                <TextField
                 label="Decrementables Descarga"
                 value={unload}
                 type="number"
                 sx={{ m: 1.5, width: "95%" }}
                 onChange={(event) =>{
                   setUnload(event.target.value)
                 }}
                 InputLabelProps={{ shrink: true }}>
                </TextField>
              </div>
              <div className="pett66">
                <TextField
                 label="Decrementables Otros"
                 value={otherDecrements}
                 type="number"
                 sx={{ m: 1.5, width: "100%" }}
                 onChange={(event) =>{
                   setOtherDecrements(event.target.value)
                 }}
                 InputLabelProps={{ shrink: true }}>
                </TextField>
              </div>
            </div>
            <div className="pett-grid-18">
              <div className="pett67">
                <TextField
                 label="Referencia"
                 value={reference}
                 sx={{ m: 1.5, width: "100%" }}
                 onChange={(event) =>{
                  if(event.target.value.length <= 30){
                    setReference(event.target.value)
                  } 
                 }}
                 InputLabelProps={{ shrink: true }}>
                </TextField>
              </div>  
            </div>
            <Box>
            <Button
              variant="contained"
              style={{"backgroundColor":"green"}}
              sx={{ m: 1.5, width: "45%" }}
              onClick={()=>{props.operation==="EDIT"?updateAccounting():create()}}
            >
             {props.operation==="EDIT"?"Actualizar":"Registrar"}
            </Button>

            <Button
              style={{"backgroundColor":"red"}}
              variant="contained"
              sx={{ m: 1.5, width: "45%" }}
              onClick={() => {
                  dialog.close();
                } 
              }
            >
            cancelar
          </Button>
          </Box>

          </div>:<></>
        }
        
          
      </div>
    </div>
  );
}
export default OPERATION;
