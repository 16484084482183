//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import { CustomDialog, useDialog, Confirm } from "react-st-modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

//COMPONENTES
import TableContainer from "../../../Table_Container_Tax";
import UPDATE_POLICY from "./tools/update_policy";
import SubTable from "../../detail_expense/datail";
import TableDetailGrupExpensePolicyDetail from "./detail_group_policy_conceptl";
import {
  get_expenses_groups_detail,
  post_expenses_groups_detail,
  delete_expenses_groups_detail,
} from "../../../../services/expenses/expenses";
import MODAL_TABLE from "../../../../main_components/modal/modal_Table";
import { refresh_token } from "../../../../main_components/tokens/tokenrefresh";
//ESTILOS
import "../../../expense.css";
//IMAGENES/ICONOS
import SAVE from "../../../../imgComponents/save.png";
import DELETE from "../../../../imgComponents/borrar.png";
import Spinner from "../../../../imgComponents/S.gif";
import CREATE from "../../../../imgComponents/create.png";
import ADD from "../../../../imgComponents/add.png";

import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../../../main_components/methods_v2/export";
import RENDER_ROW_ACTION_MENU_ITEMS_V2 from "./tools/render_row_action_menuItems_v2";
import TableDetailGrupExpensePolicyDetail_v2 from "./detail_group_policy_conceptl_v2";

const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const TableDetailGrupExpensePolicy_v2 = (props) => {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(true);
  const [menssage, setMenssage] = useState("");
  const [estadoAc, setestadoAc] = useState("");
  const [valorP, setvalorP] = useState(sessionStorage.getItem("totalPoliza"));
  const [idPolicy, setidPolicy] = useState("");
  const [codModificacble, setcodModificacble] = useState("");
  const [DetaiPlolicy, setDetaiPlolicy] = useState("none");
  const [isLoading, setIsLoading] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [reconsult, setreconsult] = useState(false);
  const [modalT, setmodalT]=useState(false);


  const handleSubmit = async (event) => {
    event?.preventDefault();

    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setOpen(true);
        setMenssage("Consultando grupo de gastos...");

        try {
          var dta = await get_expenses_groups_detail(
            sessionStorage.getItem("IdGrupExpense")
          );
          console.log(dta);
          setData(dta?.data);
          setidPolicy(dta.data[0].id);
          sessionStorage.setItem("idPolicy", dta?.data[0].id);
          setcodModificacble(dta?.data[0]?.modificable);
          console.log(dta?.data[0].id);
          setDetaiPlolicy("");
          setOpen(false);
          setMenssage("");
          setestadoAc("ocupado");
        } catch (err) {
          console.log("error", err);
          setData([]);
          setOpen(false);
          setDetaiPlolicy("none");
          setMenssage("");
          setestadoAc("vacio");
        }
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    handleSubmit();
  }, []);

  useEffect(() => {
    handleSubmit();
  }, [valorP]);

  const handle_create_policy = async () => {
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        try {
          setMenssage("Creando Poliza...");
          setOpen(true);
          setmodalT(true);

          const d = await post_expenses_groups_detail(
            {},
            sessionStorage.getItem("IdGrupExpense")
          );
          console.log(d)
          setOpen(false);
          setmodalT(false);
          handleSubmit();
          // window.location = "/expensesGrupDetail_v2";
        } catch (err) {
          setOpen(false);
          setmodalT(false);
          var error = err?.response?.data?.errorMessage;
          alert("Error.," + error);
        }
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handle_delete_expenses_groups_detail = async (id) => {
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        try {
          await delete_expenses_groups_detail(
            sessionStorage.getItem("IdGrupExpense"),
            id
          );
          setOpen(false);
          setMenssage("");
          handleSubmit();
          props?.handleSubmit();
        } catch (err) {
          setOpen(false);
          setMenssage("");
          alert("Error.," + err?.response?.data?.errorMessage);
        }
      } else {}
    } catch (err) {
      console.log(err);
    }
  };

  const columns = useMemo(
    () => [
      // {
      //   id: "CrearPoliza",
      //   header: ({ valor, row }) => {
      //     return (
      //       <img
      //         title="Crear Poliza"
      //         src={CREATE}
      //         alt=""
      //         onClick={() => handle_create_policy()}
      //       ></img>
      //     );
      //   },
      //   Cell: ({ valor, row }) => {
      //     return (
      //       <div>
      //         <img
      //           title="Inyectar"
      //           src={ADD}
      //           alt=""
      //           onClick={async () => {
      //             const result = await CustomDialog(
      //               <UPDATE_POLICY
      //                 codCocept={row.original.concept}
      //                 codType={row.original.type}
      //                 codId={row.original.id}
      //                 codAction={"INJECTION"}
      //               ></UPDATE_POLICY>,
      //               {
      //                 className: "modalTItle",
      //                 title: "Inyectar poliza",
      //                 showCloseIcon: true,
      //                 isCanClose: false,
      //               }
      //             );
      //             // eslint-disable-next-line no-lone-blocks
      //             {
      //               handleReconsulta(result);
      //             }
      //           }}
      //         ></img>
      //         <img
      //           title="Editar"
      //           alt=""
      //           src={SAVE}
      //           onClick={async () => {
      //             const result = await CustomDialog(
      //               <UPDATE_POLICY
      //                 codCocept={row.original.concept}
      //                 codType={row.original.type}
      //                 codId={row.original.id}
      //                 date={row.original.export_date}
      //                 codAction={"UPDATE"}
      //               ></UPDATE_POLICY>,
      //               {
      //                 className: "modalTItle",
      //                 title: "Editar poliza",
      //                 showCloseIcon: true,
      //                 isCanClose: false,
      //               }
      //             );
      //             // eslint-disable-next-line no-lone-blocks
      //             {
      //               handleReconsulta(result);
      //             }
      //           }}
      //         ></img>

      //         <img
      //           title="Eliminar Poliza"
      //           alt=""
      //           src={DELETE}
      //           className="centerText, taxes"
      //           onClick={async () => {
      //             const result = await Confirm(
      //               "¿Esta usted seguro de eliminar esta poliza?",
      //               "Eliminar Poliza",
      //               "Si",
      //               "No"
      //             );
      //             if (result) {
      //               handle_delete_expenses_groups_detail(row.original.id);
      //             } else {
      //             }
      //           }}
      //         ></img>
      //       </div>
      //     );
      //   },
      // },
      {
        id: "id",
        header: "No.",
        accessorKey: "id",
      },
      {
        id: "concept",
        header: "Concepto",
        accessorKey: "concept",
      },
      {
        id: "type",
        header: "Tipo",
        accessorKey: "type",
      },
      {
        id: "export_date",
        header: "Fecha",
        accessorKey: "export_date",
      },
      {
        id: "status",
        header: "Estado",
        accessorKey: "status",
      },
      {
        id: "Modificable",
        header: "Modificable",
        accessorKey: "modificable",
        accessorFn: (d) => {
          return d.modificable === true ? "✅" : "❌";
        },
      },
    ],
    []
  );

  const update_identifiers =
  [
    { id: 'string' },
    { concept: 'string' },
    { type: 'String' },
    { export_date: 'string' },
    { status: 'string' },
    { modificable: 'string' }
  ];

  return (
    <div >
      <MODAL_TABLE
        open={true}
        message={menssage}
        modalGeneral={false}
        modalT={modalT}
      ></MODAL_TABLE>

      <div>
        <h3>CABECERA DE LA POLIZA</h3>
      </div>

      <br></br>
      <div className="new_table_v2" style={{"width": "95%", "margin":"0 auto"}}>
      <MaterialReactTable
        columns={columns}
        data={data}
        enableGrouping
        enablePinning
        enableFacetedValues
        enableStickyHeader
        enableStickyFooter
        enableRowPinning
        enableColumnFilterModes
        enableRowActions
        enableRowSelection
        getRowId={(row) => row?.id}
        onRowSelectionChange={setRowSelection}
        enableTooltips={false}
        localization={MRT_Localization_ES}
        state={{ rowSelection, isLoading: isLoading }}
        enableColumnResizing
        enableColumnPinning
        enableColumnOrdering
        enableExpandAll={false}
        initialState={{ showGlobalFilter: true }}
        muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
        muiSkeletonProps={{ animation: 'pulse', height: 28 }}
        rowVirtualizerInstanceRef
        rowVirtualizerOptions={{ overscan: 5 }}
        columnVirtualizerOptions={{ overscan: 2 }}
        muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
        positionToolbarAlertBanner="bottom"
        paginationDisplayMode='pages'
        rowPinningDisplayMode='sticky'
        layoutMode="grid"
                muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

        muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
        muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
        displayColumnDefOptions={{
          'mrt-row-pin': {
            Header: () => (
              <div>
                 <img
                    style={{"cursor":"pointer"}}
                    title="Crear Poliza"
                    src={CREATE}
                    alt=""
                    onClick={() => handle_create_policy()}
                  ></img>
              </div>
            ),
            enableHiding: true,
          },
          'mrt-row-actions': {
            enableHiding: true,
          },
          'mrt-row-expand': {
            enableHiding: true,
          },
          'mrt-row-select': {
            enableHiding: true,
          }
        }}
         muiTableBodyRowDragHandleProps={({ table }) => ({
           onDragEnd: () => {
             const { draggingRow, hoveredRow } = table.getState();
             if (hoveredRow && draggingRow) {
               data.splice(
                 hoveredRow.index,
                 0,
                 data.splice(draggingRow.index, 1)[0],
               );
               setData([...data]);
             }
           },
        })}
        muiTableBodyRowProps={
          ({ row, table }) => {
            const { density } = table.getState();
            return {
              sx: {
                height: row.getIsPinned()
                  ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                  }px`
                  : undefined,
              },
            };
          }
        }
        renderRowActionMenuItems={({ row, closeMenu }) => [
            <RENDER_ROW_ACTION_MENU_ITEMS_V2
             closeMenu={closeMenu}
             setmodalT={setmodalT}
             setmodalGeneral={false}
             row={row}
             handleSubmit={handleSubmit}
            //  alertas={alertas}
           />
        ]}
        renderToolbarInternalActions={({ table }) => (
            <RENDER_TOOLBAR_INTERNAL_ACTIONS
             table={table} 
             file_name={"Reporte de Gastos Agrupados"}
             update_identifiers={update_identifiers}
            />
          )
        }
      />
      </div>
      <div style={{ display: DetaiPlolicy }}>
        <TableDetailGrupExpensePolicyDetail_v2
          idGrupExpense={idPolicy}
          codModificable={codModificacble}
        />
        </div>
    </div>
  );
};

export default TableDetailGrupExpensePolicy_v2;
