import React, { useState, useEffect, Suspense } from "react";
import axios from "axios";
import { alpha, styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Spinner from "../../../../imgComponents/S.gif";
import Button from "@mui/material/Button";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import EFECTO from "../../../../dataComponets/efect.json"
import { CustomDialog, useDialog, Confirm } from "react-st-modal";
import { create_expense_detail, update_expense_detail, get_accounting_accounts } from "../../../../services/expenses/expenses";
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
const style2 = { position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '100%', height: '100%', bgcolor: 'background.paper', border: '2px solid #000', boxShadow: 24, p: 4, };
import { Autocomplete, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import MODAL_TABLE from "../../../../main_components/modal/modal_Table";
const ValidationTextField = styled(TextField)({
  "& input:valid + fieldset": { borderColor: "green", borderWidth: 2 },
  "& input:valid:focus + fieldset": {
    borderLeftWidth: 6,
    padding: "4px !important",
  },
});


const CREATE_UPDATE_V2 = (props) => {
  const dialog = useDialog();
  const [open, setOpen] = React.useState(false);
  const [Concepto, setConcepto] = useState("");
  const [importe, setImporte] = React.useState(props.codamount);
  const [pro_servData, setpro_servData] = React.useState([]);
  const [efecto, setefecto] = React.useState(props.codeffect);
  const [id_concept, setid_concept] = useState(props?.codidConcept);
  const [concept_data, setconcept_data] = useState(props?.codconcept);
  const [concept_code, setconcept_code] = useState(props?.codaccounts);

  const handle_concept = (e, v) => {
    console.log(v)
    setid_concept(v === null ? null : v?.id);
    setconcept_data(v === null ? "" : v?.name);
    setconcept_code(v === null ? "" : v?.code);

  }


  console.log({
    detail: {
      id_account: id_concept,
      amount: Number(importe),
      efect: efecto
    }
  })

  const handleOnSearch = (string, results) => { };

  const handleOnHover = (result) => { };
  const handleOnSelect = (item) => {
    setConcepto(item);
    setid_concept(item.id);
    setconcept_data(item.name);
    setconcept_code(item.code);
  };

  const handleOnFocus = () => {
    console.log("Focused");
  };

  const handleOnClear = () => {
    console.log("Cleared");
  };

  const handelImporte = (event) => {
    setImporte(event?.target?.value);
  };


  const handelEfect = (event) => {
    setefecto(event?.target?.value);
  };


  const handelImportOnBurl = (event) => {
    setImporte(Number(importe).toFixed(2));
  };


  const handleSubmit = async () => {

    var data = {
      detail: {
        id_account: id_concept,
        amount: Number(importe),
        efect: efecto
      }
    };

    setOpen(true);
    try {
      if (props.method === "CREATE") {
        await create_expense_detail(props?.row?.id, data)
        setOpen(false);
        props?.handle_consult();
        dialog.close(true);
      } else {
        await update_expense_detail(props?.row?.id, data, props.id_detail)
        props?.handle_consult();
        setOpen(false);
        dialog.close(true);
      }
    } catch (err) {
      setOpen(false);
      alert("Error:" + JSON.stringify(err?.response?.data?.errorMessage));
    }

  };


  const handleprod_concept_list = async (event) => {
    try {
      var dt = await get_accounting_accounts(props.foreign);
      setpro_servData(dt?.data);
    } catch (error) {
    }
  };

  useEffect(() => {
    handleprod_concept_list();
  }, []);

  return (
    <div>

      <MODAL_TABLE open={true} message={""} modalT={open}></MODAL_TABLE>


      <form>

        <div style={{ display: origin === "FACTURAS" ? "none" : origin === "PROVEEDORES" ? "none" : "" }} className="expense_container_non">

          <Box sx={{ display: 'flex', gap: '5px', p: '2px', zIndex: "10", margin: "0 auto", width: "95%" }} >
            <FormControl sx={{ width: "100%" }} >
              <Autocomplete
                id="Concepto"
                options={pro_servData}
                getOptionLabel={(option) => `${option?.name_code}`}
                onChange={(event, newValue) => handle_concept(event, newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputLabelProps={{ shrink: true }}
                    label="Buscar Concepto"
                    placeholder={"Buscar Concepto"}
                  />
                )}
              />
            </FormControl>

          </Box>

          {/* 
            <div style={{ width: "95%", margin: "0 auto" }}>
              <ReactSearchAutocomplete
                items={pro_servData}
                fuseOptions={{ keys: ["id", "name_code"] }}
                resultStringKeyName={"name_code"}
                onSearch={handleOnSearch}
                onHover={handleOnHover}
                onSelect={handleOnSelect}
                onFocus={handleOnFocus}
                onClear={handleOnClear}
                showIcon={true}
                maxResults={30}
                placeholder="Buscar concepto..."
                styling={{
                  height: "34px",
                  border: "1px solid darkgreen",
                  borderRadius: "4px",
                  backgroundColor: "white",
                  boxShadow: "none",
                  hoverBackgroundColor: "lightgreen",
                  color: "black",
                  iconColor: "black",
                  lineColor: "black",
                  placeholderColor: "black",
                  clearIconMargin: "3px 8px 0 0",
                  zIndex: 10,
                }}
              />
            </div> */}


        </div>


        <Box sx={{ display: 'flex', gap: '5px', p: '2px', zIndex: "10", margin: "0 auto", width: "95%" }} >

          <ValidationTextField
            label="Concepto"
            variant="outlined"
            placeholder="Cuenta"
            sx={{ width: "50%" }}
            value={concept_data}
            InputLabelProps={{ shrink: true }}
          />
          <ValidationTextField
            label="Cuenta"
            variant="outlined"
            placeholder="Cuenta"
            sx={{ width: "50%" }}
            value={concept_code}
            InputLabelProps={{ shrink: true }}
          />

        </Box>

        <Box sx={{ display: 'flex', gap: '5px', p: '2px', zIndex: "10", margin: "0 auto", width: "95%", marginTop: '5px' }} >

          <TextField
            select
            label="Efecto"
            value={efecto}
            sx={{ width: "50%" }}
            onChange={handelEfect}
            size="small"
            name="taxes"
            InputLabelProps={{
              shrink: true,
            }}
            SelectProps={{
              native: true,
            }}
          >
            {EFECTO.map((option) => (
              <option
                key={option.value}
                value={option.value}
                selected={option.value}
              >
                {option.label}
              </option>
            ))}
          </TextField>

          <ValidationTextField
            label="Importe"
            variant="outlined"
            placeholder="Importe"
            type="number"
            sx={{ width: "50%" }}
            onChange={handelImporte}
            onBlur={handelImportOnBurl}
            value={importe}
            InputLabelProps={{ shrink: true }}
          />
        </Box>

        <Box sx={{ display: 'flex', gap: '5px', p: '2px', zIndex: "10", margin: "0 auto", width: "95%", marginTop: '5px' }} >
          <Button
            variant="contained"
            sx={{ width: "50%" }}
            className="ButtonModal"
            onClick={() => handleSubmit()}
          >
            {(props.method === "CREATE" ? "CREAR" : "ACTUALIZAR")}
          </Button>

          <Button
            variant="contained"
            sx={{ width: "50%" }}
            className="ButtonModal2"
            onClick={() => {
              dialog.close();
            }}
          >
            cancelar
          </Button>
        </Box>
        <br></br>
      </form>
    </div>
  );
};

export default CREATE_UPDATE_V2;
