import dayjs from 'dayjs';

const isoDateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;

export const update_null_identifiers = (original_obj, identifiers_to_update) => {
    return original_obj.map((item) => {
        for (const identifier in item) {
            if (item.hasOwnProperty(identifier)) {
                const isToUpdate = identifiers_to_update.some((update) => update[identifier] !== undefined);
                if (isToUpdate && item[identifier] === null) {
                    item[identifier] = typeof identifiers_to_update.find((update) => update[identifier]) === 'number' ? 0 : '';
                }
            }
        }
        return item;
    });
};





export const dateOnlyFilterFn = (row, columnId, filterValue) => {
    const isoDateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;

    console.log(filterValue);

    try {
        const filterDate = filterValue?.toISOString()?.split('T')[0];
        console.log(filterDate)
        const rowDate = row?.getValue(columnId)?.split(' ')[0];
        console.log(rowDate)
        return rowDate === filterDate;
    } catch (e) {
        console.log(e)
    }

};




// export const dateOnlyFilterFn = (row, columnId, filterValue) => {
//     console.log('Filter value:', filterValue);

//     // Verifica si filterValue es una cadena y cumple con el formato de fecha ISO
//     try {

//         if (typeof filterValue === 'string' && isoDateRegex.test(filterValue)) {
//             try {
//                 // Usa dayjs para manejar la cadena en formato ISO
//                 const filterDate = dayjs(filterValue);
//                 // Extrae la parte de la fecha en formato 'YYYY-MM-DD'
//                 const filterDateString = filterDate.format('YYYY-MM-DD');

//                 console.log('Filter date:', filterDateString);

//                 // Extrae la parte de la fecha de los datos de la fila y compara
//                 const rowValue = row.getValue(columnId);
//                 const rowDate = rowValue ? dayjs(rowValue).format('YYYY-MM-DD') : null; // Usa dayjs para formatear la fecha de la fila

//                 console.log('Row date:', rowDate);

//                 return rowDate === filterDateString;
//             } catch (e) {
//                 console.error(e);
//                 return false; // En caso de error, no filtra la fila
//             }
//         } else {
//             // Si filterValue no es una cadena que cumple con el formato, no aplica el filtro
//             return filterValue;
//         }
//     } catch (e) {

//         console.log(e)
//     }
// };
