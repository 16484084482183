
//PAQUETERIAS
import { useState, useMemo, useRef } from "react";
import { StickyTable} from "react-sticky-table";
import NotificationSystem from 'react-notification-system';
import { Bar,XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label, Brush, ReferenceLine, Line, ComposedChart } from 'recharts';
//COMPONENTES
import AlertResponse from "../../main_components/alerts/alertResponse";
import Modal_Table from "../../main_components/modal/modal_Table";
//ESTILOS
import "./min-fcst.css";
//IMAGENES/ICONOS
import Delete from "../../imgComponents/borrar.png";
import CREATE from "../../imgComponents/create.png";
import Edit from "../../imgComponents/save.png";
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";
import datamin from "./min.json";
import TableContainer from "../../main_components/Table/TableContainer";
import datafc from "./fcst.json";
import { SelectColumnFilter } from "../../componentsCFDI/filters";

export const MIN_FCST = () => {
  
    const notificationSystemRef = useRef();
    const [open, setopen]=useState(false);
    const [modalT, setmodalT] = useState(false);
    const [menssage, setmenssage] = useState("");
    const [modalGeneral, setmodalGeneral] = useState(false);
  
    const[g, setg]=useState(false);

      const columns = useMemo(
        () => [
          /*{
            Header:()=>(
              <div>
                <img
                  id="crearRol"
                  src={CREATE}
                  onClick={async () => {
                    const result = await CustomDialog(               
                   <div>
                   </div>,
                 {
                   className: "modalish",
                   title:"New Record",
                   showCloseIcon: true,
                   isCanClose:false,
                 }
             );
           }}
                  title="New Record"
                  className="cancel"
                ></img>
              </div>
            ),
            
            id: "Acciones",
            Cell: ({ valor, row }) => {
              return (
                <div>
                  <img
                    src={Edit}
                    onClick={async () => {
                        const result = await CustomDialog(               
                        <div>
                            
                        </div>,
                        {
                          className: "modalish",
                          title:"New Record",
                          showCloseIcon: true,
                          isCanClose:false,
                        }
                      );
                      }         
                      }
                    className="centerText, cancel"
                    title="Eliminar Rol"
                  ></img>
                </div>
              );
            },
          },*/
          {
            Header: "SAP ITEM",
            id: "SAP ITEM",
            accessor: "SAP ITEM"
          },
          {
            Header: "Suma de MQ 2023",
            id: "summq",
            accessor:"SumMQ2023"
          }
         
          
          ],
          []
      );     
      const columns2 = useMemo(
        () => [
          /*{
            Header:()=>(
              <div>
                <img
                  id="crearRol"
                  src={CREATE}
                  onClick={async () => {
                    const result = await CustomDialog(               
                   <div>
                   </div>,
                 {
                   className: "modalish",
                   title:"New Record",
                   showCloseIcon: true,
                   isCanClose:false,
                 }
             );
           }}
                  title="New Record"
                  className="cancel"
                ></img>
              </div>
            ),
            
            id: "Acciones",
            Cell: ({ valor, row }) => {
              return (
                <div>
                  <img
                    src={Edit}
                    onClick={async () => {
                        const result = await CustomDialog(               
                        <div>
                            
                        </div>,
                        {
                          className: "modalish",
                          title:"New Record",
                          showCloseIcon: true,
                          isCanClose:false,
                        }
                      );
                      }         
                      }
                    className="centerText, cancel"
                    title="Eliminar Rol"
                  ></img>
                </div>
              );
            },
          },*/
          {
            Header: "Supplier",
            id: "Supplier",
            accessor: "Supplier",
            Filter: SelectColumnFilter
          },
          {
            Header: "MOI",
            id: "MOI",
            accessor:"MOI"
          },
          {
            Header: "MIN",
            id: "MIN",
            accessor:"MIN"
          } 
          ],
          []
      );      

return (
    <>
     <BREADCRUMBS
        niveles={[
          { label: "MPS", path: "consult_mps" },
          { label: "Minimos y FCST", path: null}
        ]}
      ></BREADCRUMBS>
    
  <div className="">         
  <NotificationSystem  ref={notificationSystemRef} ></NotificationSystem>
  <Modal_Table
    open={true}
    message={menssage}
    modalGeneral={modalGeneral}
    modalT={modalT}
  ></Modal_Table>
    
    <div className="grid-sum">
    {
        g===false?<div className="table-min-m">
        <StickyTable>
        <TableContainer
            paginado={""}
            consultas={""}
            exportar={true}
            columns={columns}
            data={datamin}
            style={{ overflowx: 10 }}
            Gmodal={open}
        />
        </StickyTable>
        </div>:
        <div className="graph">

        <ResponsiveContainer width="100%" maxHeight={450} minHeight={500}>
        <ComposedChart
        data={datafc}
        margin={{
          top: 50,
          right: 50,
          left: 50,
          bottom: 10
        }}
      > 
    <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="Supplier"> 
            <Label value="Supplier" offset={-10} position="insideBottom"/>
        </XAxis>
        <YAxis dataKey="MIN"/>
        <Brush dataKey="Supplier" height={30} stroke="#8884d8" />  
        <Tooltip/>
        <Legend verticalAlign="top" height={50}/>
        <ReferenceLine y={0} stroke="#000" />         
        <Bar dataKey="" stackId="" name="" fill="#00CFC3"/>
      </ComposedChart>
      </ResponsiveContainer>
    </div>
    }
    
        <div className="table-min-fcst">
        <div className="table2">
         <StickyTable>
            {/* {
                g==false?
                    <button className="button-graph" onClick={()=>setg(true)}>Graph</button>:
                    <button className="button-graph color-cancel" onClick={()=>setg(false)}>Cancel</button> 
            }         */}
        <TableContainer
            paginado={""}
            consultas={""}
            exportar={true}
            columns={columns2}
            data={datafc}
            style={{ overflowx: 10 }}
            Gmodal={open}
        />
        </StickyTable>
            </div>
        
        </div>
    </div>
        

          </div>
    </>
    
  )
}
