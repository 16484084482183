//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
import { MaterialReactTable } from 'material-react-table';

//COMPONENTES
import { get_invoices_detail, get_invoices_detail_P } from "../../../../services/invoices/invoice";
import { get_invoices } from "../../../../services/invoices/invoice";
//ESTILOS
import "../../../estilo.css";
import "../../../modal.css";
import "../../../table.css";
import { refresh_token } from "../../../../main_components/tokens/tokenrefresh";
import "./detail_invoice.css";

function DETAIL_INVOICE_V2(props) {
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setdata3] = useState([]);
  const [p, setp] = useState(props.tipo.original.c_tipodecomprobante)


  console.log(p);

  const handle_get_invoice = async (event) => {

    event?.preventDefault();
    setData([]);
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            var dt = await get_invoices("", "", "", "", "", "", sessionStorage.getItem("dataSuntablefiltro"));
            setData2(dt.data);
          } catch (err) {
            setData([]);
          }
        }, 1000);
      } else { }
    } catch (err) {
      console.log(err);
    }
  };

  const handle_get_invoice_detail = async (event) => {

    event?.preventDefault();
    setData([]);
    setdata3([]);

    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {

            if (p === "P") {
              const dt = await get_invoices_detail_P(sessionStorage.getItem("dataSuntablefiltroID"));
              setData(dt?.data?.details);
              setdata3(dt?.data?.payment);
              console.log(dt?.data)
            } else {
              const dt = await get_invoices_detail(sessionStorage.getItem("dataSuntablefiltroID"));
              setData(dt?.data);
              console.log(dt?.data);
            }
          } catch (err) {
            setData([]);
          }
        }, 1000);
      } else { }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    handle_get_invoice();
    handle_get_invoice_detail();
  }, [props.reconsult]);

  console.log(data2[0]?.cancel);

  console.log(data2);


  const columnsp = useMemo(
    () => [
      {
        header: "Folio Factura",
        accessorKey: "folio"
      },
      {
        header: "UUID Factura",
        accessorKey: "uuid"
      },
      {
        header: "Monto",
        accessorFn: (d) => {
          return d.monto
            ? Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.monto)
            : Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.monto);
        },

      },
      {
        header: "Base IEPS",
        accessorFn: (d) => {
          return d.baseIEPS8
            ? Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.baseIEPS8)
            : Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.baseIEPS8);
        },
      },
      {
        header: "Base Iva",
        accessorFn: (d) => {
          return d.baseIVA0
            ? Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.baseIVA0)
            : Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.baseIVA0);
        },
      }
    ], []
  )



  const columns = useMemo(
    () => [
      {
        header: "Cantidad",
        accessorKey: "quantity",
      },
      {
        header: "Unidad",
        accessorKey: "unity",
      },
      {
        header: "Descripción",
        accessorKey: "description",
      },
      {
        header: "Descuento",
        accessorFn: (d) => {
          return d.discount
            ? Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.discount)
            : Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.discount);
        },
      },
      {
        header: "Precio Unitario",
        accessorFn: (d) => {
          return d.unitary_price
            ? Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.unitary_price)
            : Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.unitary_price);
        },
      },

      {
        header: "Total",
        accessorFn: (d) => {
          return d.total
            ? Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.total)
            : Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.total);
        },
      },
    ],
    []
  );

  const columnsCancel = useMemo(
    () => [
      {
        header: "Estado",
        accessorKey: "status",
      },
      {
        header: "¿Es cancelable?",
        accessorKey: "its_cancelable",
      },
      {
        header: "Estado de cancelación",
        accessorKey: "cancelation_status",
      },
    ],
    []
  );



  return <MaterialReactTable
    columns= { data2[0]?.cancel === true ? columnsCancel : p === "P" ? columnsp : columns }
    data= { data2[0]?.cancel === true ? data2 : data }
    enableColumnActions={false}
    enableColumnFilters
    enablePagination
    enableSorting
    enableBottomToolbar={false}
    enableTopToolbar={false}
    muiTableBodyRowProps={{ hover: false }}
  />;
}

export default DETAIL_INVOICE_V2;
