//PAQUETERIAS
import React, { useEffect, useState, useMemo, useRef } from "react";
import axios from "axios";
import { CustomDialog, useDialog, Confirm, Alert } from "react-st-modal";
import LoadingScreen from "react-loading-screen";
import NotificationSystem from "react-notification-system";
import { Table, Row, Col, Button, Input, CustomInput } from "reactstrap";

//COMPONENTES
import { SelectColumnFilter } from "../componentsCFDI/filters";
import TableContainer from "./TableContainerBusnes";
import SubTable from "./subTable";
import Set_bussines_p from "../form_business_partner/set_bussines_p";
import Update_bussines_p from "../form_business_partner/update_bussines_p.jsx.jsx";
import UpdateBP from "./UpdateBP";
import Modal_Table from "../main_components/modal/modal_Table";
import AlertResponse from "../main_components/alerts/alertResponse";
//ESTILOS
import "../componentsCFDI/estilo.css";
import "./tableclientes.css";
import "../componentsCFDI/modal.css";
//IMAGENES/ICONOS
import SAVE from "../imgComponents/lupa.png";
import DELETE from "../imgComponents/borrar.png";
import Spinner from "../imgComponents/S.gif";
import CREATE from "../imgComponents/create.png";
import CODEQR from "../imgComponents/codigoqr.png";
import SUCCESSFULLY from "../imgComponents/successfully.png";
import DemoJson from "../dataComponets/demo.json";
import { Style } from "@material-ui/icons";
import VER from "../imgComponents/visualizar.png";
import ADD from "../imgComponents/add.png";
import PDF from "../imgComponents/pdf.png";
import AnualSales from "./AnualSales";
import { Graphics } from "./Graphics";
import "./graphic.css";

var style = {
  NotificationItem: {
    // Override the notification item
    DefaultStyle: {
      // Applied to every notification, regardless of the notification level
      margin: "10px 5px 2px 1px",
    },

    success: {
      // Applied only to the success notification item
      color: "red",
    },
  },
};

const ProjectList = () => {
  const [mensajeDescarga, setmensajeDescarga] = useState("");
  console.log(DemoJson);

  const [data, setData] = useState([]);
  const [cancel, setCancel] = useState(null);
  const [isLoding, setIsLoding] = useState(true);
  const [menssage, setmenssage] = useState("Consultando socios de negocios...");
  const [modalGeneral, setmodalGeneral] = useState(true);
  const [modalT, setmodalT] = useState(true);
  const notificationSystemRef = useRef();
  const [menssageAler, setmenssageAler] = useState("");

  const handleSubmit = (msjAler) => {
    setmodalT(true);
    setData(DemoJson);
    setIsLoding(true);
    setmodalT(false);

    setmodalGeneral(false);
  };

  useEffect(() => {
    handleSubmit();
  }, []);

  const handleReconsulta = (reconsultar, msjAler) => {
    if (reconsultar === true) {
      handleSubmit(msjAler);
    } else {
      console.log("no se reconsulto");
    }
  };
  

  const columns = useMemo(
    () => [
       {
         id: "expander",
         Header: "Acciones",
         Cell: ({ row }) => {
           
           return (
            <img
              title="Summary"
              src={SAVE}
              onClick={async () => {
                const result = await CustomDialog(<AnualSales></AnualSales>, {
                  className: "modalDemo",
                  title: "Summary",
                  showCloseIcon: true,
                  isCanClose: false,
                });
                {
                  handleReconsulta(result,"Summary");
                }
              }}
            ></img>
           );
         },
       },
      {
        Header: " ",
        columns: [
          {
            Header: "Wherehose",
            accessor: "warehouse",
            Filter: SelectColumnFilter,
            id: "algonmms",
          },
          {
            Header: "#",
            accessor: "id",
          },
          {
            Header: "Resp",
            Filter: SelectColumnFilter,

            accessor: "resp",
          },
          {
            Header: "CRM",
            accessor: "CRM",
          },
          {
            Header: "Closing Chance",
            Filter: SelectColumnFilter,
            accessor: "closing_chance",
            filter: "equals",
            // Cell: (d) => {
            //   return <div>{}</div>;
            // },
          },
          {
            Header: "Customer",
            Filter: SelectColumnFilter,
            accessor: "customer",
          },
          {
            Header: "MPG",
            accessor: "MPG",
          },
          {
            Header: "Reference",
            accessor: "reference",
          },
          {
            Header: "Application",
            accessor: "application",
          },
          {
            Header: "Carry Over",
            accessor: "carry_over",
          },
          {
            Header: "Sector",
            accessor: "sector",
          },
          {
            Header: "Production Brand",
            accessor: "production_brand",
          },
          {
            Header: "Name Plate",
            accessor: "name_plate",
          },
          {
            Header: "Country",
            accessor: "country",
          },
          {
            Header: "Program",
            accessor: "program",
          },
          {
            Header: "Platfrom",
            accessor: "platform",
          },
        ],
      },
      {
        Header: "Annual Sales",
        columns: [
          {
            Header: "2023",
            id: "anualsales2023",
            columns: [
              {
                Header: "Margin",
                accessor: (d) => {
                  return <div>{d?.annual_sales.year_2023.margin}</div>;
                },
              },
              {
                Header: "Sales",
                accessor: (d) => {
                  return <div>{d?.annual_sales.year_2023.sales}</div>;
                },
              },
              {
                Header: "%",
                accessor: (d) => {
                  return (
                    <div>{d?.annual_sales.year_2023.sales_porcentaje}</div>
                  );
                },
              },
              // otras columnas aquí, si las hay
            ],
          },
          {
            Header: "2024",
            columns: [
              {
                Header: "Margin ",
                accessor: (d) => {
                  return <div>{d?.annual_sales.year_2024.margin}</div>;
                },
              },
              {
                Header: "Sales ",
                accessor: (d) => {
                  return <div>{d?.annual_sales.year_2024.sales}</div>;
                },
              },
              {
                Header: "% ",
                accessor: (d) => {
                  return (
                    <div>{d?.annual_sales.year_2024.sales_porcentaje}</div>
                  );
                },
              },
              // otras columnas aquí, si las hay
            ],
          },
          {
            Header: "2025",
            columns: [
              {
                Header: " Margin ",
                accessor: (d) => {
                  return <div>{d?.annual_sales.year_2024.margin}</div>;
                },
              },
              {
                Header: " Sales ",
                accessor: (d) => {
                  return <div>{d?.annual_sales.year_2025.sales}</div>;
                },
              },
              {
                Header: " % ",
                accessor: (d) => {
                  return (
                    <div>{d?.annual_sales.year_2025.sales_porcentaje}</div>
                  );
                },
              },
              // otras columnas aquí, si las hay
            ],
          },
        ],
      },

      {
        Header: "Blended Sales",
        columns: [
          {
            Header: "2023",
            columns: [
              {
                Header: " Margin",
                accessor: (d) => {
                  return <div>{d?.blended_sales.year_2023.margin}</div>;
                },
              },
              {
                Header: " Sales",
                accessor: (d) => {
                  return <div>{d?.blended_sales.year_2023.sales}</div>;
                },
              },
              {
                Header: " %",
                accessor: (d) => {
                  return (
                    <div>{d?.blended_sales.year_2023.sales_porcentaje}</div>
                  );
                },
              },
              // otras columnas aquí, si las hay
            ],
          },
          {
            Header: "2024",
            columns: [
              {
                Header: " Margin  ",
                accessor: (d) => {
                  return <div>{d?.blended_sales.year_2024.margin}</div>;
                },
              },
              {
                Header: " Sales  ",
                accessor: (d) => {
                  return <div>{d?.blended_sales.year_2024.sales}</div>;
                },
              },
              {
                Header: " %  ",
                accessor: (d) => {
                  return (
                    <div>{d?.blended_sales.year_2024.sales_porcentaje}</div>
                  );
                },
              },
              // otras columnas aquí, si las hay
            ],
          },
          {
            Header: "2025",
            columns: [
              {
                Header: "  Margin  ",
                accessor: (d) => {
                  return <div>{d?.blended_sales.year_2024.margin}</div>;
                },
              },
              {
                Header: "  Sales  ",
                accessor: (d) => {
                  return <div>{d?.blended_sales.year_2025.sales}</div>;
                },
              },
              {
                Header: "  %  ",
                accessor: (d) => {
                  return (
                    <div>{d?.blended_sales.year_2025.sales_porcentaje}</div>
                  );
                },
              },
              // otras columnas aquí, si las hay
            ],
          },
        ],
      },

      {
        Header: "BOM",
        columns: [
          {
            Header: " ",
            columns: [
              {
                Header: "  ",
                accessor: "DOM",
                Filter: SelectColumnFilter,
              },
            ],
          },
        ],
      },

      {
        Header: "Vehicles/Years",
        columns: [
          {
            Header: "[K]",
            columns: [
              {
                Header: "2023",
                accessor: (d) => {
                  return <div>{d?.vehicles_year.year_2023.vy}</div>;
                },
              },
              {
                Header: "2024",
                accessor: (d) => {
                  return <div>{d?.vehicles_year.year_2023.vy}</div>;
                },
              },
              {
                Header: "2025",
                accessor: (d) => {
                  return <div>{d?.vehicles_year.year_2023.vy}</div>;
                },
              },
              // otras columnas aquí, si las hay
            ],
          },
        ],
      },

      {
        Header: "Pces/Year",
        columns: [
          {
            Header: "[K] ",
            columns: [
              {
                Header: "2023 ",
                accessor: (d) => {
                  return <div>{d?.pieces_year.year_2023.pieces}</div>;
                },
              },
              {
                Header: "2024 ",
                accessor: (d) => {
                  return <div>{d?.pieces_year.year_2023.pieces}</div>;
                },
              },
              {
                Header: "2025 ",
                accessor: (d) => {
                  return <div>{d?.pieces_year.year_2023.pieces}</div>;
                },
              },
              // otras columnas aquí, si las hay
            ],
          },
        ],
      },

      {
        Header: "BIP",
        columns: [
          {
            Header: " ",
            columns: [
              {
                Header: "  ",
                accessor: "BIP",
                Filter: SelectColumnFilter,
              },
            ],
          },
        ],
      },

      {
        Header: "SUPP",
        columns: [
          {
            Header: "  ",
            columns: [
              {
                Header: " ",
                accessor: "Supp",
                Filter: SelectColumnFilter,
              },
            ],
          },
        ],
      },

      {
        Header: "I_F",
        columns: [
          {
            Header: "   ",
            columns: [
              {
                Header: "    ",
                accessor: "I_F",
                Filter: SelectColumnFilter,
              },
            ],
          },
        ],
      },

      {
        Header: "SELL/PRICE",
        columns: [
          {
            Header: "[USD/K]",
            columns: [
              {
                Header: " 2023",
                accessor: (d) => {
                  return <div>{d?.sell_price.year_2023.sp}</div>;
                },
              },
              {
                Header: " 2024",
                accessor: (d) => {
                  return <div>{d?.sell_price.year_2023.sp}</div>;
                },
              },
              {
                Header: " 2025",
                accessor: (d) => {
                  return <div>{d?.sell_price.year_2023.sp}</div>;
                },
              },
              // otras columnas aquí, si las hay
            ],
          },
        ],
      },
    ],
    []
  );

  const dtafilter = data
    .filter((a) => a.warehouse === "FAT")
    .filter((e) => e.closing_chance === "100%");

  const sumsales = dtafilter
    .filter((sale) => !isNaN(parseFloat(sale.annual_sales.year_2023.sales)))
    .map((sale) => parseFloat(sale.annual_sales.year_2023.sales))
    .reduce((previous, current) => previous + current, 0);

  console.log(sumsales);

  
  const [graphic, setgraphic]=useState(false);
  const [tableview, settableview]=useState(true);

  const handlegrafica=()=>{
    settableview(false);
    setgraphic(true);
  }
  const handletabla=()=>{
    settableview(true);
    setgraphic(false);
  }

  return (
    <div>
      <NotificationSystem ref={notificationSystemRef}></NotificationSystem>
      <Modal_Table
        open={true}
        message={menssage}
        modalGeneral={modalGeneral}
        modalT={modalT}
      ></Modal_Table>
      <div className="">
        <div className="">
        <div className="menu-superior-graphic">
            <div className="grid-button1">
                <button className="button" onClick={handletabla}>Tabla</button>
            </div>
            <div className="grid-button2">
                <button className="button" onClick={handlegrafica}>Gráfica</button>
            </div>
          </div>

          <div style={graphic===true?{"display":"block"}:{"display":"none"}}>
            <Graphics data={data}></Graphics>
          </div>

          <div className="TableConsulDemo" style={tableview===true?{"display":"block"}:{"display":"none"}}>
            <TableContainer
              exportEx={true}
              columns={columns}
              data={data}
              anualSale={true}
              Gmodal={modalT}
              style={{ overflowx: 10 }}
              renderRowSubComponent={(row) => {
                return (
                  <div style={{ padding: "20px" }}>
                    <SubTable />
                  </div>
                );
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectList;
