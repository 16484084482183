import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useDialog } from "react-st-modal";
import { refresh_token } from "../../main_components/tokens/tokenrefresh";
import { ReactSearchAutocomplete } from "react-search-autocomplete";

function CreateConceptC(props) {
  const dialog = useDialog();
  const [nameC, setnameC] = useState(null);
  const [cuenta, setcuenta] = useState(null);
  const [enable, setenable] = useState(true);
  const [bandera, setbandera] = useState(true);
  const [datacuentas, setdatacuentas] = useState([]);

  const [vnamec, setvnamec] = useState(false);
  const [vcuenta, setvcuenta] = useState(false);
  const URL_BASE = "https://7t0suvusg7.execute-api.us-east-1.amazonaws.com";

  useEffect(() => {
    refresh_token();
    handlecuentas();
  }, []);

  const handlecuentas = () => {
    setTimeout(() => {
      axios
        .get(`${URL_BASE}/od/accounting-accounts`, {
          headers: {
            Authorization: sessionStorage.getItem("getIdTokenRefresh"),
            "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log(response.data);
          const data = response.data;
          setdatacuentas(data);
        })
        .catch((err) => {
          console.log("error", err);
        });
    }, 2000);
  };

  //crear un nuevo concepto en el catalogo
  function createnewdiscount() {
    if (nameC === null || cuenta === null || cuenta === 0) {
      props.alertas("Parámetros faltantes", false);

      nameC === null ? setvnamec(true) : setvnamec(false);
      cuenta === null || cuenta === 0 ? setvcuenta(true) : setvcuenta(false);
    } else {
      props.reconsultar(true);

      setbandera(false);
      refresh_token();

      setTimeout(() => {
        var data = {
          enable: enable, //boolean = Habilitado | Deshabilitado
          concept: nameC, //String = Concepto0
          account: cuenta, //String = Cuenta
        };
        var url = `${URL_BASE}/od/expense-concepts/${sessionStorage.getItem(
          "id_catalogo"
        )}/expense-concepts-list`;
        var axiosConfig = {
          headers: {
            Authorization: sessionStorage.getItem("getIdTokenRefresh"),
            "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
            "Content-Type": "application/json",
          },
        };
        
        axios
          .post(url, data, axiosConfig)
          .then((response) => {
            console.log(response);
            props.reconsultar(false);
            props.alertas(
              "Concepto agregado con éxito al catálogo de Gastos",
              true
            );
            dialog.close();
            props.handleSubmit();
          })
          .catch((err) => {
            console.log("error post", err);

            if (err.response.status == 409) {
              props.alertas("El concepto ya existe en el catálogo", false);
            } else {
              props.alertas("No se pudo agregar el concepto", false);
            }
            setbandera(true);
            props.reconsultar(false);
          });
      }, 2000);
    }
  }

  const handleOnSearch = (string, results) => {
    setcuenta(0);
  };
  const handleOnHover = (result) => {};
  const handleOnSelect5 = (item) => {
    setcuenta(item.code);
    setvcuenta(false);
  };
  const handleOnFocus5 = () => {
    console.log("Focused");
    setvcuenta(false);
  };
  const handleOnClear = () => {
    setcuenta(0);
  };

  function Spinner() {
    return (
      <div
        className="spinner-border spinner-border-sm text-light"
        role="status"
      >
        <span className="sr-only">Cargando...</span>
      </div>
    );
  }

  return (
    <div>
      <div className="addpointsalecss">
        <div className="gridcatalogogastos">
          <div className="catalogo1">
            <TextField
              label="Nombre del Concepto"
              required
              sx={{ m: 1.5, width: "100%" }}
              value={nameC}
              disabled={!bandera}
              onChange={(event) => setnameC(event.target.value.toUpperCase())}
              InputLabelProps={{ shrink: true }}
              style={
                vnamec == true
                  ? { border: "2px solid red", "border-radius": "5px" }
                  : {}
              }
              onSelect={(event) => setvnamec(false)}
              onBlur={
                nameC == null || nameC == ""
                  ? (event) => setvnamec(true)
                  : (event) => setvnamec(false)
              }
            ></TextField>
            <p
              className="vericorreo"
              style={
                vnamec == true
                  ? { display: "block", textAlign: "center" }
                  : { display: "none" }
              }
            >
              Completa este campo{" "}
            </p>
          </div>
        </div>
        <div className="gridcatalogogastos2">
          <div className="catalogo2">
            <TextField
              select
              label="Habilitado"
              disabled={!bandera}
              value={enable}
              InputLabelProps={{ shrink: true }}
              SelectProps={{ native: true }}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setenable(!enable)}
            >
              <option key={1} value={true} selected>
                {"SI"}
              </option>
              <option key={2} value={false}>
                {"NO"}
              </option>
            </TextField>
          </div>
          <div className="catalogo3b">
            <ReactSearchAutocomplete
              items={datacuentas}
              fuseOptions={{ keys: ["code", "name"] }}
              resultStringKeyName="name_code"
              onSearch={handleOnSearch}
              onHover={handleOnHover}
              onSelect={handleOnSelect5}
              onFocus={handleOnFocus5}
              onClear={handleOnClear}
              maxResults={30}
              onBlur={
                cuenta == null || cuenta == 0
                  ? (event) => setvcuenta(true)
                  : (event) => setvcuenta(false)
              }
              styling={{
                border: "1px solid gray",
                borderRadius: "4px",
                color: "black",
                iconColor: "black",
                lineColor: "black",
                placeholderColor: "black",
                hoverBackgroundColor: "lightgreen",
                zIndex: 10,
              }}
              showIcon={true}
              placeholder="Buscar Cuenta"
            />
            <p
              className="vericorreo"
              style={
                vcuenta == true
                  ? {
                      display: "block",
                      paddingTop: "20px",
                      textAlign: "center",
                    }
                  : { display: "none" }
              }
            >
              Completa este campo{" "}
            </p>
          </div>
        </div>

        <br></br>
        <br></br>
        <Box>
          {bandera == true ? (
            <Button
              variant="contained"
              style={{ "background-color": "green" }}
              sx={{ m: 1.5, width: "45ch" }}
              onClick={createnewdiscount}
            >
              Agregar Concepto
            </Button>
          ) : (
            <Button
              variant="contained"
              disabled
              style={{ "background-color": "green", height: "35px" }}
              sx={{ m: 1.5, width: "45ch" }}
            >
              <Spinner></Spinner>
            </Button>
          )}
          <Button
            style={{ "background-color": "red" }}
            variant="contained"
            sx={{ m: 1.5, width: "20ch" }}
            disabled={!bandera}
            onClick={() => {
              dialog.close();
            }}
          >
            cancelar
          </Button>
        </Box>
      </div>
    </div>
  );
}

export default CreateConceptC;
