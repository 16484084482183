import { useEffect, useState } from "react";
import { createSupport, updateSupport, uploadimgticket } from "../../services/supports/supports";
import {
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  Typography,
  Icon,
  Backdrop,
  CircularProgress,
  FormControlLabel,
} from '@material-ui/core';
import * as h from "./helpers";
import './createcase.css';
import { BorderColor, Home } from '@material-ui/icons';
import {  SettingsSuggest, WarningAmber } from '@mui/icons-material';
import { useDialog } from "react-st-modal";
import { FormHelperText } from "@mui/material";
import { label } from "aws-amplify";
import { refresh_token } from "../../main_components/tokens/tokenrefresh";
import cancel from "../../imgComponents/borrar.png";
import { TELEGRAM } from "../telegram/telegram";
import axios from "axios";

const SupportCaseModal = (props) => {
  const dialog = useDialog();
  const [step, setStep] = useState(1);
  const [subject, setSubject] = useState('');
  const [description, setDescription] = useState('');
  const [datavalid, setDatavalid] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const [module, setmodule] = useState(0)
  const [modulo, setmodulo] = useState([]);
  const severidad = h.severidad;
  const modulos = h.modulos;
  const [categories, setcategories] = useState([]);
  const [category, setcategory] = useState("")
  const [severity, setseverity] = useState("")
  const [check1, setCheck1] = useState(false);
  const [check2, setCheck2] = useState(false);
  const [isAnyChecked, setIsAnyChecked] = useState(false);
  const [intentcontinue, setIntentcontinue] = useState(false);
  const [datos, setdatos] = useState([])
  const [intentfinalize, setIntentFinalize] = useState(false);
  const [mensaje, setmensaje]=useState("");
  const [selectedImages, setSelectedImages] = useState([]);


  const handleSubjectChange = (event) => {
    setSubject(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleNext = () => {
    if (step === 1) {
      setStep(2);
    }
  };

  const handleBack = () => {
    if (step === 2) {
      setStep(1);
    }
  };



  const handleCheck1Change = () => {
    setCheck1(!check1);
    setCheck2(false);
    setIsAnyChecked(!check1);
  };

  const handleCheck2Change = () => {
    setCheck1(false);
    setCheck2(!check2);
    setIsAnyChecked(!check2);
  };
 
  const handleClose = async () => {
      props.onClose()
      setCheck1(false)
      setCheck2(false)
      setIntentcontinue(false)
      setIsAnyChecked(false)
      setmodule(0)
      setStep(1)
      setSubject("")
      setDescription("")
      setDatavalid(false)
      setIntentFinalize(false)
      dialog.close();
    };

    const handlejira = async(folio, data)=>{
        
      var today = new Date();
      var year = today.getFullYear();
      var month = String(today.getMonth() + 1).padStart(2, '0');
      var day = String(today.getDate()).padStart(2, '0');
      var hours = String(today.getHours()).padStart(2, '0');
      var minutes = String(today.getMinutes()).padStart(2, '0');
      var seconds = String(today.getSeconds()).padStart(2, '0');
      var formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    
    
      var data ={
          folio: folio,
          asunto: data?.subject,
          description: data?.description,
          fecha : formattedDate,
          modulo: data?.module,
          submodulo: data?.submodule,
          type: data?.type,
          severity: data?.severity,
          property: localStorage.getItem("user_name"),
          company: "BOL"
      }
       const url = "https://tavmr1hck9.execute-api.us-east-1.amazonaws.com/prod/jira";
       try {
         const response = await axios.post(url, data, {
            headers: {
              // Authorization: sessionStorage.getItem("getIdTokenRefresh"),
              // "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
              "Content-Type": "application/json",
            },
          });
         return Promise.resolve(response);
       } catch (error) {
         return Promise.reject(error);
       }
    };

const handleModule = (event) => {
  setmodule(parseInt(event.target.value));
  setmodulo(event.target.value)
};

const handleCategory = (event) => {
  setcategory( event.target.value )
};


const handleCreate = async () => {

  setIntentFinalize(true)
  setIsloading(true)
  setmensaje("Creando nuevo caso...");
  if(datavalid){
        let response = []
      try {
          response = await createSupport(datos);
          if(response.status === 200 || response.status === 201){

            if(selectedImages?.length===0){
              props.alertas("Su ticket de soporte fue registrado exitosamente y se le dará atención inmediata", true);
              setmensaje(`Se ha registrado su ticket con folio: ${response.data.folio}`);
              await TELEGRAM(response?.data?.folio, "", datos?.subject, datos?.description,  "create");
              handlejira(response?.data?.folio, datos);
              setTimeout(() => {
                setIsloading(false)
                dialog.close()
                handleClose()
              }, 3000);
            }else{
              subirimg(response.data.ticket, response.data.folio);
            }
           }
          else {
            props.alertas("Ocurrió un error al registrar su ticket, por favor inicie sesión nuevamente", false);
          }
          
      } catch (err) {
        console.log("Error al intentar crear")
        props.alertas("Ocurrió un error al registrar su ticket, por favor inicie sesión nuevamente", false);
        setIsloading(false);
    } 
  } 
  else {
    setIsloading(false)
  }
};



const subirimg = async(ticket, folio)=>{

  setmensaje("Subiendo imágenes del caso...");
  setIntentFinalize(true);
  setIsloading(true);
  try {
    // Crear un array de promesas usando map
    const promises = selectedImages.map(async (item) => {
      const d = await handlepostimg(ticket, item.name, item.base64);
      return d;
    });
    const results = await Promise.all(promises);
    updateticketimg(ticket, results, folio);

  } catch (error) {
    props.alertas("Su ticket se registro correctamente, pero hubo error al subir las imagenes", false);
    setIntentFinalize(false)
    setIsloading(false)
  } 
}

const updateticketimg = async (ticket, results, folio)=>{
  
  setmensaje("Terminando de registrar caso...");
  setIntentFinalize(true);
  setIsloading(true);
  var data ={
    images: results,
  }
  try{
    var rf_token = await refresh_token();
    if(rf_token===true){
      setTimeout(async() => {
        try{
          const d = await updateSupport(ticket, data);
          setmensaje(`Su Ticket fue registrado con el folio: ${folio}`);
          setTimeout(async() => {
            props.alertas("Su ticket de soporte fue registrado exitosamente y se le dará atención inmediata",true);
            await TELEGRAM(folio, "", datos?.subject, datos?.description, "create");
            handlejira(folio, datos);
            setIsloading(false);
            dialog.close();
            handleClose();
          }, 3000);
              
        }catch(err){
          props.alertas("Su ticket se registro exitosamente, pero hubo error al subir algunas imagenes" ,true);
          setIsloading(false);
          dialog.close();
          handleClose();
        }
      }, 1000);
    }else{}
  }catch(err){
    console.log(err);
  }
}

const handlepostimg = async(ticket, name, base64)=>{

  var img = base64.split(",");
  var data = {
    'file_name': name, 
    'file_b64': img[1]
  }

  try{
    var rf_token = await refresh_token();
    if(rf_token === true){
        try {
          var d = await uploadimgticket(ticket, data);
          console.log(d);
          return d?.data?.file_name;
        }catch(err){
          return err;
        }
    }else{}
  }catch(err){
    console.log(err);
  }
}



useEffect(() => {
  switch(module){
    case 0: setcategories([ {value: 'Te falta seleccionar el módulo',label: 'Te falta seleccionar el módulo',}])
    break;
    case 1: setcategories(h.finanzas_categories);
    break;
    case 2: setcategories(h.compras_categories);
    break;
    case 3: setcategories(h.facturas_categories);
    break;
    case 4: setcategories(h.snegocios_categories);
    break;
    case 5: setcategories(h.demo);
    break;
    case 6: setcategories(h.adenda);
    break;
    case 7: setcategories(h.soporte);
    break;
    case 8: setcategories(h.pedimentos);
    break;
    case 9: setcategories(h.reportes);
    break;
    default: setcategories([])
      break;        
  }

}, [module])

useEffect( () => {

  const selectedModule = modulos[modulo]
  setdatos({
    subject: subject.trim(),
    mail_support: "",
    status: "OPEN",
    submodule: category,
    module: selectedModule?.value,
    description: description.trim(),
    severity: severity,
    type: check1 ? "Sugerencia" : "Error de sistema",
    extra1: null,
    extra2: null,
    extra3: null
  });

  if( (subject.trim() !== "" && subject.trim() !== null) && (description.trim() !== "" && description.trim() !== null) ) {
    setDatavalid(true);
  }
  else{
    setDatavalid(false)   
  }
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [subject, category, modulo, description, severity, check1, check2,])


const handleImageChange = (event) => {
  const imageFiles = event.target.files;
  const imagesArray = Array.from(imageFiles);
  var d = true;
  
  const updatedSelectedImages = imagesArray.map((imageFile) => {
  
    const acceptedExtensions = [".jpg", ".jpeg", ".png"];
    const extension = imageFile.name.substring(imageFile.name.lastIndexOf('.')).toLowerCase();
    
    if (!acceptedExtensions.includes(extension)) {
      props?.alertas("Solo se permiten archivos con extensiones .jpg, .jpeg y .png");
      d = false;
    }else{
      const reader = new FileReader();
      reader.readAsDataURL(imageFile);
      return new Promise((resolve) => {
        reader.onloadend = () => {
          const fileName = imageFile.name.replace(/\.[^/.]+$/, "");
          resolve({
            base64: reader.result,
            name: fileName,
          });
        };
      });
    }    
  });
  
  if(d === true){
    Promise.all(updatedSelectedImages).then((imagesData) => { 

      if(imagesData.length>5){
        alert("No se pueden subir mas de 5 imágenes");
      }else{
        if(selectedImages.length > 5 || (selectedImages.length+imagesData.length) > 5){
          alert("No se pueden subir mas de 5 imágenes");
        }else{
          setSelectedImages(selectedImages.concat(imagesData));
        }
      }
    });
  }  
};
const handleRemoveImage = (index) => {
  setSelectedImages((prevSelected) =>
    prevSelected.filter((_, i) => i !== index)
  );
};



const handledemo=()=>{
  dialog.close();
}



  return (
          <div className="modal-content" style={{"textAlign":"center"}}>
       <div className="modal-header">
          <div style={{border: `1px solid ${step === 1 ? "blue" : "black"}` }} className='p1' onClick={handleBack}>  <Typography color="textSecondary"> <Icon><Home style={{color: step === 1 ? "blue" : "black" }}/> </Icon> Inicio </Typography> </div>
          <div style={{border: `1px solid ${step === 2 ? "blue" : "black"}` }} className='p2'> <Typography color="textPrimary"><Icon> <BorderColor style={{color: step === 2 ? "blue" : "black" }}/> </Icon> Informaci&oacute;n Adicional</Typography> </div>
        </div>
        <div className="modal-body">
          <div className="step-content">
            {step === 1 && (
              <>
              <span className='checkbox-title'>Selecciona el tipo de soporte</span>
                <div className="checkbox-container suporttour2" id="suporttour2">                
                  <div 
                      style={{border:!intentcontinue ? "1px solid black"
                      : intentcontinue && isAnyChecked 
                      ? "1px solid black": "1px solid red"}}
                      className="checkbox-item">                    
                    <div className="checkbox-wrapper">
                      <SettingsSuggest  sx={{fontSize: "40px"}} />
                      <FormControl>
                        <FormControlLabel                        
                            control={
                            <Checkbox
                                required
                                checked={check1}
                                onChange={handleCheck1Change}
                                name="suggestions"
                                
                                color="primary"
                              />}
                              label="Sugerencias de funcionalidades"
                        />                     
                        
                      </FormControl>                    
                      
                    </div>
                  </div>
                  <div style={{border:!intentcontinue ? "1px solid black"
                              : intentcontinue && isAnyChecked 
                              ? "1px solid black": "1px solid red"}} 
                        className="checkbox-item">
                    <div className="checkbox-wrapper">
                    <WarningAmber sx={{fontSize: "40px"}} />
                      <FormControl>
                        <FormControlLabel
                          control={
                              <Checkbox
                                required
                                checked={check2}                          
                                onChange={handleCheck2Change}
                                name="systemError"
                                color="primary"
                              />}
                          label="Error de Sistema"                          
                        />                        
                      </FormControl>
                    </div>                      
                  </div>                  
                </div>
                <Typography className='helper-check' style={{display:!intentcontinue ? "none"
                              : intentcontinue && isAnyChecked 
                              ? "none": " "}} error={true}> 
                            Selecciona una opci&oacute;n
                </Typography>
                <div className="select-container">
                  <FormControl 
                      className="suporttour3"
                      id="suporttour3"
                      variant='outlined' 
                      fullWidth 
                      error={
                      !intentcontinue ? false
                      : intentcontinue && module !== 0 ? false
                      : true  }>
                    <InputLabel className='label-select'>Módulos</InputLabel>
                    <Select required value={module} onChange={ (event) => handleModule(event)} name="module">
                      {modulos.map((option, index) =>{ 
                        return (
                        <MenuItem key={index} value={index}>{option?.label}</MenuItem>
                        )})}
                    </Select>
                  </FormControl>
                  <FormControl 
                      className="suporttour4"
                      id="suporttour4"
                      variant='outlined' 
                      fullWidth 
                      error={
                        !intentcontinue ? false 
                                : intentcontinue 
                                && category !== "Seleccione un valor"
                                && category !== ""
                                && category !== "Te falta seleccionar el módulo" ? 
                                false 
                                : true }>
                    <InputLabel className='label-select'>Categor&iacute;a</InputLabel>
                    <Select required value={category} onChange={(event) => handleCategory(event) } name="submodule">
                      { categories.map((option) => {
                        return (
                        <MenuItem key={option.value} value={option.value}>
                        {option.label}
                        </MenuItem>
                      )})}
                    </Select>
                  </FormControl>
                  <FormControl 
                      className="suporttour5"
                      id="suporttour5" 
                      variant='outlined' 
                      fullWidth
                      error={!intentcontinue ? false 
                      : intentcontinue 
                      && severity !== "" 
                      && severity !== null ? 
                      false 
                      : true }
                      >
                    <InputLabel className='label-select'>Severidad</InputLabel>
                    <Select required value={severity} onChange={(event)=> setseverity(event.target.value)} name="severity">
                    { severidad.map((option) => {
                      return (
                        <MenuItem key={option.value} value={option.value}>
                        {option.label}
                        </MenuItem >
                      )})}
                    </Select>
                  </FormControl>
                </div>
              </>
            )}
          {step === 2 && (
              <>
              <div className='form-item suporttour7' id="suporttour7">
                  <TextField
                    fullWidth
                    required
                    label="Asunto"
                    value={subject}
                    multiline
                    minRows={1}
                    maxRows={1}
                    inputProps={{ maxLength: 500 }}
                    onChange={handleSubjectChange}
                    variant="outlined"
                    error={ !intentfinalize ? false
                      : intentfinalize && datavalid ? false
                      : true  }
                  />
                </div>
                <div className='form-item suporttour8' id="suporttour8">
                <FormControl variant='outlined' fullWidth>
                  <TextField                    
                    label="Descripción"
                    value={description}
                    onChange={handleDescriptionChange}
                    required
                    multiline
                    minRows={4}
                    maxRows={6}
                    inputProps={{ maxLength: 1000 }}
                    variant="outlined"
                    error={ !intentfinalize ? false
                      : intentfinalize && datavalid ? false
                      : true  }
                  />
                </FormControl>                  
                </div>
                <div className='form-item form-item-img' id="form-item-img">
                <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    multiple  
                    className="input-img-file"
                />
                <label>Imagenes Seleccionadas: {selectedImages?.length}</label>
              </div>
              <div className="carrusel-container">
                <div className="carrusel-img">
                {selectedImages.map((imageData, index) => (
                  <div key={index} className='img-support'>
                    <img src={imageData.base64} alt={`Image ${index}`} />
                    <img className="img-delet" src={cancel} onClick={() => handleRemoveImage(index)}></img>
                  </div>
                ))}
                </div>
              </div>  
              </>
            )}
          </div>
          <div className="button-group">
            {step === 1 && (
            <>
            <Button onClick={ handleClose}>
                  CANCELAR
                </Button>
              <Button 
                  className="suporttour6"
                  id="suporttour6"
                  variant="contained" 
                  color="primary" 
                  onClick={()=>{
                    setIntentcontinue(true)
                      if(isAnyChecked && ( check1 || check2 ) 
                          && module !== 0 && (category !== "Seleccione un valor" 
                          && category !== "Te falta seleccionar un módulo" && category !== "" )){
                        handleNext()
                      }
                    }}>
                Siguiente
              </Button>
              </>
            )}
            {step === 2 && (
              <>
              <Button onClick={handleClose}>
                  CANCELAR
                </Button>
                <Button 
                  className="suporttour9"
                  id="suporttour9"
                  variant="contained" 
                  color="primary" 
                  onClick={()=>{props.run===true?handledemo():handleCreate()}}
                >
                  Levantar Ticket
                </Button>                
              </>
            )}
          </div>   
          {alert && <div>{alert}</div>}
        </div>
        <Backdrop className="backdrop" style={{ width:"100%", position: "absolute", backgroundColor: "white", zIndex: 10010 }} sx={{ color: '#fff' }}
          open={isloading}          
        >
             <CircularProgress  style={{display: "block", fontSize: "large"}} color="primary" />
              <Typography component="legend"> {mensaje} </Typography>              
        </Backdrop>
      </div>
  );
};

export default SupportCaseModal;