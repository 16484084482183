//PAQUETERIAS
import { useEffect, useState, useMemo, useRef } from "react";
import NotificationSystem from 'react-notification-system';
import MODAL_TABLE from "../main_components/modal/modal_Table";
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import dayjs from 'dayjs';

//COMPONENTES
import AlertResponse from "../main_components/alerts/alertResponse";
//IMAGENES/ICONOS
import { refresh_token } from "../main_components/tokens/tokenrefresh";
import { BREADCRUMBS } from "../main_components/pagination/breadcrumbs";
import { Box } from "@mui/material";
import { calculate_total } from "../main_components/methods_v2/auxiliary_functions";
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../main_components/methods_v2/export";
import FILTERS_FACTOR from "./tools/filters";
import "./factor.css";
import { get_factor_importation, get_semaphore } from "../services/portal/portal";
import { date_current, previous_date } from "../main_components/date/date";

const SEMAPHORE = () => {

  const notificationSystemRef = useRef();
  const [open, setopen] = useState(false);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [rowSelection, setRowSelection] = useState({});
  const [reconsult, setreconsult] = useState(false);
  var consulta = 0;
  const [modalT, setmodalT] = useState(false);
  const [menssage, setmenssage] = useState("");
  const [modalGeneral, setmodalGeneral] = useState(false);
  const [usStateList, setusStateList] = useState([]);
  const [total_rows, settotal_rows] = useState("");

  const [gcosto, setgcosto] = useState("");
  const [ginventario, setginventario] = useState("");
  const [ginvalor, setginvalor] = useState("");
  const [gmin, setgmin] = useState("");
  const [gvmin, setgvmin] = useState("");
  const [gpedidosp, setgpedidosp] = useState("");
  const [gpedidosc, setgpedidosc] = useState("");
  const [gporcentaje, setgporcentaje] = useState("");
  const [orderpg, setorderpg] = useState([]);
  const [type, settype] = useState("");
  const [operation, setoperation] = useState("");

  useEffect(() => {
    handleSubmit();
  }, []);

  const update_null_identifiers = (original_obj, identifiers_to_update) => {
    return original_obj.map((item) => {
      for (const identifier in item) {
        if (item.hasOwnProperty(identifier)) {
          // Encuentra el objeto de actualización para el identificador actual, si existe
          const updateObj = identifiers_to_update.find((update) => update.identifier === identifier);
  
          // Verifica si el campo actual necesita ser actualizado y si su valor actual es null
          if (updateObj && item[identifier] === null) {
            // Decide el valor de reemplazo basado en el tipo especificado en el objeto de actualización
            switch (updateObj.type) {
              case 'number':
              case 'money': // Trata 'money' como un número
                item[identifier] = 0;
                break;
              case 'string':
              default: // Por defecto, utiliza un string vacío
                item[identifier] = '';
                break;
            }
          }
        }
      }
      return item;
    });
  };

  function alertas(msj, status) {

    if (status === true) {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={true}></AlertResponse>,
        level: 'success',
        position: 'br',
        autoDismiss: 10,
      });
    } else {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={false}></AlertResponse>,
        level: 'error',
        position: 'br',
        autoDismiss: 60,
      });
    }
  }

  const handleSubmitfilter = async () => {
    setmodalT(true);
    setIsLoading(true);

    var data = {
      filtro: `${type}${operation}`,
    }

    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            const d = await get_semaphore(data);
            console.log(d?.data?.body);
            const datajson = (d === null ? [] : d?.data?.body);
            setData(update_null_identifiers(datajson,update_identifiers));

            setgcosto(datajson?.map(obj => obj.costo).filter(d => d !== null))
            setginventario(datajson?.map(obj => obj.inventario).filter(d => d !== null))
            setginvalor(datajson?.map(obj => obj.inventario_valor).filter(d => d !== null))
            setgmin(datajson?.map(obj => obj.minimo).filter(d => d !== null))
            setgvmin(datajson?.map(obj => obj.minimo_valor).filter(d => d !== null))
            setgpedidosp(datajson?.map(obj => obj.pedidos_proveedores).filter(d => d !== null))
            setgpedidosc(datajson?.map(obj => obj.pedidos_clientes).filter(d => d !== null))
            setgporcentaje(datajson?.map(obj => obj.porcentaje).filter(d => d !== null))

            // var d1 = calculate_total(datajson?.map(obj => obj.VALOR_EN_MN).filter(d => d !== null));
            // var d2 = calculate_total(datajson?.map(obj => obj.TOTAL_GASTOS).filter(d => d !== null))
            // calcularporcentaje(d1, d2);

            const dataLength = datajson?.length || 0;
            const baseOptions = [5, 10, 20, 50, 100];
            const filteredOptions = baseOptions.filter(option => option <= dataLength);
            if (dataLength > 0 && !filteredOptions.includes(dataLength)) {
              filteredOptions.push(dataLength);
            }
            const sortedRowsPerPageOptions = filteredOptions.sort((a, b) => a - b);
            setorderpg(sortedRowsPerPageOptions);

            setopen(false);
            setmodalGeneral(false);
            setmodalT(false);
            setIsLoading(false);
          } catch (err) {
            console.log("error", err);
            setData([]);
            setopen(false);
            setmodalGeneral(false);
            setmodalT(false);
            setIsLoading(false);
          }
        }, 1000);
      } else { }
    } catch (err) {
      console.log(err);
    }
  }

  const handleSubmit = async () => {
    setmodalT(true);
    setIsLoading(true);
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            const d = await get_semaphore();
            console.log(d?.data?.body);
            const datajson = (d === null ? [] : d?.data?.body);
            setData(datajson);
            setgcosto(datajson?.map(obj => obj.costo).filter(d => d !== null))
            setginventario(datajson?.map(obj => obj.inventario).filter(d => d !== null))
            setginvalor(datajson?.map(obj => obj.inventario_valor).filter(d => d !== null))
            setgmin(datajson?.map(obj => obj.minimo).filter(d => d !== null))
            setgvmin(datajson?.map(obj => obj.minimo_valor).filter(d => d !== null))
            setgpedidosp(datajson?.map(obj => obj.pedidos_proveedores).filter(d => d !== null))
            setgpedidosc(datajson?.map(obj => obj.pedidos_clientes).filter(d => d !== null))
            setgporcentaje(datajson?.map(obj => obj.porcentaje).filter(d => d !== null))

            // var d1 = calculate_total(datajson?.map(obj => obj.VALOR_EN_MN).filter(d => d !== null));
            // var d2 = calculate_total(datajson?.map(obj => obj.TOTAL_GASTOS).filter(d => d !== null))
            // calcularporcentaje(d1, d2);
            
            const dataLength = datajson?.length || 0;
            const baseOptions = [5, 10, 20, 50, 100];
            const filteredOptions = baseOptions.filter(option => option <= dataLength);
            if (dataLength > 0 && !filteredOptions.includes(dataLength)) {
              filteredOptions.push(dataLength);
            }
            const sortedRowsPerPageOptions = filteredOptions.sort((a, b) => a - b);
            setorderpg(sortedRowsPerPageOptions);

            setopen(false);
            setmodalGeneral(false);
            setmodalT(false);
            setIsLoading(false);
          } catch (err) {
            console.log("error", err);
            setData([]);
            setopen(false);
            setmodalGeneral(false);
            setmodalT(false);
            setIsLoading(false);
          }
        }, 1000);
      } else { }
    } catch (err) {
      console.log(err);
    }
  };

  const handleStateChange = (newState) => {
    const filteredRowsCount = newState.filteredRowModel
    settotal_rows(filteredRowsCount);
  };

  const calcularporcentaje = (d1, d2) => {
    var d = ((d2 / d1) * 100).toFixed(2);
  }

  const columns = useMemo(
    () => [
      {
        id: "codigo",
        header: "Código",
        accessorKey: "codigo",
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Footer: () => (
          <div>
            <div>SubTotal:</div>
            <div>Gran Total: </div>
          </div>
        ),
      },
      {
        id: "descripcion",
        header: "Descripción",
        accessorKey: "descripcion",
        muiTableBodyCellProps: {
          align: "justify !important"
        },
        size: 300
      },
      {
        id: 'grupo',
        header: "Grupo",
        accessorKey: "grupo", 
        muiTableBodyCellProps: {
          align: 'justify !important',
        },
       
      },
      {
        id: 'proveedor',
        header: "Proveedor",
        accessorKey: "proveedor",
        muiTableBodyCellProps: {
          align: 'justify !important',
        },
      },
      {
        id: "costo",
        header: "Costo",
        accessorKey: "costo",
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              // backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              // color: '#fff',
              maxWidth: '12ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue() === 0 || cell.getValue() === null ?

              (0.0).toLocaleString?.('es-MX', {
                style: 'currency',
                currency: 'MXN',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
              : cell.getValue()?.toLocaleString?.('es-MX', {
                style: 'currency',
                currency: 'MXN',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
          </Box>
        ),
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.costo).filter(d => d !== "")
          return (
            <div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(calculate_total(gcosto))}</div>
            </div>
          )
        },
      },
      {
        header: "Inventario",
        accessorKey: "inventario",
        id: "inventario",
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              // backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              // color: '#fff',
              maxWidth: '12ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue() === 0 || cell.getValue() === null ?

              (0.0).toLocaleString?.('es-MX', {
                style: 'decimal',
                currency: 'MXN',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
              : cell.getValue()?.toLocaleString?.('es-MX', {
                style: 'decimal',
                currency: 'MXN',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
          </Box>
        ),
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.inventario).filter(d => d !== "")
          return (
            <div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "decimal",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "decimal",
                currency: "MXN",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(calculate_total(ginventario))}</div>
            </div>
          )
        },
      },
      {
        header: "Inventario valor",
        accessorKey: "inventario_valor",
        id: "inventario_valor",
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              // backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              // color: '#fff',
              maxWidth: '12ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue() === 0 || cell.getValue() === null ?

              (0.0).toLocaleString?.('es-MX', {
                style: 'currency',
                currency: 'MXN',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
              : cell.getValue()?.toLocaleString?.('es-MX', {
                style: 'currency',
                currency: 'MXN',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
          </Box>
        ),
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.inventario_valor).filter(d => d !== "")
          return (
            <div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "decimal",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(ginvalor))}</div>
            </div>
          )
        },
      },
      {
        header: "Mínimo",
        accessorKey: "minimo",
        id: "minimo",
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              // backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              // color: '#fff',
              maxWidth: '12ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue() === 0 || cell.getValue() === null ?

              (0.0).toLocaleString?.('es-MX', {
                style: 'decimal',
                currency: 'MXN',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
              : cell.getValue()?.toLocaleString?.('es-MX', {
                style: 'decimal',
                currency: 'MXN',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
          </Box>
        ),
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.minimo).filter(d => d !== "")
          return (
            <div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "decimal",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "decimal",
                currency: "MXN",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(calculate_total(gmin))}</div>
            </div>
          )
        },
      },
      {
        header: "Valor mínimo $",
        accessorKey: "minimo_valor",
        id: "minimo_valor",
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              // backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              // color: '#fff',
              maxWidth: '12ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue() === 0 || cell.getValue() === null ?

              (0.0).toLocaleString?.('es-MX', {
                style: 'currency',
                currency: 'MXN',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
              : cell.getValue()?.toLocaleString?.('es-MX', {
                style: 'currency',
                currency: 'MXN',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
          </Box>
        ),
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.minimo_valor).filter(d => d !== "")
          return (
            <div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(calculate_total(gvmin))}</div>
            </div>
          )
        },
      },
      {
        header: "Pedidos proveedores",
        accessorKey: "pedidos_proveedores",
        id: "pedidos_proveedores",
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              // backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              // color: '#fff',
              maxWidth: '12ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue() === 0 || cell.getValue() === null ?

              (0.0).toLocaleString?.('es-MX', {
                style: 'decimal',
                currency: 'MXN',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
              : cell.getValue()?.toLocaleString?.('es-MX', {
                style: 'decimal',
                currency: 'MXN',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
          </Box>
        ),
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.pedidos_proveedores).filter(d => d !== "")
          return (
            <div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "decimal",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "decimal",
                currency: "MXN",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(calculate_total(gpedidosp))}</div>
            </div>
          )
        },
      },
      {
        header: "Pedidos clientes",
        accessorKey: "pedidos_clientes",
        id: "pedidos_clientes",
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              // backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              // color: '#fff',
              maxWidth: '12ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue() === 0 || cell.getValue() === null ?

              (0.0).toLocaleString?.('es-MX', {
                style: 'decimal',
                currency: 'MXN',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
              : cell.getValue()?.toLocaleString?.('es-MX', {
                style: 'decimal',
                currency: 'MXN',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
          </Box>
        ),
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.pedidos_clientes).filter(d => d !== "")
          return (
            <div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "decimal",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "decimal",
                currency: "MXN",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(calculate_total(gpedidosc))}</div>
            </div>
          )
        },
      },
      {
        header: "%",
        accessorKey: "porcentaje",
        id: "porcentaje",
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              // backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              // color: '#fff',
              maxWidth: '12ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue() === 0 || cell.getValue() === null ?

              (0.0).toLocaleString?.('es-MX', {
                style: 'decimal',
                currency: 'MXN',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
              : cell.getValue()?.toLocaleString?.('es-MX', {
                style: 'decimal',
                currency: 'MXN',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
          </Box>
        ),
        size: 100,
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.porcentaje).filter(d => d !== "")
          return (
            <div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "decimal",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "decimal",
                currency: "MXN",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(calculate_total(gporcentaje))}</div>
            </div>
          )
        },
      },
      {
        header: "Semáforo",
        accessorKey: "semaforo",
        id: "semaforo",
        muiTableBodyCellProps: {
          align: 'center',
        },
        filterVariant: 'select',
        accessorFn: (d) => {
          return (
            d.semaforo === "BLACK" ? "⚫" :
              d.semaforo === "GREEN" ? "🟢" :
                d.semaforo === "RED" ? "🔴" :
                  d.semaforo === "YELLOW" ? "🟡" : "Sin Semáforo"
          )
        }
      },
    ],
    [
      gcosto,
      ginventario,
      ginvalor,
      gmin,
      gvmin,
      gpedidosp,
      gpedidosc,
      gporcentaje
    ]
  );


  const update_identifiers =
    [
      { codigo: 'string' },
      { descripcion: 'string' },
      { grupo: 'string' },
      { proveedor: 'string' },
      { costo: 'number' },
      { inventario: 'number' },
      { inventario_valor: 'number' },
      { minimo: 'number' },
      { minimo_valor: 'number' },
      { pedidos_proveedores: 'number' },
      { pedidos_clientes: 'number' },
      { porcentaje: 'number' },
    ];


  return (
    <>
      <BREADCRUMBS niveles={
        [
          { label: "SEMÁFORO DE INVENTARIO", path: null },
        ]
      }
        // old_version={true}
        // modul="CONSULTAR_CUENTAS_CONTABLES_V1"
        // path="/consult_accounting_account"  
        cards_dashboard={true}
        total_rows={total_rows !== "" ? total_rows : 0}
        filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
        visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
        total={data?.length}
      ></BREADCRUMBS>
      <div className='new_table_v2'>
        <div >
          <NotificationSystem ref={notificationSystemRef} ></NotificationSystem>
          <MODAL_TABLE
            open={true}
            message={menssage}
            modalGeneral={modalGeneral}
            modalT={modalT}
          ></MODAL_TABLE>
          <>
            <MaterialReactTable
              columns={columns}
              data={data}
              enableGrouping
              enablePinning
              enableFacetedValues
              enableStickyHeader
              onStateChange={handleStateChange}
              enableStickyFooter
              enableRowPinning
              enableColumnFilterModes
              // enableRowActions
              enableRowSelection
              getRowId={(row) => row?.id}
              onRowSelectionChange={setRowSelection}
              enableTooltips={false}
              localization={MRT_Localization_ES}
              state={{ rowSelection, isLoading: isLoading }}
              enableColumnResizing
              enableColumnPinning
              enableColumnOrdering
              enableExpandAll={false}
              initialState={
                {
                  showGlobalFilter: true,
                }}
              muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
              muiSkeletonProps={{ animation: 'pulse', height: 28 }}
              rowVirtualizerInstanceRef
              rowVirtualizerOptions={{ overscan: 5 }}
              columnVirtualizerOptions={{ overscan: 2 }}
              muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
              positionToolbarAlertBanner="bottom"
              paginationDisplayMode='pages'
              rowPinningDisplayMode='sticky'
              layoutMode="grid"
              muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important", } }}
              muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
              muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', rowsPerPageOptions: orderpg }}
              displayColumnDefOptions={{
                'mrt-row-pin': {
                  enableHiding: true,
                },
                'mrt-row-actions': {
                  enableHiding: true,
                },
                'mrt-row-expand': {
                  enableHiding: true,
                },
                'mrt-row-select': {
                  enableHiding: true,
                }
              }}
              muiTableBodyRowDragHandleProps={({ table }) => ({
                onDragEnd: () => {
                  const { draggingRow, hoveredRow } = table.getState();
                  if (hoveredRow && draggingRow) {
                    data.splice(
                      hoveredRow.index,
                      0,
                      data.splice(draggingRow.index, 1)[0],
                    );
                    setData([...data]);
                  }
                },
              })}
              muiTableBodyRowProps={
                ({ row, table }) => {
                  settotal_rows(table);
                  const { density } = table.getState();
                  return {
                    sx: {
                      height: row.getIsPinned()
                        ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                        }px`
                        : undefined,
                    },
                  };
                }
              }
              renderTopToolbarCustomActions={({ }) => (
                <FILTERS_FACTOR
                  handleSubmitfilter={handleSubmitfilter}
                  handleSubmit={handleSubmit}
                  settype={settype}
                  type={type}
                  operation={operation}
                  setoperation={setoperation}
                />
              )}
              renderToolbarInternalActions={({ table }) => (
                <RENDER_TOOLBAR_INTERNAL_ACTIONS
                  table={table}
                  file_name={"Factor de Importación"}
                  update_identifiers={update_identifiers}
                />
              )
              }
            />

          </>
        </div>
      </div>
    </>
  );
}

export default SEMAPHORE;
