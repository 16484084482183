import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Grid, TextField } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { ValidationTextField } from '../../../main_components/lable/labe'; // Asegúrate de que el componente esté correctamente importado
import EditIcon from '@mui/icons-material/Edit';
import DELETE from "../../../imgComponents/borrar.png"
import DeleteIcon from '@mui/icons-material/Delete';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import dayjs from 'dayjs';
import { format_year_month_day } from '../../../main_components/methods_v2/auxiliary_functions';

function FORM_PETITIONS_DETAILS_INVOICE({ open, onClose, data, onSave, isNew }) {
  const [formData, setFormData] = useState(data);
  const [errors, setErrors] = useState({});
  console.log(formData)


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value?.toUpperCase() }));
    setErrors(prev => ({ ...prev, [name]: '' }));
  };

  const validateField = (name, value) => {
    const isNumeric = ['currencyFactor', 'billingCurrencyValue', 'dollarValue'].includes(name);
  
    let error = '';

    switch (name) {
        case 'cove':
        case 'incoterm':
        case 'invoice':
            if (!value || value.length < 1 || value.length > 255) {
                error = 'El campo debe tener entre 1 y 255 caracteres';
            }
            break;

        case 'currency':
            if (!value || value.length !== 3) {
                error = 'El campo debe tener 3 caracteres (EUR,USD)';
            }
            break;

        case 'currencyFactor':
        case 'billingCurrencyValue':
        case 'dollarValue':
            if (isNaN(Number(value))) {
                error = 'Debe ser un valor numérico';
            }
            break;

        case 'invoiceDate':
            if (!value || value.length !== 10) {
                error = 'El campo debe tener 10 caracteres';
            }
            break;

        default:
            if (!value) {
                error = 'Este campo es obligatorio';
            }
            break;
    }

    return error;
};
  
  

  const handleSubmit = () => {
    let validationErrors = {};
    console.log(formData)
    Object.keys(formData).forEach(key => {
      const error = validateField(key, formData[key]);
      if (error) {
        validationErrors[key] = error;
      }
    });

    if (Object.keys(validationErrors).length === 0) {
      onSave(formData, isNew, data.docID, data.supplierCode);
      onClose();
    } else {
      setErrors(validationErrors);
    }
  };

  const handlePartsChange = (newParts) => {
    if (JSON.stringify(formData.parts) !== JSON.stringify(newParts)) {
      setFormData({ ...formData, parts: newParts });
    }
  };


  useEffect(() => {
    setFormData(data);
  }, [data, open]);
  useEffect(() => {
    // Limpiar errores cuando se cierra el diálogo
    if (!open) {
      setErrors({});
    }
  }, [open]);

  const handleDateChange = (newValue, accessor) => {
    console.log(accessor)
    setFormData(prev => ({ ...prev, [accessor]: revertirFecha(newValue?.format("YYYY/MM/DD")) }));
    setErrors(prev => ({ ...prev, [accessor]: '' }));
  };

  function convertirFecha(fecha) {
    if (fecha !== undefined) {
      console.log(fecha)
      const partes = fecha?.split('/');

      // Crear un objeto de fecha con el formato 'DD/MM/YYYY'
      const fechaObj = new Date(`${partes[2]}-${partes[1]}-${partes[0]}T00:00:00`);
      console.log(fechaObj)

      // Obtener el año, mes y día del objeto de fecha
      const year = fechaObj.getFullYear();
      const month = String(fechaObj.getMonth() + 1).padStart(2, '0'); // Los meses comienzan en 0
      const day = String(fechaObj.getDate()).padStart(2, '0');
      console.log(`${year}/${month}/${day}`)

      // Devolver la fecha en el nuevo formato 'YYYY/MM/DD'
      return `${year}/${month}/${day}`;
    }
  }


  function revertirFecha(fecha) {
    if (fecha !== undefined) {
      console.log(fecha)

      // Separar la fecha por el caracter '/'
      const partes = fecha?.split('/');
  
      // Crear un objeto de fecha con el formato 'YYYY/MM/DD'
      const fechaObj = new Date(`${partes[0]}-${partes[1]}-${partes[2]}T00:00:00`);
  
      // Obtener el año, mes y día del objeto de fecha
      const year = fechaObj.getFullYear();
      const month = String(fechaObj.getMonth() + 1).padStart(2, '0'); // Los meses comienzan en 0
      const day = String(fechaObj.getDate()).padStart(2, '0');
  
      // Devolver la fecha en el nuevo formato 'DD/MM/YYYY'
      return `${day}/${month}/${year}`;
    }
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{isNew ? 'Agregar Encabezado de Pedimento' : 'Editar Encabezado de Pedimento'}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {/* Ejemplo de campos agregados */}
          {[
            { label: "Factura", accessor: "invoice", sm: 6, dfl: '' },
            { label: "Fecha Factura", accessor: "invoiceDate", sm: 3, dfl: '27/04/2024', type: 'date' },
            { label: "Cove", accessor: "cove", sm: 3, dfl: '' },
            { label: "Incoterm", accessor: "incoterm", sm: 2, dfl: '' },
            { label: "Moneda", accessor: "currency", sm: 2, dfl: '' },
            { label: "Factor Moneda", accessor: "currencyFactor", sm: 2, dfl:  0,type: "number"},
            { label: "Valor Moneda Facturación", accessor: "billingCurrencyValue", sm: 3, dfl: 0,type: "number" },
            { label: "Valor Dólares Pedimento", accessor: "dollarValue", sm: 3, dfl: 0, type: "number" }, //es una lista que contiene 95240019289/35,95246407769/35,95246408223/35
          ].map(field => (
            <Grid item xs={12} sm={field.sm} style={{ paddingTop: "22px" }}>
              {field.type === "date" ?
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                 <DatePicker
                    label="Fecha"
                    value={dayjs(convertirFecha(formData?.[field.accessor]))}
                    onChange={(newValue) => handleDateChange(newValue, field.accessor)}
                    format="DD/MM/YYYY"
                    renderInput={(params) => <TextField {...params} fullWidth error={!!errors.accessor} helperText={errors.accessor} />}
                  />
                </LocalizationProvider>
                :
                <ValidationTextField
                  label={field.label}
                  required
                  placeholder={field.label}
                  fullWidth
                  variant="outlined"
                  name={field.accessor}
                  value={formData?.[field.accessor]}
                  type={field.type || "text"}
                  onChange={handleChange}
                  error={!!errors?.[field.accessor]}
                  helperText={errors?.[field.accessor]}
                />
              }

            </Grid>
          ))}


        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained" color="error" sx={{ m: 1, width: "100%" }}>Cancelar</Button>
        <Button onClick={handleSubmit} variant="contained" color="success" sx={{ m: 1, width: "100%" }}>
          {isNew ? 'Crear' : 'Actualizar'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default FORM_PETITIONS_DETAILS_INVOICE;



