import "./Amarely.css";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { alpha, styled } from "@mui/material/styles";
import React, { useState, useEffect,useRef } from "react";
import { useDropzone } from "react-dropzone";
import "./Amarely.css";
import { FileUploader } from "react-drag-drop-files";
import axios from "axios";
import * as tokenn from "../services/token";
import Button from "@mui/material/Button";
import ReactJsAlert from "reactjs-alert";
import "./styles.css";
import Relacionar from "./addendasvolkswagen/Relacionar";
import { CustomDialog, useDialog, Confirm } from "react-st-modal";
import NotificationSystem from "react-notification-system";

import * as acesstokenn from "../services/token";
import LoadingScreen from "react-loading-screen";

import Modal_Table from "../main_components/modal/modal_Table";
import AlertResponse from "../main_components/alerts/alertResponse";
const ValidationTextField = styled(TextField)({
  "& input:valid + fieldset": {
    borderColor: "green",
    borderWidth: 2,
    height: 42,
  },
  "& input:valid:focus + fieldset": {
    borderLeftWidth: 6,
    padding: "4px !important",
  },
});

export default function Marely() {
  const [status, setStatus] = useState(false);
  const [type, setType] = useState("success");
  const [cueropoalert, setcueropoalert] = useState("success");

  const [title, setTitle] = useState("This is a alert");

  const [isLoding, setIsLoading] = useState(false);
  const acceso = String(tokenn.tokenn);
  const accesotoken = String(acesstokenn.acesstokenn);

  const [file, setFile] = useState("");
  const [fileName, setFileName] = useState("");

  const [nFactura, setnFactura] = useState("");
  const [idinvoceD, setidinvoceD] = useState("");
  const [folioD, setfolioD] = useState("");
  const [serieD, setserieD] = useState("");
  const notificationSystemRef = useRef();
  const [modalT, setmodalT] = useState(false);

  const handleChangeNfactura = (event) => {
    setnFactura(event.target.value);
  };

  const fileToBase64 = (file, cb) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(null, reader.result);
    };
    reader.onerror = function (error) {
      cb(error, null);
    };
  };
  const fileTypes = ["PDF"];

  const handleChange = (file) => {


    fileToBase64(file, (err, result) => {
      if (result) {
        const cleanedFileName = file.name.replace(/\s/g, ''); // Eliminar espacios en blanco
        setFile(result);
        setFileName(cleanedFileName);
      }
    });
  };

  function handleSubmit(event) {
    event?.preventDefault();

    var dato = {
      idInvoice: idinvoceD,
      fileName: fileName,
      fileEncoded: file?.replace("data:application/pdf;base64,", ""),
    };

    console.log(dato);

    let axiosConfig = {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    };
    setmodalT(true);
    
    axios
      .post(
        "https://m10hvtuy76.execute-api.us-east-1.amazonaws.com/Inicial/purchaseorder",
        dato,
        axiosConfig
      )

      .then((res) => {
        // console.log("RESPONSE RECEIVED: ", res);
        setmodalT(false);
        setserieD("");
        setfolioD("");
        setidinvoceD("");
        setFileName("");
        setFile("");
        notificationSystemRef.current.addNotification({
          message: (
            <AlertResponse
              msj={
                "La addenda se generó con éxito."
              }
              view={true}
            ></AlertResponse>
          ),
          level: "success",
          position: "br",
          autoDismiss: 10,
        });
      })

      .catch((err) => {
        // console.log("AXIOS ERROR: ", err);
        var error = err?.response?.data?.errorMessage;
        setmodalT(false);

        notificationSystemRef.current.addNotification({
          message: (
            <AlertResponse
              msj={error}
              view={false}
            ></AlertResponse>
          ),
          level: "error",
          position: "br",
          autoDismiss: 60,
        });
      });
  }
  useEffect(() => {
    const handlesumar = () => {
      if (status == true) {
      }
    };
    handlesumar();
  });

 

  const handelAutoComp = (data) => {
    console.log(data);

    setserieD(data?.serie === undefined ? "" : data?.serie);
    setfolioD(data?.folio === undefined ? "" : data?.folio);
    setidinvoceD(data?.id === undefined ? "" : data?.id);
  };

  const handleSubmitValid = () => {
    var errorInvoce="Factura no seleccionada";
    var errorOrden="Se debe agregar un documento";
    var errorRem="Error: El campo 'Remisión' no puede ir vacio";

    if (idinvoceD?.length !== 0 && file?.length!==0) {
       handleSubmit();
    }else {
      notificationSystemRef.current.addNotification({
        message: (
          <AlertResponse
            msj={idinvoceD?.length===0?errorInvoce:file?.length!==10?errorOrden:"Error"}
            view={false}
          ></AlertResponse>
        ),
        level: "error",
        position: "br",
        autoDismiss: 60,
      });
    }
  };


  return (
    <div className="App">
      <Modal_Table modalT={modalT}></Modal_Table>
      <NotificationSystem ref={notificationSystemRef}></NotificationSystem>

      <div>
        <br></br>
        <div className="">
          <div className="container">
            <FileUploader
              handleChange={handleChange}
              name="file"
              types={fileTypes}
              placeholder="123-45-678"
            >
              <div className="drangAndDrop">
                <p>Arrastra o selecciona el PDF a enviar</p>
              </div>
            </FileUploader>
            <div className="upload-area">
              {fileName && <p className="filename">{fileName}</p>}
            </div>
          </div>
        </div>

        <Box>
          <ValidationTextField
            label="Serie"
            variant="outlined"
            placeholder="Serie"
            sx={{ m: 2, width: "20%" }}
            value={serieD}
            InputLabelProps={{ shrink: true }}
          />
          <ValidationTextField
            label="Folio"
            variant="outlined"
            placeholder="Folio"
            sx={{ m: 2, width: "20%" }}
            value={folioD}
            InputLabelProps={{ shrink: true }}
          />
        </Box>
        <Box>
          <Button
            variant="contained"
            sx={{ m: 2, width: "20%" }}
            className="ButtonModal2"
            onClick={async () => {
              const result = await CustomDialog(
                <Relacionar RFC={"CME910516832"}></Relacionar>,
                {
                  className: "ModalRelacionAdd",
                  title: "Buscar factura",
                  isCanClose: false,
                  showCloseIcon: true,
                }
              );
              {
                handelAutoComp(result);
              }
            }}
          >
            Buscar Factura
          </Button>

          <Button
            sx={{ m: 2, width: "20%" }}
            variant="contained"
            onClick={handleSubmitValid}
          >
            Enviar
          </Button>
        </Box>

        <div className="conenediorAviso2">
          <p>
            Nota:Una vez enviado el archivo, usted recibirá el XML con la
            addenda vía correo electrónico. Le recordamos que también puede
            realizar la descarga del XML con la addenda en el módulo de
            consultar facturas.
          </p>
        </div>

     
      </div>
    </div>
  );
}
