
import axios from "axios";

//Este método tiene como objetivo proporcionar el número 
//total de datos a consultar basándonos en el paginado solicitado.
export async function numberData(url, yy, weeks, origen, fi, ff, id_bp, id_pdv, paid,invoicing) {
  //Se recibe como parámetro una variable url

  var parameters = {};

  if (origen === "sumaryWeek") {
    parameters = {
      weeks: weeks,//Indica el número de semanas a consultar, por defecto 6
      date: yy//Indica la fecha para determinar la sumaria, por defecto hoy}
    }
  } else if (origen === "debts") {

    parameters = {
      di: fi,
      df: ff,
      id_partner: id_bp,
      id_pos: id_pdv,
      paid: paid === "" ? null : paid,
      invoicing:invoicing

    }

  } else {
    parameters = { year: yy }
  }


  try {
    //Se ejecuta una peticion get y se guarda como promesa la respuesta
    const response = await axios.get(`${url}`, {
      params: parameters,
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    const data = response.data.count;
    return data;//Se retorna la respuesta en caso de éxito
  } catch (error) {
    console.error(error);
    return null; //Se retorna un null en caso de fallo
  }
}







