import React, { useEffect, useState } from 'react'
import { refresh_token } from '../../main_components/tokens/tokenrefresh';
import { get_ihs } from '../../services/IHS/ihs';
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { InputAdornment } from '@mui/material';
import Box from "@mui/material/Box";
import { useDialog } from 'react-st-modal';

export const NEW_RECORD = (props) => {
 

const dialog = useDialog();

useEffect(() => {
    handleish()
}, [])

const handleish = async () =>{
  try{
    var rf_token = await refresh_token();
    props.reconsultar(true);
    if(rf_token===true){
        try {
          var res = await get_ihs();
          res.sort((a, b) => b.id - a.id);
          props.operation==="EDIT"?analisisedit(res):analisis(res)
          props.reconsultar(false);
        } catch (err) {
          console.log(JSON?.stringify(err.response));
          props.reconsultar(false);
        }
    }else{}
  }catch(err){
    console.log(err);
  }
}

//variables
const[country, setcountry]=useState(props.operation==="EDIT"?props.row.original.CTRY:"");
const[brand, setbrand]=useState(props.operation==="EDIT"?props.row.original.BRAND:"");
const[platform, setplatform]=useState(props.operation==="EDIT"?props.row.original.PLTFRM:"");
const[program, setprogram]=useState(props.operation==="EDIT"?props.row.original.PRGRM:"");
const[vehicle, setvehicle]=useState(props.operation==="EDIT"?props.row.original.VEHICLE:"");
const[sap, setsap]=useState(props.operation==="EDIT"?props.row.original["SAP ITEM"]:"");
const[mpg, setmpg]=useState(props.operation==="EDIT"?props.row.original.MPG:"");
const[app, setapp]=useState(props.operation==="EDIT"?props.row.original.APP:"");
const[cust, setcust]=useState(props.operation==="EDIT"?props.row.original.CUST:"");
const[sop, setsop]=useState(props.operation==="EDIT"?props.row.original.SOP:"");
const[eop, seteop]=useState(props.operation==="EDIT"?props.row.original.EOP:"");
const[status, setstatus]=useState(props.operation==="EDIT"?props.row.original["Project Status"]:"");
const[div, setdiv]=useState(props.operation==="EDIT"?props.row.original.Div:"");
const[adjust, setadjust]=useState(props.operation==="EDIT"?props.row.original["Adjust factor (%)"]:"");
const[notes, setnotes]=useState(props.operation==="EDIT"?props.row.original.NOTES:"");
const[mins, setmins]=useState(props.operation==="EDIT"?props.row.original["Min Servicio"]:"");
const[bom, setbom]=useState(props.operation==="EDIT"?props.row.original.BOM:"");

const[databrand, setdatabrand]=useState([]);
const[dataplat, setdataplat]=useState([]);
const[dataprogram, setdataprogram]=useState([]);
const[datavehicle, setdatavehicle]=useState([]);
const[dataglobal, setdataglobal]=useState([]);
const[dataglobal2, setdataglobal2]=useState([]);

var datastatus = [
    {id: 1, label: "OG"},
    {id: 2, label: "Concluyó"},
    {id: 3, label: "Cancelado"},
    {id: 4, label: "P"} 
]
var datadiv = [
    {id: 1, label: "FAT"},
    {id: 2, label: "FSS"}
]
var datamin = [
    {id: 1, label: "-"},
    {id: 2, label: 1}
]

const analisisedit = (d)=>{
    const uniqueCountries = [...new Set(d.map((item) => item.country))].sort();
    const countriesList = uniqueCountries.map((country) => ({ country }));
    setdataglobal(countriesList);
    
    const uniquebrands = [...new Set(d.map((item)=>item.production_brand))].sort();
    const brandsList = uniquebrands.map((brand)=>({brand}));
    setdatabrand(brandsList);

    const uniqueplatform = [...new Set(d.map((item)=>item.platform))].sort();
    const platList = uniqueplatform.map((platform)=>({platform}));
    setdataplat(platList);

    const uniqueprogram = [...new Set(d.map((item)=>item.program))].sort();
    setdataprogram(uniqueprogram);

    const uniquevehicle = [...new Set(d.map((item)=>item.vehicle))].sort(); 
    const vehList = uniquevehicle.map((item)=>({item})) 
    console.log(vehList)  
    setdatavehicle(vehList);
}

const analisis = (d)=>{

    //const uniqueCountries = [...new Set(d.map((item) => item.country))].sort();
    // const uniquebrands = [...new Set(d.map((item)=>item.production_brand))].sort();
    // const uniqueplatform = [...new Set(d.map((item)=>item.platform))].sort();
    // const uniqueprogram = [...new Set(d.map((item)=>item.program))].sort();
    // const uniquevehicle = [...new Set(d.map((item)=>item.vehicle))].sort();
    // setdatacountry(uniqueCountries);
    // setdatabrand(uniquebrands);
    // setdataplat(uniqueplatform);
    // setdataprogram(uniqueprogram);
    // setdatavehicle(uniquevehicle);

    const countriesGrouped = groupBy(d, "country");    
    const result = [];
    for (const country in countriesGrouped) {
        const brandsGrouped = groupBy(countriesGrouped[country], "production_brand");
        const countryData = {
        country,
        brands: []
        };
        for (const brand in brandsGrouped) {
        const platformsGrouped = groupBy(brandsGrouped[brand], "platform");
        const brandData = {
            brand,
            platforms: []
        };
        // Recorrer los grupos de plataformas
            for (const platform in platformsGrouped) {
                const programs = platformsGrouped[platform].map(item => item.program);
                const platformData = {
                platform,
                programs
                };
                brandData.platforms.push(platformData);
            }
        countryData.brands.push(brandData);
        }
        result.push(countryData);
    }
    const brandsGrouped = groupBy(d, "production_brand");
    const result2 = Object.entries(brandsGrouped).map(([brand, brandData]) => ({
        brand,
        vehicles: Object.entries(groupBy(brandData, "vehicle")).map(([item]) => ({
            item
        })),
        platforms: Object.entries(groupBy(brandData, "platform")).map(([platform, platformData]) => ({
            platform,
            programs: platformData.map(item => item.program),
        }))

    }));
    const abc = result.sort((a, b) => a.country.localeCompare(b.country));
    setdataglobal(abc);
    setdataglobal2(result2);
}


// Función para agrupar por una propiedad específica
function groupBy(arr, key) {
    return arr.reduce((acc, obj) => {
      const propertyValue = obj[key];
      if (!acc[propertyValue]) {
        acc[propertyValue] = [];
      }
      acc[propertyValue].push(obj);
      return acc;
    }, {});
  }
  



const handlefiltros=(e, n)=>{   
    
    switch(n){
        case 1:
            if(e==="select"){
                setdatabrand([]);
                setdataplat([]);
                setdataprogram([]);
            }else{
                setdatabrand([]);
                setdataplat([]);
                setdataprogram([]);
                const d = dataglobal.filter(item=>item.country===e);
                // setdatavehicle2(datavehicle.filter(item=>item.))
                const dorder = d[0]?.brands?.sort((a,b)=>a.brand.localeCompare(b.brand));
                setdatabrand(dorder);
            } 
        break;
        case 2:
            if(e==="select"){
                setdataplat([]);
                setdataprogram([]);
                setdatavehicle([]);
            }else{
                setdataplat([]);
                setdataprogram([]);
                //const d = databrand.filter(item=>item.brand===e); 
                const d = dataglobal2?.filter(item=>item.brand===e);
                const dorder = d[0]?.platforms?.sort((a,b)=>a.platform.localeCompare(b.platform));
                const vorder = d[0]?.vehicles?.sort((a,b)=>a.item.localeCompare(b.item));
                setdataplat(dorder);
                setdatavehicle(vorder);
            }
        break;
        case 3:
            if(e==="select"){
                setdataprogram([]);
            }else{
                setdataprogram([]);
                const d = dataplat?.filter(item=>item.platform===e);
                const dorder = d[0]?.programs?.sort((a,b)=>a.localeCompare(b));
                console.log(dorder);
                setdataprogram(dorder);
            }
        break;
    }
    
}
   


    return (
    <div className='container_form'>
        <div className='grid-ish1'>
            <div className='input-ish1'>
            <TextField
              label="Country"
              select
              required
              value={country}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => {
                setcountry(event.target.value);
                handlefiltros(event.target.value, 1);
            }}  
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
            >
            <option key={9999} value={"select"}>
                {"Select Country"}
            </option>
            {dataglobal.map((item, index) => (
                <option key={index} value={item.country}>
                  {item.country}
                </option>
            ))}
            </TextField>
              
            </div>
            <div className='input-ish2'>
                <TextField
                label="Brand"
                select
                required
                value={brand}
                sx={{ m: 1.5, width: "100%" }}
                onChange={(event) => {
                    setbrand(event.target.value);
                    handlefiltros(event.target.value, 2);
                }}  
                InputLabelProps={{
                    shrink: true,
                }}
                SelectProps={{
                    native: true,
                }}
                >
                <option key={9999} value={"select"}>
                    {"Select Brand"}
                </option>
                {databrand?.map((item, index) => (
                    <option key={index} value={item.brand}>
                    {item.brand}
                    </option>
                ))}
                </TextField>
            </div>
            <div className='input-ish3'>
                <TextField
                    label="Platform"
                    select
                    required
                    value={platform}
                    sx={{ m: 1.5, width: "100%" }}
                    onChange={(event) =>{
                        setplatform(event.target.value);
                        handlefiltros(event.target.value, 3)
                    }}  
                    InputLabelProps={{
                        shrink: true,
                    }}
                    SelectProps={{
                        native: true,
                    }}
                    >
                    <option key={9999} value={"select"}>
                        {"Select Platform"}
                    </option>
                    {dataplat.map((item, index) => (
                        <option key={index} value={item.platform}>
                        {item.platform}
                        </option>
                    ))}
                </TextField>
            </div>
            <div className='input-ish4'>
                <TextField
                    label="Program"
                    select
                    required
                    value={program}
                    sx={{ m: 1.5, width: "100%" }}
                    onChange={(event) => setprogram(event.target.value)}  
                    InputLabelProps={{
                        shrink: true,
                    }}
                    SelectProps={{
                        native: true,
                    }}
                    >
                    <option key={9999} value={"select"}>
                        {"Select Program"}
                    </option>
                    {dataprogram.map((option, index) => (
                        <option key={index} value={option}>
                        {option}
                        </option>
                    ))}
                </TextField>
            </div>
        </div>
        <div className='grid-ish2'>
            <div className='input-ish5'>
                <TextField
                    label="Vehicle"
                    select
                    required
                    value={vehicle}
                    sx={{ m: 1.5, width: "100%" }}
                    onChange={(event) => setvehicle(event.target.value)}  
                    InputLabelProps={{
                        shrink: true,
                    }}
                    SelectProps={{
                        native: true,
                    }}
                    >
                    <option key={9999} value={"select"}>
                        {"Select Vehicle"}
                    </option>
                    {
                        datavehicle.map((item, index)=>(
                            <option key={index} value={item.item}>{item.item}</option>
                        ))
                    }
                </TextField>
            </div>
            <div className='input-ish6'>
                 <TextField
                    label="SAP ITEM"
                    required
                    value={sap}
                    sx={{ m: 1.5, width: "100%" }}
                    onChange={(event) => setsap(event.target.value)} 
                    InputLabelProps={{
                        shrink: true,
                    }} 
                >
                </TextField>
            </div>
            <div className='input-ish7'>
                 <TextField
                    label="MPG"
                    required
                    value={mpg}
                    sx={{ m: 1.5, width: "100%" }}
                    onChange={(event) => setmpg(event.target.value)} 
                    InputLabelProps={{
                        shrink: true,
                    }} 
                >
                </TextField>
            </div>
            <div className='input-ish8'>
                 <TextField
                    label="APP"
                    required
                    value={app}
                    sx={{ m: 1.5, width: "100%" }}
                    onChange={(event) => setapp(event.target.value)} 
                    InputLabelProps={{
                        shrink: true,
                    }} 
                >
                </TextField>
            </div>
            <div className='input-ish9'>
                 <TextField
                    label="CUST"
                    required
                    value={cust}
                    sx={{ m: 1.5, width: "100%" }}
                    onChange={(event) => setcust(event.target.value)} 
                    InputLabelProps={{
                        shrink: true,
                    }} 
                >
                </TextField>
            </div>
        </div>
        <div className='grid-ish3'>
            <div className='input-ish10'>
                <TextField
                    label="SOP"
                    required
                    value={sop}
                    sx={{ m: 1.5, width: "100%" }}
                    onChange={(event) => setsop(event.target.value)}  
                    InputLabelProps={{
                        shrink: true,
                    }}
                >
                </TextField>
            </div>
            <div className='input-ish11'>
                <TextField
                    label="EOP"
                    required
                    value={eop}
                    sx={{ m: 1.5, width: "100%" }}
                    onChange={(event) => seteop(event.target.value)}  
                    InputLabelProps={{
                        shrink: true,
                    }}
                >
                </TextField>
            </div>
            <div className='input-ish12'>
                <TextField
                     label="Project Status"
                     select
                     required
                     value={status}
                     sx={{ m: 1.5, width: "100%" }}
                     onChange={(event) => setstatus(event.target.value)}  
                     InputLabelProps={{
                         shrink: true,
                     }}
                     SelectProps={{
                         native: true,
                     }}
                     >
                    <option key={9999} value={"select"}>
                         {"Select status"}
                    </option>
                    {datastatus.map(item=>(
                        <option key={item.id} value={item.label}>
                            {item.label}
                        </option>
                    ))}
                </TextField>
            </div>
            <div className='input-ish13'>
                <TextField
                     label="Div"
                     select
                     required
                     value={div}
                     sx={{ m: 1.5, width: "100%" }}
                     onChange={(event) => setdiv(event.target.value)}  
                     InputLabelProps={{
                         shrink: true,
                     }}
                     SelectProps={{
                         native: true,
                     }}
                     >
                    <option key={9999} value={"select"}>
                         {"Select Div"}
                    </option>
                    {datadiv.map(item=>(
                        <option key={item.id} value={item.label}>
                            {item.label}
                        </option>
                    ))}
                </TextField>
            </div>
        </div>
        <div className='grid-ish4'>
            <div className='input-ish14'>
                <TextField
                    label="Adjust Factor"
                    required
                    value={adjust}
                    type='number'
                    sx={{ m: 1.5, width: "100%" }}
                    onChange={(event) => setadjust(event.target.value)}  
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                >
                </TextField>
            </div>
            <div className='input-ish15'>
                <TextField
                    label="Notes"
                    required
                    value={notes}
                    sx={{ m: 1.5, width: "100%" }}
                    onChange={(event) => setnotes(event.target.value)}  
                    InputLabelProps={{
                        shrink: true,
                    }}
                >
                </TextField>
            </div>

        </div>
        <div className='grid-ish5'>
            <div className='input-ish16'>
                <TextField
                    label="Min-Service"
                    required
                    select
                    value={mins}
                    sx={{ m: 1.5, width: "100%" }}
                    onChange={(event) => setmins(event.target.value)}  
                    InputLabelProps={{
                        shrink: true,
                    }}
                    SelectProps={{
                        native: true,
                    }}
                >
                    <option key={9999} value={"select"}>
                         {"Select Min"}
                    </option>
                    {
                        datamin.map(item=>(
                            <option key={item.id} value={item.label}>
                                {item.label}
                            </option>
                        ))
                    }
                </TextField>
            </div>
            <div className='input-ish17'>
                <TextField
                    label="BOM"
                    required
                    value={bom}
                    sx={{ m: 1.5, width: "100%" }}
                    onChange={(event) => setbom(event.target.value)}  
                    InputLabelProps={{
                        shrink: true,
                    }}
                >

                </TextField>
            </div>
        </div>
    <Box> 
      <Button
      variant="contained"
      style={{"background-color":"green"}}
      sx={{ m: 1.5, width: "30ch" }}
        //   onClick={}
    >
      {props.operation==="EDIT"?"Save Changes":"Create New Record"}
    </Button>
    <Button
      style={{"background-color":"red"}}
      variant="contained"
      sx={{ m: 1.5, width: "20ch" }}
       onClick={() => {
           dialog.close();
        } 
       }
    >
    cancel
  </Button>
  </Box>
    </div>
  )
}
