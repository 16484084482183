import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import HailIcon from "@mui/icons-material/Hail";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import BuildIcon from "@mui/icons-material/Build";
import Groups2Icon from "@mui/icons-material/Groups2";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
//subcatalogo
import BrandingWatermarkIcon from "@mui/icons-material/BrandingWatermark";
import CarCrashIcon from "@mui/icons-material/CarCrash";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import EngineeringIcon from "@mui/icons-material/Engineering";
import AirlineSeatReclineNormalIcon from "@mui/icons-material/AirlineSeatReclineNormal";
import ReduceCapacityIcon from "@mui/icons-material/ReduceCapacity";
import SwitchAccountIcon from "@mui/icons-material/SwitchAccount";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
//imagenes
import ForkLeftIcon from "@mui/icons-material/ForkLeft";
import ContentPasteGoIcon from "@mui/icons-material/ContentPasteGo";
import FeedIcon from "@mui/icons-material/Feed";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import KitchenIcon from "@mui/icons-material/Kitchen";
import CommuteIcon from "@mui/icons-material/Commute";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import DiscountIcon from "@mui/icons-material/Discount";
import PercentIcon from "@mui/icons-material/Percent";
import StoreIcon from "@mui/icons-material/Store";
import StorefrontIcon from "@mui/icons-material/Storefront";
import RoomIcon from "@mui/icons-material/Room";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import AltRouteIcon from "@mui/icons-material/AltRoute";

import InventoryIcon from "@mui/icons-material/Inventory";
import IcecreamIcon from "@mui/icons-material/Icecream";
import ListAltIcon from "@mui/icons-material/ListAlt";
import FormatLineSpacingIcon from "@mui/icons-material/FormatLineSpacing";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";

import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import SummarizeIcon from "@mui/icons-material/Summarize";
import PublicIcon from "@mui/icons-material/Public";
import AssessmentIcon from "@mui/icons-material/Assessment";
import PlagiarismIcon from "@mui/icons-material/Plagiarism";

import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import BallotIcon from "@mui/icons-material/Ballot";
import FlightIcon from "@mui/icons-material/Flight";
import RequestPageIcon from "@mui/icons-material/RequestPage";
import BorderAllIcon from "@mui/icons-material/BorderAll";

import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import GavelIcon from "@mui/icons-material/Gavel";
import UploadFileIcon from "@mui/icons-material/UploadFile";

import CreditScoreIcon from "@mui/icons-material/CreditScore";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import TopicIcon from "@mui/icons-material/Topic";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";

export const data = [
  {
    id: 101,
    name: "PEDIMENTOS",
    icon: <AssessmentIcon className="icon" style={{ color: "white" }} />,
    restriccion: "",
    sections: [
      {
        id: 101101,
        name: "PEDIMENTOS",
        icon: <SummarizeIcon className="icon" style={{ color: "white" }} />,
        permision: [" "],
        videos: [
          {
            code: 1011011,
            titlevideo: "PEDIMENTOS",
            description: "PEDIMENTOS",
            url: "https://manuales-usuario.s3.amazonaws.com/PEDIMENTOS/Manual+de+pedimentos.pdf",
            img: "https://manuales-usuario.s3.amazonaws.com/PEDIMENTOS/pedimentos.png",
          },
        ],
      },
    ],
  },

  // {
  //     id: 101,
  //     name: 'ACTIVOS',
  //     icon: <AttachMoneyIcon className='icon' style={{color: "white"}}/>,
  //     restriccion:"",
  //     sections: [
  //         {
  //           id: 101101,
  //           name: 'CONGELADORES',
  //           icon: <AcUnitIcon className='icon' style={{color: "white"}}/>,
  //           permision: [' '],
  //           videos: [
  //                 {
  //                   code: 1011011,
  //                   titlevideo: "MANUAL CONGELADORES",
  //                   description: 'MANUAL CONGELADORES ',
  //                   url: 'https://documentation-od2-bot.s3.amazonaws.com/ACTIVOS/CONGELADORES/Manual+de+Congeladores.pdf',
  //                   img: 'https://documentation-od2-bot.s3.amazonaws.com/ACTIVOS/CONGELADORES/20.png',
  //                 },
  //           ],
  //         },
  //         {
  //           id: 101102,
  //           name: 'MOVIMIENTO DE CONGELADORES',
  //           icon: <KitchenIcon className='icon' style={{color: "white"}}/>,
  //           permision: [' '],
  //           videos: [
  //                 {
  //                   code: 1011021,
  //                   titlevideo: "MANUAL MOVIMIENTO DE CONGELADORES",
  //                   description: 'MANUAL MOVIMIENTO DE CONGELADORES ',
  //                   url: 'https://documentation-od2-bot.s3.amazonaws.com/ACTIVOS/MOVIMIENTO-CONGELADORES/Manual+Movimineto+de+Congeladores.pdf',
  //                   img: 'https://documentation-od2-bot.s3.amazonaws.com/ACTIVOS/MOVIMIENTO-CONGELADORES/21.png',
  //                 },
  //           ],
  //         },
  //         {
  //           id: 101103,
  //           name: 'MARCAS Y MODELOS',
  //           icon: <CommuteIcon className='icon' style={{color: "white"}}/>,
  //           permision: [' '],
  //           videos: [
  //                 {
  //                   code: 1011031,
  //                   titlevideo: "MANUAL MARCAS Y MODELOS",
  //                   description: 'MANUAL MARCAS Y MODELOS ',
  //                   url: 'https://documentation-od2-bot.s3.amazonaws.com/ACTIVOS/MARCAS-MODELOS/Manual+Marcas+y+Modelos+(1).pdf',
  //                   img: 'https://documentation-od2-bot.s3.amazonaws.com/ACTIVOS/MARCAS-MODELOS/19.png',
  //                 },
  //           ],
  //         },
  //         {
  //           id: 101104,
  //           name: 'VEHÍCULOS',
  //           permision: [' '],
  //           icon: <CarCrashIcon className='icon' style={{color: "white"}}/>,
  //           videos: [
  //                 {
  //                   code: 1011041,
  //                   titlevideo: "MANUAL DE VEHÍCULOS",
  //                   description: 'VEHÍCULOS.',
  //                   url: 'https://documentation-od2-bot.s3.amazonaws.com/ACTIVOS/VEHICULOS/Manual+Vehiculos+(1).pdf',
  //                   img: 'https://documentation-od2-bot.s3.amazonaws.com/ACTIVOS/VEHICULOS/18.png',
  //                 },
  //           ],
  //         },

  //     ]
  // },
  // {
  //     id: 102,
  //     name: 'BANCOS',
  //     icon: <AccountBalanceIcon className='icon' style={{color: "white"}}/>,
  //     restriccion:"",
  //     sections: [
  //       {
  //         id: 102101,
  //           name: 'CORTE DE CAJA',
  //           icon: <AccountBalanceWalletIcon className='icon' style={{color: "white"}}/>,
  //           permision: [' '],
  //           videos: [
  //             {
  //               code: 1021011,
  //               titlevideo: "MANUAL CORTE DE CAJA",
  //               description: 'MANUAL DE CORTE DE CAJA.',
  //               url: 'https://documentation-od2-bot.s3.amazonaws.com/BANCOS/CORTE-CAJA/Manual+de+Corte+de+Caja.pdf',
  //               img: 'https://documentation-od2-bot.s3.amazonaws.com/BANCOS/CORTE-CAJA/17.png',
  //             }
  //           ]
  //         },
  //         {
  //           id: 102102,
  //           name: 'DEUDAS',
  //           icon: <LocalAtmIcon className='icon' style={{color: "white"}}/>,
  //           permision: [' '],
  //           videos: [
  //             {
  //               code: 1021011,
  //               titlevideo: "MANUAL DE DEUDAS",
  //               description: 'MANUAL DE DEUDAS',
  //               url: 'https://documentation-od2-bot.s3.amazonaws.com/BANCOS/DEUDAS/DEUDAS.pdf',
  //               img: 'https://documentation-od2-bot.s3.amazonaws.com/BANCOS/DEUDAS/55.png',
  //             }
  //           ]
  //         },
  //         {
  //           id: 102103,
  //           name: 'PAGOS',
  //           icon: <AccountBalanceIcon className='icon' style={{color: "white"}}/>,
  //           permision: [' '],
  //           videos: [
  //             {
  //               code: 1021011,
  //               titlevideo: "MANUAL DE PAGOS",
  //               description: 'MANUAL DE PAGOS',
  //               url: 'https://documentation-od2-bot.s3.amazonaws.com/BANCOS/PAGOS/PAGOS.pdf',
  //               img: 'https://documentation-od2-bot.s3.amazonaws.com/BANCOS/PAGOS/54.png',
  //             }
  //           ]
  //         },
  //         {
  //           id: 102104,
  //           name: 'ANTIGUEDAD DE SALDOS',
  //           icon: <CreditScoreIcon className='icon' style={{color: "white"}}/>,
  //           permision: [' '],
  //           videos: [
  //             {
  //               code: 1021011,
  //               titlevideo: "MANUAL DE ANTIGUEDAD DE SALDOS",
  //               description: 'MANUAL DE ANTIGUEDAD DE SALDOS',
  //               url: 'https://documentation-od2-bot.s3.amazonaws.com/BANCOS/ANTIGUEDAD-SALDOS/Antig%C3%BCedad+de+saldos.pdf',
  //               img: 'https://documentation-od2-bot.s3.amazonaws.com/BANCOS/ANTIGUEDAD-SALDOS/2024.png',
  //             }
  //           ]
  //         }
  //       ]
  //   },
  //   {
  //       id: 103,
  //       name: 'COLABORADORES',
  //       icon: <HailIcon className='icon' style={{color: "white"}}/>,
  //       restriccion:"",
  //       sections: [
  //         {
  //           id: 103101,
  //           name: 'EMPLEADOS',
  //           icon: <EngineeringIcon className='icon' style={{color: "white"}}/>,
  //           permision: [' '],
  //           videos: [
  //             {
  //               code: 1031011,
  //               titlevideo: "MANUAL MODULO EMPLEADOS",
  //               description: 'MANUAL MODULO EMPLEADOS',
  //               url: 'https://documentation-od2-bot.s3.amazonaws.com/COLABORADORES/EMPLEADOS/Manual+Empleados.pdf',
  //               img: 'https://documentation-od2-bot.s3.amazonaws.com/COLABORADORES/EMPLEADOS/16.png',
  //             }
  //           ]
  //         },
  //         {
  //           id: 103102,
  //           name: 'CONDUCTORES',
  //           permision: [' '],
  //           icon: <AirlineSeatReclineNormalIcon className='icon' style={{color: "white"}}/>,
  //           videos: [
  //             {
  //               code: 1031021,
  //               titlevideo: "MANUAL MODULO CONDUCTORES",
  //               description: 'MANUAL MODULO CONDUCTORES',
  //               url: 'https://documentation-od2-bot.s3.amazonaws.com/COLABORADORES/CONDUCTORES/Manual+Conductores.pdf',
  //               img: 'https://documentation-od2-bot.s3.amazonaws.com/COLABORADORES/CONDUCTORES/15.png',
  //             }
  //           ]
  //         },
  //         {
  //           id: 103103,
  //           name: 'ASIGNACIÓN PERSONAL',
  //           permision: [' '],
  //           icon: <ContactMailIcon className='icon' style={{color: "white"}}/>,
  //           videos: [
  //             {
  //               code: 1031031,
  //               titlevideo: "MANUAL MODULO ASIGNACIÓN DE PERSONAL",
  //               description: 'MANUAL MODULO ASIGNACIÓN DE PERSONAL',
  //               url: 'https://documentation-od2-bot.s3.amazonaws.com/COLABORADORES/ASIGNACION-PERSONAL/Manual+Asignacio%CC%81n+de+Personal.pdf',
  //               img: 'https://documentation-od2-bot.s3.amazonaws.com/COLABORADORES/ASIGNACION-PERSONAL/14.png',
  //             }
  //           ]
  //         },
  //         {
  //           id: 103104,
  //           name: 'REGISTRO DE COMISIONES',
  //           permision: [' '],
  //           icon: <LocalAtmIcon className='icon' style={{color: "white"}}/>,
  //           videos: [
  //             {
  //               code: 1031041,
  //               titlevideo: "MANUAL MODULO REGISTRO DE COMISIONES",
  //               description: 'MANUAL MODULO REGISTRO DE COMISIONES',
  //               url: 'https://documentation-od2-bot.s3.amazonaws.com/COLABORADORES/REGISTRO-COMISIONES/Manual+Registro+de+Comisiones.pdf',
  //               img: 'https://documentation-od2-bot.s3.amazonaws.com/COLABORADORES/REGISTRO-COMISIONES/23.png',
  //             }
  //           ]
  //         }

  //       ]
  //   },
  //   {
  //        id: 104,
  //        name: 'FINANZAS',
  //        icon: <PriceChangeIcon className='icon' style={{color: "white"}}/>,
  //        restriccion:"",
  //        sections: [
  //          {
  //            id: 104101,
  //            name: 'CUENTAS CONTABLES',
  //            icon: <PointOfSaleIcon className='icon' style={{color: "white"}}/>,
  //            permision: [' '],
  //            videos: [
  //                {
  //                  code: 1041011,
  //                  titlevideo: "MANUAL DE CUENTAS CONTABLES",
  //                  description: 'MANUAL DE CUENTAS CONTABLES',
  //                  url: 'https://documentation-od2-bot.s3.amazonaws.com/FINANZAS/CUENTAS-CONTABLES/Manual+Cuentas+Contables.pdf',
  //                  img: 'https://documentation-od2-bot.s3.amazonaws.com/FINANZAS/CUENTAS-CONTABLES/13.png'
  //                 }
  //             ]
  //         },
  //         {
  //           id: 104101,
  //           name: 'CUENTAS PAGO',
  //           icon: <CurrencyExchangeIcon className='icon' style={{color: "white"}}/>,
  //           permision: [' '],
  //           videos: [
  //               {
  //                 code: 1041011,
  //                 titlevideo: "MANUAL DE CUENTAS DE PAGO",
  //                 description: 'MANUAL DE CUENTAS DE PAGO',
  //                 url: 'https://documentation-od2-bot.s3.amazonaws.com/FINANZAS/CUENTAS-PAGO/Manual+de+Cuentas+de+Pago.pdf',
  //                 img: 'https://documentation-od2-bot.s3.amazonaws.com/FINANZAS/CUENTAS-PAGO/12.png'
  //                }
  //            ]
  //        }
  //       ]
  //   },
  //   {
  //     id: 105,
  //     name: 'INVENTARIOS',
  //     icon: <InventoryIcon className='icon' style={{color: "white"}}/>,
  //     restriccion:"",
  //     sections: [
  //       {
  //         id: 105101,
  //         name: 'LINEA DE PRODUCTOS',
  //         icon: <FormatLineSpacingIcon className='icon' style={{color: "white"}}/>,
  //         permision: [' '],
  //         videos: [
  //             {
  //               code: 1051011,
  //               titlevideo: "MANUAL DE LINEA DE PRODUCTOS",
  //               description: 'MANUAL DE LINEA DE PRODUCTOS',
  //               url: 'https://documentation-od2-bot.s3.amazonaws.com/FINANZAS/CUENTAS-CONTABLES/Manual+Cuentas+Contables.pdfhttps://documentation-od2-bot.s3.amazonaws.com/INVENTARIOS/LINEA-PRODUCTOS/Manual+Linea+de+Productos.pdf',
  //               img: 'https://documentation-od2-bot.s3.amazonaws.com/INVENTARIOS/LINEA-PRODUCTOS/2024.png'
  //              }
  //          ]
  //      },
  //      {
  //       id: 105102,
  //       name: 'PRODUCTOS',
  //       icon: <IcecreamIcon className='icon' style={{color: "white"}}/>,
  //       permision: [' '],
  //       videos: [
  //           {
  //             code: 1051011,
  //             titlevideo: "MANUAL DE PRODUCTOS",
  //             description: 'MANUAL DE PRODUCTOS',
  //             url: 'https://documentation-od2-bot.s3.amazonaws.com/INVENTARIOS/PRODUCTOS/Manual+Productos.pdf',
  //             img: 'https://documentation-od2-bot.s3.amazonaws.com/INVENTARIOS/PRODUCTOS/2024+(3).png'
  //            }
  //        ]
  //    },
  //    {
  //         id: 105103,
  //         name: 'LISTA DE PRECIOS',
  //         icon: <ListAltIcon className='icon' style={{color: "white"}}/>,
  //         permision: [' '],
  //         videos: [
  //             {
  //               code: 1051031,
  //               titlevideo: "MANUAL DE LISTA DE PRECIOS",
  //               description: 'MANUAL DE LISTA DE PRECIOS',
  //               url: 'https://documentation-od2-bot.s3.amazonaws.com/INVENTARIOS/LISTA-PRECIOS/Manual+Lista+de+Precios.pdf',
  //               img: 'https://documentation-od2-bot.s3.amazonaws.com/INVENTARIOS/LISTA-PRECIOS/2024+(1).png'
  //             }
  //         ]
  //     },
  //     {
  //         id: 105104,
  //         name: 'ALMACEN DE PRODUCTOS',
  //         icon: <WarehouseIcon className='icon' style={{color: "white"}}/>,
  //         permision: [' '],
  //         videos: [
  //             {
  //               code: 1051031,
  //               titlevideo: "MANUAL DE ALMACEN DE PRODUCTOS",
  //               description: 'MANUAL DE ALMACEN DE PRODUCTOS',
  //               url: 'https://documentation-od2-bot.s3.amazonaws.com/INVENTARIOS/ALMACEN-PRODUCTOS/Manual+Almacen+de+productos.pdf',
  //               img: 'https://documentation-od2-bot.s3.amazonaws.com/INVENTARIOS/ALMACEN-PRODUCTOS/28.png'
  //             }
  //         ]
  //     },
  //     {
  //         id: 105105,
  //         name: 'MOVIMIENTO DE PRODUCTOS',
  //         icon: <ExitToAppIcon className='icon' style={{color: "white"}}/>,
  //         permision: [' '],
  //         videos: [
  //             {
  //               code: 1051031,
  //               titlevideo: "MANUAL DE MOVIMIENTO DE PRODUCTOS",
  //               description: 'MANUAL DE MOVIMIENTO DE PRODUCTOS',
  //               url: 'https://documentation-od2-bot.s3.amazonaws.com/INVENTARIOS/MOVIMIENTO-PRODUCTOS/MOVIMIENTO+DE+PRODUCTOS.pdf',
  //               img: 'https://documentation-od2-bot.s3.amazonaws.com/INVENTARIOS/MOVIMIENTO-PRODUCTOS/41.png'
  //             }
  //         ]
  //     },
  //     {
  //         id: 105106,
  //         name: 'CARGA DE PRODUCTOS',
  //         icon: <LocalShippingIcon className='icon' style={{color: "white"}}/>,
  //         permision: [' '],
  //         videos: [
  //             {
  //               code: 1051031,
  //               titlevideo: "MANUAL DE CARGA DE PRODUCTOS",
  //               description: 'MANUAL DE CARGA DE PRODUCTOS',
  //               url: 'https://documentation-od2-bot.s3.amazonaws.com/INVENTARIOS/CARGA-PRODUCTOS/Manual+Carga+de+Productos.pdf',
  //               img: 'https://documentation-od2-bot.s3.amazonaws.com/INVENTARIOS/CARGA-PRODUCTOS/27.png'
  //             }
  //         ]
  //     },

  //    ]
  //   },
  //   {
  //     id: 106,
  //     name: 'REPORTES',
  //     icon: <AssessmentIcon className='icon' style={{color: "white"}}/>,
  //     restriccion:"",
  //     sections: [
  //       {
  //         id: 106101,
  //         name: 'MAPA DE RUTAS',
  //         icon: <PublicIcon className='icon' style={{color: "white"}}/>,
  //         permision: [' '],
  //         videos: [
  //             {
  //               code: 1051011,
  //               titlevideo: "MANUAL DE MAPA DE RUTAS",
  //               description: 'MANUAL DE MAPA DE RUTAS',
  //               url: 'https://documentation-od2-bot.s3.amazonaws.com/REPORTES/MAPAS-RUTAS/Manual+Mapa+de+Rutas.pdf',
  //               img: 'https://documentation-od2-bot.s3.amazonaws.com/REPORTES/MAPAS-RUTAS/2024+(11).png'
  //              }
  //          ]
  //      },
  //      {
  //         id: 106102,
  //         name: 'REPORTE DE VENTAS',
  //         icon: <PlagiarismIcon className='icon' style={{color: "white"}}/>,
  //         permision: [' '],
  //         videos: [
  //             {
  //               code: 1051011,
  //               titlevideo: "MANUAL DE REPORTE DE VENTAS",
  //               description: 'MANUAL DE REPORTE DE VENTAS',
  //               url: 'https://documentation-od2-bot.s3.amazonaws.com/REPORTES/REPORTE-VENTAS/MANUAL+REPORTE+DE+VENTAS.pdf',
  //               img: 'https://documentation-od2-bot.s3.amazonaws.com/REPORTES/REPORTE-VENTAS/2024+(19).png'
  //             }
  //         ]
  //       },
  //       {
  //           id: 106103,
  //           name: 'REPORTE DE VENTAS POR RUTA',
  //           icon: <TrendingDownIcon className='icon' style={{color: "white"}}/>,
  //           permision: [' '],
  //           videos: [
  //               {
  //                 code: 1051011,
  //                 titlevideo: "MANUAL DE REPORTE DE VENTAS POR RUTA",
  //                 description: 'MANUAL DE REPORTE DE VENTAS POR RUTA',
  //                 url: 'https://documentation-od2-bot.s3.amazonaws.com/REPORTES/REPORTE-VENTAS-RUTA/Manual+Ventas+por+Ruta.pdf',
  //                 img: 'https://documentation-od2-bot.s3.amazonaws.com/REPORTES/REPORTE-VENTAS-RUTA/2024+(4).png'
  //               }
  //           ]
  //       },
  //       {
  //         id: 106104,
  //         name: 'REPORTE DE VENTAS EN TIEMPO REAL',
  //         icon: <PendingActionsIcon className='icon' style={{color: "white"}}/>,
  //         permision: [' '],
  //         videos: [
  //             {
  //               code: 1051011,
  //               titlevideo: "MANUAL DE REPORTE DE VENTAS EN TIEMPO REAL",
  //               description: 'MANUAL DE REPORTE DE VENTAS EN TIEMPO REAL',
  //               url: 'https://documentation-od2-bot.s3.amazonaws.com/REPORTES/REPORTE-VENTAS-TIEMPO-REAL/Manual+Ventas+en+tiempo+real.pdf',
  //               img: 'https://documentation-od2-bot.s3.amazonaws.com/REPORTES/REPORTE-VENTAS-TIEMPO-REAL/2024+(2).png'
  //             }
  //         ]
  //       },
  //       {
  //         id: 106105,
  //         name: 'SUMARIA SEMANAL',
  //         icon: <SummarizeIcon className='icon' style={{color: "white"}}/>,
  //         permision: [' '],
  //         videos: [
  //             {
  //               code: 1051011,
  //               titlevideo: "MANUAL DE SUMARIA SEMANAL",
  //               description: 'MANUAL DE SUMARIA SEMANAL',
  //               url: 'https://documentation-od2-bot.s3.amazonaws.com/REPORTES/SUMARIA-SEMANAL/Manual+Sumaria+Semanal.pdf',
  //               img: 'https://documentation-od2-bot.s3.amazonaws.com/REPORTES/SUMARIA-SEMANAL/30.png'
  //             }
  //         ]
  //       },
  //       {
  //         id: 106106,
  //         name: 'SUMARIA ANUAL',
  //         icon: <SummarizeIcon className='icon' style={{color: "white"}}/>,
  //         permision: [' '],
  //         videos: [
  //             {
  //               code: 1051011,
  //               titlevideo: "MANUAL DE SUMARIA ANUAL",
  //               description: 'MANUAL DE SUMARIA ANUAL',
  //               url: 'https://documentation-od2-bot.s3.amazonaws.com/REPORTES/SUMARIA-ANUAL/Manual+Sumaria+Anual.pdf',
  //               img: 'https://documentation-od2-bot.s3.amazonaws.com/REPORTES/SUMARIA-ANUAL/31.png'
  //             }
  //         ]
  //       },

  //     ]
  //   },
  //   {
  //     id: 107,
  //     name: 'COMPRAS',
  //     icon: <ShoppingCartIcon className='icon' style={{color: "white"}}/>,
  //     restriccion:"",
  //     sections: [

  //       {
  //         id: 107101,
  //         name: "CONSULTAR DOCUMENTOS ENVIADOS",
  //         icon: <ContentPasteSearchIcon className='icon' style={{color: "white"}}/>,
  //         permision: [' '],
  //         videos: [
  //           {
  //             code: 1071011,
  //             titlevideo: "MANUAL DE DOCUMENTOS ENVIADOS",
  //             description: 'MANUAL DE DOCUMENTOS ENVIADOS',
  //             url: 'https://documentation-od2-bot.s3.amazonaws.com/COMPRAS/DOCUMENTOS_ENVIADOS/MANUAL+FACTURAS+PROVEEDORES.pdf',
  //             img: 'https://documentation-od2-bot.s3.amazonaws.com/COMPRAS/DOCUMENTOS_ENVIADOS/2024+(5).png',
  //           }
  //         ]
  //       },
  //       {
  //         id: 107102,
  //         name: "ENVIO DE DOCUMENTOS",
  //         icon: <UploadFileIcon className='icon' style={{color: "white"}}/>,
  //         permision: [' '],
  //         videos: [
  //           {
  //             code: 1071021,
  //             titlevideo: "MANUAL DE ENVIO DE DOCUMENTOS",
  //             description: 'MANUAL DE ENVIO DE DOCUMENTOS',
  //             url: 'https://documentation-od2-bot.s3.amazonaws.com/COMPRAS/ENVIO-DOCUMENTOS/MANUAL+ENVIO+DE+DOCUMENTOS.pdf',
  //             img: 'https://documentation-od2-bot.s3.amazonaws.com/COMPRAS/ENVIO-DOCUMENTOS/2024+(6).png',
  //           }
  //         ]
  //       },
  //       {
  //         id: 107103,
  //         name: "PROVEEDORES",
  //         icon: <ContactMailIcon className='icon' style={{color: "white"}}/>,
  //         permision: [' '],
  //         videos: [
  //           {
  //             code: 1071031,
  //             titlevideo: "MANUAL DE PROVEEDORES",
  //             description: 'MANUAL DE PROVEEDORES',
  //             url: 'https://documentation-od2-bot.s3.amazonaws.com/COMPRAS/PROVEEDORES/MANUAL+PROVEEDOFRE.pdf',
  //             img: 'https://documentation-od2-bot.s3.amazonaws.com/COMPRAS/PROVEEDORES/2024+(8).png',
  //           }
  //         ]
  //       },
  //       {
  //         id: 107104,
  //         name: "CATALOGO DE GASTOS",
  //         icon: <BorderAllIcon className='icon' style={{color: "white"}}/>,
  //         permision: [' '],
  //         videos: [
  //           {
  //             code: 1071041,
  //             titlevideo: "MANUAL DE CATALOGO DE GASTOS",
  //             description: 'MANUAL DE CATALOGO DE GASTOS',
  //             url: 'https://documentation-od2-bot.s3.amazonaws.com/COMPRAS/CATALOGO-GASTOS/MANUAL+CATALOGO+DE+GASTOS.pdf',
  //             img: 'https://documentation-od2-bot.s3.amazonaws.com/COMPRAS/CATALOGO-GASTOS/2024+(20).png',
  //           }
  //         ]
  //       },
  //       {
  //         id: 107105,
  //         name: "REGLA DE GASTOS",
  //         icon: <GavelIcon className='icon' style={{color: "white"}}/>,
  //         permision: [' '],
  //         videos: [
  //           {
  //             code: 1071051,
  //             titlevideo: "MANUAL DE REGLA DE GASTOS",
  //             description: 'MANUAL DE REGLA DE GASTOS',
  //             url: 'https://documentation-od2-bot.s3.amazonaws.com/COMPRAS/REGLA-GASTOS/MANUAL+REGLA+DE+GASTOS.pdf',
  //             img: 'https://documentation-od2-bot.s3.amazonaws.com/COMPRAS/REGLA-GASTOS/2024+(1).png',
  //           }
  //         ]
  //       },
  //       {
  //         id: 107106,
  //         name: "GASTOS NO DEDUCIBLES",
  //         icon: <BallotIcon className='icon' style={{color: "white"}}/>,
  //         permision: [' '],
  //         videos: [
  //           {
  //             code: 1071061,
  //             titlevideo: "MANUAL DE GASTOS NO DEDUCIBLES",
  //             description: 'MANUAL DE GASTOS NO DEDUCIBLES',
  //             url: 'https://documentation-od2-bot.s3.amazonaws.com/COMPRAS/GASTOS-NO-DEDUCIBLES/MANUAL+GASTOS+NO+DEDUCIBLES+Y+EXTRANJEROS.pdf',
  //             img: 'https://documentation-od2-bot.s3.amazonaws.com/COMPRAS/GASTOS-NO-DEDUCIBLES/2024+(13).png',
  //           }
  //         ]
  //       },
  //       {
  //         id: 107107,
  //         name: "GASTOS DEL EXTRANJERO",
  //         icon: <FlightIcon className='icon' style={{color: "white"}}/>,
  //         permision: [' '],
  //         videos: [
  //           {
  //             code: 1071071,
  //             titlevideo: "MANUAL DE GASTOS DEL EXTRANJERO",
  //             description: 'MANUAL DE GASTOS DEL EXTRANJERO',
  //             url: 'https://documentation-od2-bot.s3.amazonaws.com/COMPRAS/GASTOS-NO-DEDUCIBLES/MANUAL+GASTOS+NO+DEDUCIBLES+Y+EXTRANJEROS.pdf',
  //             img: 'https://documentation-od2-bot.s3.amazonaws.com/COMPRAS/GASTOS-NO-DEDUCIBLES/2024+(13).png',
  //           }
  //         ]
  //       },
  //       {
  //         id: 107108,
  //         name: "REPORTE DE GASTOS",
  //         icon: <ReceiptLongIcon className='icon' style={{color: "white"}}/>,
  //         permision: [' '],
  //         videos: [
  //           {
  //             code: 1071081,
  //             titlevideo: "MANUAL DEL REPORTE DE GASTOS",
  //             description: 'MANUAL DEL REPORTE DE GASTOS',
  //             url: 'https://documentation-od2-bot.s3.amazonaws.com/COMPRAS/REPORTE-GASTOS/MANUAL+REPORTE+DE+GASTOS.pdf',
  //             img: 'https://documentation-od2-bot.s3.amazonaws.com/COMPRAS/REPORTE-GASTOS/2024+(14).png',
  //           }
  //         ]
  //       },
  //       {
  //         id: 107109,
  //         name: "REPORTE DE GASTOS AGRUPADOS",
  //         icon: <RecentActorsIcon className='icon' style={{color: "white"}}/>,
  //         permision: [' '],
  //         videos: [
  //           {
  //             code: 1071091,
  //             titlevideo: "MANUAL DEL REPORTE DE GASTOS AGRUPADOS",
  //             description: 'MANUAL DEL REPORTE DE GASTOS AGRUPADOS',
  //             url: 'https://documentation-od2-bot.s3.amazonaws.com/COMPRAS/REPORTE-GASTOS-AGRUPADOS/MANUAL+REPORTE+DE+GASTOS+AGRUPADOS.pdf',
  //             img: 'https://documentation-od2-bot.s3.amazonaws.com/COMPRAS/REPORTE-GASTOS-AGRUPADOS/2024+(15).png',
  //           }
  //         ]
  //       },
  //       {
  //         id: 107110,
  //         name: "POLIZAS",
  //         icon: <RequestPageIcon className='icon' style={{color: "white"}}/>,
  //         permision: [' '],
  //         videos: [
  //           {
  //             code: 1071101,
  //             titlevideo: "MANUAL DE POLIZAS",
  //             description: 'MANUAL DE POLIZAS',
  //             url: 'https://documentation-od2-bot.s3.amazonaws.com/COMPRAS/POLIZAS/MANUAL+POLIZAS.pdf',
  //             img: 'https://documentation-od2-bot.s3.amazonaws.com/COMPRAS/POLIZAS/2024+(16).png',
  //           }
  //         ]
  //       },
  //     ]
  // },

  //   {
  //       id: 108,
  //       name: 'FACTURAS',
  //       icon: <LibraryBooksIcon className='icon' style={{color: "white"}}/>,
  //       restriccion:"",
  //       sections: [

  //         {
  //           id: 108101,
  //           name: "CONCEPTOS",
  //           icon: <CreditScoreIcon className='icon' style={{color: "white"}}/>,
  //           permision: [' '],
  //           videos: [
  //             {
  //               code: 1081011,
  //               titlevideo: "MANUAL DE CONCEPTOS",
  //               description: 'MANUAL DE CONCEPTOS',
  //               url: 'https://documentation-od2-bot.s3.amazonaws.com/FACTURAS/CONCEPTOS/Manual+Conceptos.pdf',
  //               img: 'https://documentation-od2-bot.s3.amazonaws.com/FACTURAS/CONCEPTOS/2024+(9).png',
  //             }
  //           ]
  //         },
  //         {
  //           id: 108102,
  //           name: "CONSULTAR FACTURAS",
  //           icon: <RequestQuoteIcon className='icon' style={{color: "white"}}/>,
  //           permision: [' '],
  //           videos: [
  //             {
  //               code: 1081021,
  //               titlevideo: "MANUAL DE FACTURAS",
  //               description: 'MANUAL DE FACTURAS',
  //               url: 'https://documentation-od2-bot.s3.amazonaws.com/FACTURAS/FACTURAS/Manual+Consultar+Facturas.pdf',
  //               img: 'https://documentation-od2-bot.s3.amazonaws.com/FACTURAS/FACTURAS/11.png',
  //             }
  //           ]
  //         },
  //         {
  //           id: 108103,
  //           name: "CONSULTAR FACTURAS PPD",
  //           icon: <RequestQuoteIcon className='icon' style={{color: "white"}}/>,
  //           permision: [' '],
  //           videos: [
  //             {
  //               code: 1081031,
  //               titlevideo: "MANUAL DE FACTURAS PPD",
  //               description: 'MANUAL DE FACTURAS PPD',
  //               url: 'https://documentation-od2-bot.s3.amazonaws.com/FACTURAS/FACTURAS-PPD/Manual+Consultar+Facturas+PPD.pdf',
  //               img: 'https://documentation-od2-bot.s3.amazonaws.com/FACTURAS/FACTURAS-PPD/2024+(7).png',
  //             }
  //           ]
  //         },
  //         {
  //           id: 108104,
  //           name: "IMPUESTOS",
  //           icon: <MoneyOffIcon className='icon' style={{color: "white"}}/>,
  //           permision: [' '],
  //           videos: [
  //             {
  //               code: 1081041,
  //               titlevideo: "MANUAL DE IMPUESTOS",
  //               description: 'MANUAL DE IMPUESTOS',
  //               url: 'https://documentation-od2-bot.s3.amazonaws.com/FACTURAS/IMPUESTOS/Manual+Impuestos.pdf',
  //               img: 'https://documentation-od2-bot.s3.amazonaws.com/FACTURAS/IMPUESTOS/40.png',
  //             }
  //           ]
  //         },
  //         {
  //           id: 108105,
  //           name: "REALIZAR COMPLEMENTO DE PAGO",
  //           icon: <CurrencyExchangeIcon className='icon' style={{color: "white"}}/>,
  //           permision: [' '],
  //           videos: [
  //             {
  //               code: 1081051,
  //               titlevideo: "REALIZAR COMPLEMENTO DE PAGO",
  //               description: 'REALIZAR COMPLEMENTO DE PAGO',
  //               url: 'https://documentation-od2-bot.s3.amazonaws.com/FACTURAS/REALIZAR-COMPLEMENTO-PAGO/MANUAL+REALIZAR+COMPLEMENTO+DE+PAGO.pdf',
  //               img: 'https://documentation-od2-bot.s3.amazonaws.com/FACTURAS/REALIZAR-COMPLEMENTO-PAGO/2024+(17).png',
  //             }
  //           ]
  //         },
  //         {
  //           id: 108106,
  //           name: "REALIZAR FACTURA",
  //           icon: <FeedIcon className='icon' style={{color: "white"}}/>,
  //           permision: [' '],
  //           videos: [
  //             {
  //               code: 1081061,
  //               titlevideo: "REALIZAR FACTURA",
  //               description: 'REALIZAR FACTURA',
  //               url: 'https://documentation-od2-bot.s3.amazonaws.com/FACTURAS/REALIZAR-FACTURA/Manual+Realizar+Factura.pdf',
  //               img: 'https://documentation-od2-bot.s3.amazonaws.com/FACTURAS/REALIZAR-FACTURA/10.png',
  //             }
  //           ]
  //         },
  //         {
  //           id: 108107,
  //           name: "REPORTE COMPLEMENTO DE PAGO",
  //           icon: <SummarizeIcon className='icon' style={{color: "white"}}/>,
  //           permision: [' '],
  //           videos: [
  //             {
  //               code: 1081071,
  //               titlevideo: "REPORTE COMPLEMENTO DE PAGO",
  //               description: 'REPORTE COMPLEMENTO DE PAGO',
  //               url: 'https://documentation-od2-bot.s3.amazonaws.com/FACTURAS/REPORTE-COMPLEMENTO-PAGO/MANUAL+REPORTE+COMPLEMENTO+DE+PAGO.pdf',
  //               img: 'https://documentation-od2-bot.s3.amazonaws.com/FACTURAS/REPORTE-COMPLEMENTO-PAGO/2024+(10).png',
  //             }
  //           ]
  //         },
  //         {
  //           id: 108108,
  //           name: "REALIZAR CARTA PORTE",
  //           icon: <CarCrashIcon className='icon' style={{color: "white"}}/>,
  //           permision: [' '],
  //           videos: [
  //             {
  //               code: 1081071,
  //               titlevideo: "REALIZAR CARTA PORTE",
  //               description: 'REALIZAR CARTA PORTE',
  //               url: 'https://documentation-od2-bot.s3.amazonaws.com/FACTURAS/CARTA-PORTE/Manual+Realizar+Carta+Porte.pdf',
  //               img: 'https://documentation-od2-bot.s3.amazonaws.com/FACTURAS/CARTA-PORTE/2024+(1).png',
  //             }
  //           ]
  //         },
  //       ]
  //   },
  //   {
  //        id: 108,
  //        name: 'SOCIOS DE NEGOCIOS',
  //        icon: <Groups2Icon className='icon' style={{color: "white"}}/>,
  //        restriccion:"",
  //        sections: [
  //          {
  //            id: 108101,
  //            name: 'DESCUENTOS',
  //            icon: <PercentIcon className='icon' style={{color: "white"}}/>,
  //            permision: [' '],
  //            videos: [
  //              {
  //               code: 1081011,
  //                titlevideo: "MANUAL DESCUENTOS",
  //                description: 'MANUAL DESCUENTOS',
  //                url: 'https://documentation-od2-bot.s3.amazonaws.com/SOCIOS-NEGOCIOS/DESCUENTOS/Manual+de+Descuentos.pdf',
  //                img: 'https://documentation-od2-bot.s3.amazonaws.com/SOCIOS-NEGOCIOS/DESCUENTOS/2.png',
  //             }
  //            ]
  //          },
  //          {
  //            id: 108102,
  //            name: 'PROMOCIONES',
  //            icon: <DiscountIcon className='icon' style={{color: "white"}}/>,
  //            permision: [' '],
  //            videos: [
  //              {
  //                code: 1081021,
  //                titlevideo: "MANUAL PROMOCIONES",
  //                description: 'MANUAL PROMOCIONES.',
  //                url: 'https://documentation-od2-bot.s3.amazonaws.com/SOCIOS-NEGOCIOS/PROMOCIONES/Manual+de+Promociones.pdf',
  //                img: 'https://documentation-od2-bot.s3.amazonaws.com/SOCIOS-NEGOCIOS/PROMOCIONES/1.png',
  //              }
  //            ]
  //          },
  //         {
  //            id: 108103,
  //            name: 'PUNTOS DE VENTA',
  //            icon: <StoreIcon className='icon' style={{color: "white"}}/>,
  //            permision: [' '],
  //            videos: [
  //              {
  //               code: 1081031,
  //                titlevideo: "MANUAL PUNTOS DE VENTA",
  //                description: 'MANUAL PUNTOS DE VENTA',
  //                url: 'https://documentation-od2-bot.s3.amazonaws.com/SOCIOS-NEGOCIOS/PUNTOS-VENTA/Manual+de+Puntos+de+Venta.pdf',
  //                img: 'https://documentation-od2-bot.s3.amazonaws.com/SOCIOS-NEGOCIOS/PUNTOS-VENTA/4.png',
  //              }
  //            ]
  //          },
  //          {
  //           id: 108104,
  //           name: 'PUNTOS DE VENTA SIN SECTOR',
  //           icon: <StorefrontIcon className='icon' style={{color: "white"}}/>,
  //           permision: [' '],
  //           videos: [
  //             {
  //              code: 1081041,
  //               titlevideo: "MANUAL PUNTOS DE VENTA SIN SECTOR",
  //               description: 'MANUAL PUNTOS DE VENTA SIN SECTOR',
  //               url: 'https://documentation-od2-bot.s3.amazonaws.com/SOCIOS-NEGOCIOS/PUNTOS-VENTA-SIN-SECTOR/Manual+de+Puntos+de+Venta+sin+Sector.pdf',
  //               img: 'https://documentation-od2-bot.s3.amazonaws.com/SOCIOS-NEGOCIOS/PUNTOS-VENTA-SIN-SECTOR/5.png',
  //             }
  //           ]
  //         },
  //         {
  //           id: 108104,
  //           name: 'SECTORES',
  //           icon: <RoomIcon className='icon' style={{color: "white"}}/>,
  //           permision: [' '],
  //           videos: [
  //             {
  //              code: 1081041,
  //               titlevideo: "MANUAL SECTORES",
  //               description: 'MANUAL SECTORES',
  //               url: 'https://documentation-od2-bot.s3.amazonaws.com/SOCIOS-NEGOCIOS/SECTORES/Manual+de+Sectores.pdf',
  //               img: 'https://documentation-od2-bot.s3.amazonaws.com/SOCIOS-NEGOCIOS/SECTORES/24.png',
  //             }
  //           ]
  //         },
  //         {
  //           id: 108104,
  //           name: 'SOCIOS DE NEGOCIOS',
  //           icon: <SwitchAccountIcon className='icon' style={{color: "white"}}/>,
  //           permision: [' '],
  //           videos: [
  //             {
  //              code: 1081041,
  //               titlevideo: "MANUAL SOCIOS DE NEGOCIOS",
  //               description: 'MANUAL SOCIOS DE NEGOCIOS',
  //               url: 'https://documentation-od2-bot.s3.amazonaws.com/SOCIOS-NEGOCIOS/SOCIOS-NEGOCIO/Manual+de+Socios+de+Negocios.pdf',
  //               img: 'https://documentation-od2-bot.s3.amazonaws.com/SOCIOS-NEGOCIOS/SOCIOS-NEGOCIO/22.png',
  //             }
  //           ]
  //         },
  //         {
  //           id: 108104,
  //           name: 'RECUPERAR SOCIO DE NEGOCIOS',
  //           icon: <ReduceCapacityIcon className='icon' style={{color: "white"}}/>,
  //           permision: [' '],
  //           videos: [
  //             {
  //              code: 1081041,
  //               titlevideo: "MANUAL RECUPERAR SOCIO DE NEGOCIOS",
  //               description: 'MANUAL RECUPERAR SOCIO DE NEGOCIOS',
  //               url: 'https://documentation-od2-bot.s3.amazonaws.com/SOCIOS-NEGOCIOS/RECUPERAR-SOCIO-NEGOCIOS/Manual+de+Recuperar+Socio.pdf',
  //               img: 'https://documentation-od2-bot.s3.amazonaws.com/SOCIOS-NEGOCIOS/RECUPERAR-SOCIO-NEGOCIOS/3.png',
  //             }
  //           ]
  //         },
  //         {
  //           id: 108104,
  //           name: 'REGISTRO GENERAL',
  //           icon: <RecentActorsIcon className='icon' style={{color: "white"}}/>,
  //           permision: [' '],
  //           videos: [
  //             {
  //              code: 1081041,
  //               titlevideo: "MANUAL REGISTRO GENERAL",
  //               description: 'MANUAL REGISTOR GENERAL',
  //               url: 'https://documentation-od2-bot.s3.amazonaws.com/SOCIOS-NEGOCIOS/REGISTRO-GENERAL/Manual+de+Registro+General.pdf',
  //               img: 'https://documentation-od2-bot.s3.amazonaws.com/SOCIOS-NEGOCIOS/REGISTRO-GENERAL/2024.png',
  //             }
  //           ]
  //         },
  //         ]
  //       },
  //       {
  //         id: 109,
  //         name: 'OPERACIONES CON UNILEVER',
  //         icon: <TopicIcon className='icon' style={{color: "white"}}/>,
  //         restriccion:"",
  //         sections: [

  //           {
  //             id: 110101,
  //             name: "REPORTE DE BONIFICACIONES",
  //             icon: <ReceiptLongIcon className='icon' style={{color: "white"}}/>,
  //             permision: [' '],
  //             videos: [
  //               {
  //                 code: 1101011,
  //                 titlevideo: "MANUAL DE BONIFICACIONES",
  //                 description: 'MANUAL DE BONIFICACIONES.',
  //                 url: 'https://documentation-od2-bot.s3.amazonaws.com/OPERACIONES-UNILEVER/BONIFICACIONES/MANUAL+REPORTE+DE+BONIFICACIONES.pdf',
  //                 img: 'https://documentation-od2-bot.s3.amazonaws.com/OPERACIONES-UNILEVER/BONIFICACIONES/2024+(12).png',
  //               }
  //             ]
  //           },
  //           {
  //             id: 110102,
  //             name: "DMS",
  //             icon: <IntegrationInstructionsIcon className='icon' style={{color: "white"}}/>,
  //             permision: [' '],
  //             videos: [
  //               {
  //                 code: 1101021,
  //                 titlevideo: "MANUAL DE DMS",
  //                 description: 'MANUAL DE DMS',
  //                 url: 'https://documentation-od2-bot.s3.amazonaws.com/OPERACIONES-UNILEVER/DMS/MANUAL+DMS.pdf',
  //                 img: 'https://documentation-od2-bot.s3.amazonaws.com/OPERACIONES-UNILEVER/DMS/2024+(18).png',
  //               }
  //             ]
  //           },
  //         ]
  //     },
  //   {
  //       id: 110,
  //       name: 'CONFIGURACIÓN',
  //       icon: <BuildIcon className='icon' style={{color: "white"}}/>,
  //       restriccion:"",
  //       sections: [

  //         {
  //           id: 110101,
  //           name: "RUTAS",
  //           icon: <ForkLeftIcon className='icon' style={{color: "white"}}/>,
  //           permision: [' '],
  //           videos: [
  //             {
  //               code: 1101011,
  //               titlevideo: "MANUAL CONFIGURACION DE RUTAS",
  //               description: 'MANUAL CONFIGURACION DE RUTAS.',
  //               url: 'https://documentation-od2-bot.s3.amazonaws.com/CONFIGURACION/RUTAS/Manual+de+Rutas.pdf',
  //               img: 'https://documentation-od2-bot.s3.amazonaws.com/CONFIGURACION/RUTAS/8.png',
  //             }
  //           ]
  //         },
  //         {
  //           id: 110102,
  //           name: "ALMACENES",
  //           icon: <WarehouseIcon className='icon' style={{color: "white"}}/>,
  //           permision: [' '],
  //           videos: [
  //             {
  //               code: 1101021,
  //               titlevideo: "MANUAL CONFIGURACION DE ALMACENES",
  //               description: 'MANUAL CONFIGURACION DE ALMACENES.',
  //               url: 'https://documentation-od2-bot.s3.amazonaws.com/CONFIGURACION/ALMACENES/Manual+de+Almacenes.pdf',
  //               img: 'https://documentation-od2-bot.s3.amazonaws.com/CONFIGURACION/ALMACENES/7.png',
  //             }
  //           ]
  //         },
  //         {
  //           id: 110103,
  //           name: "GESTION DE RUTAS",
  //           icon: <AltRouteIcon className='icon' style={{color: "white"}}/>,
  //           permision: [' '],
  //           videos: [
  //             {
  //               code: 1101031,
  //               titlevideo: "MANUAL DE GESTION DE RUTAS",
  //               description: 'MANUAL DE GESTION DE RUTAS.',
  //               url: 'https://documentation-od2-bot.s3.amazonaws.com/CONFIGURACION/GESTION-RUTAS/Manual+Gestio%CC%81n+de+Rutas.pdf',
  //               img: 'https://documentation-od2-bot.s3.amazonaws.com/CONFIGURACION/GESTION-RUTAS/6.png',
  //             }
  //           ]
  //         },

  //       ]
  //   },
  //   {
  //     id: 111,
  //     name: 'SOPORTES',
  //     icon: <SupportAgentIcon className='icon' style={{color: "white"}}/>,
  //     restriccion:"",
  //     sections: [

  //       {
  //         id: 110101,
  //         name: "CENTRO DE AYUDA",
  //         icon: <LocalActivityIcon className='icon' style={{color: "white"}}/>,
  //         permision: [' '],
  //         videos: [
  //           {
  //             code: 1101011,
  //             titlevideo: "MANUAL DE CENTRO DE AYUDA",
  //             description: 'MANUAL DE CENTRO DE AYUDA',
  //             url: 'https://documentation-od2-bot.s3.amazonaws.com/SOPORTE/CENTRO-AYUDA/Manual+Centro+de+Ayuda.pdf',
  //             img: 'https://documentation-od2-bot.s3.amazonaws.com/SOPORTE/CENTRO-AYUDA/9.png',
  //           }
  //         ]
  //       },
  //     ]
  // }
];
