//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import { StickyTable, Row, Cell } from "react-sticky-table";
import { CustomDialog, useDialog, Confirm } from "react-st-modal";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
//COMPONENTES
import SubTableContainer from "../../SubTableContainer";
import CREATE_UPDATE from "./tools/create_update_detail";
import { get_expense_detail,delete_expense_detail } from "../../../services/expenses/expenses";
//ESTILOS
import "../../../componentsCFDI/estilo.css";
import "../../../componentsCFDI/modal.css";
import "../../../componentsCFDI/table.css";
import "../../expense.css";

//IMAGENES
import DELETE from "../../../imgComponents/borrar.png";
import Spinner from "../../../imgComponents/S.gif";
import SAVE from "../../../imgComponents/save.png";
import ADD from "../../../imgComponents/add.png";
import reconsultaTable from "../../../imgComponents/reconsultaTable.png";

import { faBullseye } from "@fortawesome/free-solid-svg-icons";

const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function SubTable(props) {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [equality, setEquality] = useState("");
  const [success_msg, setSuccess_msg] = useState("none");
  const [error_msg, setError_msg] = useState("none");
  const [Reconsulta, setReconsulta] = useState(false);
  const handleSubmit = async () => {
    setReconsulta(true);
      try {
        var dt = await get_expense_detail(props.row.original.id);
        const data = dt.data;
        console.log("hola xf")
        setData(data);
        const cargos = data.filter((item) => item.efect === "CARGO");
        const abonos = data.filter((item) => item.efect === "ABONO");
        const sumCargos = cargos
          .map((cargoSum) => parseFloat(cargoSum.ammount))
          .reduce((previous, current) => {
            return previous + current;
          }, 0);
        const sumAbonos = abonos
          .map((abonoSum) => parseFloat(abonoSum.ammount))
          .reduce((previous, current) => {
            return previous + current;
          }, 0);
        if (sumCargos?.toFixed(2) - sumAbonos?.toFixed(2) === 0) {
          setEquality("Los datos cuadran");
          setSuccess_msg("");
          setError_msg("none");
        } else {
          setEquality("Los datos no cuadran");
          setError_msg("");
          setSuccess_msg("none");
        }
        setReconsulta(false);
        
      } catch (error) {
        setData([]);
        setReconsulta(false);
      }


  };

  const handleReconsulta = (reconsultar) => {
    if (reconsultar === true) {
      handleSubmit();
    } else {
      console.log("no se reconsulto");
    }
  };


  const handle_delete_expense_detail = async(id)=>{
    setOpen(true);
    try {
      await delete_expense_detail(props.row.original.id,id);
      setOpen(false);
      handleSubmit();
    } catch (error) {
      setOpen(false);      
    }
  }

  useEffect(() => {
    handleSubmit();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: () => {
          return (
            <div>
              <img
                alt=""
                title="Agregar detalle"
                src={ADD}
                onClick={async () => {
                  const result = await CustomDialog(
                    <CREATE_UPDATE
                    method={"CREATE"}
                      row={props?.row?.original}
                      foreign={props.foreign}
                      codeffect={"CARGO"}
                      codamount={0}

                    ></CREATE_UPDATE>,
                    {
                      className: "modalTItle modalExpenseG",
                      title: "Selecciona la configuracion del detalle",
                      showCloseIcon: true,
                      isCanClose: false,
                    }
                  );
                  // eslint-disable-next-line no-lone-blocks
                  {
                    handleReconsulta(result);
                  }
                }}
                className="centerText, taxes"
              ></img>
            </div>
          );
        },
        id: "Acciones",

        Cell: (
          { valor, row }) => {

          return (
            <div>
              <img
                title="Editar Detalle"
                src={SAVE}
                alt=""
                onClick={async () => {
                  if (row.original.modifiable === true) {
                    const result = await CustomDialog(
                      <CREATE_UPDATE
                      method={"UPDATE"}
                        id_detail={row.original.id}
                        row={props?.row?.original}
                        codaccounts={row.original.account}
                        codconcept={row.original.concept}
                        codamount={row.original.ammount}
                        codeffect={row.original.efect}
                        codidConcept={row.original.id_account}
                        foreign={props.foreign}
                      ></CREATE_UPDATE>,
                      {
                        title: "Editar detalle del gastos",
                        className: "modalTItle modalExpenseG",
                        showCloseIcon: true,
                        isCanClose: false,
                      }
                    );
                    // eslint-disable-next-line no-lone-blocks
                    {
                      handleReconsulta(result);
                    }
                  } else {
                    alert("Este detalle ya no se puede modificar");
                  }
                }}
                className="centerText, taxes"
              ></img>

              <img
                title="Eliminar Detalle"
                src={DELETE}
                alt=""
                className="centerText, taxes"
                onClick={async () => {
                  const result = await Confirm(
                    "¿Esta usted seguro de eliminar este detalle del gasto?",
                    "Eliminar Detalle",
                    "Si",
                    "No"
                  );
                  if (result) {
                    handle_delete_expense_detail(row.original.id);
                  } else {
                  }
                }}
              ></img>
            </div>
          );
        },
      },

      {
        Header: "Concepto",
        accessor: "concept",
      },
      {
        Header: "Cuenta",
        accessor: "account",
      },
      {
        Header: "Importe",
        accessor: "ammount",
      },
      {
        Header: "Efecto",
        accessor: "efect",
      },

      {
        Header: "Concepto Factura",
        accessor: "invoice_supplier_concept",
      },

      {
        Header: "RFC",
        accessor: "rfc",
      },

      {
        Header: "UUID",
        accessor: "uuid",
      },
      {
        id: "reconsulta",
        Header: () => {
          return (
            <div>
              <br></br>
              Modificable
              <img
                alt=""
                title="Actualizar"
                src={reconsultaTable}
                onClick={handleSubmit}
                className="centerText, taxes"
              ></img>
            </div>
          );
        },
        accessor: (d) => {
          return d.modifiable === true ? "✅" : "❌";
        },
      },
    ],
    []
  );

  if (Reconsulta === false) {
    return (
      <div>
        <div>
          <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style2}>
              <div className="centermodaldescarga">
                <Typography
                  id="modal-modal-title"
                  className=""
                  variant="h6"
                  component="h2"
                >
                  <img alt="" src={Spinner}></img>
                </Typography>
                <Typography
                  id="modal-modal-description"
                  className=""
                  sx={{ mt: 2 }}
                >
                  {"Eliminando detalle..."}
                </Typography>
              </div>
            </Box>
          </Modal>
        </div>

        <div className="subtablereportexpense">
          <div class="success-msg" style={{ display: success_msg }}>
            <h2>
              <i class="fa fa-check"></i>
              {equality}
            </h2>
          </div>
          <div class="error-msg" style={{ display: error_msg }}>
            <h2>
              <i class="fa fa-times-circle"></i>
              {equality}
            </h2>
          </div>
            <SubTableContainer
              columns={columns}
              data={data}
              style={{ overflowx: 10 }}
            />
        </div>
      </div>
    );
  } else {
    return <div className="loader"></div>;
  }
}

export default SubTable;
