export const modulos = [
    {value: '',label: 'Seleccione un módulo',},
    {value: 'GENERAL', label: 'GENERAL'},
    {value: 'REPORTES',label: 'REPORTES'},
];

export const general_categories = [
    {value: '000',label: 'Seleccione un valor',},
    {value: 'GEN', label: 'General',}
];
export const reports_categories = [
    { value: '000', label: 'Seleccione un valor'},
    { value: 'CON_REP', label: 'CON_REP'},
]
