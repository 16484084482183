//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import LoadingScreen from "react-loading-screen";
import { CustomDialog, useDialog, Confirm } from "react-st-modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

//COMPONENTES
import TableContainer from "../../Table_Container_Tax";
import SubTable from "../detail_expense/datail";
import { date_current, previous_date } from "../../../main_components/date/date";
import { get_expense_group, delete_expense_group, init_server_policy } from "../../../services/expenses/expenses";
import { BREADCRUMBS } from "../../../main_components/pagination/breadcrumbs";
//ESTILOS
import "../../expense.css";
//IMAGENES/ICONOS
import DELETE from "../../../imgComponents/borrar.png";
import Spinner from "../../../imgComponents/S.gif";
import VISUALIZAR from "../../../imgComponents/lupa.png";
const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const CONSULT_GROUP_EXPENSE = () => {

  const [data, setData] = useState([]);
  const [open, setOpen] = useState(true);
  const [menssage, setMenssage] = useState("");
  const [ff, setff] = useState(date_current);
  const [fi, setfi] = useState(previous_date);

  const handleSubmit = async (event) => {
    event?.preventDefault();
    setOpen(true);
    setMenssage("Consultando grupo de gastos...");
    try {
      var dt = await get_expense_group(fi, ff);
      const data = dt?.data?.sort((a, b) => a.id < b.id ? 1 : a.id > b.id ? -1 : 0);;
      setData(data);
      setOpen(false);
      setMenssage("");
    } catch (err) {
      console.log("error", err);
      setData([]);
      setOpen(false);
      setMenssage("");
    }
  };

  const handle_init_server_policy = async (event) => {
    event?.preventDefault();
    setOpen(true);
    setMenssage("Iniciando servidor...");
    try {
      await init_server_policy();
      alert("Servidor estara disponible en 3 minutos")
      setOpen(false);
      setMenssage("");

    } catch (err) {
      console.log("error", err);
      setData([]);
      setOpen(false);
      setMenssage("");
      alert(JSON.stringify(err?.response?.data));
    }
  };





  const handleReconsulta = (reconsultar) => {
    if (reconsultar === true) {
      handleSubmit();
    } else {
      console.log("no se reconsulto");
    }
  };

  const handle_delete_expense_group = async (id) => {
    try {
      await delete_expense_group(id);
      setOpen(false);
      setMenssage("");
      handleReconsulta(true);
    } catch (err) {
      setOpen(false);
      setMenssage("");
      console.log();
      alert("Error.," + err.response?.data?.errorMessage);

    }
  }
  useEffect(() => {
    handleSubmit();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Acciones",
        id: "Acciones",

        Cell: ({ valor, row }) => {
          function ConsultDetailGrupExpense() {
            if (sessionStorage.getItem("PERMISOPOLICY") === "ROLE_POLICY") {
              sessionStorage.setItem("IdGrupExpense", row.original?.id);
              sessionStorage.setItem(
                "nameConceptExpense",
                row.original?.concept
              );
              window.location = "/expensesGrupDetail";
            } else {
              alert(
                "No cuentas con el rol necesario para visualizar los detalles de grupo., Solicita el rol con tu supervisor."
              );
            }
          }



          return (
            <div>
              <img
                title="Ver detalles"
                alt=""
                src={VISUALIZAR}
                onClick={ConsultDetailGrupExpense}
                className="centerText, taxes"
              ></img>

              <img
                title="Eliminar Grupo de gastos"
                src={DELETE}
                alt=""
                className="centerText, taxes"
                onClick={async () => {
                  const result = await Confirm(
                    "¿Esta usted seguro de eliminar este grupo de gastos?",
                    "Eliminar Grupo de gastos",
                    "Si",
                    "No"
                  );
                  if (result) {
                    handle_delete_expense_group(row.original.id);
                  } else {
                  }
                }}
              ></img>
            </div>
          );
        },
      },

      {
        Header: "No.",
        accessor: "id",
      },
      {
        Header: "Fecha",
        accessor: "date",
      },
      {
        Header: "Concepto",
        accessor: "concept",
      },
      {
        Header: "Pago",
        accessor: "payment",
      },
      {
        Header: "Total",
        accessor: (d) => {
          return d.total
            ? Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.total)
            : Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.total);
        },
      },
      {
        Header: "Usuario",
        accessor: "user_name",
      },
    ],
    []
  );

  return (
    <div className="">
      <div>
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style2}>
            <div className="centermodaldescarga">
              <Typography
                id="modal-modal-title"
                className=""
                variant="h6"
                component="h2"
              >
                <img alt="" src={Spinner}></img>
              </Typography>
              <Typography
                id="modal-modal-description"
                className=""
                sx={{ mt: 2 }}
              >
                {menssage}
              </Typography>
            </div>
          </Box>
        </Modal>
      </div>

      <BREADCRUMBS niveles={
        [
          { label: "REPORTE DE GASTOS", path: "/expensesReport" },
          { label: "REPORTE DE GASTOS AGRUPADOS", path: null },

        ]
      }
      ></BREADCRUMBS>

      <div class="containerExpenseReport2">
        <div class="expen-1 color-1">
          <input
            type="text"
            className="fExpense"
            onFocus={(e) => {
              e.currentTarget.type = "date";
              e.currentTarget.focus();
            }}
            placeholder="Fecha inicial"
            onChange={(event) => setfi(event.target.value)}
          ></input>
        </div>

        <div class="expen-2 color-2">
          <input
            type="text"
            className="fExpense"
            onFocus={(e) => {
              e.currentTarget.type = "date";
              e.currentTarget.focus();
            }}
            placeholder="Fecha Final"
            onChange={(event) => setff(event.target.value)}
          ></input>
        </div>

        <div class="expen-3 color-5">
          <Button
            sx={{ width: "100%" }}
            variant="contained"
            onClick={() => handleSubmit()}
          >
            Buscar
          </Button>
        </div>

        <div class="expen-4 color-5">
          <Button
            sx={{ width: "100%" }}
            variant="contained"
            // onClick={() => handle_init_server_policy()}
            onClick={async () => {
              const result = await Confirm(
                "¿Esta usted seguro de iniciar el servidor para inyectar polizas?",
                "Servidor Polizas",
                "Si",
                "No"
              );
              if (result) {
                handle_init_server_policy();
              } else {
              }
            }}
          >
            Iniciar Servidor Polizas
          </Button>
        </div>
      </div>

      <div className="TableReport GrupExpense">
        <div style={{ overflowX: "auto" }}>
          <TableContainer
            exportEx={false}
            columns={columns}
            data={data}
            style={{ overflowx: 10 }}
            renderRowSubComponent={(row) => {
              return (
                <div style={{ padding: "20px" }}>
                  <SubTable verData={true} />
                </div>
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CONSULT_GROUP_EXPENSE;
