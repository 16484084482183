import { useEffect, useState, Fragment } from 'react';
import {
  Box,
  Button,
  MenuItem,
} from '@mui/material';

//Date Picker Imports
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

function sleep(duration) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, duration);
  });
}

const SEARCH_FILTERS = (props) => {


  return (
    <Box className="controlInvoice" sx={{ display: 'flex', gap: '5px', p: '2px', zIndex: "10", alignItems: 'flex-start' }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
           sx={{ width: 150 }}
           label="Fecha"
           value={props.fi}
           onChange={(newValue) => props.setfi(newValue)}
           views={['year', 'month']}
           openTo="month"
           inputVariant="outlined"
           format="MM/YYYY"
           autoOk
        />
      </LocalizationProvider>
      <FormControl required sx={{ width: 70 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Button
            variant="contained"
            title='CONSULTAR'
            sx={{ height: '35px', width: "100%" }}
            onClick={(e) => {
              props.handleSubmit()
            }}
          >
            <ManageSearchIcon></ManageSearchIcon>
          </Button>
        </Box>
      </FormControl>
      <FormControl required sx={{ width: 70 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Button
            variant="contained"
            title='DESCARGAR'
            sx={{ height: '35px', width: "100%", backgroundColor: "#532383" }}
            onClick={(e) => {
              props.dowloadlayout()
            }}
          >
            <CloudDownloadIcon></CloudDownloadIcon>
          </Button>
        </Box>
      </FormControl>



    </Box>
  );
};

export default SEARCH_FILTERS;
