import { useRef, useState } from 'react';
import "./upload.css";
import json from "./dataupload.json";
import load from "../../../imgComponents/iconos/loading.gif";
import { refresh_token } from '../../tokens/tokenrefresh';
import { post_array_pos_sector } from '../../../services/businesspartners/businesspartners';
import { Confirm, CustomDialog, useDialog } from 'react-st-modal';
import { CONFIRM_UPLOAD } from './confirm';

export const UPLOAD_FILE_CSV = ({modulo, id,handleSubmit,alertas,setmodalT}) => {


    const [highlight, setHighlight] = useState(false);
    const fileInputRef = useRef(null);
    const [file, setfile]=useState(null);
    const [band, setband]=useState(false);
    const [err, seterr]=useState(false);
    const [err2, seterr2]=useState(false);
    const [misshed, setmisshed]=useState([]);
    const [data, setdata]=useState([]);
    const dialog = useDialog();

    const preventDefaults = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };
    const handleDrop = (e) => {
      e.preventDefault();
      setHighlight(false);
      seterr(false);
      seterr2(false);
      const files = e.dataTransfer.files;
      setfile(files[0]);
      handleFiles(files);
    };

    const handleFileInputClick = () => {
      fileInputRef.current.click();
    };  
    const handleFileInputChange = (e) => {
      const files = e.target.files;
      seterr(false);
      seterr2(false);
      setfile(files[0]);
      handleFiles(files);
    };
  
    const handleFiles = (files) => {
      for (const file of files) {
        if (file.type === 'text/csv') {
          const reader = new FileReader();
          reader.onload = (e) => {
            const contents = e.target.result;
            analisis(contents);
          };
          reader.readAsText(file);
        } else {
          alert('Por favor, selecciona un archivo CSV.');
          setfile(null);
        }
      }
    };

    const analisis = (conten) =>{
        
        const lines = conten.split('\n');
        const headers = lines[0].split(',').map(header => header.trim());
        const data = [];
        
        for (let i = 1; i < lines.length; i++) {
            const values = lines[i].split(',');
            if (values.some(value => value.trim() !== '')) {
                const entry = {};
                for (let j = 0; j < headers.length; j++) {
                entry[headers[j]] = values[j].trim();
                }
                data.push(entry);
            }
        }

        switch(modulo){
            case "sectores":
                const d = include_header(headers, json.pos_sectores, data); 
                d === true? setdata(data):setdata([]); 
           break;
        }
    }
    
    const include_header = (h, d, data) =>{
        
        setband(true);
        setmisshed([]);
        const missingHeaders = [];  
        let allHeadersPresent = true;
        
        d.forEach(item => {
            if (!h.includes(item.label)) {
              missingHeaders.push(item.label);
              allHeadersPresent = false;
            }
        });

        if(allHeadersPresent===true){
                const d = handle_not_empty(data);
                if(d===true){
                    setTimeout(() => {
                        setband(false); 
                    }, 1500);
                    return true;
                }else{
                    setTimeout(() => {
                        setfile(null);
                        setband(false);
                        seterr2(true);
                    }, 1500);  
                    return false;
                }         
        }else{
            setTimeout(() => {
                setfile(null);
                setband(false);
                seterr(true);
                setmisshed(missingHeaders);
            }, 1500);
            return false;
        }
    }
    
    const handle_not_empty= (d)=>{

        var band = true;

        console.log(d);
 
        switch(modulo){
            case "sectores":
                d?.map(item=>{
                    if(item.Código==="" || item.Código===null){
                        band = false;
                        return null;
                    } 
                    if(item["Orden Sector"]==="" || item["Orden Sector"]===null){
                        band = false;
                        return null;
                    }   
                })
            break;
        }

        return band;
    }

    const handleput = async()=>{
        var nuevoJSON= [];
        setmodalT(true);

        if(modulo==="sectores"){
            nuevoJSON = data.map(item => {
                return {
                  code: (item.Código),
                  order: Number(item["Orden Sector"])
                };
            });  

            var body = {
                relations: nuevoJSON
            }
            try{
                var rf_token = await refresh_token();
                if(rf_token===true){
                    setTimeout(async() => {
                        try{
                            const d = await post_array_pos_sector(id, body);
                            setmodalT(false);
                            alertas("Puntos de venta actualizados con éxito", true);
                            dialog.close();
                            handleSubmit();                            
                            console.log(d); 
                        }catch(err){
                            console.log(err);
                            if(err?.response?.status===409){
                                err.response.data.errorMessage==="Check point of sale codes"?alertas("Verifique los códigos de punto de venta", false):
                                  err.response.data.errorMessage==="Repeated codes found"?alertas("Códigos de punto de venta repetidos encontrados", false):
                                    err.response.data.errorMessage==="The points of sale belong to different routes"?alertas("Los puntos de venta pertenecen a diferentes rutas", false):
                                      err.response.data.errorMessage==="The route of the points of sale and of the sector are different"?alertas("El recorrido de los puntos de venta y del sector son diferentes", false):
                                        alertas("Error 409: No se pudo actualizar los puntos de venta", false);
                               
                            }else if(err?.response?.status===400){
                                err.response.data.errorMessage==="Check the order of point of sale"?alertas("Consultar el orden de los puntos de venta", false):
                                  alertas("Error 409: No se pudo actualizar los puntos de venta", false);
                            }else{
                                alertas("No se pudo actualizar los puntos de venta relacionados al sector", false);
                            }
                             setmodalT(false);
                        }
                    }, 1000);
                }else{}
            }catch(err){
                console.log(err);
            }
        }
    }



    return (
        <div className='container-upload-csv'>
            {
                band === false?
                <div className={`drop-area ${highlight ? 'highlight' : ''}`}
                onDrop={handleDrop}
                onDragOver={preventDefaults}
                onDragEnter={() => setHighlight(true)}
                onDragLeave={() => setHighlight(false)}
                onClick={handleFileInputClick}>
                <p>Arrastra y suelta un archivo CSV aquí o haz clic para seleccionarlo.</p>
                <input
                    type="file"
                    accept=".csv"
                    ref={fileInputRef}
                    onChange={handleFileInputChange}
                    style={{ display: 'none' }}
                />
                </div>:
                <div className='drop-load'>
                    <img src={load}></img>
                    <p>Analizando Archivo...</p>
                </div>
            }
            {
                file===null?<></>:band===true?<></>:<p className='file-select'>Archivo Seleccionado: <span>{file?.name}</span></p>
            } 
            {
                err===true?
                <>
                    <p className='p-err'>El archivo no cumple con los requisitos</p>
                    <p className='p-colum'>Columnas faltantes: <span>{misshed.join(', ')}</span></p>
                </>
                :err2===true?
                <>
                    <p className='p-err'>El archivo no cumple con los requisitos</p>
                    <p className='p-colum'>Verifique que todos los campos contengan información</p>
                </>:
                <></>
            } 
            <br/><br/>
            {
                band===false?data.length>0?
                    
                    <button 
                        onClick={async () =>{
                            await CustomDialog(
                            <CONFIRM_UPLOAD handle={handleput}></CONFIRM_UPLOAD>,
                            {
                                className: "modalmini",
                                title: "Confirmar Actualización",
                                showCloseIcon: true,
                                isCanClose: false,
                            }
                            )
                        }}                 
                    >Actualizar Puntos de Venta</button>:<></>:<></>
            }
            
        </div>
    );
}
