import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  MenuItem,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { ValidationTextField } from '../../main_components/lable/labe';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';

const FILTERS_FACTOR = (props) => {

  const [op1, setop1]=useState(true);
  const [op2, setop2]=useState(false);
  const [op3, setop3]=useState(false);
  const [op_aux, setop_aux]=useState(props?.option_aux);

  useEffect(()=>{
    setop_aux(props?.option_aux);
    if(props?.option_aux==="FECHA"){
      setop1(true);
      setop2(false);
      setop3(false);
    }

    if(props?.option_aux==="PROVEEDOR"){
      setop1(false);
      setop2(true);
      setop3(false);
    }
    if(props?.option_aux==="FECHAPROVEEDOR"){
      setop1(true);
      setop2(true);
      setop3(false);
    }

    if(props?.option_aux==="ALMACEN"){
      setop1(false);
      setop2(false);
      setop3(true);
    }

    if(props?.option_aux==="FECHAALMACEN"){
      setop1(true);
      setop2(false);
      setop3(true);
    }

    


  },[props?.option_aux])

  return (
    <div className='form-factor-import'>
        <Box className="controlInvoice" sx={{ display: 'flex', gap: '5px', p: '2px', zIndex: "10", alignItems: 'flex-start' }}>        
        <FormControl sx={{ width: 150 }}>
        <InputLabel id="demo-simple-select-required-label">Criterio</InputLabel>
        <Select
          labelId="demo-simple-select-required-label"
          id="demo-simple-select-required"
          sx={{ height: '35px' }}
          value={op_aux}
          onChange={(e) => {
            if (e.target.value === "FECHA") {
              setop1(true);
              setop2(false);
              setop3(false);
              props?.setsuplier(null);
              props?.setwarehouse(null);
              props?.setfilter("Dates");
              setop_aux("FECHA");
              props?.setoption_aux("FECHA");
              
            }else if(e.target.value === "PROVEEDOR"){
              setop1(false);
              setop2(true);
              setop3(false);
              props?.setwarehouse(null);
              props?.setfilter("Supplier");
              setop_aux("PROVEEDOR");
              props?.setoption_aux("PROVEEDOR");

            }else if(e.target.value === "FECHAPROVEEDOR"){
              setop1(true);
              setop2(true);
              setop3(false);
              props?.setfilter("SupplierAndDates");
              props?.setwarehouse(null);
              setop_aux("FECHAPROVEEDOR");
              props?.setoption_aux("FECHAPROVEEDOR");

            }else if(e.target.value === "ALMACEN"){
              setop1(false);
              setop2(false);
              setop3(true);
              props?.setsuplier(null);
              props?.setwarehouse(null);
              props?.setfilter("Warehouse");
              setop_aux("ALMACEN");
              props?.setoption_aux("ALMACEN");

            }else if(e.target.value === "FECHAALMACEN"){
              setop1(true);
              setop2(false);
              setop3(true);
              props?.setwarehouse(null);
              props?.setsuplier(null);
              props?.setfilter("WarehouseAndDates");
              setop_aux("FECHAALMACEN");
              props?.setoption_aux("FECHAALMACEN");

            }
          }}
        >
          <MenuItem value={"FECHA"}>FECHA</MenuItem>
          <MenuItem value={"PROVEEDOR"}>PROVEEDOR</MenuItem>
          <MenuItem value={"FECHAPROVEEDOR"}>FECHA Y PROVEEDOR</MenuItem>
          <MenuItem value={"ALMACEN"}>ALMACÉN</MenuItem>
          <MenuItem value={"FECHAALMACEN"}>FECHA Y ALMACÉN</MenuItem>
        </Select>
      </FormControl>
      {
        op1 === true ?
        <>
        <LocalizationProvider 
          dateAdapter={AdapterDayjs} 
        >
          <DatePicker
            sx={{ width: 150 }}
            label="Fecha Inicial"
            value={props.fi}
            onChange={(newValue) => props.setfi(newValue)}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs} style={op1===true?{"display":"none"}:{"display":""}}>
          <DatePicker
            sx={{ width: 200 }}
            label="Fecha Final"
            value={props.ff}
            onChange={(newValue) => props.setff(newValue)}
          />
        </LocalizationProvider>
      </>:<></>
      }  
      {
        op2===true?
        <>
        <FormControl sx={{ width: 200}}>
        <ValidationTextField
          label="Proveedor"
          placeholder="Proveedor"
          variant="outlined"
          id="validation-outlined-input"
          sx={{ m: .3, width: "100%" }}
          name="town"
          InputLabelProps={{
            shrink: true,
          }}
          value={props?.suplier}
          onChange={(event) => {
            props.setsuplier(event.target.value);
          }} />
      </FormControl>
        </>:
        <></>
      }
      {
        op3 === true ?
      
      <FormControl sx={{ width: 150 }}>
        <InputLabel id="demo-simple-select-required-label">Almacén</InputLabel>
        <Select
          labelId="demo-simple-select-required-label"
          id="demo-simple-select-required"
          value={props?.warehouse}
          sx={{ height: '35px' }}
          onChange={(e) => {
            props?.setwarehouse(e.target.value);
          }}
        >
          <MenuItem value={"01"}>FAT</MenuItem>
          <MenuItem value={"02"}>FSS</MenuItem>
        </Select>
      </FormControl>:
      <></>
      }
      <FormControl required sx={{ width: 120 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Button
            variant="contained"
            title='CONSULTAR'
            sx={{ height: '35px', borderRadius: "15px", marginRight: "5px", width: "50%" }}
            onClick={() => {
              props.handleSubmit();
            }}
          >
            <ManageSearchIcon></ManageSearchIcon>
          </Button>
        </Box>
      </FormControl>

      </Box>  
    </div>
  )
}
export default FILTERS_FACTOR;
