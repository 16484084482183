import { useState } from "react";
import ReactPlayer from "react-player"

export const PREVIEW = ({ url = '', key = 'video', lista = [], setnivel3 }) => {
  const [urlvideo, seturlvideo] = useState(url);
  const [list, setlist] = useState(lista);

  const reproducir = (item) => {
    var lista1 = lista.filter(it => it.titlevideo !== item.titlevideo);
    var lista2 = lista.filter(it => it.titlevideo == item.titlevideo);
    var list3 = [...lista2, ...lista1];
    setlist(list3);
    seturlvideo(item.url);
    setnivel3(item.titlevideo)
  }


  return (
    <>
      <div className="grid-play">
        <div className="reproductor-play" style={{ width: "85%", margin: "0 auto" }}>
          <iframe src={`${urlvideo}#navpanes=0`} width="100%" height="600px"></iframe>
        </div>

        <div style={{ height: "100%", width: "250px" }} className="collage">
          {
            list.map((item) => (
              <div className="recomendados-videos">
                <div className="video-recom" onClick={() => reproducir(item)}>
                  <img src={item.img} alt=""></img>
                  <p>{item.titlevideo}</p>
                </div>
              </div>
            )
            )
          }
        </div>
      </div>

    </>
  )
}
