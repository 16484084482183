import React, { useState, useEffect, Suspense } from "react";
import axios from "axios";
import { alpha, styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { CustomDialog, useDialog, Confirm } from "react-st-modal";
import { Autocomplete, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import FormControl from '@mui/material/FormControl';

import { update_expense } from "../../../services/expenses/expenses";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import Modal_Table from "../../../main_components/modal/modal_Table";
import {
  get_expense_paymentaccounts,
  get_expense_concept_list_data,
} from "../../../services/expenses/expenses";
import "../../expense.css";

const ValidationTextField = styled(TextField)({
  "& input:valid + fieldset": { borderColor: "green", borderWidth: 2 },
  "& input:valid:focus + fieldset": {
    borderLeftWidth: 6,
    padding: "4px !important",
  },
});

const UPDATE_EXPENSE_V2 = ({
  codCocept,
  codDescripcion,
  codIdPayment,
  codImporte,
  codAccount,
  codidConcept,
  id_expense,
  foreign,
  origin,
  account,
  handle_consult
}) => {
  const [open, setOpen] = useState(false);
  const [Concepto, setConcepto] = useState(codCocept);
  const [ConceptoUpdate, setConceptoUpdate] = useState(codCocept);
  const [Descripcion, setDescripcion] = useState(codDescripcion);
  const [importe, setImporte] = useState(codImporte);
  const [pro_servData, setpro_servData] = useState([]);
  const [accounts, setAccounts] = useState(codAccount);
  const [idaccounts, setidAccounts] = useState(codidConcept);
  const [accountspayment, setaccountspayment] = useState(codIdPayment);
  const [accountsList, setaccountsList] = useState([]);
  const [modalT, setmodalT] = useState(false);
  const [selectaccountsList, setselectaccountsList] = useState(codIdPayment);

  const [concept_list, setconcept_list] = useState(codCocept);
  const [acount_list, setacount_list] = useState(account);




  const handelDescripcion = (event) => {
    setDescripcion(event?.target?.value);
  };

  const handelUnidad = (event) => {
    setImporte(event?.target?.value);
  };

  const handelAccount = (event) => {
    if (event?.target?.value === "9090909090909") {
      setselectaccountsList(null);
    } else {
      setselectaccountsList(event?.target?.value);
      setaccountspayment(event?.target?.value);
    }
  };

  const handelImportOnBurl = (event) => {
    setImporte(Number(importe).toFixed(2));
  };

  const dialog = useDialog();




  var data = {
    expense: {
      id_account:
        origin === "FACTURAS"
          ? null
          : origin === "PROVEEDORES"
            ? null
            : idaccounts,
      concept:
        origin === "FACTURAS"
          ? Concepto
          : origin === "PROVEEDORES"
            ? Concepto
            : concept_list,
      id_payment: Number(accountspayment),
      description: Descripcion,
      amount: Number(importe),
    },
  };

  console.log(data);

  const handleSubmit = async (event) => {

    console.log(origin)

    event?.preventDefault();
    var data = {
      expense: {
        id_account:
          origin === "FACTURAS"
            ? null
            : origin === "PROVEEDORES"
              ? null
              : idaccounts,
        concept:
          origin === "FACTURAS"
            ? Concepto
            : origin === "PROVEEDORES"
              ? Concepto
              : concept_list,
        id_payment: Number(accountspayment),
        description: Descripcion,
        amount: Number(importe),
      },
    };

    console.log(data);

    setmodalT(true);

    try {
      await update_expense(data, id_expense);
      setmodalT(false);
      handle_consult()
      dialog.close(true);
    } catch (err) {
      setmodalT(false);
      var error = err?.response?.data?.errorMessage;
      alert("Error.," + error);
    }
  };

  const handleprod_concept_list = async () => {
    try {
      var dt = await get_expense_concept_list_data(foreign);
      const data = dt.data;
      setpro_servData(data);
    } catch (error) {
      setpro_servData([]);
    }
  };

  const handleAccountsList = async () => {
    try {
      var dt = await get_expense_paymentaccounts();

      const data = dt.data;
      const dataStatic = {
        id: "9090909090909",
        payment_method: "SELECCIONE UNA OPCIÓN",
      };
      data[data.length] = dataStatic;
      setaccountsList(data);
    } catch (error) {
      setaccountsList([]);
    }
  };

  useEffect(() => {
    handleprod_concept_list();
    handleAccountsList();
  }, []);


  const handle_concept = (e, v) => {
    setconcept_list(v?.concept);
    setConcepto(v?.id);
    setacount_list(v?.account)
    setidAccounts(v?.id_account)
  }

  return (
    <div>
      <div>
        <Modal_Table modalT={modalT}></Modal_Table>
      </div>
      <div>
        <form>
          <div style={{ display: origin === "FACTURAS" ? "none" : origin === "PROVEEDORES" ? "none" : "" }} >
            <Box sx={{ display: 'flex', gap: '5px', p: '2px', zIndex: "10", margin: "0 auto", width: "95%" }} >
              <FormControl sx={{ width: "100%" }} >
                <Autocomplete
                  id="Concepto"
                  options={pro_servData}
                  getOptionLabel={(option) => `${option?.concept} - ${option?.account}`}
                  onChange={(event, newValue) => handle_concept(event, newValue)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputLabelProps={{ shrink: true }}
                      label="Buscar Concepto"
                      placeholder={"Buscar Concepto"}
                    />
                  )}
                />
              </FormControl>

            </Box>
          </div>
          <div style={{ display: origin === "FACTURAS" ? "none" : origin === "PROVEEDORES" ? "none" : "" }} >
            <Box sx={{ display: 'flex', gap: '5px', p: '2px', zIndex: "10", margin: "0 auto", width: "95%", marginTop: "5px" }} >
              <ValidationTextField
                label="Concepto"
                variant="outlined"
                placeholder="Concepto"
                sx={{ width: "50%" }}
                value={concept_list}
                InputLabelProps={{ shrink: true }}
              />
              <ValidationTextField
                label="Cuenta"
                variant="outlined"
                placeholder="Cuenta"
                sx={{ width: "50%" }}
                value={acount_list}
                InputLabelProps={{ shrink: true }}
              />
            </Box>
          </div>

          <div style={{ display: origin === "FACTURAS" ? "" : origin === "PROVEEDORES" ? "" : "none" }}>
            <Box sx={{ display: 'flex', gap: '5px', p: '2px', zIndex: "10", margin: "0 auto", width: "95%", marginTop: "5px" }} >
              <ValidationTextField
                label="Concepto"
                variant="outlined"
                placeholder="Concepto"
                sx={{ width: "100%" }}
                value={Concepto}
                onChange={(e) => setConcepto(e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
            </Box>
          </div>

          <Box sx={{ display: 'flex', gap: '5px', p: '2px', zIndex: "10", margin: "0 auto", width: "95%", marginTop: "5px" }} >
            <TextareaAutosize
              label="Descripciónn"
              variant="outlined"
              aria-label="empty textarea"
              value={Descripcion}
              placeholder="Descripción"
              className="TexAreaAutosize"
              onChange={handelDescripcion}
            />
          </Box>
          <Box sx={{ display: 'flex', gap: '5px', p: '2px', zIndex: '10', margin: '0 auto', width: '95%', marginTop: '5px' }}>
            <ValidationTextField
              label="Importe"
              variant="outlined"
              placeholder="Importe"
              type="number"
              sx={{ width: "100%" }}
              onChange={handelUnidad}
              onBlur={handelImportOnBurl}
              value={importe}
              InputLabelProps={{ shrink: true }}
            />
          </Box>

          <Box sx={{ display: 'flex', gap: '5px', p: '2px', zIndex: '10', margin: '0 auto', width: '95%', marginTop: '5px' }}>
            <TextField
              select
              label="Cuenta de pago"
              value={selectaccountsList}
              sx={{ width: "100%" }}
              onChange={handelAccount}
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
            >
              {accountsList.map((option) => (
                <option key={option.id} value={option.id} selected={option.id}>
                  {option.payment_method}
                </option>
              ))}
            </TextField>
          </Box>


          <Box sx={{ display: 'flex', gap: '5px', p: '2px', zIndex: '10', margin: '0 auto', width: '95%', marginTop: '5px' }}>
            <Button
              variant="contained"
              sx={{ width: "50%" }}
              className="ButtonModal"
              onClick={handleSubmit}
            >
              Actualizar
            </Button>

            <Button
              variant="contained"
              sx={{ width: "50%" }}
              className="ButtonModal2"
              onClick={() => {
                dialog.close();
              }}
            >
              cancelar
            </Button>
          </Box>

          <br></br>
        </form>
      </div>
    </div>
  );
};

export default UPDATE_EXPENSE_V2;
