import React, { Component } from 'react'
import { AuthState } from '@aws-amplify/ui-components';
import { Auth } from 'aws-amplify';
import fondo from "../img/fondo.jpg"
import ht from "../img/boellhoff-logo.svg";


export class ConfirmSignUp extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
       Code : '',
       Username:'',
    }

    this.confirmSignUp = this.confirmSignUp.bind(this);
    this.handleFormSubmission = this.handleFormSubmission.bind(this);
    this.handleFormSubmission2=this.handleFormSubmission2.bind(this);
  
  }

  async resendConfirmationCode(){
    try {
      await Auth.resendSignUp(this.state.Username);
      // console.log('code resent successfully');
    } catch (err) {
        // console.log('error resending code: ', err);
    }
  }

  handleFormSubmission(evt) {
    evt.preventDefault();
    this.confirmSignUp();
  }
  handleFormSubmission2(evt){
      
    console.log(this.props);
    evt.preventDefault();
    this.renviarcodigo();
  }

  async renviarcodigo(){
    try {
      await Auth.resendSignUp(this.state.Username);
      console.log('Código reenviado exitosamente');
    } catch (err) {
      console.log('Error al reenviar código: ', err);
    }
  }

  async confirmSignUp() {
    const username = this.state.Username;
    const code = this.state.Code;

    try {
      await Auth.confirmSignUp(username, code);
      this.props.SetAuthState(AuthState.SignIn)
    } catch (error) {
        // console.log('error confirming sign up', error);
    }
  }
  
  render() {
    return (
      <div className='container_login'>
      <div className='grid-login'>
        <div className='formsesion'>
          <div className='logoempresa'>
              <img alt='' src={ht}></img>
          </div>
            
          <div className='camplogin'>
          
          < div className="div-lg3">
            <p className='bienvenido'>Verifica tu correo</p>
          </div>
          <div className='grid-form-login'>
          <div className='form-user'>
            <label className='label-usuario'>Usuario</label>
            <input className="form-control text-center" type="text" placeholder="Username" onChange={(e) => this.setState({Username: e.target.value})} ></input>

            </div>
            <div className='form-user'>
            <label className='label-usuario'>Código Verificación</label>
            <input className="text-center form-control" 
              type="text" 
              placeholder="Ingresa codigo de verificación" 
              onChange={(e) => this.setState({Code: e.target.value})}>
            </input>
                  
            </div>
          
            
            <div className=''>
                <button className='boton-login' 
                    onClick={this.handleFormSubmission}>
                  Verificar Cuenta
                </button> 
                
                <button className='boton-registrarse' 
                  onClick={this.handleFormSubmission2}>
                  Reenviar Código
                </button>        
                {/* <p className='forgot-pass'>Olvidaste tu contraseña</p> */}
              </div>
          </div>
          </div>
        </div>
        <div className='imglogin'>
          <img src={fondo}></img>
        </div>
      </div>
      </div>
    );
  }
}

export default ConfirmSignUp;

      {/*<div className="colorfondo">
        <div className="parent clearfix">
          <div className="bg-illustration">
          </div>

          <div className="login">
            <div className="container">
              <br></br>
              <h2 className="titleOD2">Verifica tu correo</h2>
                      <div className="form-group">
                        <input className="form-control text-center" type="text" placeholder="Username" onChange={(e) => this.setState({Username: e.target.value})} ></input>

                      </div>

                      <div className="form-group">
                        <input className="text-center form-control" type="text" placeholder="Ingresa codigo de verificación" onChange={(e) => this.setState({Code: e.target.value})}></input>
                      </div>
                      
                      <div className="form-group">
                        <a className="form-control btn btn-primary"  onClick={this.handleFormSubmission} >Verificar Cuenta</a>
                      </div>
                      <div className="form-group">
                        <a className="form-control btn btn-primary" onClick={() => this.resendConfirmationCode}>Reenviar Codigo</a>

                      </div> 
            </div>
          </div>
        </div>
    </div>*/}