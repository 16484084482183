//PAQUETERIAS
import { useEffect, useState, useMemo, useRef } from "react";
import { CustomDialog, Confirm } from "react-st-modal";
import NotificationSystem from "react-notification-system";

//COMPONENTES
import SubTable from "./subTable";
import Set_vehicle from "./set_vehicle";
import Pet_vehicle from "./put_vehicle";
import MODAL_TABLE from "../../main_components/modal/modal_Table";
import AlertResponse from "../../main_components/alerts/alertResponse";
//ESTILOS
import "./tableclientes.css";
//IMAGENES/ICONOS
import SAVE from "../../imgComponents/save.png";
import DELETE from "../../imgComponents/borrar.png";
import CREATE from "../../imgComponents/create.png";
import { delete_vehicle, get_vehicle } from "../../services/assets/assets";
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";
import TableContainer from "../../main_components/Table/TableContainer";
import { refresh_token } from "../../main_components/tokens/tokenrefresh";

const Consult_vehicle = () => {
  const [mensajeDescarga, setmensajeDescarga] = useState("");
  const [data, setData] = useState([]);
  const [isLoding, setIsLoding] = useState(true);
  const [menssage, setmenssage] = useState("Consultando Vehículos...");
  const [modalGeneral, setmodalGeneral] = useState(true);
  const [modalT, setmodalT] = useState(true);
  const notificationSystemRef = useRef();


  function alertas(msj, status) {

    if (status === true) {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={true}></AlertResponse>,
        level: 'success',
        position: 'br',
        autoDismiss: 10,
      });
    } else {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={false}></AlertResponse>,
        level: 'error',
        position: 'br',
        autoDismiss: 60,
      });
    }
  }


  const handleSubmit = async (msjAler) => {
    setmodalT(true);
    setData([]);
    setIsLoding(true);
    setTimeout(async () => {
      setmodalGeneral(false);
      try {
        var rf_token = await refresh_token();
        if (rf_token === true) {
          setTimeout(async () => {
            try {
              const d = await get_vehicle();
              const data = d === null ? [] : d.data;
              setmodalT(false);
              setData(data);
              setIsLoding(false);
              if (msjAler?.length > 1) {
                notificationSystemRef.current.addNotification({
                  message: (
                    <AlertResponse msj={msjAler} view={true}></AlertResponse>
                  ),
                  level: "success",
                  position: "br",
                  autoDismiss: 10,
                });
              }
            } catch (err) {
              var errroM = err?.response?.data?.errorMessage;
              setData([]);
              notificationSystemRef.current.addNotification({
                message: (
                  <AlertResponse
                    msj={
                      errroM === undefined
                        ? "Error 401:No autorizado intentelo nuevamente o recarge la pagina nuevamente oprimiendo la tecla 'CTRL + F5'"
                        : errroM
                    }
                    view={false}
                  ></AlertResponse>
                ),
                level: "error",
                position: "br",
                autoDismiss: 60,
              });
              setmodalT(false);
            }
          }, 1000);
        } else { }
      } catch (err) {
        console.log(err);
      }
    }, 2000);
  };

  useEffect(() => {
    handleSubmit();
  }, []);

  const handleReconsulta = (reconsultar, msjAler) => {
    if (reconsultar === true) {
      handleSubmit(msjAler);
    } else {
      console.log("no se reconsulto");
    }
  };
  const columns = useMemo(
    () => [

      {
        Header: () => {
          return (
            <div>
              <p style={{ color: "white" }} >Acciones</p>
              <img
                title="Crear vehículo"
                src={CREATE}
                className="cursorPointer"
                alt=""
                onClick={async () => {
                  const result = await CustomDialog(
                    <Set_vehicle
                      setmodalT={setmodalT}
                      handleSubmit={handleSubmit}
                      alertas={alertas}
                    ></Set_vehicle>,
                    {
                      className: "modalSetBussnes",

                      title: "Crear vehículo",
                      showCloseIcon: true,
                      isCanClose: false,
                    }
                  );
                  {
                    handleReconsulta(
                      result,
                      "vehículo creado correctamente"
                    );
                  }
                }}
              ></img>
            </div>
          );
        },
        id: "Acciones",

        Cell: ({ row }) => {
          const DeleteBusnes = async () => {
            setmenssage("Eliminando vehículo");
            setmodalGeneral(true);
            setIsLoding(true);
            setmensajeDescarga("Eliminando");
            try {
              var rf_token = await refresh_token();
              if (rf_token === true) {
                setTimeout(async () => {
                  try {
                    const d = await delete_vehicle(row.original.id);
                    console.log(d);
                    handleReconsulta(true, "Vehículo eliminado correctamente");
                  } catch (err) {
                    var errroM = err?.response?.data?.errorMessage;
                    notificationSystemRef.current.addNotification({
                      message: (
                        <AlertResponse
                          msj={
                            errroM === undefined
                              ? "Error 401:No autorizado intentelo nuevamente o recarge la pagina nuevamente oprimiendo la tecla 'CTRL + F5'"
                              : errroM
                          }
                          view={false}
                        ></AlertResponse>
                      ),
                      level: "error",
                      position: "br",
                      autoDismiss: 60,
                    });
                  }
                }, 1000);
              } else { }
            } catch (err) {
              console.log(err);
            }
          }

          return (
            <div>
              <img
                title="Actualizar Vehículo"
                src={SAVE}
                alt=""
                className="cursorPointer"
                onClick={async () => {
                  const result = await CustomDialog(
                    // eslint-disable-next-line react/jsx-pascal-case
                    <Pet_vehicle
                      vehicular_config={row.original.vehicular_config}
                      license_plate={row.original.license_plate}
                      asegura_resp_civil={row.original.asegura_resp_civil}
                      poliza_resp_civil={row.original.poliza_resp_civil}
                      id_model_fk={row.original.id_model_fk}
                      description={row.original.description}
                      asegura_med_ambiente={row.original.asegura_med_ambiente}
                      poliza_med_ambiente={row.original.poliza_med_ambiente}
                      asegura_carga={row.original.asegura_carga}
                      poliza_carga={row.original.poliza_carga}
                      prima_seguro={row.original.prima_seguro}
                      id_route_fk={row.original.id_route_fk}
                      id_vehicle={row.original.id}
                      year_model={row.original.year_model}
                      id_brand_model_fk={row.original.id_brand_model_fk}
                      name_brand={row.original.brand}
                      permissible_gross_vehicle_weight={row?.original?.permissible_gross_vehicle_weight}
                      setmodalT={setmodalT}
                      handleSubmit={handleSubmit}
                      alertas={alertas}
                    ></Pet_vehicle>,
                    {
                      className: "modalSetBussnes",
                      title: "Actualizar vehículo",
                      showCloseIcon: true,
                      isCanClose: false,
                    }
                  );
                }}
              ></img>

              <img
                src={DELETE}
                alt=""
                className="cursorPointer"
                onClick={async () => {
                  const result = await Confirm(
                    "¿Esta usted seguro de eliminar este vehículo?",
                    "Eliminar vehículo",
                    "Si",
                    "No"
                  );
                  if (result) {
                    DeleteBusnes();
                  } else {
                  }
                }}
              ></img>
            </div>
          );
        },
      },

      {
        Header: "Descripción",
        accessor: "description",
      },
      {
        Header: "Configuración Vehícular",
        accessor: "vehicular_config",
      },
      {
        Header: "Placa",
        accessor: "license_plate",
      },
      {
        Header: "Año del Modelo",
        accessor: "year_model",
      },
      {
        Header: "Peso Bruto de la Unidad",
        accessor: "permissible_gross_vehicle_weight",
      },
      {
        Header: "Aseguradora Responsabilidad Civil",
        accessor: "asegura_resp_civil",
      },
      {
        Header: "Poliza Responsabilidad Civil",
        accessor: "poliza_resp_civil",
      },
      {
        Header: "Aseguradora Medio Ambiente",
        accessor: "asegura_med_ambiente",
      },
      {
        Header: "Poliza Medio Ambiente",
        accessor: "poliza_med_ambiente",
      },
      {
        Header: "Aseguradora de Carga",
        accessor: "asegura_carga",
      },
      {
        Header: "Poliza de Carga",
        accessor: "poliza_carga",
      },
      {
        Header: "Prima de Seguro",
        accessor: "prima_seguro",
      },
      {
        Header: "Fecha de registro",
        accessor: "date_register",
      },
    ],
    []
  );






  return (
    <div>
      <BREADCRUMBS niveles={
        [
          { label: "CONSULTAR VEHICULOS", path: null },
        ]
      }
        // new_version={true}
        // modul="CONSULTAR VEHICULOS V2"
        // path={"/consult_vehicles_v2"}
      ></BREADCRUMBS>
      <NotificationSystem ref={notificationSystemRef}></NotificationSystem>
      <MODAL_TABLE
        open={true}
        message={menssage}
        modalGeneral={modalGeneral}
        modalT={modalT}
      ></MODAL_TABLE>
      <div >
        <div className="TableCOnsultaClientes">
          <TableContainer
            exportar={true}
            columns={columns}
            data={data}
            Gmodal={modalT}
            Grantotal={false} 
            GrantotalCabeceras={
              ["Descripción", 
              "Configuración Vehícular", 
              "Placa", "Año del Modelo",
              "Peso Bruto de la Unidad",
              "Aseguradora Responsabilidad Civil",
              "Poliza Responsabilidad Civil",
              "Aseguradora Medio Ambiente",
              "Poliza Medio Ambiente",
              "Aseguradora de Carga",
              "Poliza de Carga",
              "Prima de Seguro",
              "Fecha de registro"
            ]}
            style={{ overflowx: 10 }}
            nametable={"Vehiculos"}
            renderRowSubComponent={(row) => {
              return (
                <div style={{ padding: "20px" }}>
                  <SubTable />
                </div>
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Consult_vehicle;
