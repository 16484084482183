import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Grid, TextField } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { ValidationTextField } from '../../../main_components/lable/labe'; // Asegúrate de que el componente esté correctamente importado
import EditIcon from '@mui/icons-material/Edit';
import DELETE from "../../../imgComponents/borrar.png"
import DeleteIcon from '@mui/icons-material/Delete';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
function PartsManager({ parts, onChange }) {
  const [partList, setPartList] = useState(parts);
  const [newPart, setNewPart] = useState('');
  const [editIndex, setEditIndex] = useState(-1);
  const [editValue, setEditValue] = useState('');

  useEffect(() => {
    onChange(partList);
  }, [partList, onChange]);

  const handleAddPart = () => {
    if (newPart) {
      setPartList([...partList, newPart]);
      setNewPart('');
    }
  };

  const handleDeletePart = (index) => {
    const updatedParts = [...partList];
    updatedParts.splice(index, 1);
    setPartList(updatedParts);
  };

  const handleEditPart = (index) => {
    setEditIndex(index);
    setEditValue(partList[index]);
  };

  const handleSaveEdit = () => {
    const updatedParts = [...partList];
    updatedParts[editIndex] = editValue;
    setPartList(updatedParts);
    setEditIndex(-1);
    setEditValue('');
    onChange(updatedParts); // Llama a onChange solo cuando se confirma un cambio
  };

  const handleCancelEdit = () => {
    setEditIndex(-1);
    setEditValue('');
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8}>
          <TextField
            label="Agregar nueva parte"
            value={newPart}
            onChange={(e) => setNewPart(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Button style={{ width: "100%" }} onClick={handleAddPart} color="primary" variant="contained">
            Agregar
          </Button>
        </Grid>
      </Grid>
      <br></br>
      <ul style={{ overflowY: "scroll", maxHeight: "200px" }}>
        {partList.map((part, index) => (
          <li key={index}>
            {editIndex === index ? (
              <>

                <TextField
                  value={editValue}
                  onChange={(e) => setEditValue(e.target.value)}
                />


                <Button onClick={handleSaveEdit} color="primary">
                  Guardar
                </Button>

                <Button onClick={handleCancelEdit} color="secondary">
                  Cancelar
                </Button>


              </>
            ) : (
              <div >

                <section className="uploaded-area" style={{ width: "70%" }}>
                  <ul>
                    <li className={`row rownew`}>
                      <div className="content upload">
                        <LocalParkingIcon />
                        <div className="details">
                          <span className="name">{`${part}`}</span>
                        </div>
                      </div>
                      <div>
                        <EditIcon onClick={() => handleEditPart(index)} color="primary" style={{ cursor: "pointer" }}></EditIcon>
                        <DeleteIcon onClick={() => handleDeletePart(index)} style={{ cursor: "pointer", color: "red" }}></DeleteIcon>
                      </div>
                    </li>
                  </ul>
                </section>
              </div>
            )}
          </li>
        ))}
      </ul>
    </div >
  );
}

function FORM_PETITIONS_DETAILS({ open, onClose, data, onSave, isNew }) {
  const [formData, setFormData] = useState(data);
  const [errors, setErrors] = useState({});
  console.log(formData)


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value?.toUpperCase() }));
    setErrors(prev => ({ ...prev, [name]: '' }));
  };

  const validateField = (name, value) => {
    const isNumeric = ['vatPaymentMethodItem','igiPaymentMethodItem'].includes(name);
    const isRequired = true;
    let error = '';

    if (isRequired && (value === undefined || value === null || value === '')) {
      error = 'Este campo es obligatorio';
    } else if (isNumeric && value && isNaN(Number(value))) {
      error = 'Debe ser un valor numérico';
      console.log(value)
    } else if (value && ['docID', 'supplierCode', 'customs'].includes(name) && value.length > 255) {
      error = 'Máximo 255 caracteres';
    } else if (name === 'currency' && value && (value.length < 3 || value.length > 3)) {
      error = 'Debe contener 3 caracteres';
    } else if ((name === 'date' || name === 'entryDate' || name === 'invoiceDate' || name === 'sortDate') && value && value.length !== 10) {
      error = 'Formato de fecha incorrecto';
    }
    return error;
  };

  const handleSubmit = () => {
    let validationErrors = {};
    Object.keys(formData).forEach(key => {
      const error = validateField(key, formData[key]);
      if (error) {
        validationErrors[key] = error;
      }
    });

    if (Object.keys(validationErrors).length === 0) {
      onSave(formData, isNew, data.docID, data.supplierCode);
      onClose();
    } else {
      setErrors(validationErrors);
    }
  };

  const handlePartsChange = (newParts) => {
    if (JSON.stringify(formData.parts) !== JSON.stringify(newParts)) {
      setFormData({ ...formData, parts: newParts });
    }
  };


  useEffect(() => {
    setFormData(data);
  }, [data, open]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{isNew ? 'Agregar Detalles del Pedimento' : 'Editar Detalles del Pedimento'}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {/* Ejemplo de campos agregados */}
          {[
            { label: "Vendedor", accessor: "seller", sm: 3, dfl: '' },
            { label: "Forma de Pago IGI", accessor: "igiPaymentMethodItem", sm: 3, dfl: '',type: "number" },
            { label: "Unidad", accessor: "unit", sm: 6, dfl: '' },
            { label: "Secuencia", accessor: "sequence", sm: 6, dfl: '' },
            { label: "Origen", accessor: "origin", sm: 4, dfl: '' },
            { label: "Forma de Pago IVA Partida", accessor: "vatPaymentMethodItem", sm: 2, dfl: '', type: "number" },
            { label: "Fracción partida Pedimento", accessor: "tariffFractionEntrySummary", sm: 6, dfl: '' },
            { label: "Partes", accessor: "parts", sm: 12, dfl: '', type: "parts" }, //es una lista que contiene 95240019289/35,95246407769/35,95246408223/35

          ].map(field => (
            <Grid item xs={12} sm={field.sm} style={{ paddingTop: "22px" }}>
              {field.type === "parts" ?
                <PartsManager parts={formData?.parts || []} onChange={handlePartsChange} />
                :
                <ValidationTextField
                  label={field.label}
                  required
                  placeholder={field.label}
                  fullWidth
                  variant="outlined"
                  name={field.accessor}
                  value={formData?.[field.accessor]}
                  type={field.type || "text"}
                  onChange={handleChange}
                  error={!!errors?.[field.accessor]}
                  helperText={errors?.[field.accessor]}
                />
              }

            </Grid>
          ))}


        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained" color="error" sx={{ m: 1, width: "100%" }}>Cancelar</Button>
        <Button onClick={handleSubmit} variant="contained" color="success" sx={{ m: 1, width: "100%" }}>
          {isNew ? 'Crear' : 'Actualizar'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default FORM_PETITIONS_DETAILS;



