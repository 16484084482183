import React, { useState, useEffect, Suspense } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { FileUploader } from "react-drag-drop-files";
import { CustomDialog, useDialog, Confirm } from "react-st-modal";
import Modal_Table from "../../../main_components/modal/modal_Table";
import "../../expense.css";
import { refresh_token } from "../../../main_components/tokens/tokenrefresh";
import {
  get_expense_concept_list_data,
  get_expense_paymentaccounts,
  create_expense,
  update_expense,
} from "../../../services/expenses/expenses";
import jsPDF from 'jspdf';



const ValidationTextField = styled(TextField)({
  "& input:valid + fieldset": { borderColor: "green", borderWidth: 2 },
  "& input:valid:focus + fieldset": {
    borderLeftWidth: 6,
    padding: "4px !important",
  },
});

const CREATE_UPDATE_EXPENSE = (props) => {

  const [Descripcion, setDescripcion] = useState(props?.codDescripcion);
  const [importe, setImporte] = useState(props?.codImporte);
  const [pro_servData, setpro_servData] = useState([]);
  const [accountsList, setaccountsList] = useState([]);
  const [selectaccountsList, setselectaccountsList] = useState(
    props.codAccount
  );
  const [ff, setff] = useState(props.codDate);
  const [accounts, setAccounts] = useState(props.codAccount);
  const [account, setAccount] = useState(props.codAccount);
  const [Concepto, setConcepto] = useState(props?.codCocept);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [modalT, setmodalT] = useState(false);
  const dialog = useDialog();

  const handelAccount = (event) => {
    if (event?.target?.value === "9090909090909") {
      setselectaccountsList(null);
    } else {
      setselectaccountsList(event?.target?.value);
    }
  };

  const handelClave_prod_serv = (event) => {

    console.log(event?.target?.value)
    if (event?.target?.value === "9090909090909") {

    } else {
      const result = pro_servData.filter(
        (item) => item.concept == event?.target?.value
      );
      setAccounts(result[0]?.id_account);
      setAccount(result[0]?.account);
      setConcepto(result[0]?.concept);
    }

    //se hizo una modificacion exclusiva para FC tomando el nombre del concepto
  };

  const handelDescripcion = (event) => {
    setDescripcion(event?.target?.value);
  };

  const handelUnidad = (event) => {
    setImporte(event?.target?.value);
  };
  const handelImportOnBurl = (event) => {
    setImporte(Number(importe).toFixed(2));
  };

  const fileToBase64 = (file, cb) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(null, reader.result);
    };
    reader.onerror = function (error) {
      cb(error, null);
    };
  };
  const fileTypes = ["PDF", "JPG", "JPEG", "PNG"];

  const handleChange = async (file) => {

    const fileExtension = file.name.split('.').pop().toLowerCase();

    if (fileExtension === 'pdf') {
      fileToBase64(file, (err, result) => {
        if (result) {
          setFile(result);
          setFileName(file);
        }
      });
    } else {
      convertImageToPdf(URL.createObjectURL(file)).then((pdfBase64) => {
        setFile(pdfBase64);
        setFileName(file.name.replace(/\.[^/.]+$/, '') + '.pdf');
      });
    }
  };

  const convertImageToPdf = (imagePath) => {
    return new Promise((resolve, reject) => {
      const pdf = new jsPDF();
      const img = new Image();

      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0, img.width, img.height);

        const originalWidth = img.width;
        const originalHeight = img.height;
        const maxWidth = 190;
        const maxHeight = 277;
        let newWidth = originalWidth;
        let newHeight = originalHeight;
        if (originalWidth > maxWidth) {
          newWidth = maxWidth;
          newHeight = (originalHeight * maxWidth) / originalWidth;
        }
        if (newHeight > maxHeight) {
          newHeight = maxHeight;
          newWidth = (originalWidth * maxHeight) / originalHeight;
        }
        pdf.addImage(canvas.toDataURL('image/jpeg', 0.3), 'JPEG', 10, 10, newWidth, newHeight);
        const pdfBase64 = pdf.output('datauristring').split(',')[1];
        resolve(pdfBase64);
      };
      img.src = imagePath;
    });
  };

  const handleprod_concept_list = async () => {
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        try {
          var dt = await get_expense_concept_list_data(props.foreing);

          const data = dt.data;
          const dataStatic = {
            id_account: "9090909090909",
            concept: "SELECCIONE UNA OPCIÓN",
            account: "SELECCIONE UNA OPCIÓN",
          };

          var datanew = [dataStatic, ...data];

          // data[data.length] = dataStatic;

          setpro_servData(datanew);
        } catch (error) {
          setpro_servData([]);
        }
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleAccountsList = async () => {
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        try {
          var dt = await get_expense_paymentaccounts();

          const data = dt.data;
          const dataStatic = {
            id: "9090909090909",
            payment_method: "SELECCIONE UNA OPCIÓN",
          };
          data[data.length] = dataStatic;
          setaccountsList(data);
        } catch (error) {
          setaccountsList([]);
        }
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };

  var data = {
    expense: {
      concept: Concepto,
      id_payment: Number(selectaccountsList),
      account: account,
      description: Descripcion,
      date: ff,
      amount: Number(importe),
      origin: 1,
    },
    file: {
      content: file?.replace("data:application/pdf;base64,", ""),
      type: "pdf",
    },
  };
  console.log(data);

  const handleSubmit = async () => {
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        var data = {
          expense: {
            concept: Concepto,
            id_payment: Number(selectaccountsList),
            account: account,
            description: Descripcion,
            date: ff,
            amount: Number(importe),
            origin: props?.origin,
          },
          file: {
            content: file?.replace("data:application/pdf;base64,", ""),
            type: "pdf",
          },
        };

        setmodalT(true);
        try {
          await create_expense(data);
          setmodalT(false);
          props?.alertas ? props?.alertas("Gasto Registrado con éxito", true) : console.log("");
          dialog.close(true);
          props?.handleSubmit ? props?.handleSubmit() : console.log("");
        } catch (err) {
          setmodalT(false);
          props?.alertas ? props.alertas("Error al registrar el gasto", false) : console.log(err);
        }
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    handleprod_concept_list();
    handleAccountsList();
  }, []);

  return (
    <div style={{ "textAlign": "center" }}>
      <Modal_Table modalT={modalT}></Modal_Table>
      <div>
        <form>
          <Box sx={{ display: 'flex', gap: '5px', p: '2px', zIndex: "10", margin: "0 auto", width: "95%" }} >
            <TextField
              select
              label="Concepto"
              value={Concepto}
              sx={{ width: "100%" }}
              onChange={handelClave_prod_serv}
              size="small"
              name="taxes"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
            >
              {pro_servData.map((option, index) => (
                <option
                  key={index}
                  value={option.concept}
                  selected={option.id_account}
                >
                  {option.concept}
                </option>
              ))}
            </TextField>
          </Box>

          <Box sx={{ display: 'flex', gap: '5px', p: '2px', zIndex: "10", margin: "0 auto", width: "95%" }} >
            <TextareaAutosize
              label="Descripciónn"
              variant="outlined"
              aria-label="empty textarea"
              value={Descripcion}
              placeholder="Descripción"
              className="TexAreaAutosize"
              onChange={handelDescripcion}
            />
          </Box>

          <Box sx={{ display: 'flex', gap: '5px', p: '2px', zIndex: "10", margin: "0 auto", width: "95%" }} >
            <input
              type="text"
              className="fExpense2"
              onFocus={(e) => {
                e.currentTarget.type = "date";
                e.currentTarget.focus();
              }}
              placeholder={ff === "" ? "FECHA" : ff}
              onChange={(event) => setff(event.target.value)}
            ></input>

            <ValidationTextField
              label="Importe"
              type="number"
              variant="outlined"
              placeholder="Importe"
              sx={{ width: "50%" }}
              onChange={handelUnidad}
              onBlur={handelImportOnBurl}
              value={importe}
              InputLabelProps={{ shrink: true }}
            />
          </Box>

          <Box sx={{ display: 'flex', gap: '5px', p: '2px', zIndex: "10", margin: "0 auto", width: "95%" }} >
            <TextField
              select
              label="Cuenta de pago"
              value={selectaccountsList}
              sx={{ width: "100%" }}
              onChange={handelAccount}
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
            >
              {accountsList.map((option) => (
                <option key={option.id} value={option.id} selected={option.id}>
                  {option.payment_method}
                </option>
              ))}
            </TextField>
          </Box>


          <Box className="drangAndDropExpense" sx={{ display: 'flex', gap: '5px', p: '2px', zIndex: "10", margin: "0 auto", width: "95%" }} >
            <FileUploader
              handleChange={handleChange}
              name="file"
              types={fileTypes}
              placeholder="123-45-678"
            >
              <div style={{ margin: "0 auto", width: "190%" }}>
                <p>Arrastra o selecciona el PDF O Imagén a enviar</p>
              </div>
            </FileUploader>

            <br />


          </Box>
          <div className="upload-expense" style={{ width: "100%" }}>
            {fileName && <p className="filename">
              {fileName.name ? fileName.name : fileName}
            </p>}
          </div>
          <Box sx={{ display: 'flex', gap: '5px', p: '2px', zIndex: "10", margin: "0 auto", width: "95%" }} >
            <Button
              variant="contained"
              sx={{ width: "50%" }}
              className="ButtonModal"
              onClick={handleSubmit}
            >
              Crear
            </Button>

            <Button
              variant="contained"
              sx={{ width: "50%" }}
              className="ButtonModal2"
              onClick={() => {
                dialog.close();
              }}
            >
              cancelar
            </Button>
          </Box>
          <br></br>
        </form>
      </div>
    </div>
  );
};

export default CREATE_UPDATE_EXPENSE;
