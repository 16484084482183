//PAQUETERIAS

import { useEffect, useState, useMemo, useRef } from "react";
import { Box } from "@mui/material";
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import dayjs from 'dayjs';
import NotificationSystem from "react-notification-system";
//COMPONENTES Y FUNCIONES
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../../main_components/methods_v2/export";
import MODAL_TABLE from "../../../main_components/modal/modal_Table";
import { BREADCRUMBS } from "../../../main_components/pagination/breadcrumbs";
import { refresh_token } from "../../../main_components/tokens/tokenrefresh";
import { date_current } from "../../../main_components/date/date";
import { previous_date } from "../../../main_components/date/date";
import JSON_DETAIL from "../json/details.json";
import { calculate_total } from "../../../main_components/methods_v2/auxiliary_functions";
//ESTILOS

//IMAGENES/ICONOS

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const DEBETS_PAYMENTS_DETAIL = (props) => {
    const [data, setData] = useState([]);
    const [modalGeneral, setmodalGeneral] = useState(true);
    const [modalT, setmodalT] = useState(false);
    const [ff, setff] = useState(dayjs(date_current));
    const [fi, setfi] = useState(dayjs(previous_date));
    const notificationSystemRef = useRef();
    const [isLoading, setIsLoading] = useState(false);
    const [rowSelection, setRowSelection] = useState({});
    const [total_rows, settotal_rows] = useState("");
    const update_identifiers = [
        { folio: 'string' },
        { serie: 'string' },
        { invoice_date: 'string' },
        { due_date: 'string' },
        { total: 'string' },
        { currency: 'string' },
        { name_business_partner: 'string' },
        { rfc_business_partner: 'string' },
        { amount_paid: 'string' },
        { amount_of_debt: 'string' },
        { change_type: 'string' },
    ];



    const handleSubmit = async () => {
        setIsLoading(true)

        try {
            setTimeout(() => {
                console.log(props?.id_client)
                setData(JSON_DETAIL.filter((d) => {
                    return d.name_business_partner == props?.id_client
                }));
                setIsLoading(false)
            }, 3000);
        } catch (err) {
            console.log(err);
        }
    };



    const columns_detail = useMemo(
        () => [
            {
                header: "Pagado",
                accessorKey: "paid",
                accessorFn: (d) => {
                    return d.paid === true ? "SI" : "NO"
                },
                size: 150,
                muiTableBodyCellProps: {
                    align: 'justify',
                },
                filterVariant: 'select',
                enableEditing: false,
                enableRowOrdering: false,
                enableColumnDragging: false,
                enableColumnActions: false,
                enableFilterMatchHighlighting: false,
                enableColumnFilterModes: false,
            },

            {
                header: "Socio de Negocios",
                accessorKey: "name_business_partner",
                size: 300,
                muiTableBodyCellProps: {
                    align: 'justify',
                },
            },
            {
                header: "RFC del Socio",
                accessorKey: "rfc_business_partner",
                size: 200,
                muiTableBodyCellProps: {
                    align: 'justify',
                },
            },

            {
                header: "Folio",
                accessorKey: "folio",
                size: 150,
                muiTableBodyCellProps: {
                    align: 'justify',
                },
            },
            {
                header: "Serie",
                accessorKey: "serie",
                size: 100,
                muiTableBodyCellProps: {
                    align: 'justify',
                },
                filterVariant: 'select',
                enableEditing: false,
                enableRowOrdering: false,
                enableColumnDragging: false,
                enableColumnActions: false,
                enableFilterMatchHighlighting: false,
                enableColumnFilterModes: false,

            },
            {
                header: "Fecha de Factura",
                accessorKey: "invoice_date",
                size: 200,
                muiTableBodyCellProps: {
                    align: 'justify',
                },
            },
            {
                header: "Fecha de Vencimiento",
                accessorKey: "due_date",
                size: 200,
                muiTableBodyCellProps: {
                    align: 'justify',
                },
            },
            {
                header: "Fecha de Pago",
                accessorKey: "payment_date",
                size: 200,
                muiTableBodyCellProps: {
                    align: 'justify',
                },
            },



            {
                header: "Tipo de Cambio",
                accessorKey: "change_type",
                size: 150,
                Cell: ({ cell }) => {
                    return (
                        <Box
                            component="span"
                            sx={(theme) => ({
                                backgroundColor: theme.palette.success.dark,
                                borderRadius: '0.25rem',
                                color: '#fff',
                                maxWidth: '16ch',
                                p: '0.25rem',
                            })}
                        >
                            {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
                                style: 'currency',
                                currency: 'MXN',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }) : cell.getValue()?.toLocaleString?.('es-MX', {
                                style: 'currency',
                                currency: 'MXN',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}
                        </Box>
                    )
                },
                muiTableBodyCellProps: {
                    align: 'right',
                },
                muiTableFooterCellProps: {
                    align: 'right',
                },
            },

            {
                header: "Condición de Pago",
                accessorKey: "paiment_condition",
                size: 200,
                muiTableBodyCellProps: {
                    align: 'justify',
                },
                filterVariant: 'select',
                enableEditing: false,
                enableRowOrdering: false,
                enableColumnDragging: false,
                enableColumnActions: false,
                enableFilterMatchHighlighting: false,
                enableColumnFilterModes: false,
            },

            {
                header: "Moneda",
                accessorKey: "currency",
                Cell: ({ cell }) => {
                    return (
                        <Box
                            component="span"
                            sx={(theme) => ({
                                backgroundColor: 'rgb(245, 124, 0)',
                                borderRadius: '0.25rem',
                                color: '#fff',
                                maxWidth: '16ch',
                                p: '0.25rem',
                            })}
                        >
                            {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
                                style: 'currency',
                                currency: 'MXN',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }) : cell.getValue()?.toLocaleString?.('es-MX', {
                                style: 'currency',
                                currency: 'MXN',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}
                        </Box>
                    )
                },
                muiTableBodyCellProps: {
                    align: 'right',
                },
                muiTableFooterCellProps: {
                    align: 'right',
                },
                filterVariant: 'select',
                enableEditing: false,
                enableRowOrdering: false,
                enableColumnDragging: false,
                enableColumnActions: false,
                enableFilterMatchHighlighting: false,
                enableColumnFilterModes: false,
            },
            {
                header: "Sub-Total",
                accessorKey: "subtotal",
                size: 150,

                Cell: ({ cell }) => {
                    return (
                        <Box
                            component="span"
                            sx={(theme) => ({
                                backgroundColor: theme.palette.success.dark,
                                borderRadius: '0.25rem',
                                color: '#fff',
                                maxWidth: '16ch',
                                p: '0.25rem',
                            })}
                        >
                            {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
                                style: 'currency',
                                currency: 'MXN',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }) : cell.getValue()?.toLocaleString?.('es-MX', {
                                style: 'currency',
                                currency: 'MXN',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}
                        </Box>
                    )
                },
                muiTableBodyCellProps: {
                    align: 'right',
                },
                muiTableFooterCellProps: {
                    align: 'right',
                },
                Footer: (d) => {
                    const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => isNaN(obj.subtotal) ? 0 : obj.subtotal)
                    const total = data.map(obj => isNaN(obj.subtotal) ? 0 : obj.subtotal)
                    return (
                        <div >
                            <div>{calculate_total(subtotal).toLocaleString('es-Mx', {
                                style: 'currency',
                                currency: 'MXN',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}</div>
                            <div>{calculate_total(total).toLocaleString('es-Mx', {
                                style: 'currency',
                                currency: 'MXN',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}</div>
                        </div>
                    )
                },
            },
            {
                header: "Descuento",
                accessorKey: "discount",
                size: 150,
                Cell: ({ cell }) => {
                    return (
                        <Box
                            component="span"
                            sx={(theme) => ({
                                backgroundColor: theme.palette.success.dark,
                                borderRadius: '0.25rem',
                                color: '#fff',
                                maxWidth: '16ch',
                                p: '0.25rem',
                            })}
                        >
                            {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
                                style: 'currency',
                                currency: 'MXN',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }) : cell.getValue()?.toLocaleString?.('es-MX', {
                                style: 'currency',
                                currency: 'MXN',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}
                        </Box>
                    )
                },
                muiTableBodyCellProps: {
                    align: 'right',
                },
                muiTableFooterCellProps: {
                    align: 'right',
                },
                Footer: (d) => {
                    const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => isNaN(obj.discount) ? 0 : obj.discount)
                    const total = data.map(obj => isNaN(obj.discount) ? 0 : obj.discount)
                    return (
                        <div >
                            <div>{calculate_total(subtotal).toLocaleString('es-Mx', {
                                style: 'currency',
                                currency: 'MXN',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}</div>
                            <div>{calculate_total(total).toLocaleString('es-Mx', {
                                style: 'currency',
                                currency: 'MXN',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}</div>
                        </div>
                    )
                },
            },

            {
                header: "Impuestos",
                accessorKey: "tax",
                size: 150,
                Cell: ({ cell }) => {
                    return (
                        <Box
                            component="span"
                            sx={(theme) => ({
                                backgroundColor: theme.palette.success.dark,
                                borderRadius: '0.25rem',
                                color: '#fff',
                                maxWidth: '16ch',
                                p: '0.25rem',
                            })}
                        >
                            {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
                                style: 'currency',
                                currency: 'MXN',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }) : cell.getValue()?.toLocaleString?.('es-MX', {
                                style: 'currency',
                                currency: 'MXN',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}
                        </Box>
                    )
                },
                muiTableBodyCellProps: {
                    align: 'right',
                },
                muiTableFooterCellProps: {
                    align: 'right',
                },
                Footer: (d) => {
                    const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => isNaN(obj.tax) ? 0 : obj.tax)
                    const total = data.map(obj => isNaN(obj.tax) ? 0 : obj.tax)
                    return (
                        <div >
                            <div>{calculate_total(subtotal).toLocaleString('es-Mx', {
                                style: 'currency',
                                currency: 'MXN',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}</div>
                            <div>{calculate_total(total).toLocaleString('es-Mx', {
                                style: 'currency',
                                currency: 'MXN',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}</div>
                        </div>
                    )
                },
            },


            {
                header: "Total",
                accessorKey: "total",
                size: 150,

                Cell: ({ cell }) => {
                    return (
                        <Box
                            component="span"
                            sx={(theme) => ({
                                backgroundColor: theme.palette.success.dark,
                                borderRadius: '0.25rem',
                                color: '#fff',
                                maxWidth: '16ch',
                                p: '0.25rem',
                            })}
                        >
                            {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
                                style: 'currency',
                                currency: 'MXN',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }) : cell.getValue()?.toLocaleString?.('es-MX', {
                                style: 'currency',
                                currency: 'MXN',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}
                        </Box>
                    )
                },
                muiTableBodyCellProps: {
                    align: 'right',
                },
                muiTableFooterCellProps: {
                    align: 'right',
                },
                Footer: (d) => {
                    const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => isNaN(obj.total) ? 0 : obj.total)
                    const total = data.map(obj => isNaN(obj.total) ? 0 : obj.total)
                    return (
                        <div >
                            <div>{calculate_total(subtotal).toLocaleString('es-Mx', {
                                style: 'currency',
                                currency: 'MXN',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}</div>
                            <div>{calculate_total(total).toLocaleString('es-Mx', {
                                style: 'currency',
                                currency: 'MXN',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}</div>
                        </div>
                    )
                },
            },
            {
                header: "Total Real",
                accessorKey: "real_total",
                size: 150,
                Cell: ({ cell }) => {
                    return (
                        <Box
                            component="span"
                            sx={(theme) => ({
                                backgroundColor: theme.palette.success.dark,
                                borderRadius: '0.25rem',
                                color: '#fff',
                                maxWidth: '16ch',
                                p: '0.25rem',
                            })}
                        >
                            {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
                                style: 'currency',
                                currency: 'MXN',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }) : cell.getValue()?.toLocaleString?.('es-MX', {
                                style: 'currency',
                                currency: 'MXN',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}
                        </Box>
                    )
                },
                muiTableBodyCellProps: {
                    align: 'right',
                },
                muiTableFooterCellProps: {
                    align: 'right',
                },
                Footer: (d) => {
                    const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => isNaN(obj.real_total) ? 0 : obj.real_total)
                    const total = data.map(obj => isNaN(obj.real_total) ? 0 : obj.real_total)
                    return (
                        <div >
                            <div>{calculate_total(subtotal).toLocaleString('es-Mx', {
                                style: 'currency',
                                currency: 'MXN',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}</div>
                            <div>{calculate_total(total).toLocaleString('es-Mx', {
                                style: 'currency',
                                currency: 'MXN',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}</div>
                        </div>
                    )
                },
            },

            {
                header: "Monto Pagado",
                accessorKey: "amount_paid",
                size: 150,
                Cell: ({ cell }) => {
                    return (
                        <Box
                            component="span"
                            sx={(theme) => ({
                                backgroundColor: theme.palette.success.dark,
                                borderRadius: '0.25rem',
                                color: '#fff',
                                maxWidth: '16ch',
                                p: '0.25rem',
                            })}
                        >
                            {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
                                style: 'currency',
                                currency: 'MXN',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }) : cell.getValue()?.toLocaleString?.('es-MX', {
                                style: 'currency',
                                currency: 'MXN',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}
                        </Box>
                    )
                },
                muiTableBodyCellProps: {
                    align: 'right',
                },
                muiTableFooterCellProps: {
                    align: 'right',
                },
                Footer: (d) => {
                    const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => isNaN(obj.amount_paid) ? 0 : obj.amount_paid)
                    const total = data.map(obj => isNaN(obj.amount_paid) ? 0 : obj.amount_paid)
                    return (
                        <div >
                            <div>{calculate_total(subtotal).toLocaleString('es-Mx', {
                                style: 'currency',
                                currency: 'MXN',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}</div>
                            <div>{calculate_total(total).toLocaleString('es-Mx', {
                                style: 'currency',
                                currency: 'MXN',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}</div>
                        </div>
                    )
                },
            },
            {
                header: "Monto de Deuda",
                accessorKey: "amount_of_debt",
                size: 150,
                Cell: ({ cell }) => {
                    return (
                        <Box
                            component="span"
                            sx={(theme) => ({
                                backgroundColor: theme.palette.success.dark,
                                borderRadius: '0.25rem',
                                color: '#fff',
                                maxWidth: '16ch',
                                p: '0.25rem',
                            })}
                        >
                            {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
                                style: 'currency',
                                currency: 'MXN',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }) : cell.getValue()?.toLocaleString?.('es-MX', {
                                style: 'currency',
                                currency: 'MXN',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}
                        </Box>
                    )
                },
                muiTableBodyCellProps: {
                    align: 'right',
                },
                muiTableFooterCellProps: {
                    align: 'right',
                },
                Footer: (d) => {
                    const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => isNaN(obj.amount_of_debt) ? 0 : obj.amount_of_debt)
                    const total = data.map(obj => isNaN(obj.amount_of_debt) ? 0 : obj.amount_of_debt)
                    return (
                        <div >
                            <div>{calculate_total(subtotal).toLocaleString('es-Mx', {
                                style: 'currency',
                                currency: 'MXN',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}</div>
                            <div>{calculate_total(total).toLocaleString('es-Mx', {
                                style: 'currency',
                                currency: 'MXN',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}</div>
                        </div>
                    )
                },
            },
            {
                header: "UUDI_SAT",
                accessorKey: "uuid_sat",
                size: 200,
                muiTableBodyCellProps: {
                    align: 'justify',
                },
            },

        ],
        [data]
    );



    const handleStateChange = (newState) => {
        const filteredRowsCount = newState.filteredRowModel
        settotal_rows(filteredRowsCount);
    };

    useEffect(() => { handleSubmit() }, [props?.id_client]);

    return (
        <div >
            <MODAL_TABLE open={true} modalT={modalT}></MODAL_TABLE>
            <NotificationSystem ref={notificationSystemRef}></NotificationSystem>
            <BREADCRUMBS
                niveles={[
                    {
                        label: props?.id_client,
                        path: null,
                    },
                ]}
                cards_dashboard={true}
                total_rows={total_rows !== "" ? total_rows : 0}
                filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
                visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
                total={data?.length}
            ></BREADCRUMBS>

            <div className="new_table_v2">

                <MaterialReactTable
                    columns={columns_detail}
                    data={data}
                    enableGrouping
                    enablePinning
                    enableFacetedValues
                    enableStickyHeader
                    enableStickyFooter
                    enableRowPinning
                    enableColumnFilterModes
                    onStateChange={handleStateChange}
                    enableRowSelection
                    getRowId={(row) => row?.id}
                    onRowSelectionChange={setRowSelection}
                    enableTooltips={false}
                    localization={MRT_Localization_ES}
                    state={{ rowSelection, isLoading: isLoading }}
                    enableColumnResizing
                    enableColumnPinning
                    enableColumnOrdering
                    enableExpandAll={false}
                    enableColumnDragging={false}
                    initialState={{ showGlobalFilter: true, showColumnFilters: true }}
                    muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
                    muiSkeletonProps={{ animation: 'pulse', height: 28 }}
                    rowVirtualizerInstanceRef
                    rowVirtualizerOptions={{ overscan: 5 }}
                    columnVirtualizerOptions={{ overscan: 2 }}
                    muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
                    positionToolbarAlertBanner="bottom"
                    paginationDisplayMode='pages'
                    rowPinningDisplayMode='sticky'
                    layoutMode="grid"
                    muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
                    muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
                    muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
                    displayColumnDefOptions={{
                        'mrt-row-pin': {
                            Header: () => (
                                <div title="Regresar">
                                    <ArrowBackIcon
                                        style={{ cursor: "pointer" }}
                                        onClick={() => { props?.set_enable_detail(false); props?.set_id_client("") }}
                                    ></ArrowBackIcon>
                                </div>
                            ),
                            enableHiding: true,
                        },
                        'mrt-row-actions': {
                            enableHiding: true,
                        },
                        'mrt-row-expand': {
                            enableHiding: true,
                        },
                        'mrt-row-select': {
                            enableHiding: true,
                        }
                    }}
                    muiTableBodyRowDragHandleProps={({ table }) => ({
                        onDragEnd: () => {
                            const { draggingRow, hoveredRow } = table.getState();
                            if (hoveredRow && draggingRow) {
                                data.splice(
                                    hoveredRow.index,
                                    0,
                                    data.splice(draggingRow.index, 1)[0],
                                );
                                setData([...data]);
                            }
                        },
                    })}
                    muiTableBodyRowProps={
                        ({ row, table }) => {
                            settotal_rows(table)
                            const { density } = table.getState();
                            return {
                                sx: {
                                    height: row.getIsPinned()
                                        ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                                        }px`
                                        : undefined,
                                },
                            };
                        }
                    }


                    renderToolbarInternalActions={({ table }) => (
                        <RENDER_TOOLBAR_INTERNAL_ACTIONS
                            table={table}
                            file_name={"Reporte de pagos y deudas"}
                            update_identifiers={update_identifiers}
                            settotal_rows={settotal_rows}
                        />
                    )}

                />
            </div>
        </div>
    );
};

export default DEBETS_PAYMENTS_DETAIL;
