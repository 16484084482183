import { useEffect, useState} from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useDialog } from "react-st-modal";
import { refresh_token } from "../../main_components/tokens/tokenrefresh";
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
//ICONOS
import PersonIcon from '@mui/icons-material/Person';
import ListAltIcon from '@mui/icons-material/ListAlt';
//IMAGENES
import Flecha from "../../imgComponents/agregarRole.png";
import Delete from "../../imgComponents/borrar.png";

function EditUser(props) {

console.log(props.row)  

const dialog = useDialog();

const [name, setname]=useState(props.row.original.fullusername);
const [username, setusername]=useState(props.row.original.username);
const [telefono, settelefono]=useState(props.row.original.phone);
const [puesto, setpuesto]=useState(props.row.original.job);
const [correo, setcorreo]=useState(props.row.original.email);

const [vpuesto, setvpuesto]=useState(false);
const [vtelefono, setvtelefono]=useState(false);

const [bandera, setbandera]=useState(true);
const [elementos, setElementos] = useState([]);
const [elementosArrastrados, setElementosArrastrados] = useState([]);
const [value, setValue]=useState(0);
const URL_BASE = "https://lxwr6pudd9.execute-api.us-east-1.amazonaws.com";



useEffect(()=>{
  handleSubmit();
},[]);

//proceso de consultar todos los ROLES
const handleSubmit = () => {
  console.log("entra a consultar todos los roles");
  refresh_token();
  setTimeout(() => {
    axios
      .get(
        `${URL_BASE}/development/company/roles`,
        {
          headers: {
            Authorization: sessionStorage.getItem("getIdTokenRefresh"),
            "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        const data = response.data.company_roles;
        rolesuser(data);
      })
      .catch((err) => {
        console.log("error", err);
        setElementos([]);
      });
  }, 2000);
};

function rolesuser(d){

  const filteredRoles = d.filter(role => !props.row.original.roles.includes(role.name_role));
  const filteredRoles2 = d.filter(role => props.row.original.roles.includes(role.name_role));
  console.log(filteredRoles);
  console.log(filteredRoles2);
  setElementosArrastrados(filteredRoles2);
  setElementos(filteredRoles);
}

// Creamos las funciones para manejar el drag and drop
const handleOnDragStart = (event, item) => {
  // Almacenamos el elemento en el estado "elementosArrastrados"
  setElementosArrastrados((prevState) => [...prevState, item]);
  setElementos((prevState) => prevState.filter((i) => i.id !== item.id));
  //}
};

const handleOnDrop = (event) => {
  // Eliminamos el elemento del estado "elementosArrastrados"
  setElementosArrastrados([]);
};

const handleOnDragOver = (event) => {
  // Evitamos que el elemento sea movido por defecto
  //event.preventDefault();
};

const handleRemoveItem = (item) => {
setElementosArrastrados((prevState) =>
    prevState.filter((i) => i.id !== item.id)
  );
  // Añadimos el elemento a la lista izquierda
  setElementos((prevState) => [...prevState, item]);
};


//editar un usuario

function createuser(){
var nameroles=elementosArrastrados.map(role=>role.name_role);

console.log(name);
console.log(username);
console.log(puesto);
console.log(correo);
console.log(telefono);
console.log(nameroles);


if(telefono===null || telefono==="" || puesto===null || puesto==="" || correo === null || correo === ""){
  props.alertas("Parámetros Faltantes", false);
  telefono===null || telefono===""?setvtelefono(true):setvtelefono(false);
  puesto===null || puesto===""?setvpuesto(true):setvpuesto(false);
  return null;
}
if(telefono.length<10){
  props.alertas("El Número Teléfonico debe contener 10 digitos");
  setvtelefono(true);
  return null;
}

setbandera(false);
  refresh_token();
  props.reconsultar(true);

  setTimeout(() => {
    var data = {
      "username": username,
      "email": correo,
      "fullusername": name,    
      "phone" : telefono,
      "job": puesto,
      "roles": nameroles
  }
  var url = `${URL_BASE}/development/users/${props.row.original.id}`;
 
  var axiosConfig = {
    headers: {
      Authorization: sessionStorage.getItem("getIdTokenRefresh"),
      "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
      "Content-Type": "application/json",
    },
  }
  axios
  .put(url, data,axiosConfig)
  .then((response) => {
    console.log(response);
    props.alertas("Usuario actualizado con éxito", true);
    props.reconsultar(false);
    dialog.close();
    props.handleSubmit();
  })
  .catch((err) => {
    console.log("error put", err);
    setbandera(true);
    if(err.response.status==500){
      props.alertas("No se pudo actualizar el usuario, vuelva a intertarlo", false);
    }else{
      props.alertas("No se pudo actualizar el usuario, vuelva a intentarlo", false)
    }
    props.reconsultar(false);
    });
  }, 2000);

}
 
function Spinner() {
  return (
    <div className="spinner-border spinner-border-sm text-light" role="status">
      <span className="sr-only">Cargando...</span>
    </div>
  );
}

const [infousuarios, setinfousuarios]=useState(true);
const [inforoles, setinforoles]=useState(false);

function seccionrol(){
   setinforoles(false);
   setinfousuarios(true);
}
function seccionusuario(){
   setinforoles(true);
   setinfousuarios(false);
}

  return (
    <div>
      <div className="adduser">
<br/>
    <div className="pestañasusuarios">
    <div className="navegacioncompany">
      <Box sx={{ margin: "0 auto" ,width: 400 }}>
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        <BottomNavigationAction style={infousuarios==false?{"backgroundColor":"#E7E7E7"}:{}} label="Información General" icon={<PersonIcon onClick={seccionrol} />}  onClick={seccionrol}/>
        {
          props.row.original.editable===true?
            <BottomNavigationAction style={inforoles==false?{"backgroundColor":"#E7E7E7"}:{}} label="Permisos" icon={<ListAltIcon onClick={seccionusuario}/>} onClick={seccionusuario}/>:""
        }
      </BottomNavigation>
    </Box>
    </div>
    
    </div>
      <div className="infouser" style={infousuarios==true?{"display":"block"}:{"display":"none"}}>
      <div className="griduser1">
          <div className="user1">
           <TextField
              label="Nombre"
              value={name}
              required
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setname(event.target.value)}  
              InputLabelProps={{ shrink: true }}
            ></TextField>
          </div>
          <div className="user2">
          <TextField
              label="Nombre de Usuario"
              required
              value={username}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setusername(event.target.value)}  
              InputLabelProps={{ shrink: true }}
            ></TextField>
          </div>
        </div>
        <div className="griduser2">
          <div className="user3">
             <TextField
              label="Número Teléfonico"
              value={telefono}
              type={"number"}
              required
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => settelefono(event.target.value.slice(0,10))}  
              onSelect={(event)=>setvtelefono(false)}
              InputLabelProps={{ shrink: true }}
            ></TextField>
             <p className="vericorreo" style={vtelefono==true?{"display":"block"}:{"display":"none"}}>Completa este campo</p>
         
          </div>
          <div className="user4">
            <TextField
              label="Puesto"
              required
              value={puesto}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setpuesto(event.target.value.toUpperCase())}  
              InputLabelProps={{ shrink: true }}
              onSelect={(event)=>setvpuesto(false)}
            ></TextField>
             <p className="vericorreo" style={vpuesto==true?{"display":"block"}:{"display":"none"}}>Completa este campo</p>
          </div>
        </div>
        <div className="griduser3">
        <div className="user5">
          <TextField
              label="Correo"
              value={correo}
              required
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setcorreo(event.target.value)}  
              InputLabelProps={{ shrink: true }}
            ></TextField>
          </div> 
          
        </div>
      </div>
      <div className="infouserroles" style={inforoles==true?{"display":"block"}:{"display":"none"}}>
      <div className="drag-and-drop-user">
      {/* Mostramos los elementos en la lista izquierda */}
      <div className="lista-izquierda-drag">
        <h2>Roles Disponibles</h2>
        {elementos.map((item) => (
          <div
            key={item.id}
            className="item-drag"
            draggable
            //onClick={(event) => handleOnDragStart(event, item)}
          >
            <div className="itemnamerole">      
            {item.name_role}
            </div>
            <div className="imagitem">
            <img
              src={Flecha}
              onClick={(event) => handleOnDragStart(event, item)}              
            ></img>
            </div>
          </div>
        ))}
      </div>
      {/* Mostramos los elementos arrastrados en la lista derecha */}
      <div
        className="lista-derecha-drag"
        onDrop={handleOnDrop}
        onDragOver={handleOnDragOver}
      >
        <h2>Roles Habilitados</h2>        
        {elementosArrastrados.map((item) => (
          <div key={item.id} className="item-drop">
            <img
              src={Delete}
              onClick={() => handleRemoveItem(item)}                 
            ></img>
            {item.name_role}
            
            {/* <button onClick={() => handleRemoveItem(item)}>Eliminar</button> */}
          </div>
        ))}
      </div>
  </div>
  <br/>
</div>
        <br/>
        <Box>
      {
  bandera==true?
      <Button
      variant="contained"
      style={{"background-color":"green"}}
      sx={{ m: 1.5, width: "30ch" }}
      onClick={createuser}
    >
      Guardar Cambios
    </Button>:
    <Button
    variant="contained"
    disabled
    style={{"background-color":"green", "height":"35px"}}
    sx={{ m: 1.5, width: "30ch" }}
  ><Spinner/></Button>
    }
    
    <Button
      style={{"background-color":"red"}}
      variant="contained"
      disabled={!bandera}
      sx={{ m: 1.5, width: "20ch" }}
      onClick={() => {
          dialog.close();
        } 
      }
    >
    cancelar
  </Button>
  </Box>
          
      </div>
    </div>
  );
}

export default EditUser;
