import { Verified } from "@mui/icons-material";

export const arrStatus =[
    {status: 'OPEN', spanish: "ABIERTO"},
    {status: 'CLOSE', spanish: "CERRADO"}
  ]
  
export const colors = [
    {letter: 'A', color: '#7F8C8D'},
    {letter: 'B', color: '#1ABC9C'},
    {letter: 'C', color: '#2ECC71'},
    {letter: 'D', color: '#3498DB'},
    {letter: 'E', color: '#9B59B6'},
    {letter: 'F', color: '#34495E'},
    {letter: 'G', color: '#F1C40F'},
    {letter: 'H', color: '#E67E22'},
    {letter: 'I', color: 'red'},
    {letter: 'J', color: '#FA6E79'},
    {letter: 'K', color: '#2C3E50'},
    {letter: 'L', color: '#FD79A8'},
    {letter: 'M', color: '#6C5CE7'},
    {letter: 'N', color: '#00B894'},
    {letter: 'O', color: '#00CEC9'},
    {letter: 'P', color: '#0984E3'},
    {letter: 'Q', color: '#6D214F'},
    {letter: 'R', color: '#E84393'},
    {letter: 'S', color: '#FF7675'},
    {letter: 'T', color: '#D63031'},
    {letter: 'U', color: '#E17055'},
    {letter: 'V', color: '#74B9FF'},
    {letter: 'W', color: '#A29BFE'},
    {letter: 'X', color: '#636E72'},
    {letter: 'Y', color: '#2D3436'},
    {letter: 'Z', color: '#B2BFB8'},
  ];

  export const support_avatar = 'linear-gradient(to bottom right, #00B674, #0A6644, #08A36B, #0A6644, #4DB690)';

  /* Styles  */
  export const message1 = {fontSize: "10em"}
  export const message2 = {fontSize: "8em"}
  export const messagetext = {fontStyle:"italic", fontSize: "large"}
  export const stringDate = {fontSize: "14px", fontStyle: "italic"}
  export const replyContainer = { display: "flex", flexDirection: "row", justifyContent: "space-between", bottom: 0, fontStyle: "italic", fontSize: "16px"}
  export const styleActions = { cursor: 'pointer', fontSize: "16px", color: "#525252"}
  export const verifiedIcon = {color:"blue", alignSelf: "center", marginTop: "-5px"}
  export const avatarStyle = { marginLeft: "-10px", padding: "0 5px 5px 5px",borderBottom: "2px solid #ddd" };

  /* Functions */
  export const checkRoleSupport = (userRoles = "") => {
    const rolesArray = userRoles.split(',');
    return rolesArray.includes('ROLE_SUPPORT');
  };

 export const getColorFromUserName = (userName = '', colorArray = colors, support_staft = false) => {
    const firstLetter = userName[0].toUpperCase();
    const colorObject = colorArray.find(color => color.letter === firstLetter);    
    return  !support_staft ? colorObject ? colorObject.color : "#000000" : support_avatar  
  };

  export const getInitial = (role_support = false, userName = '') => {
    return role_support ? "S" : userName.charAt(0);
  };

  export const getName = (userName = '', support_staft = false) => {
    const fullName = userName.split(' ')
    const name = support_staft ? <span style={{fontWeight: "bold"}}>SOPORTE HI <Verified style={verifiedIcon}/></span>: `${fullName[0]} ${fullName[1]}`
    return name;
  };

  export const truncateText = (text = '', maxLength = 0) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  };