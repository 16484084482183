import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useDialog } from "react-st-modal";
import { refresh_token } from "../../main_components/tokens/tokenrefresh";
import NotificationSystem from 'react-notification-system';
//COMPONENTES
import AlertResponse from "../../main_components/alerts/alertResponse";

function CreateExpenseConcept(props) {

const dialog = useDialog();

const notificationSystemRef = useRef();

const [namecat, setnamecat]=useState(null);
const [roleperm, setroleperm]=useState(null);
const [enable, setenable]=useState(true);
const [bandera, setbandera]=useState(true);
const [dataroles, setdataroles]=useState([]); 

const [vnamecat, setvnamecat]=useState(false);
const [vroleperm, setvroleperm]=useState(false);
const URL_BASE="https://7t0suvusg7.execute-api.us-east-1.amazonaws.com";

useEffect(()=>{
  refresh_token();
  handleroles();  
},[]); 

const handleroles=()=>{
    axios
      .get(
        `${URL_BASE}/od/expense-concepts/roles`,
        {
          headers: {
            Authorization: sessionStorage.getItem("getIdTokenRefresh"),
            "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log(response.data.allow_roles);
        const data = response.data.allow_roles
        setdataroles(data);
      })
      .catch((err) => {
        console.log("error", err);
      });
}


//crear un nuevo catalogo 
function createnewdiscount(){

if(namecat==null || roleperm==null){
  setvnamecat(true);
  setvroleperm(true);
  props.alertas("Parámetros faltantes", false);
  
}else{
  
props.reconsultar(true);
setbandera(false);
refresh_token();
setTimeout(() => {
  var data = {
    "enable": enable,//boolean = Habilitado | Deshabilitado
    "catalog_name": namecat, //string
    "role_approved": roleperm //int
}
var url = `${URL_BASE}/od/expense-concepts`;
var axiosConfig = {
  headers: {
    Authorization: sessionStorage.getItem("getIdTokenRefresh"),
    "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
    "Content-Type": "application/json",
  },
}
axios
.post(url, data,axiosConfig)
.then((response) => {
  console.log(response);
  props.alertas("Catálogo de Gastos creado con éxito",true);
  props.reconsultar(false);
  dialog.close();
  props.handleSubmit();
})
.catch((err) => {
  console.log("error post", err);
  if(err.response.status===409){
    props.alertas("Ya existe un catálogo de conceptos con el mismo nombre", false);
  }else{
    props.alertas("No se pudo crear el catálogo de Gastos", false);
  }
  props.reconsultar(false);
  setbandera(true);  
});
}, 2000);
}
}

function Spinner() {
  return (
    <div className="spinner-border spinner-border-sm text-light" role="status">
      <span className="sr-only">Cargando...</span>
    </div>
  );
}

  return (
    <div>
      <NotificationSystem  ref={notificationSystemRef} ></NotificationSystem>

      <div className="addpointsalecss">
          <div className="gridcatalogogastos">
            <div className="catalogo1">
            <TextField
              label="Nombre del Catálogo"
              required
              sx={{ m: 1.5, width: "100%" }}
              value={namecat}
              disabled={!bandera}
              onChange={(event) => setnamecat(event.target.value.toUpperCase())}  
              InputLabelProps={{ shrink: true }}
              style={
                vnamecat==true?{"border":"2px solid red", "border-radius":"5px"}:{}
              }
              onSelect={(event)=>setvnamecat(false)}
              onBlur={
                namecat==null?(event)=>setvnamecat(true):(event)=>setvnamecat(false)
              }
            ></TextField>
             <p className="p_alert" style={vnamecat==true?{"display":"block","textAlign":"center"}:{"display":"none"}}>Completa este campo </p>
  
            </div>
          </div>
          <div className="gridcatalogogastos2">
          <div className="catalogo2">
          <TextField
              select
              label="Habilitado"
              disabled={!bandera}
              value={enable}
              InputLabelProps={{shrink: true,}}
              SelectProps={{native: true,}}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setenable(!enable)} 
             >
              <option key={1} value={true} selected >
                  {"SI"}
              </option>
              <option key={2} value={false}>
                  {"NO"}
              </option>
            </TextField>
            </div>
            <div className="catalogo3">
            <TextField
              select
              required
              label="Roles permitidos"
              disabled={!bandera}
              InputLabelProps={{shrink: true,}}
              SelectProps={{native: true,}}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setroleperm(parseInt(event.target.value))} 
              style={
                vroleperm==true?{"border":"2px solid red", "border-radius":"5px"}:{}
              }
              onSelect={(event)=>setvroleperm(false)}
              onBlur={
                roleperm==null?(event)=>setvroleperm(true):(event)=>setvroleperm(false)
              }  
            >
            <option key={0} value={0}>
              Selecciona un Role
            </option>
            {
              dataroles?.map((item)=>(
                <option key={item.id} value={item.id}>
                  {item.codeAndName}
                </option>
              ))
            }
            </TextField>
            <p className="p_alert" style={vroleperm==true?{"display":"block","textAlign":"center"}:{"display":"none"}}>Completa este campo </p>
  
            </div>
          
          </div>
      
          <br></br>
          <br></br>
          <Box>
            {
              bandera==true?
              
            <Button
            variant="contained"
            style={{"background-color":"green"}}
            sx={{ m: 1.5, width: "45ch" }}
            onClick={createnewdiscount}
          >
            Crear Catálogo de Gastos
          </Button>:
          <Button
          variant="contained"
          disabled
          style={{"background-color":"green", "height":"35px"}}
          sx={{ m: 1.5, width: "45ch" }}
        >
          <Spinner></Spinner>
        </Button>
            }
            <Button
              style={{"background-color":"red"}}
              variant="contained"
              sx={{ m: 1.5, width: "20ch" }}
              disabled={!bandera}
              onClick={() => {
                  dialog.close();
                } 
              }
            >
            cancelar
          </Button>
          </Box>
      </div>
    </div>
  );
}

export default CreateExpenseConcept;
