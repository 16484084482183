import React, { useState, useEffect, Suspense, useMemo, useRef } from "react";
import axios from "axios";
import { alpha, styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Spinner from "../../imgComponents/S.gif";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { FileUploader } from "react-drag-drop-files";
import { CustomDialog, useDialog, Confirm } from "react-st-modal";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import NotificationSystem from "react-notification-system";

//COMPONENTES
import Relacionar from "./Relacionar";
import TableContainer from "../Table_Container_Addendas";
import Modal_Table from "../../main_components/modal/modal_Table";
import AlertResponse from "../../main_components/alerts/alertResponse";
const ValidationTextField = styled(TextField)({
  "& input:valid + fieldset": { borderColor: "green", borderWidth: 2 },
  "& input:valid:focus + fieldset": {
    borderLeftWidth: 6,
    padding: "4px !important",
  },
});
const codDes = [
  { value: 6010, label: 6010 },
  { value: 6011, label: 6011 },
  { value: 6020, label: 6020 },
  { value: 6050, label: 6050 },
  { value: 6090, label: 6090 },
];
const Addenda_PMT = () => {
  const [open, setOpen] = useState(false);
  const [modalT, setmodalT] = useState(false);
  const [serie, setSerie] = useState("");
  const [folio, setfolio] = useState("");
  const [type, settype] = useState("PMT");
  const [order, setorder] = useState("");
  const [destino, setdestino] = useState("6010");
  const [rem, setrem] = useState("");
  const [idInvoice, setidInvoice] = useState("");
  const [dataTable, setDataTable] = useState([]);
  const [dataTable2, setDataTable2] = useState([]);
  const [actB, setactB] = useState(true);

  const notificationSystemRef = useRef();

  const handelOrder = (event) => {
    setorder(event?.target?.value);
  };
  const handelfolio = (event) => {
    setfolio(event?.target?.value);
  };
  const handelserie = (event) => {
    setSerie(event?.target?.value);
  };
  const handelType = (event) => {
    settype(event?.target?.value);
  };
  const handelDestino = (event) => {
    setdestino(event?.target?.value);
  };
  const handelRem = (event) => {
    setrem(event?.target?.value);
  };

  const handleSubmit3 = (event) => {
    event?.preventDefault();
    var jsonMandar = JSON.parse(sessionStorage.getItem("TikectconsulReturn"));
    console.log(jsonMandar);

    var data = {
      type: type,
      order: order,
      destino: destino,
      rem: rem,
      partes: jsonMandar,
    };

    setmodalT(true);
    setOpen(true);
    let axiosConfig = {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    };
    axios
      .post(
        `https://rfpx9okh43.execute-api.us-east-1.amazonaws.com/od/invoices/addendas/volkswagen/${idInvoice}?action=POST`,
        data,
        axiosConfig
      )
      .then((res) => {
        var data = res?.data?.document?.content;

        notificationSystemRef.current.addNotification({
          message: (
            <AlertResponse
              msj={
                "La addenda se generó con éxito y está disponible en el módulo de facturas."
              }
              view={true}
            ></AlertResponse>
          ),
          level: "success",
          position: "br",
          autoDismiss: 10,
        });
        setOpen(false);
        setmodalT(false);
        settype("PMT");
        setorder("");
        setrem("");
        setdestino("6010");
        setSerie("");
        setfolio("");
        setidInvoice("");
        setDataTable([]);
        var blob = base64toBlobxml(data);
        const blobUrl = URL.createObjectURL(blob);
        const downloadLink = document.createElement("a");
        downloadLink.href = blobUrl;
        downloadLink.download = serie + folio + type + ".xml";
        downloadLink.click();
        setactB(true);

      })
      .catch((err) => {
        setmodalT(false);
        setOpen(false);
        console.log(err);
        var error = err?.response?.data?.errorMessage;

        notificationSystemRef.current.addNotification({
          message: (
            <AlertResponse
              msj={
                error === undefined
                  ? "Error 401:No autorizado intentelo nuevamente o recarge la pagina nuevamente oprimiendo la tecla 'CTRL + F5'"
                  : error
              }
              view={false}
            ></AlertResponse>
          ),
          level: "error",
          position: "br",
          autoDismiss: 60,
        });
      });
  };

  function base64toBlobxml(base64Data) {
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: "application/xml" });
  }

  const handleSubmit2 = (event) => {
    event?.preventDefault();
    var jsonMandar = JSON.parse(sessionStorage.getItem("TikectconsulReturn"));
    console.log(jsonMandar);

    var data = {
      type: type,
      order: order,
      destino: destino,
      rem: rem,
      partes: jsonMandar,
    };

    setmodalT(true);
    setOpen(true);
    let axiosConfig = {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    };
    axios
      .post(
        `https://rfpx9okh43.execute-api.us-east-1.amazonaws.com/od/invoices/addendas/volkswagen/${idInvoice}?action=GET`,
        data,
        axiosConfig
      )
      .then((res) => {
        setOpen(false);
        setmodalT(false);
        console.log(res?.data);
        const xmlString = res.data.addenda_xml;
        console.log(xmlString);
        const blob = new Blob([xmlString], { type: "text/xml" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "Ac" + serie + folio + type + ".xml";
        link.click();
        var data = res?.data?.addenda_json?.partes.map((d, i) => {
          var json = {
            posicion: d.posicion,
            numeroMaterial: d.numeroMaterial,
            descripcionMaterial: d.descripcionMaterial,
            cantidadMaterial: d.cantidadMaterial,
            unidadMedida: d.unidadMedida,
            precioUnitario: d.precioUnitario,
            montoLinea: d.montoLinea,
          };
          return json;
        });
        setDataTable(data);
      })
      .catch((err) => {
        setmodalT(false);
        setOpen(false);
        console.log(err);
        var error = err?.response?.data?.errorMessage;
        alert("Error.," + error);
      });
  };

  const handleSubmit = (event) => {
    event?.preventDefault();

    var data = {
      type: type,
      order: order,
      destino: destino,
      rem: rem,
      partes: dataTable,
    };

    console.log(data);
    setmodalT(true);

    setOpen(true);
    let axiosConfig = {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    };
    axios
      .post(
        `https://rfpx9okh43.execute-api.us-east-1.amazonaws.com/od/invoices/addendas/volkswagen/${idInvoice}?action=GET`,
        data,
        axiosConfig
      )
      .then((res) => {
        setOpen(false);
        setmodalT(false);
        console.log(res?.data);
        const xmlString = res.data.addenda_xml;
        console.log(xmlString);
        const blob = new Blob([xmlString], { type: "text/xml" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "Vs" + serie + folio + type + ".xml";
        link.click();
        var data = res?.data?.addenda_json?.partes.map((d, i) => {
          var json = {
            posicion: d.posicion,
            numeroMaterial: d.numeroMaterial,
            descripcionMaterial: d.descripcionMaterial,
            cantidadMaterial: d.cantidadMaterial,
            unidadMedida: d.unidadMedida,
            precioUnitario: d.precioUnitario,
            montoLinea: d.montoLinea,
          };
          return json;
        });
        setDataTable(data);
        setactB(false);
      })
      .catch((err) => {
        setmodalT(false);
        setOpen(false);
        console.log(err);
        var error = err?.response?.data?.errorMessage;
        alert("Error.," + error);
      });
  };

  const handleSubmitValid = () => {
    var errorInvoce="Factura no seleccionada";
    var errorOrden="El campo 'ORDEN', debe contener una longitud de 10 caracteres";
    var errorRem="El campo 'Remisión' no puede ir vacío";

    //POSICIÒN TYPE ENTERO
    //DECRIOCION 39 CARACTERES    
    //1-3 UNIDAD MEDIDA
    //1-18  NUMERO DE MATERIAL
    //LOS OTROS NUMBER
    console.log(folio.length)
    if (folio?.length !== 0 && order?.length===10 && rem?.length!==0) {
       handleSubmit();
    }else {
      notificationSystemRef.current.addNotification({
        message: (
          <AlertResponse
            msj={folio?.length===0?errorInvoce:order?.length!==10?errorOrden:rem?.length===0?errorRem:"Error"}
            view={false}
          ></AlertResponse>
        ),
        level: "error",
        position: "br",
        autoDismiss: 60,
      });
    }
  };

  const handelAutoComp = (data) => {
    console.log(data);
    setDataTable([]);
    const json = data?.reference1===null?{}: data?.reference1===undefined?{}:JSON?.parse(data?.reference1);

    //del json
    setorder(json?.order === undefined ? "" : json?.order);
    setrem(json?.rem === undefined ? "" : json?.rem);
    setdestino(json?.destino === undefined ? "6020" : json?.destino);
    //del data
    setSerie(data?.serie === undefined ? "" : data?.serie);
    setfolio(data?.folio === undefined ? "" : data?.folio);
    setidInvoice(data?.id === undefined ? "" : data?.id);
    setDataTable(json?.partes === undefined ? [] : json?.partes);
    setactB(true);

  };

  useEffect(() => {
    setDataTable2(dataTable2);
  }, [dataTable]);

  const [originalData] = React.useState(dataTable);
  const [skipPageReset, setSkipPageReset] = React.useState(false);
  const updateMyData = (rowIndex, columnId, value) => {
    setSkipPageReset(true);
    setDataTable((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };
  React.useEffect(() => {
    setSkipPageReset(false);
  }, [dataTable]);
  const resetData = () => dataTable(originalData);

  const columns = useMemo(
    () => [
      {
        Header: "Posición",
        accessor: "posicion",
      },
      {
        Header: "Cantidad",
        accessor: "cantidadMaterial",
      },
      {
        Header: "Descripción",
        accessor: "descripcionMaterial",
      },

      {
        Header: "Monto",
        accessor: "montoLinea",
      },
      {
        Header: "Número Material",
        accessor: "numeroMaterial",
      },
      {
        Header: "Precio Unitario",
        accessor: "precioUnitario",
      },
      {
        Header: "Unidad de Medida",
        accessor: "unidadMedida",
      },
    ],
    []
  );
  return (
    <div>
      <Modal_Table modalT={modalT}></Modal_Table>
      <NotificationSystem ref={notificationSystemRef}></NotificationSystem>

      <div>
        <Box>
          <ValidationTextField
            label="Serie"
            variant="outlined"
            placeholder="Serie"
            sx={{ m: 2, width: "20%" }}
            value={serie}
            InputLabelProps={{ shrink: true }}
          />
          <ValidationTextField
            label="Folio"
            variant="outlined"
            placeholder="Folio"
            sx={{ m: 2, width: "20%" }}
            value={folio}
            InputLabelProps={{ shrink: true }}
          />
        </Box>

        <Box>
          <ValidationTextField
            label="Tipo"
            variant="outlined"
            placeholder="Tipo"
            sx={{ m: 2, width: "20%" }}
            value={type}
            InputLabelProps={{ shrink: true }}
          />
          <ValidationTextField
            label="Orden"
            variant="outlined"
            placeholder="Orden"
            inputProps={{ maxLength: 10, minLength:10}}
            sx={{ m: 2, width: "20%" }}
            onChange={handelOrder}
            value={order}
            InputLabelProps={{ shrink: true }}
          />
        </Box>

        <Box>
          {/* <ValidationTextField
            label="Destino"
            variant="outlined"
            placeholder="Destino"
            sx={{ m: 2, width: "20%" }}
            onChange={handelDestino}
            value={destino}
            InputLabelProps={{ shrink: true }}
          /> */}
          <TextField
            select
            label="Destino"
            value={destino}
            sx={{ m: 1.8, width: "20%" }}
            onChange={handelDestino}
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
            SelectProps={{
              native: true,
            }}
          >
            {codDes.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </TextField>

          <ValidationTextField
            label="Remisión"
            variant="outlined"
            placeholder="Remisión"
            sx={{ m: 2, width: "20%" }}
            onChange={handelRem}
            inputProps={{ maxLength: 16, minLength:1}}
            value={rem}
            InputLabelProps={{ shrink: true }}
          />
        </Box>

        <Box>
          <Button
            variant="contained"
            sx={{ m: 2, width: "20%" }}
            className="ButtonModal2"
            onClick={async () => {
              const result = await CustomDialog(
                <Relacionar RFC={"VME640813HF6"}></Relacionar>,
                {
                  className: "ModalRelacionAdd",
                  title: "Buscar factura",
                  isCanClose: false,
                  showCloseIcon: true,
                }
              );
              {
                handelAutoComp(result);
              }
            }}
          >
            Buscar Factura
          </Button>

          <Button
            variant="contained"
            sx={{ m: 2, width: "20%" }}
            className="ButtonModalG"
            onClick={handleSubmitValid}
          >
            Visualizar Addenda
          </Button>
        </Box>

        <Box>
          <Button
            variant="contained"
            sx={{ m: 2, width: "20%" }}
            className="ButtonModalA"
            onClick={handleSubmit2}
            disabled={actB}
          >
            Actualizar
          </Button>

          <Button
            variant="contained"
            disabled={actB}
            sx={{ m: 2, width: "20%" }}
            className="ButtonModal"
            onClick={handleSubmit3}
          >
            Guardar Addenda
          </Button>
        </Box>
      </div>
      <div className="TableCOnsultaClientes TableConsultaAddenda">
        <TableContainer
          exportEx={true}
          columns={columns}
          data={dataTable}
          Gmodal={false}
          style={{ overflowx: 10 }}
          updateMyData={updateMyData}
          skipPageReset={skipPageReset}
          
        />
      </div>
    </div>
  );
};

export default Addenda_PMT;

//componer el mensaje de error y 16 caracteres  remision

//P C Pieza D Monto PU => de momento no se cambia

//cambiar nota

//cantidad  4 decimales

//monto line  mx y 2 decimales

//preceio unitario 20 y 5 decimales

// 80 mx