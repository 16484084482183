var date = new Date();
var day = `${date.getDate()}`.padStart(2, "0");
var month = `${date.getMonth() + 1}`.padStart(2, "0");
var year = date.getFullYear();

var previousMonth = Number(month) - 1;
var previousYear = year;
if (previousMonth === 0) {
  previousMonth = 12;
  previousYear = previousYear - 1;
}

var lastDayOfPreviousMonth;
if (previousMonth === 2) {
  if ((previousYear % 4 === 0 && previousYear % 100 !== 0) || previousYear % 400 === 0) {
    lastDayOfPreviousMonth = 29;
  } else {
    lastDayOfPreviousMonth = 28;
  }
} else {
  lastDayOfPreviousMonth = new Date(previousYear, previousMonth, 0).getDate();
}

var previousDay = Math.min(day, lastDayOfPreviousMonth);
var previousMonthStr = `${previousMonth}`.padStart(2, "0");
var previousDayStr = `${previousDay}`.padStart(2, "0");

export const date_current = `${year}-${month}-${day}`;
export const previous_date = `${previousYear}-${previousMonthStr}-${previousDayStr}`;
