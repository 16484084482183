import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';
import VisibilityIcon from '@mui/icons-material/Visibility';
import "./breadcrumbs.css";

import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
export const BREADCRUMBS = (props) => {
  const [open, setOpen] = React.useState(true);
  // console.log(props?.total_rows?.getFilteredRowModel()?.rows?.length)

  return (
    <div>
      <div className="breadcrumbs-general">
        <a href="/">INICIO</a>
        {props.niveles.map((item, index) =>
          item.path === null ? (
            <React.Fragment key={index}>
              /<span>{item.label}</span>
            </React.Fragment>
          ) : (
            <React.Fragment key={index}>
              /<a href={item.path}>{item.label}</a>
            </React.Fragment>
          )
        )}

        <div style={{ display: props.new_version === true ? open === true ? "" : "none" : "none" }}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => setOpen(false)}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            variant="filled" severity="info" style={{ color: "white", display: "" }} className="alert-new-version">
            La nueva experiencia del módulo {props.modul} ya está disponible. Hemos realizado un rediseño completo con nuevas funciones, estilos más profesionales y una interfaz más fácil de usar.
            Le invitamos a probar la nueva versión haciendo clic en la siguiente URL: <a href={props.path} className="color-url-new">[{props.modul}]</a>
            A partir del "01-03-2024", el antiguo módulo dejará de estar disponible. Asegúrese de actualizar a la nueva versión para disfrutar de todas las mejoras y evitar interrupciones en el servicio.
          </Alert>
        </div>
        <div style={{ display: props.old_version === true ? open === true ? "" : "none" : "none" }}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => setOpen(false)}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            variant="filled" severity="info" style={{ color: "white", display: "" }} className="alert-new-version">
            Bienvenido a la Nueva Interfaz.Continúa utilizando nuestra interfaz mejorada y comparte tus comentarios o sugerencias de mejora en: <a className="color-url-new" href="/help_center">[Soporte-Smit].</a>
            <br></br>
            Regresar a la interfaz antigua: <a href={props.path} className="color-url-new">[{props.modul}].</a>
          </Alert>
        </div>
      </div>


      <div className= {props.cards_secundary===true?"card-container-2":props.cards_secundary_invoices===true?"card-container-3":"card-container"} style={{ display: props.cards_dashboard ? "" : "none" }}>
        <div className="card">
          {/* <p><span>Visualizados:</span>10</p> */}
          <div className="card-box bg-blue">
            <div className="inner">
              <h3> {props?.visibility_row} </h3>
              <p> Visualizados </p>
            </div>
            <div className="icon">
              <VisibilityIcon className="icon-vi" />
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-box bg-orange">
            <div className="inner">
              <h3> {props?.filter_row}</h3>
              <p> Filtrados </p>
            </div>

            <div className="icon">
              <i className="fa " aria-hidden="true"></i>
              <FilterListIcon className="icon-vi" ></FilterListIcon>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-box bg-green">
            <div className="inner">
              <h3>{props?.total} </h3>
              <p>Consultados </p>
            </div>
            <div className="icon">
              <i className="fa " aria-hidden="true"></i>
              <SearchIcon className="icon-vi" ></SearchIcon>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};