

import React from "react";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import "./warbibg.css";
const AlertResponse = ({ msj, view }) => {
  const containerClass = view ? "notification-container-success" : "notification-container-error";
  console.log(view);
  return (
    <div className={containerClass}>
      <div className="notification-title">
        <CheckCircleIcon sx={{ color: 'green', fontSize: 50 }} style={{display:view===true?"":"none"}}/>
        <ErrorIcon sx={{ color: 'white', fontSize: 50 }} style={{display:view===false?"":"none"}}/>
        <br></br>
        <span className="alert-text">{view===true?"¡EXITO!":"¡ERROR!"}</span>
      </div>
      <div className="notification-content">
        <p>{msj}</p>
      </div>
    </div>
  );
};

export default AlertResponse;
