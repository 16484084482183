import React, { useEffect, useRef, useState } from 'react'
import MapsUgcIcon from '@mui/icons-material/MapsUgc';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import { BOT_FC } from '../bot/bot';
import "./bandeja.css";
import "./newchat.css";

export const CHATMINI = (props) => {

  const [open, setopen]=useState(false);
  const[notificacion, setnotificacion]=useState(false);
  return (
    <div>
        <div className='chat-mini'>
        { 
        open===true?
          <div className='seccion-bot'>
              <BOT_FC setopen={setopen}></BOT_FC>
          </div>:
          <div className='icono-chat-mini' onClick={()=>{setopen(true); setnotificacion(false)}}>
            {
              notificacion===true?<CircleNotificationsIcon className='notification'/>:<></>
            }
            <MapsUgcIcon></MapsUgcIcon>
          </div>
        }
      </div>
    </div>
  )
}
