import React, { useState, useEffect, Suspense } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import Spinner from "../../../../../imgComponents/S.gif";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { CustomDialog, useDialog, Confirm } from "react-st-modal";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { update_expenses_groups_detail } from "../../../../../services/expenses/expenses";
import "../../../../expense.css";

const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ValueType = [
  { value: "INGRESO", label: "INGRESO" },
  { value: "EGRESO", label: "EGRESO" },
];

const UPDATE_POLICY = ({ codCocept, codType, codId, codAction, date }) => {
  const dialog = useDialog();
  const [open, setOpen] = useState(false);
  const [codTypePolicy, setcodTypePolicy] = useState(codType);
  const [codConceptPolicy, setcodConcepPolicy] = useState(codCocept);
  const [ff, setff] = useState(date);

  const handelConcept = (event) => {
    setcodConcepPolicy(event?.target?.value);
  };
  const handelType = (event) => {
    setcodTypePolicy(event?.target?.value);
  };

  var data = {
    policy: {
      concept: codConceptPolicy,
      type: codTypePolicy,
      export_date: ff,
    },
  };

  console.log(data);

  const handle_update_expenses_groups_detail = async (event) => {
    event?.preventDefault();
    var fechaI = ff?.split(' ');
    var data = {
      policy: {
        concept: codConceptPolicy,
        type: codTypePolicy,
        export_date: codAction === "INJECTION" ? ff : fechaI[0],
      },
    };
    setOpen(true);
    try {
      await update_expenses_groups_detail(data, sessionStorage.getItem("IdGrupExpense"), codId, codAction);
      setOpen(false);
      dialog.close(true);

    } catch (err) {
      setOpen(false);
      alert("Error.," + err?.response?.data?.errorMessage);
    }




  }
  return (
    <div>
      <div>
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style2}>
            <div className="centermodaldescarga">
              <Typography
                id="modal-modal-title"
                className=""
                variant="h6"
                component="h2"
              >
                <img src={Spinner}></img>
              </Typography>
              <Typography
                id="modal-modal-description"
                className=""
                sx={{ mt: 2 }}
              >
                {"Ejecutando proceso..."}
              </Typography>
            </div>
          </Box>
        </Modal>
      </div>
      <div>
        <form>
          <div style={{ display: codAction === "UPDATE" ? "" : "none" }}>


            <Box sx={{ display: 'flex', gap: '5px', p: '2px', zIndex: "10", margin: "0 auto", width: "95%", marginTop: "5px" }} >
              <TextareaAutosize
                label="Descripciónn"
                variant="outlined"
                aria-label="empty textarea"
                value={codConceptPolicy}
                placeholder="Descripción"
                className="TexAreaAutosize"
                onChange={handelConcept}
              />
            </Box>

            <Box sx={{ display: 'flex', gap: '5px', p: '2px', zIndex: "10", margin: "0 auto", width: "95%", marginTop: "5px" }} >
              <input
                type="text"
                className="fdateExpense"
                onFocus={(e) => {
                  e.currentTarget.type = "date";
                  e.currentTarget.focus();
                }}
                placeholder={ff}
                onChange={(event) => setff(event.target.value)}
              ></input>


              <TextField
                select
                label="Tipo"
                value={codTypePolicy}
                sx={{ width: "80%" }}
                onChange={handelType}
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                SelectProps={{
                  native: true,
                }}
              >
                {ValueType.map((option) => (
                  <option
                    key={option.value}
                    value={option.value}
                    selected={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Box>
          </div>

          <br></br>

          <Box sx={{ display: 'flex', gap: '5px', p: '2px', zIndex: "10", margin: "0 auto", width: "95%", marginTop: "5px" }} >
            <Button
              variant="contained"
              sx={{ width: "50%" }}
              className="ButtonModal"
              onClick={handle_update_expenses_groups_detail}
            >
              {codAction === "UPDATE" ? "ACTUALIZAR" : "INYECTAR"}
            </Button>

            <Button
              variant="contained"
              sx={{ width: "50%" }}
              className="ButtonModal2"
              onClick={() => {
                dialog.close();
              }}
            >
              cancelar
            </Button>
          </Box>
        </form>
      </div >
    </div >
  );
};

export default UPDATE_POLICY;
