import React, { useState } from 'react'
import {
  Box,
  Button,
  MenuItem,
} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { ValidationTextField } from '../../main_components/lable/labe';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import CachedIcon from '@mui/icons-material/Cached';

const FILTERS_FACTOR = (props) => {

  return (
    <div className='form-factor-import'>
        <Box className="controlInvoice" sx={{ display: 'flex', gap: '5px', p: '2px', zIndex: "10", alignItems: 'flex-start' }}>        
        <FormControl sx={{ width: 150 }}>
        <InputLabel id="demo-simple-select-required-label">Rango</InputLabel>
        <Select
          labelId="demo-simple-select-required-label"
          id="demo-simple-select-required"
          sx={{ height: '35px' }}
          value={props?.type}
          onChange={(e) => {
            props?.settype(e.target.value)
          }}
        >
          <MenuItem value={"inventario"}>Inventario</MenuItem>
          <MenuItem value={"minimo"}>Mínimo</MenuItem>
          <MenuItem value={"p_clientes"}>Pedidos Clientes</MenuItem>
          <MenuItem value={"p_proveedores"}>Pedidos Proveedores</MenuItem>
        </Select>
      </FormControl>
      <FormControl sx={{ width: 150 }}>
        <InputLabel id="demo-simple-select-required-label">Operación</InputLabel>
        <Select
          labelId="demo-simple-select-required-label"
          id="demo-simple-select-required"
          value={props?.operation}
          sx={{ height: '35px' }}
          onChange={(e) => {
            props?.setoperation(e?.target.value)
          }}
        >
          <MenuItem value={"_mayor_cero"}>!=</MenuItem>
          <MenuItem value={"_igual_cero"}>=</MenuItem>
        </Select>
      </FormControl>
        <FormControl sx={{ width: 50}}>
        <ValidationTextField
          placeholder="0"
          variant="outlined"
          id="validation-outlined-input"
          sx={{ m: .3, width: "100%" }}
          name="town"
          InputLabelProps={{
            shrink: true,
          }}
          disabled
          />
      </FormControl>
      &nbsp;
      <FormControl required sx={{ width: 60 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Button
            variant="contained"
            title='CONSULTAR'
            sx={{ height: '35px', borderRadius: "15px", marginRight: "5px", width: "50%" }}
            onClick={() => {
              props.handleSubmitfilter();
            }}
          >
            <ManageSearchIcon></ManageSearchIcon>
          </Button>
        </Box>
      </FormControl>
      &nbsp;
      <FormControl required sx={{ width: 50 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Button
            variant="contained"
            title='RECARGAR'
            sx={{ height: '35px', borderRadius: "15px", marginRight: "5px", width: "50%" }}
            onClick={() => {
              props.handleSubmit();
            }}
          >
            <CachedIcon></CachedIcon>
          </Button>
        </Box>
      </FormControl>

      </Box>  
    </div>
  )
}
export default FILTERS_FACTOR;
