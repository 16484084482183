import { useExportData } from "react-table-plugins";
import React, { useEffect, useState, useMemo, Fragment } from "react";
import { CSVLink } from "react-csv";
import Papa from "papaparse";
import XLSX from "xlsx";
import JsPDF from "jspdf";
import exportE from "../imgComponents/export.png";
import "./dashboarModal.css";

import {
  useTable,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
  useRowSelect,
  useBlockLayout
} from "react-table";
import { Table, Row, Col, Button, Input, CustomInput } from "reactstrap";
import { Filter, DefaultColumnFilter } from "../componentsCFDI/filters";


const TableContainer = ({
  columns,
  data,
  renderRowSubComponent,
  exportEx,
  visible,
  Gtotal,
  campSUbtable,
  GtotalBonus,
  GtotalP,
  GtotalNsales,
  Gmodal,
  Gmodal2,
  pgd,
  imp,
  desc,
  totalv,
  ad,
  st
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    footerGroups,
    headerGroups,
    page,
    prepareRow,
    visibleColumns,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    state: { pageIndex, pageSize },
    exportData,
    selectedFlatRows,
    state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      TableStickyHeader:true,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        hiddenColumns: columns
          .filter((col) => col.show === false)
          .map((col) => col.id),
        pageIndex: 0,
        pageSize: 10,
      },
      getExportFileBlob,
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useExportData,
    useRowSelect,
    
  );

  const [difT, setdifT] = useState([]);

  const [totalsubtotal, settotalsubtotal] = useState(0);
  const [totaldiscount, settotaldiscount] = useState(0);
  const [totaltax, settotaltax] = useState(0);
  const [totaltotal, settotaltotal] = useState(0);
  const [totaldebt, settotaldebt] = useState(0);
  const [totalpayed, settotalpayed] = useState(0);


  useEffect(() => {
    console.log("+++++++++");
    const map2 = page;

    const map1 = page.map(x => x.original);
    console.log(map2);

    console.log(map1);
    console.log("+++++++++");
    // setdifT([])
    // for (let i = 0; i < page?.length; i++) {
 
    //   console.log(page[i]?.original?.payed?.toFixed(2));
    //   difT[i] = {
    //     subtotal: page[i]?.original?.subtotal,
    //     discount: page[i]?.original?.discount,
    //     tax: page[i]?.original?.tax,
    //     total: Number(page[i]?.original?.total),
    //     debt: page[i]?.original?.debt,
    //     payed: page[i]?.original?.payed,
    //   };
    // }

    const sumsubtotal = map1
    .filter(cliente => !isNaN(parseFloat(cliente.subtotal)))
    .map(cliente => parseFloat(cliente.subtotal))
    .reduce((previous, current) => previous + current, 0);
    console.log(sumsubtotal);

    const sumdiscount = map1
    .filter(cliente => cliente?.discount != undefined)
    .filter(cliente => !isNaN(parseFloat(cliente.discount)))
    .map(cliente => parseFloat(cliente.discount))
    .reduce((previous, current) => previous + current, 0);


   

    const sumtax = map1
    .filter(cliente => !isNaN(parseFloat(cliente.tax)))
    .map(cliente => parseFloat(cliente.tax))
    .reduce((previous, current) => previous + current, 0);

    const sumtotal = map1
      .filter(cliente => !isNaN(parseFloat(cliente.total)))
      .map(cliente => parseFloat(cliente.total))
      .reduce((previous, current) => previous + current, 0);

    const sumdebt = map1
      .filter(cliente => !isNaN(parseFloat(cliente.debt)))
      .map(cliente => parseFloat(cliente.debt))
      .reduce((previous, current) => previous + current, 0);


      const sumpayed = map1
      .filter(cliente => !isNaN(parseFloat(cliente.payed)))
      .map(cliente => parseFloat(cliente.payed))
      .reduce((previous, current) => previous + current, 0);


  

    settotaltotal(sumtotal);
    settotaldebt(sumdebt);
    settotalpayed(sumpayed);
    settotaltax(sumtax);
    settotalsubtotal(sumsubtotal);
    settotaldiscount(sumdiscount);

  }, [page]);





  useEffect(()=>{

    const sumarClientes = difT;
    console.log(sumarClientes);

  },[difT])


  function getExportFileBlob({ columns, data, fileType, fileName }) {
    if (fileType === "csv") {
      const headerNames = columns.map((col) => col.exportValue);
      const csvString = Papa.unparse({ fields: headerNames, data });
      return new Blob([csvString], { type: "text/csv" });
    } else if (fileType === "xlsx") {
      const header = columns.map((c) => c.exportValue);
      const compatibleData = data.map((row) => {
        const obj = {};
        header.forEach((col, index) => {
          obj[col] = row[index];
        });
        return obj;
      });
      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
      XLSX.writeFile(wb, `${fileName}.xlsx`);
      return false;
    }

    return false;
  }
  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : "";
  };

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };

  const onChangeInInput = (event) => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };


  const [position, setPosition] = useState('static');

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY === 0) {
        setPosition('fixed');
      } else {
        setPosition('static');
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  
  return (
    <>
      <div
        style={{ display: exportEx === true ? "" : "none" }}
        className="containerExportC"
      >
        <div className="expen-6">
          <img
            src={exportE}
            className=" "
            id=""
            title="Exportar"
            onClick={() => {
              exportData("csv", false);
            }}
          ></img>
        </div>
      </div>

      <Fragment>
      <div class="tableFixHead">

        <Table bordered hover {...getTableProps()}>
          <thead className="fijoHeader">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    <div {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                      {generateSortingIndicator(column)}
                    </div>
                    <Filter column={column} />
                  </th>
                ))}
              </tr>
              
            ))}
          </thead>

          <tbody {...getTableBodyProps()} className="fijoBodyTable">
            {page.map((row) => {
              prepareRow(row);
              return (
                <Fragment key={row.getRowProps().key}>
                  <tr>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                  
                  {row.isExpanded && (
                    <tr>
                      <td colSpan={visibleColumns.length}>
                        {renderRowSubComponent(row)}
                      </td>
                    </tr>
                  )}
                </Fragment>
              );
            })}
          </tbody>
          {/* <tfoot>
        {footerGroups.map(group => (
          <tr {...group.getFooterGroupProps()}>
            {group.headers.map(column => (
              <td {...column.getFooterProps()}>{column.render("Footer")}</td>
            ))}
          </tr>
        ))}
      </tfoot> */}

          <tbody {...getTableBodyProps()} style={{ display: Gmodal2 === false ? "" : "none" }}>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>

              <td style={{ display: st === true ? "" : "none" }} >{totalsubtotal.toFixed(2)}</td>
              <td style={{ display: desc === true ? "" : "none" }}>{totaldiscount.toFixed(2)}</td>
              <td style={{ display: imp === true ? "" : "none" }}>{totaltax.toFixed(2)}</td>
              <td style={{ display: totalv === true ? "" : "none" }}>{totaltotal.toFixed(2)}</td>
              <td style={{ display: ad === true ? "" : "none" }}>{totaldebt.toFixed(2)}</td>
              <td style={{ display: pgd === true ? "" : "none" }}>{totalpayed.toFixed(2)}</td>
              
              

            </tr>
          </tbody>
        </Table>
        </div>

        <div
          className="sk-fading-circle"
          style={{ display: Gmodal === true ? "" : "none" }}
        >
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>

        <div style={{ display: visible === true ? "none" : "" }}>
          <Row
            style={{ maxWidth: 1300, margin: "0 auto", textAlign: "center" }}
          >
            <Col md={3}>
              <Button
                color="primary"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {"<<"}
              </Button>
              <Button
                color="primary"
                onClick={previousPage}
                disabled={!canPreviousPage}
              >
                {"<"}
              </Button>
            </Col>
            <Col md={2} style={{ marginTop: 7 }}>
              Pagina{" "}
              <strong>
                {pageIndex + 1} de {pageOptions.length}
              </strong>
            </Col>

            <Col md={4}>
              <CustomInput
                id="select"
                type="select"
                onChange={onChangeInSelect}
              >
                {[
                  10,
                  Math.round(data.length / 4),
                  Math.round(data.length / 2),
                  data.length,
                ].map((pageSize) => (
                  <option key={pageSize.id} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </CustomInput>
            </Col>
            <Col md={3}>
              <Button
                color="primary"
                onClick={nextPage}
                disabled={!canNextPage}
              >
                {">"}
              </Button>
              <Button
                color="primary"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {">>"}
              </Button>
            </Col>
          </Row>
          <h3 className="facturasConsultasTotales">
            Consultados: {data.length}
          </h3>
        </div>
      </Fragment>
    </>
  );
};

export default TableContainer;
