import { useEffect, useState, Fragment } from 'react';
import {
  Box,
  Button,
  MenuItem,
} from '@mui/material';

//Date Picker Imports
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
function sleep(duration) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, duration);
  });
}

const SEARCH_FILTERS = (props) => {


  return (
    <Box className="controlInvoiceorpetitios" sx={{ display: 'flex', gap: '5px', p: '2px', zIndex: "10", alignItems: 'flex-start' }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          sx={{ width: 120 }}
          label="Fecha Inicial"
          value={props.fi}
          format='DD/MM/YYYY'
          onChange={(newValue) => props.setfi(newValue)}
        />
      </LocalizationProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          sx={{ width: 120 }}
          label="Fecha Final"
          value={props.ff}
          format='DD/MM/YYYY'

          onChange={(newValue) => props.setff(newValue)}
        />
      </LocalizationProvider>



      <FormControl >
        <Button
          variant="contained"
          title='CONSULTAR'
          sx={{ width: 20 }}
          onClick={(e) => {
            props.handleSubmit()
          }}
        >
          <ManageSearchIcon></ManageSearchIcon>
        </Button>
      </FormControl>
      <FormControl  style={{display:props?.active_filter!=undefined?"":"none"}}>
        <Button
          variant="contained"
          title={props?.active_filter ? 'VER FACTURAS RELACIONADAS' : 'OCULTAR FACTURAS RELACIONADAS'}
          sx={{ width: 20 }}
          style={{ "backgroundColor": "rgb(158, 90, 175" }}


          onClick={(e) => {
            props.handle_filter_invoices()
          }}
        >
          {props?.active_filter ?
            <FilterAltIcon></FilterAltIcon> :
            <FilterAltOffIcon></FilterAltOffIcon>
          }


        </Button>
      </FormControl>


    </Box>
  );
};

export default SEARCH_FILTERS;
