//PAQUETERIAS
import React, { useState } from "react";
import { useDialog } from "react-st-modal";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";



const ValidationTextField = styled(TextField)({
    "& input:valid + fieldset": { borderColor: "green", borderWidth: 2 },
    "& input:valid:focus + fieldset": {
      borderLeftWidth: 6,
      padding: "4px !important",
    },
  });

const SHARE_FILTER = (props) => {
    const [filter_shear_data, setfilter_shear_data] = useState("");
    const dialog = useDialog();


    return (
        <div>

            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%", // Esto es opcional según tus necesidades
                }}>
                <ValidationTextField
                    label="Filtro"
                    required
                    placeholder="Filtro"
                    sx={{ m: 2, width: "100%" }}
                    variant="outlined"
                    id="validation-outlined-input"
                    name="ticket"
                    onChange={(e)=>setfilter_shear_data(e.target.value?.trim())}
                    value={filter_shear_data}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Box>

            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%", // Esto es opcional según tus necesidades
                }}
            >
                <Button
                    sx={{ m: 2, width: "92%" }}
                    variant="contained"
                    onClick={()=>props.handle_share_filter()}
                >
                    COPIAR FILTRO
                </Button>

                <Button
                    sx={{ m: 2, width: "92%" }}
                    variant="contained"
                    onClick={()=>{props.handle_set_filter(filter_shear_data);dialog.close()}}
                >
                    APLICAR FILTRO
                </Button>

            </Box>

        </div>
    );
};

export default SHARE_FILTER;
