import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Grid, TextField } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { ValidationTextField } from '../../../main_components/lable/labe'; // Asegúrate de que el componente esté correctamente importado
import dayjs from 'dayjs';

function FORM_PETITIONS_HEADERS({ open, onClose, data, onSave, isNew }) {
  const [formData, setFormData] = useState(data);
  const [errors, setErrors] = useState({});
  console.log(formData)


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    setErrors(prev => ({ ...prev, [name]: '' }));
  };

  const validateField = (name, value) => {
    const isNumeric = ['commercialValueLocalCurrencyEntrySummary', 'dollarValue', 'dta', 'freight', 'igi', 'insurance', 'others', 'packaging', 'prv', 'vat', 'vat/prv'].includes(name);
    const isRequired = ['grossWeight', 'vatPaymentMethodSettlement', 'prvPaymentMethodSettlement', 'dtaPaymentMethodSettlement', 'vat/prvPaymentMethodSettlement'].includes(name);
    // const isRequired = true;
    let error = '';

    if (!isRequired && (value === undefined || value === null || value === '')) {
      error = 'Este campo es obligatorio';
      console.log('Este campo es obligatorio ' + value)

    } else if (isNumeric && value && isNaN(Number(value))) {
      error = 'Debe ser un valor numérico';
      console.log(value)
    } else if (value && ['docID', 'supplierCode', 'customs'].includes(name) && value.length > 255) {
      error = 'Máximo 255 caracteres';
    } else if (name === 'currency' && value && (value.length < 3 || value.length > 3)) {
      error = 'Debe contener 3 caracteres';
    } else if ((name === 'date' || name === 'entryDate' || name === 'invoiceDate' || name === 'sortDate') && value && value.length !== 10) {
      error = 'Formato de fecha incorrecto';
    }
    return error;
  };
	

  const handleSubmit = () => {
    let validationErrors = {};
    Object.keys(formData).forEach(key => {
      const error = validateField(key, formData[key]);
      if (error) {
        validationErrors[key] = error;
      }
    });

    if (Object.keys(validationErrors).length === 0) {
      onSave(formData, isNew, data.docID, data.supplierCode);
      onClose();
    } else {
      setErrors(validationErrors);
    }
  };

  const handleDateChange = (newValue, accessor) => {
    console.log(accessor)
    setFormData(prev => ({ ...prev, [accessor]: revertirFecha(newValue?.format("YYYY/MM/DD")) }));
    setErrors(prev => ({ ...prev, [accessor]: '' }));
  };



  function convertirFecha(fecha) {
    if (fecha !== undefined) {
      console.log(fecha)
      const partes = fecha?.split('/');

      // Crear un objeto de fecha con el formato 'DD/MM/YYYY'
      const fechaObj = new Date(`${partes[2]}-${partes[1]}-${partes[0]}T00:00:00`);
      console.log(fechaObj)

      // Obtener el año, mes y día del objeto de fecha
      const year = fechaObj.getFullYear();
      const month = String(fechaObj.getMonth() + 1).padStart(2, '0'); // Los meses comienzan en 0
      const day = String(fechaObj.getDate()).padStart(2, '0');
      console.log(`${year}/${month}/${day}`)

      // Devolver la fecha en el nuevo formato 'YYYY/MM/DD'
      return `${year}/${month}/${day}`;
    }
  }


  function revertirFecha(fecha) {
    if (fecha !== undefined) {
      console.log(fecha)

      // Separar la fecha por el caracter '/'
      const partes = fecha?.split('/');
  
      // Crear un objeto de fecha con el formato 'YYYY/MM/DD'
      const fechaObj = new Date(`${partes[0]}-${partes[1]}-${partes[2]}T00:00:00`);
  
      // Obtener el año, mes y día del objeto de fecha
      const year = fechaObj.getFullYear();
      const month = String(fechaObj.getMonth() + 1).padStart(2, '0'); // Los meses comienzan en 0
      const day = String(fechaObj.getDate()).padStart(2, '0');
  
      // Devolver la fecha en el nuevo formato 'DD/MM/YYYY'
      return `${day}/${month}/${year}`;
    }
  }



  useEffect(() => {
    setFormData(data);
  }, [data, open]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{isNew ? 'Agregar Encabezado de Pedimento' : 'Editar Encabezado de Pedimento'}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {/* Ejemplo de campos agregados */}
          <Grid item xs={12} sm={6} style={{ paddingTop: "22px" }}>
            <ValidationTextField
              label="Código del Proveedor"
              required
              placeholder="Código de Proveedor"
              fullWidth
              variant="outlined"
              name="supplierCode"
              value={formData?.supplierCode || ''}
              onChange={handleChange}
              error={!!errors?.supplierCode}
              helperText={errors?.supplierCode}
            />
          </Grid>

          {[
            { label: "Fecha", accessor: "date", sm: 3, dfl: '', type: 'date' },
            { label: "Documento", accessor: "docType", sm: 3, dfl: '' },
            { label: "Folio", accessor: "docID", sm: 6, dfl: '' },
            { label: "Régimen", accessor: "regime", sm: 4, dfl: '' },
            { label: "Flete", accessor: "freight", sm: 2, dfl: '', type: "number" },
            { label: "Referencia", accessor: "reference", sm: 6, dfl: '' },
            { label: "Otros", accessor: "others", sm: 6, dfl: '' },
            { label: "Iva", accessor: "vat", sm: 3, dfl: '' },
            { label: "Clave Pedimento", accessor: "entrySummaryKey", sm: 3, dfl: '' },
            { label: "Embalaje", accessor: "packaging", sm: 3, dfl: 0.0 },
            { label: "PRV", accessor: "prv", sm: 3, dfl: '' },
            { label: "Seguro", accessor: "insurance", sm: 3, dfl: 0.0 },
            { label: "Tipo de Cambio", accessor: "exchangeRate", sm: 3, dfl: '' },
            { label: "Valor Dólar", accessor: "dollarValue", sm: 3, dfl: '' },
            { label: "Peso Bruto", accessor: "grossWeight", sm: 3, dfl: '' },
            { label: "Forma de Pago VAT cuadro de Liquidación", accessor: "vatPaymentMethodSettlement", sm: 6, dfl: '' },
            { label: "Forma de Pago PRV cuadro de Liquidación", accessor: "prvPaymentMethodSettlement", sm: 6, dfl: '' },
            { label: "Forma de Pago DTA cuadro de Liquidación", accessor: "dtaPaymentMethodSettlement", sm: 6, dfl: '' },
            { label: "Forma de Pago VAT/PRV cuadro de Liquidación", accessor: "var/prvPaymentMethodSettlement", sm: 6, dfl: '' },
            { label: "Valor Comercial MN Pedimento", accessor: "commercialValueLocalCurrencyEntrySummary", sm: 6, dfl: '' },
            { label: "Valor Aduana MN Pedimento", accessor: "customsValueLocalCurrencyEntrySummary", sm: 6, dfl: '' },
            { label: "IGI", accessor: "igi", sm: 3, dfl: '' },
            { label: "IVA/PRV", accessor: "vat/prv", sm: 3, dfl: '' },
            { label: "DTA", accessor: "dta", sm: 3, dfl: '' },
            { label: "Tipo de Operación", accessor: "operationType", sm: 3, dfl: '' },
            { label: "Aduana", accessor: "customs", sm: 3, dfl: '' },
            { label: "Fecha Entrada", accessor: "entryDate", sm: 3, dfl: '', type: "date" }



          ].map(field => (
            <Grid item xs={12} sm={field.sm} style={{ paddingTop: "22px" }}>
              {field.type === "date" ?
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Fecha"
                    value={dayjs(convertirFecha(formData?.[field.accessor]))}
                    onChange={(newValue) => handleDateChange(newValue, field.accessor)}
                    format="DD/MM/YYYY"
                    renderInput={(params) => <TextField {...params} fullWidth error={!!errors.accessor} helperText={errors.accessor} />}
                  />
                </LocalizationProvider>
                :
                <ValidationTextField
                  label={field.label}
                  required
                  placeholder={field.label}
                  fullWidth
                  variant="outlined"
                  name={field.accessor}
                  value={formData?.[field.accessor]}
                  type={field.type || "text"}
                  onChange={handleChange}
                  error={!!errors?.[field.accessor]}
                  helperText={errors?.[field.accessor]}
                />
              }
            </Grid>
          ))}

        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained" color="error" sx={{ m: 1, width: "100%" }}>Cancelar</Button>
        <Button onClick={handleSubmit} variant="contained" color="success" sx={{ m: 1, width: "100%" }}>
          {isNew ? 'Crear' : 'Actualizar'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default FORM_PETITIONS_HEADERS;
