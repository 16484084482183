//PAQUETERIAS
import { useEffect, useState, useMemo, useRef, Fragment } from "react";
import NotificationSystem from 'react-notification-system';
import MODAL_TABLE from "../../main_components/modal/modal_Table";
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import { CustomDialog, Confirm } from "react-st-modal";

import dayjs from 'dayjs';

//COMPONENTES
import AlertResponse from "../../main_components/alerts/alertResponse";
//IMAGENES/ICONOS
import { refresh_token } from "../../main_components/tokens/tokenrefresh";
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";
import { Box, Button } from "@mui/material";
import { calculate_total } from "../../main_components/methods_v2/auxiliary_functions";
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../main_components/methods_v2/export";
import { cosult_documents } from "../../services/portal/portal";
import { date_current, previous_date } from "../../main_components/date/date";
import SelectableHeaderCheckbox from "./select_handler";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { create_order } from "../../services/portal/portal";
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import { COUNT_CARDS } from "../../main_components/pagination/countcards";
import SEARCH_FILTERS from "./tools/shearch_filter";
import UPDATE from "../../imgComponents/save.png";
import DETAILS_INVOICES from "./details_invoices/details_invoices";
import VIEW from "../../imgComponents/lupa.png"
import EditIcon from '@mui/icons-material/Edit';
import EditNoteIcon from '@mui/icons-material/EditNote';
import FORM_INVOICES_HEADERS from "./tools/form_invoices_headers";
import SyncAltIcon from '@mui/icons-material/SyncAlt';

import { update_items_invoices, delete_items_invoices } from "../../services/portal/portal";
import DELETE from "../../imgComponents/borrar.png"
const customLocalization = {
  ...MRT_Localization_ES,
  toolbar: {
    ...MRT_Localization_ES.toolbar,
    resetOrder: 'Resetear Orden',
  },
};
const INVOICE = (props) => {

  const notificationSystemRef = useRef();
  const [open, setopen] = useState(false);
  const [data, setData] = useState([]);
  const [data_original, setData_original] = useState([]);

  const [keys, setkeys] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [rowSelection, setRowSelection] = useState({});
  const [reconsult, setreconsult] = useState(false);
  var consulta = 0;
  const [modalT, setmodalT] = useState(false);
  const [menssage, setmenssage] = useState("");
  const [modalGeneral, setmodalGeneral] = useState(false);
  const [usStateList, setusStateList] = useState([]);
  const [total_rows, settotal_rows] = useState("");

  const [gvalor_en_mn, setgvalor_en_mn] = useState(0);

  const [porc, setporc] = useState(0.0);

  const [ff, setff] = useState(dayjs(date_current));
  const [fi, setfi] = useState(dayjs(previous_date));
  const [suplier, setsuplier] = useState(null);
  const [warehouse, setwarehouse] = useState(null);
  const [filter, setfilter] = useState("Dates");
  const [orderpg, setorderpg] = useState([]);
  const [option_aux, setoption_aux] = useState("FECHA");
  const [selectedColumns, setSelectedColumns] = useState({});
  const [order_forecast, setorder_forecast] = useState([]);
  const [active_filter, setactive_filter] = useState(false);


  console.log(props?.ff_original_facturas)
  console.log(props?.fi_original_facturas)

  const [query_filters, setquery_filters] = useState({});
  const [query_filters_dt, setquery_filters_data] = useState("");


  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [currentRowData, setCurrentRowData] = useState(null);
  const [isNewEntry, setIsNewEntry] = useState(false);


  const handleOpenEditDialog = (rowData, isNew = false) => {
    console.log(rowData)
    setCurrentRowData(rowData);
    setIsNewEntry(isNew);
    setEditDialogOpen(true);
  };

  const handleCloseEditDialog = () => {
    setEditDialogOpen(false);
  };

  const handleSaveData = async (newData, isNew, docID, supplierCode) => {
    console.log(newData)
    console.log(data)
    var newData_mod = {
      "currency": newData.currency,
      "docID": newData.docID,
      "date": newData?.date?.format('DD/MM/YYYY'),
      "sortDate": newData?.date?.format('YYYY/MM/DD')
    }
    let updatedData;
    if (isNew) {
      updatedData = [...data, newData_mod];

    } else {
      updatedData = data.map(row => {
        if (row.id === newData.id) {
          // Utiliza el operador de propagación para conservar los valores existentes
          // y sobrescribe solo los campos que vienen en newData que necesitas actualizar.
          return { ...row, ...newData_mod };
        }
        return row;
      })
    }
    console.log(updatedData)
    var original_data = data;
    setData(updatedData);
    try {
      setIsLoading(true);
      var res = await update_items_invoices(docID, supplierCode, {
        "currency": newData?.currency,
        "docID": newData?.docID,
        "date": newData?.date?.format('DD/MM/YYYY')
      })

    } catch (error) {
      alert(JSON.stringify(error?.response?.data));
      setData(original_data);

    } finally {
      setIsLoading(false);
    }

  };

  const handleAddNewRow = () => {
    const newRow = {
      id: data.length + 1,
      description: '',
      total: '',
      quantity: '',
      line: '',
      price: '',
    };
    handleOpenEditDialog(newRow, true);
  };



  const update_identifiers =
    [

    ];




  const update_null_identifiers = (original_obj, identifiers_to_update) => {
    return original_obj.map((item) => {
      for (const identifier in item) {
        if (item.hasOwnProperty(identifier)) {
          const isToUpdate = identifiers_to_update.some((update) => update[identifier] !== undefined);
          if (isToUpdate && item[identifier] === null) {
            item[identifier] = typeof identifiers_to_update.find((update) => update[identifier]) === 'number' ? 0 : '';
          }
        }
      }
      return item;
    });
  };



  const handle_delete_item = async (docID, supplierCode) => {

    try {
      setIsLoading(true);
      var res = await delete_items_invoices(docID, supplierCode)

    } catch (error) {
      alert(JSON.stringify(error?.response?.data));
      setData(data_original);

    } finally {
      setIsLoading(false);
    }
  }

  //proceso de consultar todas las cuentas contables
  const handleSubmit = async (query_filters_flag, query_filters_data) => {

    setmodalT(true);
    setIsLoading(true);

    var data;

    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {

            var d;
            if (query_filters_flag) {
              setfi(dayjs(query_filters_data.datei))
              setff(dayjs(query_filters_data.datef))
              setoption_aux(query_filters_data.option_aux);
              setsuplier(query_filters_data.supplier);
              setwarehouse(query_filters_data.whscode);
              d = await cosult_documents(query_filters_data);

              setquery_filters(query_filters_data);



            } else {

              data = {
                filtro: filter,
                datei: filter === "Dates" || filter === "SupplierAndDates" || filter === "WarehouseAndDates" ? fi?.format('YYYY-MM-DD') : null,
                datef: filter === "Dates" || filter === "SupplierAndDates" || filter === "WarehouseAndDates" ? ff?.format('YYYY-MM-DD') : null,
                supplier: suplier,
                whscode: warehouse,
                option_aux: option_aux
              }

              setquery_filters(data);

              d = await cosult_documents(fi.format('YYYY/MM/DD'), ff.format('YYYY/MM/DD'));
            }


            console.log(d.data.response)

            // setData(d.data.response);

            let dta = d.data.response;

            // Iterar sobre la lista y agregar el atributo 'id'
            for (let i = 0; i < dta.length; i++) {
              dta[i].id = i + 1;
            }
            console.log(dta)
            setData_original(dta)
            setData(dta.filter((d)=>d.related?.length===0));
            setactive_filter(true)
            setmodalT(false);

            setIsLoading(false);

          } catch (err) {

            console.log("error", err);
            setData([]);
            setopen(false);
            setmodalGeneral(false);
            setmodalT(false);
            setIsLoading(false);
          }
        }, 1000);
      } else { }
    } catch (err) {
      console.log(err);
    }
  };

  const handle_filter_invoices =()=>{
    if(data_original===data){
      setData_original(data_original)
      setData(data.filter((d)=>d.related?.length===0));
      setactive_filter(true)
    }else{
      setData(data_original)
      setactive_filter(false)
    }
  }

  const handleStateChange = (newState) => {
    const filteredRowsCount = newState.filteredRowModel
    settotal_rows(filteredRowsCount);
  };



  const handle_success_filter = (a, b) => {
    handleSubmit(a, b);
    notificationSystemRef.current.addNotification({
      message: <AlertResponse msj={"Filtro Aplicado"} view={true}></AlertResponse>,
      level: "success",
      position: "br",
      autoDismiss: 10,
    });

  }

  const handleColumnSelectionChange = (columnId) => {
    setSelectedColumns(prev => ({ ...prev, [columnId]: !prev[columnId] }));
  };




  const transformDataForSelectedColumns = (selectedColumns, tableData) => {
    const selectedColumnKeys = Object.keys(selectedColumns).filter(key => selectedColumns[key]);

    const orders = selectedColumnKeys.map(columnKey => {
      // Extraer el valor numérico antes del guion
      const weekNumber = columnKey.split('-')[0];

      return {
        week: Number(weekNumber), // Solo el valor numérico antes del guion
        details: tableData.map(row => ({
          code: row['code'],
          quantity: row[columnKey]
        }))
      };
    });

    return { orders };
  };


  useEffect(() => {
    const transformedData = transformDataForSelectedColumns(selectedColumns, data);
    setorder_forecast(transformedData)
  }, [selectedColumns, data]);




  useEffect(() => {
    handleSubmit();
  }, [props?.flag_reconsult]);


  const columns = useMemo(
    () => [
      {
        header: "Ac.",
        size: 80,
        accessorFn: (d) => {

          return (
            <div>
              <span title={"Pedimentos relacionadas: " + d.related?.join(', ')}>
                <SyncAltIcon
                  style={{ color: "green", cursor: "pointer", display: d?.related?.length === 0 ? "none" : "" }}
                ></SyncAltIcon>
              </span>
              <img
                style={{ cursor: "pointer" }}
                src={VIEW}
                title="DETALLES"
                onClick={async () => {
                  await CustomDialog(
                    <DETAILS_INVOICES
                      items={d.items}
                      docID={d.docID}
                      supplierCode={d.supplierCode}
                      handle_cosult_invoice={handleSubmit}
                    ></DETAILS_INVOICES>,
                    {
                      className: "custom-modal-3",
                      title: `Detalles de la factura: ${d.docID}`,
                      showCloseIcon: true,
                    }
                  );
                }}
              ></img>

              <span style={{ cursor: "pointer" }} title="EDITAR FACTURA">
                <EditNoteIcon onClick={() => handleOpenEditDialog({ id: d.id, currency: d.currency, docType: d.docType, docID: d.docID, supplierCode: d.supplierCode, date: dayjs(d.sortDate) })}></EditNoteIcon>
              </span>


              <img
                style={{ cursor: "pointer" }}
                src={DELETE}
                title="ELIMINAR FACTURA"
                onClick={async () => {
                  const result = await Confirm(
                    "¿Esta usted seguro de eliminar esta factura?",
                    "Eliminar Factura",
                    "Si",
                    "No"
                  );
                  if (result) {
                    handle_delete_item(d.docID, d.supplierCode);
                  } else {
                  }

                }}
              ></img>

            </div>
          )
        },

        id: "Acciones",
        enableFilters: false,
        enableEditing: false,
        enableRowOrdering: false,
        enableGrouping: false,
        enableColumnDragging: false,
        enableColumnActions: false,
        enableSorting: false,
      },
      {
        header: "Proveedor",
        size: 200,
        accessorKey: "supplierCode",
        id: "supplierCode",
      },
      {
        header: "Fecha",
        size: 150,
        accessorKey: "date",
        id: "date",
      },
      {
        header: "Documento",
        size: 100,
        accessorKey: "docType",
        id: "docType",
      },
      {
        header: "Folio",
        size: 220,
        accessorKey: "docID",
        id: "docID",
      },
      {
        header: "Moneda",
        size: 120,
        accessorKey: "currency",
        id: "currency",
      },
    ],
    [
      gvalor_en_mn,
      porc,
    ]
  );



  useEffect(() => {
    if (total_rows === "") {
    } else {
      const getCurrentPath = () => {
        const search = window.location.search;
        const pathWithoutQuestionMark = search.startsWith('?') ? search.slice(1) : search;
        return pathWithoutQuestionMark;
      };

      const pathWithoutQuestionMark = getCurrentPath();
      setquery_filters_data(pathWithoutQuestionMark)
    }
  }, [total_rows])



  useEffect(() => {
    const selectedIds = Object.keys(rowSelection);
    const filteredData = data.filter(item => selectedIds.includes(item.id.toString()));
    props?.setinvoice_docts(filteredData)
  }, [rowSelection])


  return (
    <>



      <div className={props?.flag_relation ? 'new_table_v2' : 'new_table_v2 invoice_or_petitios'}>
        <div >
          <NotificationSystem ref={notificationSystemRef} ></NotificationSystem>
          <MODAL_TABLE
            open={true}
            message={menssage}
            modalGeneral={modalGeneral}
            modalT={modalT}
          ></MODAL_TABLE>
          <>

            <div style={{ display: props?.flag_relation ? "none" : "" }}>
              <COUNT_CARDS niveles={
                [
                  { label: "FACTURAS", path: null },
                ]
              }
                // old_version={true}
                // modul="CONSULTAR_CUENTAS_CONTABLES_V1"
                // path="/consult_accounting_account"  
                cards_dashboard={true}
                total_rows={total_rows !== "" ? total_rows : 0}
                filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
                visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
                total={data?.length}
                cards_secundary_countcard={true}
              ></COUNT_CARDS>
            </div>
            <div style={{ display: props?.flag_relation ? "" : "none" }}>
              <BREADCRUMBS
                niveles={
                  [
                    { label: "FACTURAS", path: null },
                  ]
                }
                cards_dashboard={true}
                total_rows={total_rows !== "" ? total_rows : 0}
                filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
                visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
                total={data?.length}
                cards_secundary={true}
              ></BREADCRUMBS>
            </div>

            <FORM_INVOICES_HEADERS
              open={editDialogOpen}
              onClose={handleCloseEditDialog}
              data={currentRowData}
              onSave={handleSaveData}
              isNew={isNewEntry}
              original_data={data}
            />


            <MaterialReactTable
              key={props?.key_table}
              columns={columns}
              data={data}
              enableGrouping
              enablePinning
              enableFacetedValues
              enableStickyHeader
              enableStickyFooter
              enableRowPinning
              enableColumnFilterModes
              onStateChange={handleStateChange}
              enableRowSelection
              getRowId={(row) => row?.id}
              onRowSelectionChange={setRowSelection}
              enableTooltips={false}
              localization={customLocalization}
              state={{ rowSelection, isLoading: isLoading }}
              enableColumnResizing
              enableColumnPinning
              enableColumnOrdering
              enableExpandAll={false}

              initialState={
                {
                  showGlobalFilter: true,
                  density: 'compact',
                  columnVisibility: {
                    currency: props?.flag_relation,
                    "mrt-row-pin": props?.flag_relation,
                    Acciones: props?.flag_relation,
                    docType: props?.flag_relation,
                    currency: props?.flag_relation,

                  }
                }}
              muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
              muiSkeletonProps={{ animation: 'pulse', height: 28 }}
              rowVirtualizerInstanceRef
              rowVirtualizerOptions={{ overscan: 5 }}
              columnVirtualizerOptions={{ overscan: 2 }}
              muiTableContainerProps={{ sx: { maxHeight: '65vh', '@media (max-height: 810px)': { maxHeight: '60vh' } } }}
              positionToolbarAlertBanner="top"
              paginationDisplayMode='pages'
              rowPinningDisplayMode='sticky'
              layoutMode="grid"
              muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important", } }}
              muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
              muiTableFooterCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
              muiPaginationProps={{
                color: 'primary', shape: 'rounded', variant: 'variant', rowsPerPageOptions: [10, 15, 30, 50, 100, 200, 1000],
              }}

              displayColumnDefOptions={{
                'mrt-row-pin': {
                  enableHiding: true,
                },
                'mrt-row-actions': {
                  enableHiding: true,
                },
                'mrt-row-expand': {
                  enableHiding: true,
                },
                'mrt-row-select': {
                  enableHiding: true,
                }
              }}

              muiTableBodyRowDragHandleProps={({ table }) => ({
                onDragEnd: () => {
                  const { draggingRow, hoveredRow } = table.getState();
                  if (hoveredRow && draggingRow) {
                    data.splice(
                      hoveredRow.index,
                      0,
                      data.splice(draggingRow.index, 1)[0],
                    );
                    setData([...data]);
                  }
                },
              })}
              muiTableBodyRowProps={
                ({ row, table }) => {
                  settotal_rows(table);
                  const { density } = table.getState();
                  return {
                    sx: {
                      height: row.getIsPinned()
                        ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                        }px`
                        : undefined,
                    },
                  };
                }
              }
              renderTopToolbarCustomActions={({ }) => (
                <SEARCH_FILTERS
                  ff={ff}
                  fi={fi}
                  setfi={setfi}
                  setff={setff}
                  handleSubmit={handleSubmit}
                  handle_filter_invoices={handle_filter_invoices}
                  active_filter={active_filter}
                ></SEARCH_FILTERS>
              )}

              renderToolbarInternalActions={({ table, idenx }) => (
                <RENDER_TOOLBAR_INTERNAL_ACTIONS
                  table={table}
                  file_name={"FACTURAS"}
                  update_identifiers={update_identifiers}
                  gvalor_en_mn={gvalor_en_mn}
                  porc={porc}
                  query_filters={query_filters}
                  query_filters_dt={query_filters_dt}
                  handle_get_consult={handleSubmit}
                  key={idenx}
                  settotal_rows={settotal_rows}
                  handle_success_filter={handle_success_filter}
                  share_filter={false}
                  total_rows={total_rows !== "" ? total_rows : 0}
                />
              )
              }
            />

          </>
        </div>
      </div>


    </>
  );
}

export default INVOICE;
