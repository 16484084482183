import { CircularProgress } from "@material-ui/core"
import { Typography } from "@mui/material"

export const SpinnerComments = ({ 
    backgroundColor = "white", 
    color = "black", 
    display = true,
    height = "100%", 
    message = "",  
    time = 0, 
    width = "100%" 
  }) => {
  return (
    <div 
        style={{
            position: "absolute",
            display: display ? "flex" : "none",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            backgroundColor: backgroundColor,
            color: color, 
            height: height,
            top: 0,
            left:0,
            right:0,
            bottom: 0,
            width: width,
            zIndex:1000}}>
        <CircularProgress size={100} />
       <Typography style={{marginTop: "20px"}}>{ message }</Typography> 
    </div>
  )
}
