import { Autocomplete, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import {useEffect, useState } from 'react'
import { get_accountingA } from '../../../services/finances/finances';
import { get_route_users } from '../../../services/configuration/configuration';
import FormControl from '@mui/material/FormControl';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useDialog } from 'react-st-modal';
import { refresh_token } from '../../../main_components/tokens/tokenrefresh';
import { post_payment_accounts, put_payment_accounts } from '../../../services/banck/banck';

export const OP_PAYACC = (props) => {

    const [payment_method, setpayment_method]=useState(props?.operation==="EDIT"?props?.row?.original?.payment_method:"");
    const [digits, setdigits]=useState(props?.operation==="EDIT"?props?.row?.original?.digits:"");
    const [payment_account, setpayment_account]=useState(props?.operation==="EDIT"?props?.row?.original?.payment_account:"");
    const [user_name, setuser_name]=useState(props?.operation==="EDIT"? props?.row?.original?.user_name:"");
    const [defaultb, setdefaultb]=useState(props?.operation === "EDIT"? (props?.row?.original?.defaultb===null ? true : props?.row?.original?.defaultb) : true);
    const [enable, setenable]=useState(true);
    const [open, setOpen] = useState(false);
    const [id_user, setid_user]=useState(props?.operation === "EDIT"? props?.row?.original?.id_user_fk:0);    
    const [options, setOptions] = useState([]);
    const [options2, setOptions2]=useState([]);
    const dialog = useDialog();

  useEffect(() => {
    accounting();
    users();
  }, [])

  const accounting = async()=>{
    try{
        const d = await get_accountingA();
        console.log(d?.data?.acc_accounts);
        setOptions(d?.data?.acc_accounts);
    }catch(err){
        console.log(err);
    }
  }

  const users = async()=>{
    try{
        const d = await get_route_users();
        console.log(d?.data?.sales_employess);
        setOptions2(d?.data?.sales_employess);
    }catch(err){
        console.log(err);
    }
  }

  const create =async () =>{
    
    var data = {
      "payment_method": payment_method,
      "digits": digits, 
      "payment_account": payment_account,
      "user_name": user_name,
      "defaultb": defaultb,
      "id_user": id_user
    }

    if(payment_method === "" || digits==="" || payment_account ==="" || user_name === "" || id_user === 0){
      props.alertas("Parámetros Faltantes", false);
      return null;
    }

    props?.setmodalT(true);
    try{
      var rf_token = await refresh_token();
       if(rf_token === true){
         setTimeout(async() => {
          try{
            const d = await post_payment_accounts(data);
            console.log(d);  
            props.alertas("Cuenta de Pago Registrada", true);
            props.setmodalT(false);
            dialog.close();
            props.handleSubmit();
          }catch(err){
            console.log(err);
            props?.setmodalT(false);
            props?.alertas("Error al crear la cuenta de pago", false);
          }
         }, 1500);
      }else{}
   }catch(err){
      console.log(err);
    }
  }

  const update = async () =>{
    var data = {
      "payment_method": payment_method,
      "digits": digits, 
      "payment_account": payment_account,
      "user_name": user_name,
      "defaultb": defaultb,
      "id_user": id_user,
      "enable": enable
    }

    if(payment_method === "" || digits === "" || payment_account === ""){
      props.alertas("Parámetros Faltantes", false);
      return null;
    }

    console.log(data);
    props?.setmodalT(true);  
     try{
       var rf_token = await refresh_token();
        if(rf_token === true){
          setTimeout(async() => {
          try{
            const d = await put_payment_accounts(props?.row?.original?.id, data);
            console.log(d);  
            props.alertas("Cuenta de Pago Actualizada", true);
            props.setmodalT(false);
            dialog.close();
            props.handleSubmit();
          }catch(err){
            console.log(err);
            props?.setmodalT(false);
            props?.alertas("Error al Actualizar la Cuenta de Pago", false);
          }
        }, 1500);
       }else{}
    }catch(err){
       console.log(err);
     }
  }

  const handleuser = (e, v) =>{
     setid_user(v?.id);
     setuser_name(v?.username)
  }
  const handleacc = (e)=>{
    console.log(e);
    setpayment_account(e?.code);
  }


  return (
    <>
    <div className='cont-form-payr'>
      <div className='cont-form-grid'>
        <div className='conforg1'>
              <TextField
                label="Nombre cuenta de pago"
                value={payment_method}
                sx={{ m: 1.5, width: "100%" }}
                onChange={(event) =>{
                  const inputValue = event.target.value.slice(0, 100);
                  setpayment_method(inputValue)}  
                }
                InputLabelProps={{ shrink: true }}
              ></TextField>
          </div>
      </div>
      <div className='cont-form-grid-2'>
        <div className='conforg2'>
                <TextField
                  label="Últimos 4 dígitos de la Cuenta"
                  value={digits}
                  sx={{ m: 1.5, width: "100%" }}
                  onChange={(event) => {
                    const inputValue = event.target.value.slice(0,4);
                    setdigits(inputValue)
                  }}  
                  InputLabelProps={{ shrink: true }}
                ></TextField>
            </div>
          <div className='conforg3'>
          <FormControl sx={{ width: "100%"}} >
          <Autocomplete
            id="Cuenta Contable"
            options={options}
            placeholder='Buscar...'
            getOptionLabel={(option) => `${option.code} - ${option.name}`}
            onChange={(event, newValue)=>handleacc(newValue)}
            renderInput={(params) => 
              <TextField 
                {...params} 
                label="Buscar Cuenta Contable"
                InputLabelProps={{ shrink: true }}
                placeholder={payment_account?payment_account : ""} 
              />
            }
          />
        </FormControl>
          </div>
          <div className='conforg4'>
          <FormControl sx={{ width: "100%"}} >
          <InputLabel id="demo-simple-select-required-label">Por Defecto</InputLabel>
          <Select
            value={defaultb}
            label="Cuenta por Defecto "
            sx={{ height: '50px' }}
            onChange={(e) => setdefaultb(e.target.value)}
          >
            <MenuItem value={true}>SI</MenuItem>
            <MenuItem value={false}>NO</MenuItem>
          </Select>
        </FormControl>
          </div>
      </div>
      <div className='cont-form-grid-3'>
      <div className='conforg5'>
        <FormControl sx={{ width: "101%"}} >
          <Autocomplete
            id="Usuario"
            options={options2}
            getOptionLabel={(option) => `${option?.fullusername}`}
            onChange={(event, newValue)=>handleuser(event, newValue)}
            renderInput={(params) => (
              <TextField 
                {...params} 
                InputLabelProps={{ shrink: true }}
                label="Buscar Usuario"
                placeholder={user_name ? user_name : ""}
              />
            )}
          />
        </FormControl>
        </div>
        <div className='conforg6'>
          <FormControl sx={{ width: "100%"}} >
          <InputLabel id="demo-simple-select-required-label">Cuenta Habilitada</InputLabel>
          <Select
            value={enable}
            label="Cuenta Habilitada "
            sx={{ height: '50px' }}
            onChange={(e) => setenable(e.target.value)}
            disabled={props?.operation==="EDIT"?false:true}
          >
            <MenuItem value={true}>SI</MenuItem>
            <MenuItem value={false}>NO</MenuItem>
          </Select>
        </FormControl>
          </div>
      </div>
        
    </div>
    <br/>
    <Box className="boton-op-routes">
          <Button
            variant="contained"
            style={{"backgroundColor":"green"}}
            sx={{ m: 1.5, width: "25ch" }}
            onClick={()=>{props?.operation==="EDIT"?update():create()}}
          >{props?.operation==="EDIT"?"ACTUALIZAR":"REGISTRAR"}
          </Button>
            <Button
              style={{"backgroundColor":"red"}}
              variant="contained"
              sx={{ m: 1.5, width: "20ch" }}
              onClick={() => {
                  dialog.close();
                } 
              }
            >
            cancelar
          </Button>
          </Box>
    </>
    
  )
}
