//PAQUETERIAS
import React, { useEffect, useState, useMemo, useRef } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import { alpha, styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { CustomDialog, useDialog, Confirm } from "react-st-modal";

import NotificationSystem from 'react-notification-system';
//COMPONENTES
import TableContainer from "../TableContainerBusnes";

import AlertResponse from "../../main_components/alerts/alertResponse";
import { get_ihs} from "../../services/IHS/ihs"
import { post_catalogs } from "../../services/catalogs/catalogs";
import { put_catalogs } from "../../services/catalogs/catalogs";
import { delete_catalogs } from "../../services/catalogs/catalogs";
import Modal_Table from "../../main_components/modal/modal_Table";
import Upload_file from "./upload_file";
//ESTILOS
import { upperCase } from "lodash";
import "../tableclientes.css"
import CREATE from "../../imgComponents/create.png";
import DELETE from "../../imgComponents/borrar.png";
import SAVE from "../../imgComponents/save.png";
import "./ish.css";

const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const ValidationTextField = styled(TextField)({
  "& input:valid + fieldset": { borderColor: "green", borderWidth: 2 },
  "& input:valid:focus + fieldset": {
    borderLeftWidth: 6,
    padding: "4px !important",
  },
});

const TableISH = (props) => {
  
  const notificationSystemRef = useRef();
  const [data, setData] = React.useState([]);
  const [modalT, setmodalT] = useState(false);
  const [menssage, setmenssage] = useState("");
  const [modalGeneral, setmodalGeneral] = useState(false);
  const [open, setopen]=useState(false);



  

  useEffect(() => {
    handledata();
  }, []);

  const handledata = async () => {
    setData([]);
    try {
      setopen(true);
      setmodalT(true);  
      var res = await get_ihs(props.tablename);
      res.sort((a, b) => b.id - a.id);
      setData(res);
      setopen(false);
      setmodalT(false);
    } catch (err) {
      console.log(JSON?.stringify(err.response));
      setData([]);
      setopen(false);
      setmodalT(false);
    }
  };

 

  const handlereconsult=(valid)=>{
    if(valid===true){
      setData([]);
      handledata();
    }else{
      
    }
  }




  const columns = useMemo(
    () => [
      {
        Header:()=>(
          <div>
            <img
                src={CREATE}
                className="ihsbt"
                onClick={async () => {
                 const result = await CustomDialog(
                  <Upload_file></Upload_file>,
                   {
                     className: "upload-file-xlsx",
                     title: "Upload",
                     showCloseIcon: true,
                     isCanClose: false,
                   }
                 );
                 handlereconsult(result);
               }}
             
              ></img>
          </div>
        ),
        id: "expander",
        Cell: ({ row }) => {
          return (
            <div>{row.index} </div>
          );
        },
      },

      {
        Header: `${upperCase("country")}`,
        accessor: `country`,
      },
      {
        Header: `${upperCase("production plant")}`,
        accessor: `production_plant`,
      },
      {
        Header: `${upperCase("production brand")}`,
        accessor: `production_brand`,
      },
      {
        Header: `${upperCase("platform")}`,
        accessor: `platform`,
      },
      {
        Header: `${upperCase("program")}`,
        accessor: `program`,
      },
      {
        Header: `${upperCase("production nameplate")}`,
        accessor: `production_nameplate`,
      },
      {
        Header: `${upperCase("sop")}`,
        accessor: `sop`,
      },
      {
        Header: `${upperCase("eop")}`,
        accessor: `eop`,
      },
      {
        Header: `${upperCase("lifecycle")}`,
        accessor: `lifecycle`,
      },
      {
        Header: `${upperCase("vehicle")}`,
        accessor: `vehicle`,
      },
      {
        Header: `${upperCase("assembly type")}`,
        accessor: `assembly_type`,
      },
      {
        Header: `${upperCase("strategic group")}`,
        accessor: `strategic_group`,
      },
      {
        Header: `${upperCase("sales group")}`,
        accessor: `sales_group`,
      },
      {
        Header: `${upperCase("global nameplate")}`,
        accessor: `global_nameplate`,
      },
      {
        Header: `${upperCase("primary design center")}`,
        accessor: `primary_design_center`,
      },
      {
        Header: `${upperCase("primary design country")}`,
        accessor: `primary_design_country`,
      },
      {
        Header: `${upperCase("primary design region")}`,
        accessor: `primary_design_region`,
      },
      {
        Header: `${upperCase("secondary_design_center")}`,
        accessor: `secondary_design_center`,
      },
      {
        Header: `${upperCase("secondary_design_country")}`,
        accessor: `secondary_design_country`,
      },
      {
        Header: `${upperCase("secondary_design_region")}`,
        accessor: `secondary_design_region`,
      },
      {
        Header: `${upperCase("gvw_rating")}`,
        accessor: `gvw_rating`,
      },
      {
        Header: `${upperCase("gvw_class")}`,
        accessor: `gvw_class`,
      },
      {
        Header: `${upperCase("car/truck")}`,
        accessor: `car`,
      },
      {
        Header: `${upperCase("production_type")}`,
        accessor: `production_type`,
      },
     
      {
        Header: `${upperCase("global_production_segment")}`,
        accessor: `global_production_segment`,
      },
      {
        Header: `${upperCase("regional_sales_segment")}`,
        accessor: `regional_sales_segment`,
      },
      {
        Header: `${upperCase("global_production_price_class")}`,
        accessor: `global_production_price_class`,
      },
      {
        Header: `${upperCase("global_sales_segment")}`,
        accessor: `global_sales_segment`,
      },
      {
        Header: `${upperCase("global_sales_sub-segment")}`,
        accessor: `global_sales_sub-segment`,
      },
      {
        Header: `${upperCase("global_sales_price_class")}`,
        accessor: `global_sales_price_class`,
      },
      {
        Header: `${upperCase("short-term_risk_rating")}`,
        accessor: `short-term_risk_rating`,
      },
      {
        Header: `${upperCase("long-term_risk_rating")}`,
        accessor: `long-term_risk_rating`,
      },
      {
        Header: `${upperCase(`cy_202${3}`)}`,
        accessor: `cy_202${3}`,
      },
      {
        Header: `${upperCase(`cy_202${4}`)}`,
        accessor: `cy_202${4}`,
      },
      {
        Header: `${upperCase(`cy_202${5}`)}`,
        accessor: `cy_202${5}`,
      },
      {
        Header: `${upperCase(`cy_202${6}`)}`,
        accessor: `cy_202${6}`,
      },

      
    ],
    [data]
  );

  return (
    <div className="table-ihs">
     <Modal_Table
    open={true}
    message={menssage}
    modalGeneral={modalGeneral}
    modalT={modalT}
  ></Modal_Table>
   <NotificationSystem  ref={notificationSystemRef} ></NotificationSystem>
 
      <TableContainer
        exportEx={true}
        columns={columns}
        data={data}
        anualSale={true}
        Gmodal={open}
        style={{ overflowx: 10 }}
        renderRowSubComponent={(row) => {
          return <div style={{ padding: "20px" }}></div>;
        }}
      />
    </div>
  );
};

export default TableISH;
