import axios from "axios";
import config from "../../config";

const urlexp = `${config?.baseURLPVD}`;









export const get_supplier_invoices = async (fi,ff) => {
  const url = `${urlexp}/od/supplier-invoices`;
  try {
    const response = await axios.get(url, {
      params: {
        di:fi,
        df:ff,
      },
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const delete_supplier_invoices = async (id) => {
  const url = `${urlexp}/od/supplier-invoices/${id}`;
  try {
    const response = await axios.delete(url, {
    
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};


export const donwload_invoices = async (id,file_name,document_type) => {
  const url = `${urlexp}/od/supplier-invoices/${id}`;
  try {
    const response = await axios.get(url, {
      params: {
        filename: file_name,
        documentType: document_type,
      },
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};


export const create_supplier_invoices = async (dta) => {
  const url = `${urlexp}/od/supplier-invoices`;
  try {
    const response = await axios.post(url,dta, {
   
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};


export const get_expense = async (fi,ff,origin,rfc,bp) => {
  const url = `${urlexp}/od/expenses`;
  try {
    const response = await axios.get(url, {
      params: {
        di:fi,
        df:ff,
        origin:origin,
        rfc:rfc,
        bp:bp,

      },
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};


export const create_expense = async (dta) => {
  const url = `${urlexp}/od/expenses`;
  try {
    const response = await axios.post(url, dta,{

      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const update_expense = async (dta,id) => {
  const url = `${urlexp}/od/expenses/${id}`;
  try {
    const response = await axios.put(url, dta,{

      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const get_expense_concept_list_data = async (foreign) => {
  const url = `${urlexp}/od/expenses/concept-list`;
  try {
    const response = await axios.get(url, {
      params: {
        foreign:foreign,
      },
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const get_expense_paymentaccounts = async (foreign) => {
  const url = `${urlexp}/od/paymentaccounts`;
  try {
    const response = await axios.get(url, {
   
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const get_expense_detail = async (id) => {
  const url = `${urlexp}/od/expenses/${id}/details`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const delete_expense = async (id) => {
  const url = `${urlexp}/od/expenses/${id}`;
  try {
    const response = await axios.delete(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};




export const delete_expense_detail = async (id_expense,id_detail) => {
  const url = `${urlexp}/od/expenses/${id_expense}/details/${id_detail}`;
  try {
    const response = await axios.delete(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};



export const create_expense_group = async (dta) => {
  const url = `${urlexp}/od/expenses/group`;
  try {
    const response = await axios.post(url, dta,{

      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};


export const create_expense_detail = async (id,dta) => {
  const url = `${urlexp}/od/expenses/${id}/details`;
  try {
    const response = await axios.post(url, dta,{
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const update_expense_detail = async (id,dta,id_detail) => {
  const url = `${urlexp}/od/expenses/${id}/details/${id_detail}`;
  try {
    const response = await axios.put(url, dta,{
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};




export const get_expense_group = async (fi,ff) => {
  const url = `${urlexp}/od/grouped_expenses`;
  try {
    const response = await axios.get(url, {
      params: {
        di:fi,
        df:ff,
     
      },
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const delete_expense_group = async (id) => {
  const url = `${urlexp}/od/grouped_expenses/${id}`;
  try {
    const response = await axios.delete(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const init_server_policy = async (id) => {
  const url = `${urlexp}/od/policys/init-server`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};



export const get_expense_concepts = async (fi,ff,origin) => {
    const url = `${urlexp}/od/expense-concepts`;
    try {
      const response = await axios.get(url, {
        params: {
          di:fi,
          df:ff,
          origin:origin,
       
        },
        headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
};

export const post_expense_concept = async (data) => {
  const url = `${urlexp}/od/expense-concepts`;
  try {
    const response = await axios.post(url, data, {
      headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const put_expense_concept = async (id, data) => {
    const url = `${urlexp}/od/expense-concepts/${id}`;
    try {
      const response = await axios.put(url, data, {
        headers: {
            Authorization: sessionStorage.getItem("getIdTokenRefresh"),
            "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
            "Content-Type": "application/json",
          },
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };


  export const delete_expense_concepts = async (id) => {
    const url = `${urlexp}/od/expense-concepts/${id}`;
    try {
      const response = await axios.delete(url, {
        headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
  

  export const get_expense_concepts_roles = async () => {
    const url = `${urlexp}/od/expense-concepts/roles`;
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
};


export const download_expense = async (id) => {
  const url = `${urlexp}/od/expenses/${id}`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};


export const get_expenses_groups = async (id) => {
  const url = `${urlexp}/od/grouped_expenses/${id}`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const get_expenses_groups_detail = async (id) => {
  const url = `${urlexp}/od/grouped_expenses/${id}/policy`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};




export const post_expenses_groups_detail = async (data,id) => {
  const url = `${urlexp}/od/grouped_expenses/${id}/policy`;
  try {
    const response = await axios.post(url, data, {
      headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};


export const delete_expenses_groups_detail = async (id,id_policy) => {
  const url = `${urlexp}/od/grouped_expenses/${id}/policy/${id_policy}`;
  try {
    const response = await axios.delete(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};



export const update_expenses_groups_detail = async (data,id,codId,codAction) => {
  const url = `${urlexp}/od/grouped_expenses/${id}/policy/${codId}?action=${codAction}`;
  try {
    const response = await axios.put(url, data, {
      headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const get_expenses_groups_detail_policy = async (id,id_policy) => {
  const url = `${urlexp}/od/grouped_expenses/${id}/policy/${id_policy}/details`;
  try {
    const response = await axios.get(url,{
      headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};


export const delete_expenses_groups_detail_policy = async (id,id_policy,id_detail) => {
  const url = `${urlexp}/od/grouped_expenses/${id}/policy/${id_policy}/details/${id_detail}`;
  try {
    const response = await axios.delete(url,{
      headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};



export const create_expenses_groups_policy = async (data,id,codIdPolicy) => {
  const url = `${urlexp}/od/grouped_expenses/${id}/policy/${codIdPolicy}/details`;
  try {
    const response = await axios.post(url, data, {
      headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const update_expenses_groups_policy = async (data,id,codIdPolicy,codIdDetail) => {
  const url = `${urlexp}/od/grouped_expenses/${id}/policy/${codIdPolicy}/details/${codIdDetail}`;
  try {
    const response = await axios.put(url, data, {
      headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
 
export const get_policy = async (fi,ff) => {
  const url = `${urlexp}/od/policys`;
  try {
    const response = await axios.get(url, {
      params: {
        di:fi,
        df:ff,     
      },
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};



export const delete_policy = async (id) => {
  const url = `${urlexp}/od/policys/${id}`;
  try {
    const response = await axios.delete(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};



export const update_policy = async (data,codId,codAction) => {
  const url = `${urlexp}/od/policys/${codId}?action=${codAction}`;
  try {
    const response = await axios.put(url,data,{
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};



export const create_policy = async (data) => {
  const url = `${urlexp}/od/policys`;
  try {
    const response = await axios.post(url,data,{
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const get_policy_detail = async (id) => {
  const url = `${urlexp}/od/policys/${id}/details`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const delete_policy_detail = async (id,id_detail) => {
  const url = `${urlexp}/od/policys/${id}/details/${id_detail}`;
  try {
    const response = await axios.delete(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};



export const update_policy_detail = async (data,id,id_detail) => {
  const url = `${urlexp}/od/policys/${id}/details/${id_detail}`;
  try {
    const response = await axios.put(url,data,{
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const create_policy_detail = async (data,id) => {
  const url = `${urlexp}/od/policys/${id}/details`;
  try {
    const response = await axios.post(url,data,{
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
///////////////////////////////// conceptos del catalogo ////////////////////////////////////

//crear concepto en el catalogo 
export const post_expense_concept_list = async (id, data) => {
  const url = `${urlexp}/od/expense-concepts/${id}/expense-concepts-list`;
  try {
    const response = await axios.post(url, data, {
      headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const put_expense_concept_list = async (idca, idco, data) => {
  const url = `${urlexp}/od/expense-concepts/${idca}/expense-concepts-list/${idco}`;
  try {
    const response = await axios.put(url, data, {
      headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const get_expense_concept_list = async (id) => {
  const url = `${urlexp}/od/expense-concepts/${id}/expense-concepts-list`;
  try {
    const response = await axios.get(url, {
      headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};


export const delete_expense_concept_list = async (idcat, idcon) => {
  const url = `${urlexp}/od/expense-concepts/${idcat}/expense-concepts-list/${idcon}`;
  try {
    const response = await axios.delete(url, {
      headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

///////////////////// REGLA DE GASTOS ///////////////////////////////

export const get_expense_income_rules = async () => {
  const url = `${urlexp}/od/expense-income-rules`;
  try {
    const response = await axios.get(url, {
      headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const delete_expense_income_rules = async (id) => {
  const url = `${urlexp}/od/expense-income-rules/${id}`;
  try {
    const response = await axios.delete(url, {
      headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const post_expense_income_rules = async (data) => {
  const url = `${urlexp}/od/expense-income-rules`;
  try {
    const response = await axios.post(url, data, {
      headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const put_expense_income_rules = async (id, data) => {
  const url = `${urlexp}/od/expense-income-rules/${id}`;
  try {
    const response = await axios.put(url, data, {
      headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const get_expense_income_rules_users = async () => {
  const url = `${urlexp}/od/expense-income-rules/users`;
  try {
    const response = await axios.get(url, {
      headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};



export const get_accounting_accounts = async (foreign) => {
  const url = `${urlexp}/od/accounting-accounts`;
  try {
    const response = await axios.get(url, {
      params: {
        foreign: foreign,
      },
      headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};



