import { useEffect, useState } from 'react';
import { Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label, Brush, ReferenceLine, Line, ComposedChart } from 'recharts';
import "./graph.css";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";

export const GRAPH = (props) => {

    const [data, setdata] = useState([]);
    useEffect(() => {
        agrupamiento(props?.data);
    }, [props?.data])

    const agrupamiento = (dt) => {

        const groupedData = dt.reduce((acc, item) => {
            const { currency, proyectado, diferencia, promesa } = item;

            if (!acc[currency]) {
                acc[currency] = {
                    currency,
                    proyectado: 0,
                    diferencia: 0,
                    promesa: 0
                };
            }

            acc[currency].proyectado += proyectado;
            acc[currency].diferencia += diferencia;
            acc[currency].promesa += promesa;

            return acc;
        }, {});
        //   const result = Object.values(groupedData)
        const result = Object.values(groupedData).map(item => ({
            ...item,
            proyectado: parseFloat(item.proyectado.toFixed(2)),
            diferencia: parseFloat(item.diferencia.toFixed(2)),
            promesa: parseFloat(item.promesa.toFixed(2))
        }));
        console.log(result);
        setdata(result)
    }

    const [type, settype] = useState("");
    const [client, setclient] = useState("");


    return (
        <div>
            <Box sx={{ display: 'flex', gap: '5px', p: '2px', zIndex: "10", alignItems: 'flex-start', width: "98%", margin: "0 auto" }}>
                <TextField
                    select
                    label="Tipo"
                    value={client}
                    defaultValue={""}
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ native: true }}
                    sx={{ width: "50%" }}
                    onChange={(event) => setclient(event.target.value)}
                >
                    <option key={0} value={""}>{"Selecciona"}</option>
                    <option key={1} value={"cliente"}>{"Clientes"}</option>
                    <option key={2} value={"moneda"}>{"Moneda"}</option>
                </TextField>

                <TextField
                    select
                    label="Tipo"
                    value={type}
                    defaultValue={""}
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ native: true }}
                    sx={{ width: "50%" }}
                    onChange={(event) => settype(event.target.value)}
                >
                    <option key={0} value={""}>{"Selecciona"}</option>
                    <option key={2} value={"Diferencia"}>{"Diferencia"}</option>
                    <option key={3} value={"Promesa"}>{"Promesa"}</option>
                    <option key={4} value={"Proyectado"}>{"Proyectado"}</option>
                    <option key={5} value={"Todos"}>{"Todos"}</option>
                </TextField>
            </Box>


            {
                client === "cliente" ?
                    <ResponsiveContainer width="100%" maxHeight={450} minHeight={500}>
                        <ComposedChart
                            data={props?.data}
                            margin={{
                                top: 50,
                                right: 50,
                                left: 50,
                                bottom: 10
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name">
                                <Label value="name" offset={-10} position="insideBottom" />
                            </XAxis>
                            <YAxis />
                            <Tooltip />
                            <Legend verticalAlign="top" height={50} />
                            <Bar dataKey="proyectado" stackId="a" fill="green" />
                            <Bar dataKey="promesa" stackId="b" fill="blue" />
                            <Bar dataKey="diferencia" stackId="c" fill="red" />
                            <Brush
                                // dataKey="proyectado" 
                                height={30} stroke="#8884d8"
                            />
                            <ReferenceLine y={0} stroke="#000" />
                            <Bar dataKey="" stackId="" name="" fill="#00CFC3" />
                        </ComposedChart>
                    </ResponsiveContainer> :
                    client === "moneda" ?
                        <ResponsiveContainer width="100%" maxHeight={450} minHeight={500}>
                            <ComposedChart
                                data={data}
                                margin={{
                                    top: 50,
                                    right: 50,
                                    left: 50,
                                    bottom: 10
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="currency">
                                    <Label value="currency" offset={-10} position="insideBottom" />
                                </XAxis>
                                <YAxis />
                                <Tooltip />
                                <Legend verticalAlign="top" height={50} />
                                {
                                    type === "" ? <></> :
                                        type === "Diferencia" ? <Bar dataKey="diferencia" stackId="a" fill="green" /> :
                                            type === "Promesa" ? <Bar dataKey="promesa" stackId="a" fill="red" /> :
                                                type === "Proyectado" ? <Bar dataKey="proyectado" stackId="a" fill="blue" /> :
                                                    type === "Todos" ?
                                                        <>
                                                            <Bar dataKey="diferencia" stackId="a" fill="green" />
                                                            <Bar dataKey="proyectado" stackId="b" fill="blue" />
                                                            <Bar dataKey="promesa" stackId="c" fill="red" />
                                                        </> :
                                                        <></>
                                }
                                <Brush
                                    // dataKey="proyectado" 
                                    height={30} stroke="#8884d8" />


                                <ReferenceLine y={0} stroke="#000" />
                                <Bar dataKey="" stackId="" name="" fill="#00CFC3" />
                            </ComposedChart>
                        </ResponsiveContainer> :
                        <></>
            }


        </div>
    )
}
