//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import { alpha, styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { CustomDialog, useDialog, Confirm } from "react-st-modal";

//COMPONENTES
import TableContainer from "../../TableContainerBusnes";
import { get_catalogs } from "../../../services/catalogs/catalogs";
import { post_catalogs } from "../../../services/catalogs/catalogs";
import { put_catalogs } from "../../../services/catalogs/catalogs";
import { get_users } from "../../../services/catalogs/catalogs";

//ESTILOS
import "../general_catalogs.css";

const ValidationTextField = styled(TextField)({
  "& input:valid + fieldset": { borderColor: "green", borderWidth: 2 },
  "& input:valid:focus + fieldset": {
    borderLeftWidth: 6,
    padding: "4px !important",
  },
});


const Methods = (props) => {
  const dialog = useDialog();

  const [value, setValue] = React.useState(
    props?.method === "post" ? "" : props?.value
  );
  const [valuetwo, setValuetwo] = React.useState(
    props?.method === "post" ? "" : props?.valuetwo
  );
  const [valuethree, setValuethree] = React.useState(
    props?.method === "post" ? "" : props?.valuethree
  );
  const [id_value_data, setid_value_data] = React.useState(props?.id_data);
  const [users, setusers] = React.useState([]);
  const [selectuser, setselectuser] = React.useState([]);

  const [selectaccountsList, setselectaccountsList] = React.useState(
    props?.method === "post" ? "0" : props?.valuetwo === undefined ? "0" : props?.valuetwo
  );;
  const [accountspayment, setaccountspayment] = useState();


  const handle_set_data = async () => {
    try {
      console.log("entra");
      var data_post = {
        [`${props.response}`]: value,
      };
      var data_post2 = {
        [`${props.response}`]: value,
        [`${props.response2}`]: valuetwo,
      };
      var data_post3 = {
        [`${props.response}`]: value,
        [`${props.response2}`]: valuetwo,
        [`${props.response3}`]: valuethree,
      };
      var data_post_agentdata = {
        [`${props.response}`]: value,
        [`${props.response2}`]: selectaccountsList,
      };

      if (props.columdata === 3) {
        if (
          value === null ||
          value === "" ||
          valuetwo === null ||
          valuetwo === "" ||
          valuethree === null ||
          valuethree === ""
        ) {
          props.alerts("Incomplete Parameters", false);
          dialog.close();
          return null;
        }
      }
      if (props.columdata === 2) {
        if (
          value === null ||
          value === "" ||
          valuetwo === null ||
          valuetwo === ""
        ) {
          props.alerts("Incomplete Parameters", false);
          dialog.close();
          return null;
        }
      } else {
        if (value === null || value === "") {
          props.alerts("Incomplete Parameters", false);
          dialog.close();
          return null;
        }
      }
      props.reconsultar(true);
      console.log(props);
      var res = await post_catalogs(
        props.tablename,
        props.tablename === 'Agent'
          ? data_post_agentdata
          : props.columdata === 3
            ? data_post3
            : props.columdata === 2
              ? data_post2
              : data_post
      );
      props.alerts("Successfully Created", true);
      props.reconsultar(false);
      dialog.close(true);
    } catch (err) {
      console.log(JSON?.stringify(err.response));
      props.alerts("Failed to Create", false);
      props.reconsultar(false);
      dialog.close();
    }
  };

  const handle_put_data = async (id_value) => {

    try {
      props.reconsultar(true);

      var data_post = {
        [`${props.response}`]: value,
      };
      var data_post2 = {
        [`${props.response}`]: value,
        [`${props.response2}`]: valuetwo,
      };
      var data_post3 = {
        [`${props.response}`]: value,
        [`${props.response2}`]: valuetwo,
        [`${props.response3}`]: valuethree,
      };
      var data_post_agentdata = {
        [`${props.response}`]: value,
        [`${props.response2}`]: selectaccountsList,
      };

      var res = await put_catalogs(
        props.tablename,
        props.tablename === 'Agent'
          ? data_post_agentdata
          : props.columdata === 3
            ? data_post3
            : props.columdata === 2
              ? data_post2
              : data_post,
        id_value_data
      );
      props.alerts("Successfully Updated", true);
      props.reconsultar(false);
      dialog.close(true);
    } catch (err) {
      props.alerts("Failed to Update", false);
      props.reconsultar(false);
      console.log(JSON?.stringify(err.response));
      dialog.close();
    }
  };

  const handle_get_user = async (id_value) => {
    try {
      if (props.tablename === "Agent") {
        var res = await get_users();
        res.data.unshift({ "id": 0, "name": "---" });

        setusers(res.data)
      }
    } catch (err) {
      console.log(JSON?.stringify(err.response));
    }
  };

  const handel_select_user = (event) => {
    if (event?.target?.value === "9090909090909") {
      setselectaccountsList(null);
    } else {
      setselectaccountsList(event?.target?.value);
      setaccountspayment(event?.target?.value);
    }
  };

  useEffect(() => {
    handle_get_user();
  }, []);

  return (
    <div className="">
      <Box sx={{ textAlign: "center" }}>
        <ValidationTextField
          label={props.response}
          variant="outlined"
          placeholder={props.response}
          sx={{ m: 2, width: "70%" }}
          value={value}
          InputLabelProps={{ shrink: true }}
          onChange={(event) => setValue(event.target.value)}
        />
        <ValidationTextField
          label={props.response2}
          variant="outlined"
          placeholder={props.response2}
          style={{ display: props.visibilitydate === 2 ? "" : "none" }}
          sx={{ m: 2, width: "70%" }}
          value={valuetwo}
          InputLabelProps={{ shrink: true }}
          onChange={(event) => setValuetwo(event.target.value)}
        />

        <ValidationTextField
          label={props.response3}
          style={{ display: props.columdata === 3 ? "" : "none" }}
          variant="outlined"
          placeholder={props.response2}
          sx={{ m: 2, width: "70%" }}
          value={valuethree}
          InputLabelProps={{ shrink: true }}
          onChange={(event) => setValuethree(event.target.value)}
        />

        <TextField
          select
          label="user"
          style={{ display: props.tablename === "Agent" ? "" : "none" }}
          value={selectaccountsList}
          sx={{ m: 1.5, width: "70%" }}
          onChange={handel_select_user}
          size="small"
          InputLabelProps={{
            shrink: true,
          }}
          SelectProps={{
            native: true,
          }}
        >
          {users?.map((option) => (
            <option key={option.id} value={option.id} selected={option.id}>
              {option.name}
            </option>
          ))}
        </TextField>
      </Box>
      <Box sx={{ textAlign: "center" }}>
        <Button
          variant="contained"
          style={{
            "background-color": "green",
            display: props?.method === "post" ? "" : "none",
          }}
          sx={{ m: 1.5, width: "70%" }}
          onClick={async () => {
            const result = await Confirm(
              "Are you sure to create the new attribute?",
              "CREATE",
              "YES",
              "No"
            );
            if (result) {
              handle_set_data();
            } else {
            }
          }}
        >
          CREATE
        </Button>
        <Button
          variant="contained"
          style={{
            "background-color": "green",
            display: props?.method === "post" ? "none" : "",
          }}
          sx={{ m: 1.5, width: "35ch" }}
          onClick={async () => {
            const result = await Confirm(
              "Are you sure to update the attribute?",
              "UPDATE",
              "YES",
              "No"
            );
            if (result) {
              handle_put_data();
            } else {
            }
          }}
        >
          SAVE
        </Button>
      </Box>
    </div>
  );
};

export default Methods;
