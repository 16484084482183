import React, { useState } from 'react'
import { BarChart, Bar,XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label, Brush, ReferenceLine } from 'recharts';

import TextField from "@mui/material/TextField";

export const Graphics = (props) => {
  
  console.log(props.data);

  const [datag,setdatag]=useState([]);
  const [tipo, settipo]=useState("general");

  //filtro 1 general agrupar responsable con numero de proyectos
  //filtro 2 FAt
  //filtro 3 FSS
  function fil1(){

    console.log(tipo);

    

  //FAT
  if(tipo==="general"){
    

    console.log(props.data);

    
    var newArraygeneral = [];   
    const groupedByDate2 = props.data.reduce((acc, obj) => {
      const resp = obj.resp.split(" ")[0];
      if (!acc[resp]) {
        acc[resp] = {
          resp: resp,
          details: [],
          total: 0,
         }
      }
      acc[resp].total +=1;
      acc[resp].details.push({
        id: obj.id,
        warehouse: obj.warehouse,
        resp: obj.resp,
        CRM: obj.CRM,
        closing_chance: obj.closing_chance,
        customer: obj.customer,
        MPG: obj.MPG,
        reference: obj.reference,
        application: obj.application,
        carry_over: obj.carry_over,
        sector: obj.sector,
        production_brand: obj.production_brand,
        name_plate: obj.name_plate,
        country: obj.country,
        program: obj.program,
        platform: obj.platform,
      })    
      return acc;
    }, {});
    for (let i in groupedByDate2) {
    newArraygeneral.push({
       resp: groupedByDate2[i].resp,
       total: (groupedByDate2[i].total),
       details: (groupedByDate2[i].details)
    });
  }

  console.log(newArraygeneral);

  setdatag(newArraygeneral);

  }else{
    

    var newArray = [];   
    const groupedByDate = props.data.reduce((acc, obj) => {
      const warehouse = obj.warehouse.split(" ")[0];
      if (!acc[warehouse]) {
        acc[warehouse] = {
          warehouse: warehouse,
          total: 0,     
          details: [],     
        }
      }
      acc[warehouse].total +=1;
      acc[warehouse].details.push({
        id: obj.id,
        warehouse: obj.warehouse,
        resp: obj.resp,
        CRM: obj.CRM,
        closing_chance: obj.closing_chance,
        customer: obj.customer,
        MPG: obj.MPG,
        reference: obj.reference,
        application: obj.application,
        carry_over: obj.carry_over,
        sector: obj.sector,
        production_brand: obj.production_brand,
        name_plate: obj.name_plate,
        country: obj.country,
        program: obj.program,
        platform: obj.platform,
      })    
      return acc;
    }, {});
    for (let i in groupedByDate) {
    newArray.push({
       warehouse: groupedByDate[i].warehouse,
       total: (groupedByDate[i].total),
       details: (groupedByDate[i].details)
    });
  }

    const FAT = newArray.filter(item=>item.warehouse===(tipo==="fat"?"FAT":"FSS"));
    
    // console.log(FAT[0].details);
 
     var newArray2 = [];   
      const groupedByDate2 = FAT[0].details.reduce((acc, obj) => {
        const resp = obj.resp.split(" ")[0];
        if (!acc[resp]) {
          acc[resp] = {
            resp: resp,
            details: [],
            total: 0,
           }
        }
        acc[resp].total +=1;
        acc[resp].details.push({
          id: obj.id,
          warehouse: obj.warehouse,
          resp: obj.resp,
          CRM: obj.CRM,
          closing_chance: obj.closing_chance,
          customer: obj.customer,
          MPG: obj.MPG,
          reference: obj.reference,
          application: obj.application,
          carry_over: obj.carry_over,
          sector: obj.sector,
          production_brand: obj.production_brand,
          name_plate: obj.name_plate,
          country: obj.country,
          program: obj.program,
          platform: obj.platform,
        })    
        return acc;
      }, {});
      for (let i in groupedByDate2) {
      newArray2.push({
         resp: groupedByDate2[i].resp,
         total: (groupedByDate2[i].total),
         details: (groupedByDate2[i].details)
      });
    }
    console.log(newArray2);
    setdatag(newArray2);
  
    }
  }


  
    return (
<div className="">
  <div className='filtros_personalizados'>
  <div className="fil1">
      <TextField
          select
          label="Almacen"
          InputLabelProps={{shrink: true,}}
          SelectProps={{native: true,}}
          sx={{ m: 1.5, width: "100%" }}
          onChange={(event) => settipo(event.target.value)}
      >
          <option key={1} value={"general"} selected>
            GENERAL
          </option>
          <option key={2} value={"fat"}>
            FAT
          </option>
          <option key={3} value={"fss"}>
            FSS
          </option>
      </TextField>
  </div>
  <div className='fil2'>
      <button className='button' onClick={fil1}>
          Filtrar
      </button>
  </div>  
  </div>


<ResponsiveContainer width="95%" maxHeight={450} minHeight={500}>
        <BarChart
        data={datag}
        margin={{
          top: 20,
          right: 50,
          left: 50,
          bottom: 10
        }}
      > 
        <CartesianGrid strokeDasharray="3 3" />
      
        <XAxis dataKey="resp"> 
          <Label value="responsable" offset={-10} position="insideBottom"/>
        </XAxis>
        <YAxis dataKey="total" 
          label={{value:"Número total de proyectos", angle:90, top: "20"}} 
        /><Brush dataKey="total" height={30} stroke="#8884d8" />  
        <Tooltip/>  
        <Legend verticalAlign="top" height={50}/>
        <ReferenceLine y={0} stroke="#000" />
        <Bar dataKey="total" stackId="a" name="PROYECTOS" fill="#3f87a6"/>
      </BarChart>
      </ResponsiveContainer>

    </div>
  )
}
