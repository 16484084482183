//PAQUETERIAS
import { useEffect, useState, useMemo, useRef } from "react";
import axios from "axios";
import { StickyTable, Row, Cell } from "react-sticky-table";
import {CustomDialog, Confirm} from "react-st-modal";
import NotificationSystem from 'react-notification-system';
//COMPONENTES
import AlertResponse from "../../main_components/alerts/alertResponse";
import Modal_Table from "../../main_components/modal/modal_Table";
import TableContainer from "../../main_components/Table/TableContainer";
//ESTILOS
import "./roles.css";
//IMAGENES/ICONOS
import Delete from "../../imgComponents/borrar.png";
import CREATE from "../../imgComponents/create.png";
import Edit from "../../imgComponents/save.png";
import { refresh_token } from "../../main_components/tokens/tokenrefresh";
import CreateRole from "./CreateRole";
import EditRole from "./EditRole";

const TableRoles= () => {
  
  const notificationSystemRef = useRef();
  const [data, setData] = useState([]);
  const [open, setopen]=useState(false);
  var entrada=0;

  const [modalT, setmodalT] = useState(false);
  const [menssage, setmenssage] = useState("");
  const [modalGeneral, setmodalGeneral] = useState(false);
 
  const URL_BASE = "https://lxwr6pudd9.execute-api.us-east-1.amazonaws.com";

  

useEffect(()=>{
  handleSubmit();
},[]);

function alertas(msj,status){

  if(status==true){
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={true}></AlertResponse>,
      level: 'success',
      position: 'br', 
      autoDismiss: 10, 
    });  
  }else{
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={false}></AlertResponse>,
      level: 'error',
      position: 'br', 
      autoDismiss: 60, 
    });
  }  
}
const reconsultar=(n)=>{
  if(n==true){
    setmodalT(true);  
  }else{
    setmodalT(false);
  } 
}
//proceso de consultar todos los ROLES
const handleSubmit = () => {
    console.log("entra a consultar todos los roles");
    refresh_token();
    
    
    if(entrada==0){
      entrada=entrada+1;
      setmenssage("Consultando Roles...");
      setmodalGeneral(true);
      setmodalT(true);
    }else{
      setmodalT(true);
    }
    setopen(true);
    setData([]);

    setTimeout(() => {
      axios
        .get(
          `${URL_BASE}/development/roles`,
          {
            headers: {
              Authorization: sessionStorage.getItem("getIdTokenRefresh"),
              "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
              "Content-Type": "application/json",
            },
          } 
        )
        .then((response) => {
          console.log(response.data);
          const data = response.data.roles;
          setData(data);
          setopen(false);
          setmodalGeneral(false);
          setmodalT(false);
        })
        .catch((err) => {
          console.log("error", err);
          setData([]);
          setopen(false);
          setmodalGeneral(false);
          setmodalT(false);
        });
    }, 2000);
};

const columns = useMemo(
  () => [
    {
      Header:()=>(
        <div>
          <img
            id="crearRol"
            src={CREATE}
            onClick={async () => {
              const result = await CustomDialog(               
             <div>
                <CreateRole handleSubmit={handleSubmit} alertas={alertas} reconsultar={reconsultar}></CreateRole>
             </div>,
           {
             className: "modalTItle",
             title:"Crear nuevo Rol",
             showCloseIcon: true,
             isCanClose:false,
           }
       );
     }}
            title="Crear Rol"
            className="cancel"
          ></img>
        </div>
      ),
      
      id: "Acciones",
      Cell: ({ valor, row }) => {
      function DeleteSector(){  
      
      setmodalT(true);
      refresh_token();
      setData([]);
      setopen(true);
       
      setTimeout(() => {
        var url = `${URL_BASE}/development/roles/${row.original.id}`;
        var axiosConfig = {
          headers: {
            Authorization: sessionStorage.getItem("getIdTokenRefresh"),
            "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
            "Content-Type": "application/json",
          },
        }
        axios
        .delete(url,axiosConfig)
        .then((response) => {
          console.log(response);
           alertas("Rol Eliminado", true);
           handleSubmit();
        })
        .catch((err) => {
          console.log("error", err);
          setopen(false);
          alertas("Error, no se pudo eliminar el Rol", false);
          setmodalT(false);
          handleSubmit();
        });
       }, 2000);
      }
        return (
          <div>
            <img
              src={Delete}
              onClick={async() =>{
                  const result = await Confirm('¿Esta usted seguro de eliminar el Rol?','Eliminar Roles','Si','No');
                  if(result){
                    DeleteSector()
                    }
                  }            
                }
              className="centerText, cancel"
              title="Eliminar Rol"
            ></img>
              &nbsp;
            <img
              src={Edit}
              onClick={
                async () => {
                  const result = await CustomDialog(               
                 <div>
                    <EditRole row={row} handleSubmit={handleSubmit} alertas={alertas} reconsultar={reconsultar}></EditRole>
                 </div>,
               {
                 className: "modalTItle",
                 title:"Editar Rol",
                 showCloseIcon: true,
                 isCanClose:false,
               }
           ); 
         }
        }
              className="centerText, cancel"
              title="Editar Rol"
            ></img>
          </div>
        );
      },
    },
    {
      Header: "Nombre del Rol",
      accessor:"name_role"
    },
    {
      Header: "Descripción del Rol",
      accessor: "description"
    }
    
    ],
    []
);
  return (
    <>
<div className="TableRoles">
  <div className="">         
  <NotificationSystem  ref={notificationSystemRef} ></NotificationSystem>
  <Modal_Table
    open={true}
    message={menssage}
    modalGeneral={modalGeneral}
    modalT={modalT}
  ></Modal_Table>
          <>
          <div className="titletable">
            <br/>
          <h1>Datos de Roles</h1>
          

          </div>
          <StickyTable>
            <TableContainer
              paginado={"Roles"}
              consultas={"Roles Consultados"}
              exportar={true}
              columns={columns}
              data={data}
              style={{ overflowx: 10 }}
              Gmodal={open}
            />
          </StickyTable>
          </>   
          </div>
        </div>
    </>
  );
}

export default TableRoles;
