import {
  Box,
  Button,
} from '@mui/material';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormControl from '@mui/material/FormControl';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
dayjs.locale('es')

const SEARCH_FILTERS = (props) => {
 
  return (
    <Box className="controlInvoice" sx={{ display: 'flex', gap: '5px', p: '2px', zIndex: "10", alignItems: 'flex-start' }}>
      <LocalizationProvider dateAdapter={AdapterDayjs} >
        <DatePicker
          sx={{ width: 200 }}
          label="Fecha Inicial"
          value={props.fi}
          onChange={(newValue) => props.setfi(newValue)}
          format="DD/MM/YYYY"

        />
      </LocalizationProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs} >
        <DatePicker
          sx={{ width: 200 }}
          label="Fecha Final"
          value={props.ff}
          onChange={(newValue) => props.setff(newValue)}
          format="DD/MM/YYYY"

        />
      </LocalizationProvider>

      <FormControl required sx={{ width: 120 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Button
            variant="contained"
            title='CONSULTAR'
            sx={{ height: '35px', borderRadius: "15px", marginRight: "5px", width: "50%" }}
            onClick={(e) => { props.handle_get_sales() }}
          >
            <ManageSearchIcon></ManageSearchIcon>
          </Button>
        </Box>
      </FormControl>


    </Box>
  );
};

export default SEARCH_FILTERS;
