import { useEffect, useMemo, useRef, useState } from 'react';
import {
    ListItemIcon,
    MenuItem,
} from '@mui/material';
import { CustomDialog, Confirm } from "react-st-modal";
import PDF from "../../../../../imgComponents/pdf.png";
import { download_expense } from '../../../../../services/expenses/expenses';
import { base64toBlob } from '../../../../../main_components/donwloadfuncion/download';
import { refresh_token } from '../../../../../main_components/tokens/tokenrefresh';

const ACTIONS_SPECIALS = (props) => {

  const handle_download = async (id, file_name) => {
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        try {
          props?.setmodalT(true);
          var dta = await download_expense(id);
          const data = dta.data;
          var blob = base64toBlob(data.file);
          const blobUrl = URL.createObjectURL(blob);
          const downloadLink = document.createElement("a");
          downloadLink.href = blobUrl;
          var arr = file_name.split("/");
          downloadLink.download = arr[4] + ".pdf";
          downloadLink.click();
          props?.setmodalT(false);
        } catch (err) {
          props?.setmodalT(false);
        }
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };
    


    return (
        <div>
            <MenuItem
                key={"keymenu1"}
                sx={{ m: 0 }}
                onClick={async () => {
                   props.closeMenu();
                   handle_download(props?.row.original.id, props?.row.original.file_name);
                }}>
                <ListItemIcon>
                    <img
                        alt=""
                        src={PDF}
                        className="mapa_icono"
                        title="Descargar PDF"
                    ></img>
                </ListItemIcon>
              Descargar PDF
            </MenuItem>

        </div >
    );
};

export default ACTIONS_SPECIALS;
