import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";

const poolData = {UserPoolId: "us-east-1_NQEAPA3lr", ClientId: "5tfr134r87l91odndrcmbbm5v8"};
const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

// React
const cognitoUser = userPool.getCurrentUser();
  if (cognitoUser != null) {
    cognitoUser.getSession((err, session) => {
      if (err) {
        // console.log(err);
      } else if (!session.isValid()) {
        // console.log("Invalid session.");
      } else {
      }
    
    });
  } else {
    // console.log("User not found.");
  }

export const tokenn = cognitoUser?.signInUserSession?.idToken.jwtToken;
export const acesstokenn=  cognitoUser?.signInUserSession?.accessToken.jwtToken;
