import EXPENSES_V2 from "../consult_expenses_v2";
const EXPENSES_NON_DEDUCTIBLE_V2 = () => {
  return (
    <>
    <EXPENSES_V2 foreing={0} expense={"NO DEDUCIBLES"} origin={1}/>
    </>
  );
};

export default EXPENSES_NON_DEDUCTIBLE_V2;
