import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import GroupSharpIcon from "@mui/icons-material/GroupSharp";
import Checkbox from "@mui/material/Checkbox";
import estados from "../../../dataComponets/state.json";
import regimen from "../../../dataComponets/regimen.json";
import usocfdi from "../../../dataComponets/usoCFDI.json";
import metodopago from "../../../dataComponets/metodo_pago.json";
import { useDialog } from "react-st-modal";
import { styled } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import AddCardIcon from "@mui/icons-material/AddCard";
import MODAL_TABLE from "../../../main_components/modal/modal_Table";
import NotificationSystem from "react-notification-system";
import AlertResponse from "../../../main_components/alerts/alertResponse";
import { get_code_businesspartners } from "../../../services/businesspartners/businesspartners";
import { refresh_token } from "../../../main_components/tokens/tokenrefresh";
import { create_bp, update_bp } from "../../../services/businesspartners/businesspartners";
import type_bp_json from "../../../dataComponets/bp/type_bp.json";
import partner_typee from "../../../dataComponets/bp/partner_type.json";
import code_method_payment from "../../../dataComponets/bp/code_method_payment.json"
import { get_address_businesspartners } from "../../../services/businesspartners/businesspartners";
import { get_data_businesspartners } from "../../../services/businesspartners/businesspartners";
import { MaterialUISwitch } from "../../../main_components/material_switch/switch";
import { get_accounting_accounts } from "../../../services/expenses/expenses";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import DataExt from "./dataExt";
const ValidationTextField = styled(TextField)({
  "& input:valid + fieldset": { borderColor: "black", borderWidth: 1 },
  "& input:valid:focus + fieldset": {
    borderLeftWidth: 6,
    padding: "4px !important",
  },
});

const address_type_json = [{ value: "FISCAL", label: "FISCAL" }];
const subsidiary_json = [{ value: "MATRIZ", label: "MATRIZ" }];

const Set_bussines_p = (props) => {
  const [values, setValues] = useState({
    code: props.code,
    business_partner: props.business_partner,
    tax_regime: props.tax_regime,
    name: props.name,
    first_last_name: props.first_last_name,
    second_last_name: props.second_last_name,
    balance: props.balance,
    credit_days: props.credit_days,
    business_partner_type: props.business_partner_type,
    capital_regime: props.capital_regime,
    partner_type: props.partner_type,
    person_type: props.person_type,
    cfdi_use: props.cfdi_use,
    phone: props.phone,
    metodo_pago: props.metodo_pago,
    paymethod: props.paymethod,
    allow_invoicing: props.allow_invoicing,
    street: props.street,
    outdoor_number: props.outdoor_number,
    inside_number: props.inside_number,
    neighborhood: props.neighborhood,
    zip_code: props.zip_code,
    city: props.city,
    state: props.state,
    town: props.town,
    country: props.country,
    reference: props.reference,
    address_type: props.address_type,
    subsidiary: props.subsidiary,
    tax_residence: props.tax_residence,
    num_reg_id_trib: props.num_reg_id_trib,
  });


  const [extra, setextra] = useState(false);
  const [type_bp, set_type_bp] = useState(props?.business_partner_type);
  const [partner_type_data, set_partner_type_data] = useState(props.partner_type_data);
  const [uso_cfdi_data, set_uso_cfdi_data] = useState(props.uso_cfdi_data);
  console.log(uso_cfdi_data);
  console.log(props.uso_cfdi_data);

  const [paymethod_data, set_paymethod_data] = useState(props.paymethod);
  const [metodo_pago_data, set_metodo_pago_data] = useState(props.metodo_pago_data);
  const [state_data, set_state_data] = useState(props.state_data);
  const [address_type_data, set_address_type_data] = useState(props.address_type_data);
  const [subsidiary_data, set_subsidiary_data] = useState(props.subsidiary_data);
  const [tax_regime_data, set_tax_regime_data] = useState(props.tax_regime_data);
  const [forcecode, setforcecode] = useState(false);
  const [codeforce, setcodeforce] = useState(props.code);
  const [outer_code, setouter_code] = useState(props.outer_code);
  const [account_number, setaccount_number] = useState(props.account_number);
  const [rfc, setrfc] = useState(props.rfc);
  const [emails, setEmails] = useState(props.email);
  const dialog = useDialog();
  const [modalT, setmodalT] = useState(false);
  const notificationSystemRef = useRef();
  const [value, setValue] = useState(0);
  const [clients, setclients] = useState("");
  const [checkedGeneral, setcheckedGeneral] = useState(props?.supplierdata === false ? true : false);
  const [checkedGeneral2, setcheckedGeneral2] = useState(props?.method === "UPDATE" ? true : false);
  const [validate_bp, setvalidate_bp] = useState("none");
  const [validate_phone, setvalidate_phone] = useState("none");
  const [validate_email, setvalidate_email] = useState("none");
  const [validate_street, setvalidate_street] = useState("none");
  const [validate_neighborhood, setvalidate_neighborhood] = useState("none");
  const [validate_zip_code, setvalidate_zip_code] = useState("none");
  const [validate_city, setvalidate_city] = useState("none");
  const [errorRFC, seterrorRFC] = useState("none");
  const [adress_from, setadress_from] = useState("none");
  const [system_commentary, setsystem_commentary] = useState(false)
  const [infoext, setinfoext] = React.useState("none");
  const [id_accounting_account_fk, setid_accounting_account_fk] = useState(props?.id_accounting_account_fk);
  const [pro_servData, setpro_servData] = useState([]);
  const [account, setaccount] = useState(props.account_data);
  const [variableextranjero, setvariableextranjero] = useState(null);
  const [validate_type_client, setvalidate_type_client] = useState(false);
  function validarEmails(emails) {
    const regex = /^\s*([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(,\s*[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})*\s*$/;
    return regex.test(emails);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;

    setValues({ ...values, [name]: value.toUpperCase() });
  };

  const handleRFC = (event) => { setrfc(event.target.value.toUpperCase()); };
  var regex = new RegExp('^[A-ZÑ&]{3,4}[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[A-Z\\d]{3}$');
  const [regErr, setregErr] = useState(false);


  const onBlurRFC = () => {
    if (!regex.test(rfc)) {


      seterrorRFC("")
    } else {
      seterrorRFC("none")
    }
  };


  const handleEmail = (event) => {
    const value = event.target.value;
    setEmails(value);

    var eml_dta = validarEmails(event.target.value)
    if (eml_dta) {
      setEmails(value);
      setvalidate_email("none")
    } else {
      setvalidate_email("");
    }
  };

  const handle_code_bp = async () => {
    try {
      var rf = await refresh_token();
      if (rf) {
        try {
          setTimeout(async () => {
            var dt = await get_code_businesspartners();
            setcodeforce(dt.data.code);
          }, 1000);
        } catch (err) { }
      }
    } catch (err) {
    }

  };

  const handleOnSearch = (string, results) => { };

  const handleOnHover = (result) => { };
  const handleOnSelect = (item) => {
    setid_accounting_account_fk(item?.id);
    setaccount(item?.name + "-" + item?.code)
  };


  const handle_accounting_account = async () => {
    var dt = await get_accounting_accounts(0);
    setpro_servData(dt.data);
  }

  const handleOnFocus = () => {
    console.log("Focused");
  };

  const handleOnClear = () => {
    console.log("Cleared");
  };
  const handleChangee1 = (event) => {
    set_type_bp(event.target.value.toUpperCase());
  };



  async function handleSubmit(event) {
    event.preventDefault();
    validate_address_fields();
    // setmodalT(true);
    var data = {
      business_partner: {
        business_partner: values.business_partner,
        rfc: rfc,
        tax_regime: tax_regime_data,
        business_partner_type: type_bp,
        partner_type: partner_type_data,
        person_type: rfc?.length === 13 ? "FÍSICA" : "MORAL",
        cfdi_use: uso_cfdi_data,
        tax_residence: values.tax_residence === "" ? null : values.tax_residence,
        num_reg_id_trib: values.num_reg_id_trib === "" ? null : values.num_reg_id_trib,
        metodo_pago: metodo_pago_data,
        paymethod: paymethod_data,
        capital_regime: values.capital_regime === "" ? null : values.capital_regime,
        code: codeforce,
        force_code: forcecode,
        name: values.name === "" ? null : values.name,
        first_last_name: values.first_last_name === "" ? null : values.first_last_name,
        second_last_name: values.first_last_name === "" ? null : values.second_last_name,
        email: emails === "" ? null : emails,
        phone: values.phone === "" ? null : values.phone,
        balance: 0,
        credit_days: 0,
        account_number: account_number === "" ? null : account_number,
        outer_code: outer_code === "" ? null : outer_code,
        enable: true,
        id_accounting_account_fk: id_accounting_account_fk === "" ? null : id_accounting_account_fk
      },

      address: {
        zip_code: values.zip_code === "" ? null : values.zip_code,
        state: state_data,
        address_type: address_type_data,
        country: values.country === "" ? null : values.country,
        neighborhood: values.neighborhood === "" ? null : values.neighborhood,
        city: values.city === "" ? null : values.city,
        town: values.town === "" ? null : values.town,
        street: values.street === "" ? null : values.street,
        outdoor_number: values.outdoor_number === "" ? null : values.outdoor_number,
        inside_number: values.inside_number === "" ? null : values.inside_number,
        reference: values.reference === "" ? null : values.reference,
        subsidiary: subsidiary_data,
      },
    };


    console.log(data);

    try {
      if (props.method === "UPDATE") {
        var dt = await update_bp(data, props.id);
        setmodalT(false);
        setTimeout(() => {
          if (props.new_version === true) {
            dialog.close({ reconsult: true, data_bp: dt });
          } else {
            dialog.close(true);

          }
        }, 100);
      } else {
        await create_bp(data);
        setmodalT(false);
        setTimeout(() => {
          dialog.close(true);
        }, 100);
        if (props.reconsultar === true) {
          alert("socio creado correctamente");
          window.location = "/updateBP";
        }
      }
    } catch (err) {
      notificationSystemRef.current.addNotification({
        message: (
          <AlertResponse
            msj={JSON.stringify(err?.response?.data)}
            view={false}
          ></AlertResponse>
        ),
        level: "error",
        position: "br",
        autoDismiss: 60,
      });
      setmodalT(false);
    }

  }
  const handlechecked = (event) => {
    setcheckedGeneral(event.target.checked);

  }
  const handleCodeForce = (event) => {
    if (forcecode === true) {
      setcodeforce(event.target.value);
    }
  };

  const handelClient = () => {
    setclients("");
    setadress_from("none");
    setinfoext("none");
  };


  function validate_address_fields() {
    var err_msj = "";
    if (props.modul !== "PROVEEDOR" && values.street === "") {
      setvalidate_street("")
      err_msj += "El campo Calle obligatorio. ";
    }
    if (props.modul !== "PROVEEDOR" && values.neighborhood === "") {
      setvalidate_neighborhood("")
      err_msj += "El campo Colonia es obligatorio. ";
    }
    if (props.modul !== "PROVEEDOR" && values.city === "") {
      setvalidate_city("")
      err_msj += "El campo Municipio es obligatorio. ";
    }
    if (props.modul !== "PROVEEDOR" && values.zip_code === "") {
      setvalidate_zip_code("")
      setvalidate_email("");
      err_msj += "El campo Código Postal es obligatorio. ";
    }

    if (props.modul === "PROVEEDOR" && values.zip_code === "") {
      setvalidate_zip_code("");
      err_msj += "El campo Código Postal es obligatorio. ";
    }

    if (err_msj !== "") {
      notificationSystemRef.current.addNotification({
        message: (
          <AlertResponse
            msj={"Error: " + err_msj + "Favor de verificar los datos y volver a intentar."}
            view={false}
          ></AlertResponse>
        ),
        level: "error",
        position: "br",
        autoDismiss: 30,
      });
      return false;
    }
    setvalidate_street("none");
    setclients("none");
    setvalidate_city("none");
    setvalidate_zip_code("none");

    return true;
  }

  function validate_tax_fields() {
    var err_msj = "";
    if (values?.business_partner === "") {
      setvalidate_bp("")
      err_msj += "El campo Socio de Negocios es obligatorio. ";
    }
    if (uso_cfdi_data === "") {
      err_msj += "El campo Uso de CFDI es obligatorio. ";
    }
    if (paymethod_data === "") {
      err_msj += "El campo Método de pago es obligatorio. ";
    }
    if (!validarEmails(emails)) {
      setvalidate_email("");
      err_msj += "Los correos electrónicos no contienen el formato correcto. ";
    }

    if (!regex.test(rfc)) {
      onBlurRFC();
      err_msj += "El RFC proporcionado no está bien estructurado. ";
    }

    if (err_msj !== "") {
      notificationSystemRef.current.addNotification({
        message: (
          <AlertResponse
            msj={"Error: " + err_msj + "Favor de verificar los datos y volver a intentar."}
            view={false}
          ></AlertResponse>
        ),
        level: "error",
        position: "br",
        autoDismiss: 30,
      });
      return false;
    }
    setvalidate_bp("none");
    setvalidate_email("none");
    setclients("none");
    setadress_from("");
    setinfoext("none");

    return true;
  }


  const handeladress_from = () => {
    validate_tax_fields();

    if (props.method === "UPDATE") {
      if (partner_type_data === "PROPIO" || partner_type_data === "UNILEVER" || partner_type_data === "EXTERNO") {
        setvalidate_type_client(false);
      } else {
        setvalidate_type_client(true);
        return null;
      }
    }



  };



  const handle_get_adrres = async (id) => {
    try {
      var rf = await refresh_token();
      if (rf) {
        try {
          setTimeout(async () => {
            var dt = await get_address_businesspartners(id);
            set_state_data(dt.data.state);
            setValues((prevValues) => ({
              ...prevValues,
              street: dt.data.street === null ? "" : dt.data.street,
              zip_code: dt.data.zip_code === null ? "" : dt.data.zip_code,
              address_type: dt.data.address_type === null ? "" : dt.data.address_type,
              country: dt.data.country === null ? "" : dt.data.country,
              city: dt.data.city === null ? "" : dt.data.city,
              town: dt.data.town === null ? "" : dt.data.town,
              outdoor_number: dt.data.outdoor_number === null ? "" : dt.data.outdoor_number,
              inside_number: dt.data.inside_number === null ? "" : dt.data.inside_number,
              reference: dt.data.reference === null ? "" : dt.data.reference,
              subsidiary: dt.data.subsidiary === null ? "" : dt.data.subsidiary,
              neighborhood: dt.data.neighborhood === null ? "" : dt.data.neighborhood,

            }));
          }, 1000);
        } catch (err) { }
      }
    } catch (err) {
    }
  }

  const handle_get_bp_data = async (id) => {
    try {
      var rf = await refresh_token();
      if (rf) {
        try {
          setTimeout(async () => {
            var dt = await get_data_businesspartners(id);
            set_type_bp(dt.data.business_partner_type === undefined ? "" : dt.data.business_partner_type);
            set_partner_type_data(dt.data.partner_type === undefined ? "" : dt.data.partner_type);
            setaccount_number(dt.data.account_number === undefined ? "" : dt.data.account_number);
            setouter_code(dt.data.outer_code === undefined ? "" : dt.data.outer_code);
            setsystem_commentary(dt.data?.system_commentary === undefined ? "" : dt.data.system_commentary)
            setValues((prevValues) => ({
              ...prevValues,
              phone: dt.data.phone === undefined ? "" : dt.data.phone,
              balance: dt.data.balance === undefined ? "" : dt.data.balance,
              credit_days: dt.data.credit_days === undefined ? "" : dt.data.credit_days
            }));

          }, 1000);
        } catch (err) { }
      }
    } catch (err) {
    }
  }


  const infExt = () => {
    setclients("none");
    setadress_from("none");
    setinfoext("");
  };

  useEffect(() => {
    if (props.method !== "UPDATE") {
      handle_code_bp();
    } else {
      handle_get_adrres(props.id);
      handle_get_bp_data(props.id);
    }
  }, []);


  useEffect(() => {
    handle_accounting_account();
  }, []);


  return (
    <div >
      <NotificationSystem ref={notificationSystemRef} />

      <MODAL_TABLE
        open={true}
        message={""}
        modalGeneral={false}
        modalT={modalT}
      ></MODAL_TABLE>
      <Box sx={{ margin: "0 auto", width: "100%" }}>
        <BottomNavigation
          showlabel
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
          <BottomNavigationAction
            className="btonwith"
            showlabel
            label="Datos generales"
            icon={<GroupSharpIcon onClick={handelClient} />}
            onClick={handelClient}
          />
          <BottomNavigationAction
            showlabel
            sx={{ width: "50%" }}
            label="Dirección"
            icon={<LocationOnIcon onClick={handeladress_from} />}
            onClick={handeladress_from}
          />
          <BottomNavigationAction
            style={{ display: props.method === "UPDATE" ? "" : "none" }}
            sx={{ width: "50%" }}
            label="Información Extra"
            icon={<AddCardIcon onClick={infExt} />}
            onClick={infExt}
          />
          <div className="switchRegister">
            <FormControlLabel
              control={<MaterialUISwitch sx={{ m: 1 }} onChange={handlechecked} checked={checkedGeneral} />}
              label={checkedGeneral === true ? "Registro solo para facturar" : "Registro general"}
            />
          </div>
          <div className="switchRegister">

          </div>
        </BottomNavigation>

      </Box>
      <div style={{ display: clients }}>
        <div className="containerBussnessp">
          <div className="bp-1">
            <ValidationTextField
              label="Código"
              required
              placeholder="Código"
              sx={{ m: 2, width: "100%" }}
              variant="outlined"

              id="validation-outlined-input"
              name="code"
              value={codeforce}
              onChange={handleCodeForce}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div className="bp-7">
            <FormControlLabel
              value="bottom"
              control={
                <Checkbox checked={forcecode} onChange={(e) => setforcecode(e.target.checked)} />
              }
              label="Forzar Código"
              labelPlacement="bottom"
            />
          </div>
          <div className="bp-2">
            <ValidationTextField
              label="Socio de Negocios "
              required
              placeholder="Socio de Negocios"
              sx={{ m: 2, width: "100%" }}
              variant="outlined"

              id="validation-outlined-input"
              name="business_partner"
              value={values.business_partner}
              onChange={handleChange}
              onBlur={(e) => e.target.value === "" ? setvalidate_bp("") : setvalidate_bp("none")}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <p
              style={{
                display: validate_bp,
                color: "red",
                textAlign: "justify",
                margin: "0 auto",
                width: "90%",
              }}
            >Campo obligatorio</p>

          </div>
          <div className="bp-3">
            <ValidationTextField
              label="RFC"
              inputProps={{ maxLength: 13, minLength: 12 }}
              required
              placeholder="RFC"
              sx={{ m: 2, width: "100%" }}
              variant="outlined"
              id="getRFC"
              name="rfc"
              value={rfc}
              onChange={handleRFC}
              onBlur={onBlurRFC}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <p
              style={{
                display: errorRFC,
                color: "red",
                textAlign: "justify",
                margin: "0 auto",
                width: "90%",
              }}
            >El RFC ingresado no es valido</p>
          </div>

          <div className="bp-4">
            <TextField
              id="regimenfiscal"
              select
              label="Régimen Fiscal"
              value={tax_regime_data}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(e) =>
                set_tax_regime_data(e.target.value.toUpperCase())
              }
              size="small"
              name="regimenfiscal"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
            >
              {regimen.filter((option) => option.value !== "").map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
          </div>

          <div className="bp-5">
            <ValidationTextField
              label="Régimen Capital"
              placeholder="Régimen Capital"
              variant="outlined"
              sx={{ m: 2, width: "100%" }}
              id="validation-outlined-input"
              name="capital_regime"
              value={values.capital_regime}
              onChange={handleChange}
              disabled={regErr}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>

          <div className="bp-6">
            {
              extra === true ?
                <TextField
                  id="validation-outlined-input"
                  select
                  value={variableextranjero === null ? rfc?.length === 13 ? "FÍSICA" : "MORAL" : variableextranjero}
                  label="Tipo de persona"
                  sx={{ m: 1.5, width: "100%" }}
                  onChange={(e) => setvariableextranjero(e.target.value)}
                  size="small"
                  name="businessPartnerType"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  SelectProps={{
                    native: true,
                  }}
                >
                  <option key={1} value={"MORAL"}>
                    {"MORAL"}
                  </option>
                  <option key={2} value={"FÍSICA"}>
                    {"FÍSICA"}
                  </option>

                </TextField>
                :
                <ValidationTextField
                  label="Tipo de persona"
                  placeholder="Tipo de persona"
                  variant="outlined"
                  sx={{ m: 2, width: "100%" }}

                  id="validation-outlined-input"
                  name="capital_regime"
                  value={rfc?.length === 13 ? "FÍSICA" : "MORAL"}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
            }

          </div>

          <div
            className="bp-26"
            style={{ display: props.supplierdata === true ? "" : "none" }}
          >
            <ValidationTextField
              label="Código Externo"
              placeholder="Código Externo"
              variant="outlined"
              sx={{ m: 2, width: "100%" }}
              id="validation-outlined-input"
              name="outdoor_number"
              value={outer_code}
              onChange={(e) => setouter_code(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>

          <div
            className="bp-27"
            style={{ display: props.supplierdata === true ? "" : "none", width: "95%", margin: "0 auto" }}

          >
            <ReactSearchAutocomplete
              items={pro_servData}
              fuseOptions={{ keys: ["id", "name_code"] }}
              resultStringKeyName={"name_code"}
              onSearch={handleOnSearch}
              onHover={handleOnHover}
              onSelect={handleOnSelect}
              onFocus={handleOnFocus}
              onClear={handleOnClear}
              showIcon={true}
              maxResults={30}
              placeholder="Buscar cuenta contable..."
              styling={{
                height: "34px",
                border: "1px solid darkgreen",
                borderRadius: "4px",
                backgroundColor: "white",
                boxShadow: "none",
                hoverBackgroundColor: "lightgreen",
                color: "black",
                iconColor: "black",
                lineColor: "black",
                placeholderColor: "black",
                clearIconMargin: "3px 8px 0 0",
                zIndex: 10,
              }} />
          </div>
          <div className="bp-28"
            style={{ display: props.supplierdata === true ? "" : "none", width: "100%", margin: "0 auto" }}>
            <ValidationTextField
              label="Cuenta"
              variant="outlined"
              placeholder="Cuenta"
              id="validation-outlined-input"
              sx={{ m: 2, width: "100%" }}
              value={account}
              InputLabelProps={{ shrink: true }}
              name="cuenta"


            />
          </div>



          <div
            className="bp-8"
            style={{ display: rfc?.length === 13 ? "" : "none" }}
          >
            <ValidationTextField
              label="Nombre del Representante Legal"
              variant="outlined"
              placeholder="Nombre del Representante Legal"
              sx={{ m: 2, width: "100%" }}

              id="validation-outlined-input"
              name="name"
              value={values.name}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleChange}
            />
          </div>
          <div
            className="bp-9"
            style={{ display: rfc?.length === 13 ? "" : "none" }}
          >
            <ValidationTextField
              label="Apellido Paterno del Representante Legal "
              placeholder="Apellido Paterno del Representante Legal"
              variant="outlined"

              id="validation-outlined-input"
              sx={{ m: 2, width: "100%" }}
              name="first_last_name"
              InputLabelProps={{
                shrink: true,
              }}
              value={values.first_last_name}
              onChange={handleChange}
            />
          </div>
          <div
            className="bp-10"
            style={{ display: rfc?.length === 13 ? "" : "none" }}
          >
            <ValidationTextField
              label="Apellido Materno del Representante Legal"
              placeholder="Apellido Materno del Representante Legal"
              variant="outlined"

              id="validation-outlined-input"
              sx={{ m: 2, width: "100%" }}
              name="second_last_name"
              InputLabelProps={{
                shrink: true,
              }}
              value={values.second_last_name}
              onChange={handleChange}
            />
          </div>

          <div className="bp-11">
            <ValidationTextField
              label="Correo Electrónico"
              placeholder="Correo Electrónico"
              variant="outlined"
              required
              id="validation-outlined-input"
              sx={{ m: 2, width: "100%" }}
              name="email"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleEmail}
              onBlur={handleEmail}
              value={emails}
            />
            <p
              style={{
                display: validate_email,
                color: "red",
                textAlign: "justify",
                margin: "0 auto",
                width: "90%",
              }}
            >El formato del correo electrónico debe ser ejemplo@ejemplo.com, en caso de ser mas de un correo electrónico deberan estar separados por una ','</p>
          </div>

          <div
            style={{ display: checkedGeneral === true ? "none" : "" }}
            className="bp-12"
          >
            <ValidationTextField
              label="Teléfono"
              placeholder="Número telefónico"
              variant="outlined"

              id="validation-outlined-input"
              sx={{ m: 2, width: "100%" }}
              name="phone"
              InputLabelProps={{
                shrink: true,
              }}
              value={values.phone}
              onChange={handleChange}
              onBlur={(e) => e.target.value === "" ? setvalidate_phone("") : setvalidate_phone("none")}
            />
            <p
              style={{
                display: validate_phone,
                color: "red",
                textAlign: "justify",
                margin: "0 auto",
                width: "90%",
              }}
            >Campo obligatorio</p>


          </div>
          <div
            style={{ display: checkedGeneral === true ? "none" : "" }}
            className="bp-13"
          >
            <ValidationTextField
              label="Linea de crédito"
              required
              placeholder="Linea de crédito"
              variant="outlined"

              id="validation-outlined-input"
              sx={{ m: 2, width: "100%" }}
              name="balance"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              value={values.balance}
              onChange={handleChange}
            />
          </div>
          <div
            style={{ display: checkedGeneral === true ? "none" : "" }}
            className="bp-14"
          >
            <ValidationTextField
              label="Días de crédito"
              required
              placeholder="Días de crédito"
              variant="outlined"

              id="validation-outlined-input"
              sx={{ m: 2, width: "100%" }}
              name="credit_days"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              value={values.credit_days}
              onChange={handleChange}
            />
          </div>
          <div
            style={{ display: checkedGeneral === true ? "none" : "" }}
            className="bp-15"
          >
            <TextField
              id="businessPartnerType"
              select
              label="Tipo de Socio de Negocio"
              value={type_bp}
              sx={{ m: 1.5, width: "100%" }}
              onChange={handleChangee1}
              size="small"
              name="businessPartnerType"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
            >
              {(type_bp_json).map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
          </div>
          <div
            style={{
              display: checkedGeneral === true ? "none" : "",
            }}
            className="bp-16"
          >
            <TextField
              id="partnerType"
              select
              label="Tipo de cliente"
              value={partner_type_data}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(e) => set_partner_type_data(e.target.value)}
              size="small"
              name="partnerType"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
            >
              {partner_typee.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
            {
              validate_type_client === true ?
                <p
                  style={{
                    color: "red",
                    textAlign: "justify",
                    margin: "0 auto",
                    width: "90%",
                  }}
                >Campo obligatorio</p> : <></>
            }

          </div>
          <div className={checkedGeneral === true ? "bp-12" : "bp-17"}>
            <TextField
              id="CFDIUse"
              select
              label="Uso de CFDI"
              value={uso_cfdi_data}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(e) => set_uso_cfdi_data(e.target.value)}
              size="small"
              name="CFDIUse"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
            >
              {usocfdi.filter((option) => option.value !== "").map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
          </div>
          <div className={checkedGeneral === true ? "bp-13" : "bp-18"}>
            <TextField
              id="payMethod"
              select
              label="Método de pago"
              value={paymethod_data}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(e) => set_paymethod_data(e.target.value.toUpperCase())}
              size="small"
              name="payMethod"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
            >
              {metodopago.filter((option) => option.value !== "").map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
          </div>
          <div className={checkedGeneral === true ? "bp-14" : "bp-19"}>
            <TextField
              id="metodoPago"
              select
              label="Pago"
              value={metodo_pago_data}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(e) =>
                set_metodo_pago_data(e.target.value)
              }
              size="small"
              name="metodoPago"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
            >
              {code_method_payment.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
          </div>
          <div
            style={{ display: checkedGeneral === true ? "none" : "" }}
            className="bp-20"
          >
            <ValidationTextField
              label="Número de cuenta"
              placeholder="Ultimos 4 digitos de la cuenta"
              variant="outlined"
              id="validation-outlined-input"
              sx={{ m: 2, width: "100%" }}
              name="account_number"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                maxLength: 4,
              }}
              value={account_number}
              onChange={(e) => setaccount_number(e.target.value)}
              onBlur={(e) => setaccount_number(e.target.value.length < 4 ? "" : e.target.value)}
            />
          </div>

          <div
            style={{ display: props?.supplierdata === true ? "none" : "" }}
            className="bp-25"
          >
            <h4>DATOS SOLO SI ES UN CLIENTE EXTRANJERO</h4>
          </div>

          <div
            style={{ display: props?.supplierdata === true ? "none" : "" }}
            className="bp-23"
          >
            <ValidationTextField
              label="Residencia Fiscal (País)"
              placeholder="Residencia Fiscal (País)"
              variant="outlined"

              id="validation-outlined-input"
              name="tax_residence"
              sx={{ m: 2, width: "100%" }}
              InputLabelProps={{
                shrink: true,
              }}
              value={values.tax_residence}
              disabled={rfc === "XEXX010101000" ? false : true}
              onChange={(e) => {
                const { name, value } = e.target;
                const truncatedValue = value.slice(0, 3);
                setValues({ ...values, [name]: truncatedValue.toUpperCase() });
              }}
              onBlur={(e) => {
                const { name, value } = e.target;
                if (value.length < 3) {
                  // Borra el dato si es menor a 3 caracteres
                  setValues({ ...values, [name]: "" });
                }
              }}
            />

          </div>

          <div
            style={{ display: props?.supplierdata === true ? "none" : "" }}
            className="bp-24"
          >
            <ValidationTextField
              label="Número de Registro de Identificación Fiscal"
              placeholder="Número de Registro de Identificación Fiscal"
              variant="outlined"
              name="num_reg_id_trib"
              id="validation-outlined-input"
              sx={{ m: 2, width: "100%" }}
              disabled={rfc === "XEXX010101000" ? false : true}
              InputLabelProps={{
                shrink: true,
              }}
              value={values.num_reg_id_trib}
              onChange={(e) => {
                const { name, value } = e.target;
                const truncatedValue = value.slice(0, 20);
                setValues({ ...values, [name]: truncatedValue.toUpperCase() });
              }} />
          </div>

          <div className="bp-21">
            <Button
              onClick={handeladress_from}
              sx={{ m: 2, width: "100%" }}
              variant="contained"
              style={{ background: "green" }}
            >
              SIGUIENTE
            </Button>
          </div>

          <div className="bp-22">
            <Button
              onClick={() => {
                dialog.close();
              }}
              sx={{ m: 2, width: "100%" }}
              variant="contained"
              style={{ background: "red" }}
            >
              CANCELAR
            </Button>
          </div>
        </div>
      </div>

      <div style={{ display: adress_from }}>
        <div className="contenerFormSetBusnesAdress">
          <div className="dr-1">
            <ValidationTextField
              label="Calle"
              placeholder="Calle"
              variant="outlined"

              id="validation-outlined-input"
              sx={{ m: 2, width: "100%" }}
              name="street"
              InputLabelProps={{
                shrink: true,
              }}
              value={values.street}
              onChange={handleChange}
              onBlur={(e) => e.target.value === "" ? setvalidate_street("") : setvalidate_street("none")}
            />
            <p
              style={{
                display: validate_street,
                color: "red",
                textAlign: "justify",
                margin: "0 auto",
                width: "90%",
              }}
            >Campo obligatorio</p>
          </div>
          <div className="dr-2">
            <ValidationTextField
              label="Número extrerior"
              placeholder="Número extrerior"
              variant="outlined"

              id="validation-outlined-input"
              sx={{ m: 2, width: "100%" }}
              name="outdoor_number"
              InputLabelProps={{
                shrink: true,
              }}
              value={values.outdoor_number}
              onChange={handleChange}
            />
          </div>
          <div className="dr-3">
            <ValidationTextField
              label="Número interior"
              placeholder="Número interior"
              variant="outlined"

              id="validation-outlined-input"
              sx={{ m: 2, width: "100%" }}
              name="inside_number"
              InputLabelProps={{
                shrink: true,
              }}
              value={values.inside_number}
              onChange={handleChange}
            />
          </div>

          <div className="dr-4">
            <ValidationTextField
              label="Colonia"
              placeholder="Colonia"
              variant="outlined"

              id="validation-outlined-input"
              sx={{ m: 2, width: "100%" }}
              name="neighborhood"
              InputLabelProps={{
                shrink: true,
              }}
              value={values.neighborhood}
              onChange={handleChange}
              onBlur={(e) => e.target.value === "" ? setvalidate_neighborhood("") : setvalidate_neighborhood("none")}
            />
            <p
              style={{
                display: validate_neighborhood,
                color: "red",
                textAlign: "justify",
                margin: "0 auto",
                width: "90%",
              }}
            >Campo obligatorio</p>
          </div>
          <div className="dr-5">
            <ValidationTextField
              label="Código Postal"
              required
              placeholder="Codigo Postal"
              variant="outlined"
              inputProps={{ maxLength: 5 }}
              id="validation-outlined-input"
              sx={{ m: 2, width: "100%" }}
              name="zip_code"
              InputLabelProps={{
                shrink: true,
              }}
              value={values.zip_code}
              onChange={handleChange}
              onBlur={(e) => e.target.value === "" ? setvalidate_zip_code("") : setvalidate_zip_code("none")}
            />
            <p
              style={{
                display: validate_zip_code,
                color: "red",
                textAlign: "justify",
                margin: "0 auto",
                width: "90%",
              }}
            >Campo obligatorio</p>
          </div>
          <div className="dr-6">
            <ValidationTextField
              label="Municipio"
              placeholder="Municipio"
              variant="outlined"

              id="validation-outlined-input"
              sx={{ m: 2, width: "100%" }}
              name="city"
              InputLabelProps={{
                shrink: true,
              }}
              value={values.city}
              onChange={handleChange}
              onBlur={(e) => e.target.value === "" ? setvalidate_city("") : setvalidate_city("none")}
            />
            <p
              style={{
                display: validate_city,
                color: "red",
                textAlign: "justify",
                margin: "0 auto",
                width: "90%",
              }}
            >Campo obligatorio</p>
          </div>
          <div className="dr-7">
            <TextField
              id="state"
              select
              label="Estado"
              value={state_data}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(e) => set_state_data(e.target.value)}
              size="small"
              name="state"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
            >
              {estados.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
          </div>
          <div className="dr-8">
            <ValidationTextField
              label="Localidad"
              placeholder="Localidad"
              variant="outlined"

              id="validation-outlined-input"
              sx={{ m: 2, width: "100%" }}
              name="town"
              InputLabelProps={{
                shrink: true,
              }}
              value={values.town}
              onChange={handleChange}
            />
          </div>
          <div className="dr-9">
            <ValidationTextField
              label="País"
              placeholder="País"
              variant="outlined"

              id="validation-outlined-input"
              sx={{ m: 2, width: "100%" }}
              name="country"
              InputLabelProps={{
                shrink: true,
              }}
              value={values.country}
              onChange={handleChange}
            />
          </div>

          <div className="dr-10">
            <ValidationTextField
              label="Referencia"
              placeholder="Referencia"
              variant="outlined"

              id="validation-outlined-input"
              sx={{ m: 2, width: "100%" }}
              name="reference"
              InputLabelProps={{
                shrink: true,
              }}
              value={values.reference}
              onChange={handleChange}
            />
          </div>
          <div className="dr-11">
            <TextField
              id="addressType"
              select
              label="Tipo de Dirección"
              value={address_type_data}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(e) =>
                set_address_type_data(e.target.value.toUpperCase())
              }
              size="small"
              name="addressType"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
            >
              {address_type_json.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
          </div>
          <div className="dr-12">
            <TextField
              id="subsidiary"
              select
              label="Sucursal"
              value={subsidiary_data}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(e) =>
                set_subsidiary_data(e.target.value.toUpperCase())
              }
              size="small"
              name="subsidiary"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
            >
              {subsidiary_json.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
          </div>
          <div className="dr-13">
            <Button
              onClick={handleSubmit}
              sx={{ m: 2, width: "100%" }}
              variant="contained"
              style={{ background: "green" }}
            >
              {props.method === "UPDATE" ? "ACTUALIZAR" : "CREAR"}
            </Button>
          </div>

          <div className="dr-14">
            <Button
              onClick={() => {
                dialog.close();
              }}
              sx={{ m: 2, width: "100%" }}
              variant="contained"
              style={{ background: "red" }}
            >
              CANCELAR
            </Button>
          </div>
        </div>

      </div>

      <div style={{ display: infoext }}>
        <DataExt bp={props.business_partner} rfc={props.rfc} id={props.id} method={props.method} system_commentary={system_commentary}>
        </DataExt>
      </div>

    </div>
  );
};

export default Set_bussines_p;
