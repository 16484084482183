import React from "react";
import { CustomInput } from "reactstrap";
import "./estilo.css";
import Select from 'react-select';

export const Filter = ({ column }) => {
  return (
    <div style={{ marginTop: 5 }}>
      {column.canFilter && column.render("Filter")}
    </div>
  );
};

export const DefaultAAA = ({
  column: {
    filterValue,
    setFilter,
    preFilteredRows: { length },
  },
}) => {
  return <p></p>;
};

export const DefaultColumnFilter = ({
  column: {
    filterValue,
    setFilter,
    preFilteredRows: { length },
  },
}) => {
  return (
    <input
      value={filterValue || ""}
      id="custom-filter"
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    />
  );
};

export const ColumnFilter = ({
  column: {
    filterValue,
    setFilter,
    preFilteredRows: { length },
  },
}) => {
  return (
    <input
      value={filterValue || ""}
      id="custom-filter2"
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    />
  );
};
export const ColumnFilter2 = ({
  column: {
    filterValue,
    setFilter,
    preFilteredRows: { length },
  },
}) => {
  return (
    <input
      value={filterValue || ""}
      id="custom-filter3"
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    />
  );
};

export const MultipleProperty = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}) => {
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()].sort((a, b) => a - b);
  }, [id, preFilteredRows]);

  return (
    <CustomInput
      id="custom-select"
      type="select"
      multiple
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">Todos</option>
      {options.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </CustomInput>
  );
};

export const SelectColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}) => {
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()].sort((a, b) => a - b);
  }, [id, preFilteredRows]);

  return (
    <CustomInput
      id="custom-select"
      type="select"
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">Todos</option>
      {options.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </CustomInput>
  );
};

export function MultiSelectFilterFn(rows, id, filterValues) {
  if (filterValues.length === 0) return rows;

  return rows.filter((r) => filterValues.includes(r.values[id]));
}

export const SelectColumnFilter2 = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}) => {
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  return (
    <CustomInput
      id="custom-select2"
      type="select"
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">Todos</option>
      {options.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </CustomInput>
  );
};


export function MultiSelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id,filteredRows },
}) {
  console.log(filterValue)
  console.log(setFilter)
  console.log(preFilteredRows)
  console.log(id)

  // Crear una lista de opciones para el filtro select

  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach(row => {
      options.add(row.original[id]);
    });
    return [...options.values()].map(option => ({ value: option, label: option }));
  }, [id, preFilteredRows]);

  const options2 = React.useMemo(() => {
    const options2 = new Set();
    filteredRows.forEach(row => {
      options2.add(row.original[id]);
    });
    return [...options2.values()].map(option => ({ value: option, label: option }));
  }, [id, filteredRows]);

  if(filteredRows.length===preFilteredRows.length){
    var dt=true;
  }else{
    var dt=false;
    console.log("jer")
  }


  

  return (
    <Select
      options={options}
      value={filterValue}
      isMulti
      onChange={val => setFilter(val || undefined)}
    />
  );
}
