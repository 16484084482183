import { useEffect, useMemo, useRef, useState } from 'react';
import {
    ListItemIcon,
    MenuItem,
} from '@mui/material';
import { CustomDialog, Confirm } from "react-st-modal";

import PDF from "../../../../../imgComponents/pdf.png";
import save from "../../../../../imgComponents/save.png";
import del from "../../../../../imgComponents/borrar.png";

import { delete_expenses_groups_detail_policy, download_expense } from '../../../../../services/expenses/expenses';
import { base64toBlob } from '../../../../../main_components/donwloadfuncion/download';
import { refresh_token } from '../../../../../main_components/tokens/tokenrefresh';
import UPDATE_DETAIL_POLICY from './update_detail_policy';

const ACTIONS_SPECIALS_V2 = (props) => {

  const handle_delete_detail_policy = async (id) => {
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        props?.setmodalT(true);
        try {
          await delete_expenses_groups_detail_policy(
            sessionStorage.getItem("IdGrupExpense"),
            sessionStorage.getItem("idPolicy"),
            id
          );
          props?.handleSubmit();
          props?.setmodalT(false);
        } catch (err) {
          props?.setmodalT(false);
        }
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };
    


    return (
        <div>
            <MenuItem
                key={"keymenu1"}
                sx={{ m: 0 }}
                onClick={async () => {
                   props.closeMenu();
                   const result = await CustomDialog(
                      <UPDATE_DETAIL_POLICY
                        codIdPolicy={sessionStorage.getItem("idPolicy")}
                        id_detail={props?.row.original.id}
                        codaccounts={props?.row.original.accounting_account}
                        codconcept={props?.row.original.concept}
                        codamount={props?.row.original.ammount}
                        codeffect={props?.row.original.effect}
                        codidConcept={props?.row.original.id_account}
                        codIdDetail={props?.row.original.id}
                        handleSubmit2={props?.handleSubmit}
                      ></UPDATE_DETAIL_POLICY>,
                      {
                        title: "Editar detalle del gasto",
                        className: "modalTItle",
                        showCloseIcon: true,
                        isCanClose: false,
                      }
                    );
                }}>
                <ListItemIcon>
                    <img
                        alt=""
                        src={save}
                        className="mapa_icono"
                        title="Editar Detalle"
                    ></img>
                </ListItemIcon>
              Editar Detalle
            </MenuItem>
            <MenuItem
                key={"keymenu1"}
                sx={{ m: 0 }}
                onClick={async () => {
                   props.closeMenu();
                   const result = await Confirm(
                      "¿Esta usted seguro de eliminar este detalle?",
                        "Eliminar Detalle",
                        "Si",
                        "No"
                      );
                      if (result) {
                        handle_delete_detail_policy(props?.row.original.id);
                      } else {}
                }}>
                <ListItemIcon>
                    <img
                        alt=""
                        src={del}
                        className="mapa_icono"
                        title="Eliminar Detalle"
                    ></img>
                </ListItemIcon>
              Eliminar Detalle
            </MenuItem>

        </div >
    );
};

export default ACTIONS_SPECIALS_V2;
