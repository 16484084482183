//PAQUETERIAS
import React, { useEffect, useState, useMemo,useRef } from "react";
import axios from "axios";
import LoadingScreen from "react-loading-screen";
import { CustomDialog, useDialog, Confirm } from "react-st-modal";
import Button from "@mui/material/Button";
import { StickyTable, Row, Cell } from "react-sticky-table";
import NotificationSystem from "react-notification-system";
//COMPONENTES
import TableContainer from "../../Table_Container_Tax";
import SubTable from "../../expenses/detail_expense/datail";
import Modal_Table from "../../../main_components/modal/modal_Table";
import { date_current } from "../../../main_components/date/date";
import { previous_date } from "../../../main_components/date/date";
import { get_expense,download_expense } from "../../../services/expenses/expenses";
import UPDATE_EXPENSE from "../tools/update_expense";
import GENERATE_EXPENSE_GROUP from "./tools/generate_expense_group";
import { BREADCRUMBS } from "../../../main_components/pagination/breadcrumbs";
import { base64toBlob } from "../../../main_components/donwloadfuncion/download";
//ESTILOS
import "../../expense.css";
//IMAGENES/ICONOS
import SAVE from "../../../imgComponents/save.png";
import PDF from "../../../imgComponents/pdf.png";
import Agrupar from '../../../imgComponents/agrupar.png';



const REPORT_EXPENSE = () => {
  const [ff, setff] = useState(date_current);
  const [fi, setfi] = useState(previous_date);  
  const [sumCantidad, setSumCantidad] = useState(0);
  const [data, setData] = useState([]);
  const [menssage, setMenssage] = useState("");
  const [rfc, setRFC] = useState("");
  const [bp, setBP] = useState("");
  const notificationSystemRef = useRef();
  const [modalT, setmodalT] = useState(false);
  const [modalGeneral, setmodalGeneral] = useState(true);


  const handleSubmit = async() => {
    if(totalG===0){
      try {
          setmodalT(true);
          setMenssage("Consultando gastos...");
          var dt = await get_expense(fi, ff, null);
          setData(dt?.data?.sort((a, b) => a.id < b.id ? 1 : a.id > b.id ? -1 : 0));
          setmodalT(false);
          setmodalGeneral(false);
          setMenssage("");
   
        } catch (err) {
          console.log("error", err);
          setData([]);
          setmodalT(false);
          setMenssage("");
        }
    
    }else{
      alert("Verifica que no tengas ningun gasto seleccionado");
    }
  };

  const handleReconsulta = (reconsultar) => {
    if (reconsultar === true) {
      handleSubmit();
    } else {
      console.log("no se reconsulto");
    }
  };
  useEffect(() => {
    handleSubmit();
  }, []);

  const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef();
      const resolvedRef = ref || defaultRef;

      useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
      }, [resolvedRef, indeterminate]);

      return (
        <>
          <input title="Seleccionar gasto" type="checkbox" ref={resolvedRef} {...rest} />
        </>
      );
    }
  );

  const [difT, setDifT] = useState([]);
  const [totalG, setTotalG] = useState(0);

  const result = difT?.filter(idExpense => idExpense?.id !='elminarEsto');

  console.log(result);


  const handle_download =async(id,file_name)=>{
    setmodalT(true);
    try {
      var dta= await download_expense(id)
      const data = dta.data;
    var blob = base64toBlob(data.file);
    const blobUrl = URL.createObjectURL(blob);
    const downloadLink = document.createElement("a");
    downloadLink.href = blobUrl;
    var arr = file_name.split("/");
    downloadLink.download = arr[4] + ".pdf";
    downloadLink.click();
    setmodalT(false);
    } catch (err) {
      setmodalT(true);
    }
    
  }


  useEffect(() => {
    const handleExpense = () => {
      setDifT(difT);
      setTotalG(totalG);
      setSumCantidad(sumCantidad);
    };
    handleExpense();
  }, []);



  const columns = useMemo(
    () => [
      {
        id: "expander",
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => {
            const resultDif = difT?.filter(idExpense => idExpense!==null);

          return (

            <img
                title="Agrupar Gasto"
                src={Agrupar}
                alt=""
                onClick={async () => {
                      const result = await CustomDialog(
                       <div>
                        <div className="loader"></div>
                        <GENERATE_EXPENSE_GROUP grupExpense={resultDif} ></GENERATE_EXPENSE_GROUP>
          
                        </div> ,
                        {
                          className: "modalTItle",
                          title: "Agrupando gastos...",
                          showCloseIcon: true,
                          isCanClose: false,
                        }
                      );
                      // eslint-disable-next-line no-lone-blocks
                      {
                        handleReconsulta(result);
                      }
                }}
                ></img>
          );
        },
        Cell: ({ row }) => {

          function guardardato() {
            sessionStorage.setItem("id_expense_subtable", row.original.id);
          }

          function Denegado() {
            alert("No tienes el rol necesario para ver los detalles del gasto");
          }

          if (
            sessionStorage.getItem("PERMISOVERDETALLESGASTO") ===
            "ROLE_EXPENSE_ADMINISTRATIVOS"
          ) {
            return (
              <div>
              <a
                title="Consultar impuestos del concepto"
                {...row.getToggleRowExpandedProps()}
              >
                {row?.isExpanded ? (
                  <span title="Consultar detalles del gasto">🔼</span>
                ) : (
                  <span
                    title="Consultar detalles del gasto"
                    onClick={guardardato}
                  >
                    🔽
                  </span>
                )}
              </a>

              <img
                title="Descargar pdf"
                alt=""
                src={PDF}
                onClick={() => handle_download(row.original.id,row.original.file_name)}
                className=""
              ></img>

              <img
                title="Editar Gasto"
                src={SAVE}
                alt=""
                onClick={async () => {
                  if (row.original.modifiable === true) {
                    
                      const result = await CustomDialog(
                        <UPDATE_EXPENSE 
                          codCocept={row.original.concept}
                          codDescripcion={row.original.description}
                          codDate={row.original.date}
                          codImporte={row.original.ammount}
                          codAccount={row.original.account}
                          codIdPayment={row.original.paiement_account}
                          id_expense={row.original.id}
                          codidConcept={row.original.id_account}
                          foreign={row.original.origin==="EXTRANJERO"?1:0}
                          origin={row.original.origin}
                          concept={row.original.concept}
                          ></UPDATE_EXPENSE>,
                        {
                          className: "modalTItle",
                          title: "Editar Gasto",
                          showCloseIcon: true,
                          isCanClose: false,
                        }
                      );
                      // eslint-disable-next-line no-lone-blocks
                      {
                        handleReconsulta(result);
                      }
                   
                  } else {
                    alert("Este gasto ya no se puede modificar.");
                  }
                }}
                className="centerText, taxes"
              ></img>

             
            
              </div>
            );
          } else {
            return (
              <span title="Consultar detalles del gasto" onClick={Denegado}>
                🔽
              </span>
            );
          }
        },
      },

      

      {
        Header: "No.",
        accessor: "spend_folio_number",
      },
      {
        Header: "F. Recepción",
        accessor: "date",
      },
      {
        Header: "F. Documento",
        accessor: "date_document",
      },
      {
        Header: "Concepto",
        accessor: "concept",
      },
      {
        Header: "Descripción",
        accessor: "description",
      },
      {
        Header: "Importe",
        accessor: (d) => {
          return d.ammount
            ? Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.ammount)
            : Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.ammount);
        },
      },
      {
        Header: "Usuario",
        accessor: "user_name",
      },
      {
        Header: "Origen",
        accessor: "origin",
      },
      {
        Header: "Pagado",
        accessor: (d) => {
          return d.modifiable === false ? "✅" : "❌";
        },
      },
      {
        Header: "Cuenta de pago",
        accessor: "payment",
      },
      {
        Header: "F. Vencimiento",
        accessor: "scheduled_payment_date",
      },
      {
        Header: "F. Pago",
        accessor: "payment_date",
      },
      
      { Header: "Proveedor",
        accessor: "business_partner_name"
      },

      {
        id: "selection2",

        Header: ({ getToggleAllRowsSelectedProps }) => (<div></div>),

        Cell: ({ row, value, onChange }) => {

          function idExpense() {
            if (row.isSelected === false) {    
              if(difT[row.index]?.length!=0){
                difT[row.index] =  {  
                  id: row.original.id,
                  importe:row.original.ammount,
                };
            }else{
              setDifT([
                ...difT,
                  {
                    id: row.original.id,
                    importe:row.original.ammount,
                  },
                ]);
            }
         

            } else {
          
              difT[row.index] = {
                id:"elminarEsto"
              };
              setDifT(difT);
            }

          
          }


          useEffect(() => {
            const handleExpense = () => {
              setDifT(difT);
              console.log(difT);
              setTotalG(totalG);
              setSumCantidad(sumCantidad);

            };
            handleExpense();
          }, []);

        useEffect(() => {
          const handleSumar = () => {
            const result = difT?.filter(idExpense => idExpense?.id !=='elminarEsto');

            const sumar = result?.map((saldo) => parseFloat(isNaN(saldo?.importe)===true?0:saldo.importe))
              .reduce((previous, current) => {
                return previous + current;
              }, 0);
            setSumCantidad((sumar.toFixed(2)));
            setTotalG(result?.length);
          };
          handleSumar();
        });


        if(row.original.modifiable===true){

       
          return (
            <div className="campoPagoSelect">
              <IndeterminateCheckbox
                className="NoInput"
                onClick={idExpense}
                onChange={idExpense}
                {...row.getToggleRowSelectedProps()}
              />
            </div>
          );
        }else{
          return(<div></div>)
        }
        },
      },
    ],
    []
  );

  return (
    <div className="">
       <Modal_Table message={menssage} modalGeneral={modalGeneral} modalT={modalT}></Modal_Table>
      <NotificationSystem ref={notificationSystemRef}></NotificationSystem>


      <BREADCRUMBS
        niveles={[
          { label: "REPORTE DE GASTOS", path: null },
          { label: "REPORTE DE GASTOS AGRUPADOS", path: "/expensesGrup" },
        ]}
      ></BREADCRUMBS>

      
      <div className="TableReport">
        <div className="">
        <div class="containerExpenseReport">
        <div class="expen-1 color-1">
          <input
            type="text"
            className="fExpense"
            onFocus={(e) => {
              e.currentTarget.type = "date";
              e.currentTarget.focus();
            }}
            placeholder="Fecha inicial"
            onChange={(event) => setfi(event.target.value)}
          ></input>
        </div>

        <div class="expen-2 color-2">
          <input
            type="text"
            className="fExpense"
            onFocus={(e) => {
              e.currentTarget.type = "date";
              e.currentTarget.focus();
            }}
            placeholder="Fecha Final"
            onChange={(event) => setff(event.target.value)}
          ></input>
        </div>

        <div class="expen-3 color-4">
          <input
            type="text"
            placeholder="Busqueda por RFC"
            className="fExpense"
            onChange={(event) => setRFC(event.target.value)}
          ></input>
        </div>

        <div class="expen-4 color-5">
          <input
            type="text"
            className="fExpense"
            placeholder="Busqueda por socio de negocios"
            onChange={(event) => setBP(event.target.value)}
          ></input>
        </div>

        <div class="expen-5  color-5">
          <Button
            className="botonExpense"
            sx={{ width: "100%" }}
            variant="contained"
            onClick={()=>handleSubmit()}
          >
            Buscar
          </Button>
        </div>
        <div class="expen-8  color-5">
          <h3>Seleccionados: {totalG}</h3>
        </div>
        <div class="expen-7  color-5">
          <h3>Total de Gastos: {sumCantidad}</h3>
        </div>
      </div>
      <StickyTable>
          <TableContainer
            columns={columns}
            exportEx={false}
            data={data}
            style={{ overflowx: 10 }}
            renderRowSubComponent={(row) => {
              return (
                <div style={{ padding: "20px" }}>
                  <SubTable verData={true} row={row} foreign={null} />
                </div>
              );
            }}
            
          />
          </StickyTable>
        </div>
      </div>
    </div>
  );
};

export default REPORT_EXPENSE;
