import { useEffect, useState, useMemo, useRef } from "react";
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import { refresh_token } from "../main_components/tokens/tokenrefresh";
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../main_components/methods_v2/export";
import AlertResponse from "../main_components/alerts/alertResponse";
import RENDER_ROW_ACTION_MENU_ITEMS from "./tools/render_row_action_menuItems";
import "./petitions.css";
import { get_layout, get_layout_download } from "../services/petitions/petitions";
import OPERATION from "./tools/op_operation";
import CREATE from "../imgComponents/create.png";
import { CustomDialog } from "react-st-modal";
import MODAL_TABLE from "../main_components/modal/modal_Table";
import NotificationSystem from 'react-notification-system';
import { BREADCRUMBS } from "../main_components/pagination/breadcrumbs";
import SEARCH_FILTERS from "./tools/shearch_filter";
import dayjs from "dayjs";
import { formattedCurrentDate } from "../main_components/date/day";
import WARNING from "../main_components/alerts/warning";

const customLocalization = {
  ...MRT_Localization_ES,
  toolbar: {
    ...MRT_Localization_ES.toolbar,
    resetOrder: 'Resetear Orden',
  },
};

export const PETITIONS = () => {

  const notificationSystemRef = useRef();
  const [open, setopen] = useState(false);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [rowSelection, setRowSelection] = useState({});
  const [modalT, setmodalT] = useState(false);
  const [menssage, setmenssage] = useState("");
  const [modalGeneral, setmodalGeneral] = useState(false);
  const [total_rows, settotal_rows] = useState("");
  const [orderpg, setorderpg] = useState([]);
  const [fi, setfi] = useState(dayjs(formattedCurrentDate))


  const handleStateChange = (newState) => {
    const filteredRowsCount = newState.filteredRowModel
    console.log(filteredRowsCount)
    settotal_rows(filteredRowsCount);
  };

  useEffect(() => {
    handleSubmit();
  }, [])


  const update_identifiers = [
    { entrySummary: "string" },
    { customs: "string" },
    { date: "string" },
    { exchangeRate: "string" },
    { vat: "string" },
    { entrySummaryKey: "string" },
    { freight: "string" },
    { insurance: "string" },
    { packaging: "string" },
    { others: "string" },
    { dta: "string" },
    { commercialValueLocalCurrencyEntrySummary: "string" },
    { customsValueLocalCurrencyEntrySummary: "string" },
    { remarks: "string" },
    { dischargeByInvoiceDate: "string" },
    { receivedFrom: "string" },
    { internalNote1EntrySummaryHeader: "string" },
    { prevalidation: "string" },
    { invoice: "string" },
    { supplierCode: "string" },
    { invoiceDate: "string" },
    { currencyFactor: "string" },
    { partNumber: "string" },
    { tariffFractionEntrySummary: "string" },
    { rate: "string" },
    { rateType: "string" },
    { unit: "string" },
    { unitPrice: "string" },
    { quantity: "string" },
    { origin: "string" },
    { seller: "string" },
    { longNote: "string" },
    { igiPaymentMethod: "string" },
    { incoterm: "string" },
    { treaty: "string" },
    { ebId: "string" },
    { onConsignment: "string" },
    { note2Detail: "string" },
    { cove: "string" },
    { fines: "string" },
    { surcharges: "string" },
    { internalNote2EntrySummaryHeader: "string" },
    { cnt: "string" },
    { sequence: "string" },
    { conversionFactor: "string" },
    { tariffUnit: "string" },
    { tariffQuantity: "string" },
    { vatPaymentMethodItem: "string" },
    { entryDate: "string" },
    { regime: "string" },
    { operationType: "string" },
    { pcIdentifier: "string" },
    { imIdentifier: "string" },
    { ieps: "string" },
    { iepsPaymentMethod: "string" },
    { vatPrv: "string" },
    { currency: "string" },
    { billingCurrencyValue: "string" },
    { dollarValue: "string" },
    { igiLocalCurrencyEntrySummary: "string" },
    { msIdentifier: "string" },
    { decreasingTransport: "string" },
    { decreasingInsurance: "string" },
    { load: "string" },
    { unload: "string" },
    { otherDecrements: "string" },
    { reference: "string" }
  ];


  const update_null_identifiers = (original_obj, identifiers_to_update) => {
    return original_obj.map((item) => {
      for (const identifier in item) {
        if (item.hasOwnProperty(identifier)) {
          const isToUpdate = identifiers_to_update.some((update) => update[identifier] !== undefined);
          if (isToUpdate && item[identifier] === null) {
            item[identifier] = typeof identifiers_to_update.find((update) => update[identifier]) === 'number' ? 0 : '';
          }
        }
      }
      return item;
    });
  };

  const handleSubmit = async () => {
    setmodalT(true);
    setIsLoading(true);
    setData([]);
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            const d = await get_layout(fi?.format('YYYY/MM'));
            console.log(d)
            const datajson = (d === null ? [] : d.data.response);
            setData(update_null_identifiers(datajson, update_identifiers));
            const dataLength = datajson?.length || 0;
            const baseOptions = [5, 10, 20, 50, 100];
            const filteredOptions = baseOptions.filter(option => option <= dataLength);
            if (dataLength > 0 && !filteredOptions.includes(dataLength)) {
              filteredOptions.push(dataLength);
            }
            const sortedRowsPerPageOptions = filteredOptions.sort((a, b) => a - b);
            setorderpg(sortedRowsPerPageOptions);
           
            setopen(false);
            setmodalGeneral(false);
            setmodalT(false);
            setIsLoading(false);
          } catch (err) {
            console.log("error", err);
            setData([]);
            setopen(false);
            setmodalGeneral(false);
            setmodalT(false);
            setIsLoading(false);
          }
        }, 1000);
      } else { }
    } catch (err) {
      console.log(err);
    }
  };

  const columns = useMemo(
    () => [
      {
        id: "entrySummary",
        header: "Pedimento",
        accessorKey: "entrySummary",
      },
      {
        id: "customs",
        header: "Aduana",
        accessorKey: "customs"
      },
      {
        id: "date",
        header: "Fecha",
        accessorKey: "date"
      },
      {
        id: "exchangeRate",
        accessorKey: "exchangeRate",
        header: "Tipo de Cambio"
      },
      {
        id: "vat",
        accessorKey: "vat",
        header: "IVA"
      },
      {
        id: "entrySummaryKey",
        accessorKey: "entrySummaryKey",
        header: "Clave"
      },
      {
        id: "freight",
        accessorKey: "freight",
        header: "Fletes"
      },
      {
        id: "insurance",
        accessorKey: "insurance",
        header: "Seguros",
      },
      {
        id: "packaging",
        accessorKey: "packaging",
        header: "Embalajes"
      },
      {
        id: "others",
        accessorKey: "others",
        header: "Otros"
      },
      {
        id: "dta",
        accessorKey: "dta",
        header: "DTA"
      },
      {
        id: "commercialValueLocalCurrencyEntrySummary",
        accessorKey: "commercialValueLocalCurrencyEntrySummary",
        header: "Valor Comercial MN Pedimento"
      },
      {
        id: "customsValueLocalCurrencyEntrySummary",
        accessorKey: "customsValueLocalCurrencyEntrySummary",
        header: "Valor Aduana MN Pedimento"
      },
      {
        id: "remarks",
        accessorKey: "remarks",
        header: "Observaciones"
      },
      {
        id: "dischargeByInvoiceDate",
        accessorKey: "dischargeByInvoiceDate",
        header: "Descargo x Fecha Factura"
      },
      {
        id: "receivedFrom",
        accessorKey: "receivedFrom",
        header: "Recibida De"
      },
      {
        id: "internalNote1EntrySummaryHeader",
        accessorKey: "internalNote1EntrySummaryHeader",
        header: "Nota Interna 1 Pedimento  (Encabezado)"
      },
      {
        id: "prevalidation",
        accessorKey: "prevalidation",
        header: "Prevalidación"
      },
      {
        id: "invoice",
        accessorKey: "invoice",
        header: "Factura"
      },
      {
        id: "supplierCode",
        accessorKey: "supplierCode",
        header: "Código Proveedor"
      },
      {
        id: "invoiceDate",
        accessorKey: "invoiceDate",
        header: "Fecha Factura"
      },
      {
        id: "currencyFactor",
        accessorKey: "currencyFactor",
        header: "Factor Moneda"
      },
      {
        id: "partNumber",
        accessorKey: "partNumber",
        header: "Número de Parte"
      },
      {
        id: "tariffFractionEntrySummary",
        accessorKey: "tariffFractionEntrySummary",
        header: "Fracción partida Pedimento"
      },
      {
        id: "rate",
        accessorKey: "rate",
        header: "Tasa"
      },
      {
        id: "rateType",
        accessorKey: "rateType",
        header: "Tipo Tasa"
      },
      {
        id: "unit",
        accessorKey: "unit",
        header: "Unidad"
      },
      {
        id: "unitPrice",
        accessorKey: "unitPrice",
        header: "Precio Unitario"
      },
      {
        id: "quantity",
        accessorKey: "quantity",
        header: "Cantidad"
      },
      {
        id: "origin",
        accessorKey: "origin",
        header: "Origen"
      },
      {
        id: "seller",
        accessorKey: "seller",
        header: "Vendedor"
      },
      {
        id: "longNote",
        accessorKey: "longNote",
        header: "Nota Larga"
      },
      {
        id: "igiPaymentMethod",
        accessorKey: "igiPaymentMethod",
        header: "Forma de Pago IGI"
      },
      {
        id: "incoterm",
        accessorKey: "incoterm",
        header: "Incoterm"
      },
      {
        id: "treaty",
        accessorKey: "treaty",
        header: "Tratado"
      },
      {
        id: "ebId",
        accessorKey: "ebId",
        header: "Id. EB"
      },
      {
        id: "onConsignment",
        accessorKey: "onConsignment",
        header: "En Consignación"
      },
      {
        id: "note2Detail",
        accessorKey: "note2Detail",
        header: "Nota 2 (Det)"
      },
      {
        id: "cove",
        accessorKey: "cove",
        header: "Cove"
      },
      {
        id: "fines",
        accessorKey: "fines",
        header: "Multas"
      },
      {
        id: "surcharges",
        accessorKey: "surcharges",
        header: "Recargos"
      },
      {
        id: "internalNote2EntrySummaryHeader",
        accessorKey: "internalNote2EntrySummaryHeader",
        header: "Nota Interna 2 Pedimento (Encabezado)"
      },
      {
        id: "cnt",
        accessorKey: "cnt",
        header: "CNT"
      },
      {
        id: "sequence",
        accessorKey: "sequence",
        header: "Secuencia"
      },
      {
        id: "conversionFactor",
        accessorKey: "conversionFactor",
        header: "Factor de Conversión"
      },
      {
        id: "tariffUnit",
        accessorKey: "tariffUnit",
        header: "Unidad Tarifa"
      },
      {
        id: "tariffQuantity",
        accessorKey: "tariffQuantity",
        header: "Cantidad Tarifa"
      },
      {
        id: "vatPaymentMethodItem",
        accessorKey: "vatPaymentMethodItem",
        header: "Forma de Pago IVA Partida"
      },
      {
        id: "entryDate",
        accessorKey: "entryDate",
        header: "Fecha Entrada"
      },
      {
        id: "regime",
        accessorKey: "regime",
        header: "Régimen"
      },
      {
        id: "operationType",
        accessorKey: "operationType",
        header: "Tipo de Operación"
      },
      {
        id: "pcIdentifier",
        accessorKey: "pcIdentifier",
        header: "Identificador PC"
      },
      {
        id: "imIdentifier",
        accessorKey: "imIdentifier",
        header: "Identificador IM"
      },
      {
        id: "ieps",
        accessorKey: "ieps",
        header: "IEPS"
      },
      {
        id: "iepsPaymentMethod",
        accessorKey: "iepsPaymentMethod",
        header: "Forma de Pago IEPS"
      },
      {
        id: "vatPrv",
        header: "IVA/PRV",
        accessorKey: "vatPrv"
      },
      {
        id: "currency",
        accessorKey: "currency",
        header: "Moneda"
      },
      {
        id: "billingCurrencyValue",
        accessorKey: "billingCurrencyValue",
        header: "Valor Moneda Facturación"
      },
      {
        id: "dollarValue",
        accessorKey: "dollarValue",
        header: "Valor Dólares Pedimento"
      },
      {
        id: "igiLocalCurrencyEntrySummary",
        accessorKey: "igiLocalCurrencyEntrySummary",
        header: "IGI MN Pedimento"
      },
      {
        id: "msIdentifier",
        accessorKey: "msIdentifier",
        header: "Identificador MS"
      },
      {
        id: "decreasingTransport",
        accessorKey: "decreasingTransport",
        header: "Decrementables Transporte"
      },
      {
        id: "decreasingInsurance",
        accessorKey: "decreasingInsurance",
        header: "Decrementables Seguro"
      },
      {
        id: "load",
        accessorKey: "load",
        header: "Decrementables Carga"
      },
      {
        id: "unload",
        accessorKey: "unload",
        header: "Decrementables Descarga"
      },
      {
        id: "otherDecrements",
        accessorKey: "otherDecrements",
        header: "Decrementables Otros"
      },
      {
        id: "reference",
        accessorKey: "reference",
        header: "Referencia"
      }
    ], [])

  function alertas(msj, status) {
    if (status === "warning") {
      notificationSystemRef.current.addNotification({
        message: <WARNING msj={msj} view={true}></WARNING>,
        level: "success",
        position: "br",
        autoDismiss: 30,
      });
    } else if (status === true) {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={true}></AlertResponse>,
        level: 'success',
        position: 'br',
        autoDismiss: 10,
      });
    } else {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={false}></AlertResponse>,
        level: 'error',
        position: 'br',
        autoDismiss: 60,
      });
    }
  }

  const dowloadlayout = async () => {
    console.log("descargar layout")
    setmodalT(true);
    try {
      const token = await refresh_token();
      if (token === true) {
        setTimeout(async () => {
          try {
            const d = await get_layout_download(fi.format('YYYY-MM'));
            const decodedData = atob(d?.data?.content);
            const byteCharacters = decodedData.split('').map(char => char.charCodeAt(0));
            const byteArray = new Uint8Array(byteCharacters);
            const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const blobUrl = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = blobUrl;
            link.download = `${d?.data?.filename}`;
            link.click();
            alertas("Archivo descargado con éxito", true);
            setmodalT(false);
          } catch (err) {
            console.log(err);
            setmodalT(false);
            alertas("No se pudo descargar el archivo", false);
          }
        }, 1500);
      } else { }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <div >
      <NotificationSystem ref={notificationSystemRef} ></NotificationSystem>
      <MODAL_TABLE
        open={true}
        message={menssage}
        modalGeneral={modalGeneral}
        modalT={modalT}
      ></MODAL_TABLE>

      <BREADCRUMBS niveles={
        [
          { label: "PEDIMENTOS", path: null },
        ]
      }

        cards_dashboard={true}
        total_rows={total_rows !== "" ? total_rows : 0}
        filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
        visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
        total={data?.length}
      ></BREADCRUMBS>

      <div style={{ width: "100%", margin: "0 auto" }} className='new_table_v2 petitions_cont'>

        <MaterialReactTable
          columns={columns}
          data={data}
          enableGrouping
          enablePinning
          enableFacetedValues
          enableStickyHeader
          enableStickyFooter
          enableRowPinning
          enableColumnFilterModes
          enableRowActions
          onStateChange={handleStateChange}
          enableRowSelection
          //   muiTableFooterCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
          getRowId={(row) => row?.id}
          onRowSelectionChange={setRowSelection}
          enableTo
          oltips={false}
          localization={customLocalization}
          state={{ rowSelection, isLoading: isLoading }}
          enableColumnResizing
          enableColumnPinning
          enableColumnOrdering
          muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
          muiSkeletonProps={{ animation: 'pulse', height: 28 }}
          rowVirtualizerInstanceRef
          rowVirtualizerOptions={{ overscan: 5 }}
          columnVirtualizerOptions={{ overscan: 2 }}
          muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
          positionToolbarAlertBanner="bottom"
          paginationDisplayMode='pages'
          rowPinningDisplayMode='sticky'
          layoutMode="grid"
          muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important", color: "black" } }}
          muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
          muiTableFooterCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
          muiPaginationProps={{
            color: 'primary', shape: 'rounded', variant: 'variant',
          }}

          displayColumnDefOptions={{
            'mrt-row-pin': {
              Header: () => (
                <div>
                  <img
                    title="Registrar"
                    src={CREATE}
                    style={{ "cursor": "pointer" }}
                    className=""
                    onClick={async () => {
                      const result = await CustomDialog(
                        <OPERATION
                          operation={"CREATE"}
                          setmodalT={setmodalT}
                          handleSubmit={handleSubmit}
                          alertas={alertas}
                        />,
                        {
                          className: "custom-modal-3",
                          title: "Registrar",
                          showCloseIcon: true,
                          isCanClose: false,
                        }
                      );
                    }}
                  ></img>
                </div>
              ),
              enableHiding: true,
            },
            'mrt-row-actions': {
              enableHiding: true,
            },
            'mrt-row-expand': {
              enableHiding: true,
            },
            'mrt-row-select': {
              enableHiding: true,
            }
          }}
          muiTableBodyRowDragHandleProps={({ table }) => ({
            onDragEnd: () => {
              const { draggingRow, hoveredRow } = table.getState();
              if (hoveredRow && draggingRow) {
                data.splice(
                  hoveredRow.index,
                  0,
                  data.splice(draggingRow.index, 1)[0],
                );
                setData([...data]);
              }
            },
          })}
          muiTableBodyRowProps={
            ({ row, table }) => {
              settotal_rows(table);
              const { density } = table.getState();
              return {
                sx: {
                  height: row.getIsPinned()
                    ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                    }px`
                    : undefined,
                },
              };
            }
          }
          renderTopToolbarCustomActions={({ }) => (
            <SEARCH_FILTERS
              handleSubmit={handleSubmit}
              dowloadlayout={dowloadlayout}
              fi={fi}
              setfi={setfi}

            />)}
          renderRowActionMenuItems={({ row, closeMenu }) => [
            <RENDER_ROW_ACTION_MENU_ITEMS
              closeMenu={closeMenu}
              setmodalT={setmodalT}
              setmenssage={setmenssage}
              setmodalGeneral={setmodalGeneral}
              row={row}
              handleSubmit={handleSubmit}
              alertas={alertas}
              fi={fi.format("YYYY-MM")}
            />
          ]}

          renderToolbarInternalActions={({ table, idenx }) => (
            <RENDER_TOOLBAR_INTERNAL_ACTIONS
              table={table}
              file_name={"Pedimentos"}
              update_identifiers={update_identifiers}
              handle_get_consult={handleSubmit}
              key={idenx}
            />
          )}
        />
      </div>
    </div>
  )
}
