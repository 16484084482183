import React, { useEffect, useState} from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useDialog } from "react-st-modal";
import { refresh_token } from "../../main_components/tokens/tokenrefresh";
import { ReactSearchAutocomplete } from "react-search-autocomplete";


function EditConceptC(props) {

const dialog = useDialog();

const [nameC, setnameC]=useState(props.row.original.concept);
const [cuenta, setcuenta]=useState(props.row.original.account);
const [codecuenta, setcodecuenta]=useState(props.row.original.account);
const [namecuenta, setnamecuenta]=useState(null);
const cuentacopy = props.row.original.account;

const [enable, setenable]=useState(props.row.original.enable);
const [bandera, setbandera]=useState(true);
const [datacuentas, setdatacuentas]=useState([]); 
const URL_BASE="https://7t0suvusg7.execute-api.us-east-1.amazonaws.com";

useEffect(()=>{
  refresh_token();
  handlecuentas();  
},[]); 

const handlecuentas=()=>{
    axios
      .get(
        `${URL_BASE}/od/accounting-accounts`,
        {
          headers: {
            Authorization: sessionStorage.getItem("getIdTokenRefresh"),
            "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
       const data = response.data;
        setdatacuentas(data);
        const itemToSelect = (response.data.find(item => item.code === codecuenta))?.name;
        console.log(itemToSelect);
        setnamecuenta(itemToSelect);        
      })
      .catch((err) => {
      console.log("error", err);
      });
}

//crear un nuevo catalogo 
function updateconcepto(){

console.log(enable);
console.log(nameC);
console.log(cuenta);

if(cuenta==0){
  props.alertas("La cuenta ingresada no es aceptada", false)
}else{

props.reconsultar(true);
setbandera(false);
refresh_token();
setTimeout(() => {
  var data = {
    "enable": enable,//boolean = Habilitado | Deshabilitado
    "concept": nameC,//String = Concepto0
    "account": cuenta//String = Cuenta
}
var url = `${URL_BASE}/od/expense-concepts/${sessionStorage.getItem("id_catalogo")}/expense-concepts-list/${props.row.original.id}`;
var axiosConfig = {
  headers: {
    Authorization: sessionStorage.getItem("getIdTokenRefresh"),
    "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
    "Content-Type": "application/json",
  },
}
axios
.put(url, data,axiosConfig)
.then((response) => {
  console.log(response);
  props.alertas("Concepto actualizado con éxito", true);
  props.reconsultar(false);
  dialog.close();
  props.handleSubmit();
})
.catch((err) => {
  console.log("error post", err);
  props.alertas("No se pudo actualizar el concepto", false)
  props.reconsultar(false);
  setbandera(true);  
});
}, 2000);
}
}

const handleOnSearch = (string, results) => {
  setcuenta(cuentacopy);
};
const handleOnHover = (result) => {};
const handleOnSelect5 = (item) => {
    setcuenta(item.code);
    setcodecuenta(item.code);
    setnamecuenta(item.name);
};
const handleOnFocus5 = () => {
  //console.log("Focused");
};
const handleOnClear = () => {
  //console.log("Cleared");
};

function Spinner() {
  return (
    <div className="spinner-border spinner-border-sm text-light" role="status">
      <span className="sr-only">Cargando...</span>
    </div>
  );
}

const [selectedItem, setSelectedItem] = useState(null);

  return (
    <div>
      <div className="addpointsalecss">
          <div className="gridcatalogogastos">
            <div className="catalogo1">
            <TextField
              label="Nombre del Concepto"
              required
              sx={{ m: 1.5, width: "100%" }}
              value={nameC}
              disabled={!bandera}
              onChange={(event) => setnameC(event.target.value.toUpperCase())}  
              InputLabelProps={{ shrink: true }}
            ></TextField>
            </div>
          </div>
          <div className="gridcatalogogastos2">
          <div className="catalogo2">
          <TextField
              select
              label="Habilitado"
              disabled={!bandera}
              value={enable}
              InputLabelProps={{shrink: true,}}
              SelectProps={{native: true,}}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setenable(!enable)}  
            >
              <option key={1} value={true} selected >
                  {"SI"}
              </option>
              <option key={2} value={false}>
                  {"NO"}
              </option>
            </TextField>
            </div>
            <div className="catalogo3b">
            <ReactSearchAutocomplete
                      items={datacuentas}
                      fuseOptions={{ keys: ["code","name"]}}
                      resultStringKeyName="name_code"
                      onSearch={handleOnSearch}
                      onHover={handleOnHover}
                      onSelect={handleOnSelect5}
                      onFocus={handleOnFocus5}
                      onClear={handleOnClear}
                      
                      styling={{
                        border: "1px solid gray",
                        borderRadius: "4px",
                        color: "black",
                        iconColor: "black",
                        lineColor: "black",
                        placeholderColor: "black",
                        hoverBackgroundColor: "lightgreen",
                        zIndex: 10,
                      }}
                      showIcon={true}
                      placeholder={"Buscar Nueva Cuenta Contable"}    
                    /> 
           </div>
          </div>
          <div className="gridcatalogogastos3">
            <div className="catalogo4">
            <TextField
              label="Código Cuenta Contable Actual"
              sx={{ m: 1.5, width: "100%" }}
              value={codecuenta}
              disabled
              InputLabelProps={{ shrink: true }}
            ></TextField>
            </div>
            <div className="catalogo5">
              <TextField
                label="Nombre Cuenta Contable Actual"
                
                sx={{ m: 1.5, width: "100%" }}
                value={namecuenta}
                disabled
                InputLabelProps={{ shrink: true }}
              ></TextField>
            </div>
          </div>
      
          <br></br>
          <br></br>
          <Box>
            {
              bandera==true?
              
            <Button
            variant="contained"
            style={{"background-color":"green"}}
            sx={{ m: 1.5, width: "45ch" }}
            onClick={updateconcepto}
          >
            Guardar cambios
          </Button>:
          <Button
          variant="contained"
          disabled
          style={{"background-color":"green", "height":"35px"}}
          sx={{ m: 1.5, width: "45ch" }}
        >
          <Spinner></Spinner>
        </Button>
            }
            <Button
              style={{"background-color":"red"}}
              variant="contained"
              sx={{ m: 1.5, width: "20ch" }}
              disabled={!bandera}
              onClick={() => {
                  dialog.close();
                } 
              }
            >
            cancelar
          </Button>
          </Box>
      </div>
    </div>
  );
}

export default EditConceptC;
