import axios from "axios";
import config from "../../config";

const urlspt = `${config?.baseURLSUPPORT}`;

//Consultar Soportes
export const get_factor_importation = async (data) => {
  const url = `https://dkh94m66n1.execute-api.us-east-1.amazonaws.com/prod/factorimportacion`;
  try {
    const response = await axios.get(url, {
      params: {
        filtro: data?.filtro,
        datei: data?.datei,
        datef: data?.datef,
        supplier: data?.supplier,
        whscode: data?.whscode
      },
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};


export const get_semaphore = async (data) => {
  const url = `https://3uyoh9h5zh.execute-api.us-east-1.amazonaws.com/prod/semaphore`;
  try {
    const response = await axios.get(url, {
      params: {
        filtro: data?.filtro,
      },
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};




export const get_semaphore_filter = async () => {
  const url = `https://3uyoh9h5zh.execute-api.us-east-1.amazonaws.com/prod/semaphore`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};



export const send_file_post_data = async (data) => {
  const url = `https://3uyoh9h5zh.execute-api.us-east-1.amazonaws.com/prod/forecast/file`;
  try {
    const response = await axios.post(url, data, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const get_send_file_post_data = async (data) => {
  const url = `https://3uyoh9h5zh.execute-api.us-east-1.amazonaws.com/prod/forecast/file/package`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};





export const get_forecast = async (data) => {
  const url = `https://3uyoh9h5zh.execute-api.us-east-1.amazonaws.com/prod/forecast`;
  try {
    const response = await axios.get(url, {
      params: {
        scope: "week"

      },
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};



export const delete_table_forecast = async (data) => {
  const url = `https://3uyoh9h5zh.execute-api.us-east-1.amazonaws.com/prod/forecast/table`;
  try {
    const response = await axios.get(url, {

      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};


export const create_order = async (data) => {
  const url = `${"https://3uyoh9h5zh.execute-api.us-east-1.amazonaws.com/prod/forecast/orders"}`;
  try {
    const response = await axios.post(url, data, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};




export const cosult_documents = async (fi,ff) => {
  const url = `${"https://mz2b94va5l.execute-api.us-east-1.amazonaws.com/dev/documents"}`;
  try {
    const response = await axios.get(url, {
      params: {
        iDate: fi,
        fDate: ff,
        docType: "FACTURA",

      },
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};


export const cosult_documents_petitions = async (fi,ff) => {
  const url = `${"https://mz2b94va5l.execute-api.us-east-1.amazonaws.com/dev/documents"}`;
  try {
    const response = await axios.get(url, {
      params: {
        iDate: fi,
        fDate: ff,
        docType: "PEDIMENTO",

      },
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};




export const process_documents = async (data) => {
  const url = `${"https://mz2b94va5l.execute-api.us-east-1.amazonaws.com/dev/documents/process"}`;
  try {
    const response = await axios.post(url, data, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};



export const load_documents = async (data) => {
  const url = `${"https://mz2b94va5l.execute-api.us-east-1.amazonaws.com/dev/documents"}`;
  try {
    const response = await axios.post(url, data, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};



export const update_items_invoices = async (docID,supplierCode,data) => {
  const url = `https://mz2b94va5l.execute-api.us-east-1.amazonaws.com/dev/documents?docID=${docID}&supplierCode=${supplierCode?.replace(/&/g, '%26')}`;
  try {
    const response = await axios.put(url, data, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};


export const delete_items_invoices = async (docID,supplierCode,data) => {
  const url = `https://mz2b94va5l.execute-api.us-east-1.amazonaws.com/dev/documents?docID=${docID}&supplierCode=${supplierCode}`;
  try {
    const response = await axios.delete(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};