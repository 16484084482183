//PAQUETERIAS
import { useEffect, useMemo, useRef, useState } from 'react';
import { Box } from '@mui/material';
import dayjs from 'dayjs';
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import { useDialog, Confirm } from "react-st-modal";
import NotificationSystem from "react-notification-system";
import Button from "@mui/material/Button";

//COMPONENTES
import { refresh_token } from '../../../main_components/tokens/tokenrefresh';
import { custom_multiple_filter, calculate_total } from '../../../main_components/methods_v2/auxiliary_functions';
import { get_invoices } from '../../../services/invoices/invoice';
import { formattedCurrentDate, formattedPreviousDate } from "../../../main_components/date/day";
import SEARCH_FILTERS from '../consult_invoice/tools/shearch_filter';
import MODAL_TABLE from '../../../main_components/modal/modal_Table';
import RENDER_TOOLBAR_INTERNAL_ACTIONS from '../../../main_components/methods_v2/export';
import DETAIL_INVOICE_V2 from '../consult_invoice/detail/detail_invoce_v2';
import { BREADCRUMBS } from "../../../main_components/pagination/breadcrumbs";
import RENDER_ROW_ACTION_MENU_ITEMS from '../consult_invoice/tools/render_row_action_menuItems';
import AlertResponse from '../../../main_components/alerts/alertResponse';
import Motivo from "../../../imgComponents/motivo.png";
import { cancel_invoice } from '../../../services/invoices/invoice';
import { catchErrorModul } from '../../../main_components/catchErr/catchError';

const JOIN_INVOICE_CANCEL_2 = ({ reason_cancel, open, id_cancel, handleSubmit }) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [rowSelection, setRowSelection] = useState({});
  const [reconsult, setreconsult] = useState(false);
  const [ff, setff] = useState(dayjs(formattedCurrentDate));
  const [fi, setfi] = useState(dayjs(formattedPreviousDate));
  const notificationSystemRef = useRef();
  const [rfc, setRFC] = useState("");
  const [sdn, setSDN] = useState(sessionStorage.getItem("rfcBusqueda"));
  const [cancel, setCancel] = useState("all");
  const [tipodoc, setTipoDoc] = useState("null");
  const [modalT, setmodalT] = useState(false);
  const [menssage, setmenssage] = useState("");
  const [modalGeneral, setmodalGeneral] = useState(false);

  const [g_total, setg_total] = useState([]);
  const [g_subtotal, setg_subtotal] = useState([]);
  const [g_tax, setg_tax] = useState([]);
  const [g_discount, setg_discount] = useState([]);
  const [usStateList, setusStateList] = useState([]);
  const [total_rows, settotal_rows] = useState("");
  const [query_filters, setquery_filters] = useState({});
  const [uuidRelation, setuuidRelation] = useState("");
  const [viewR, setviewR] = useState(false);
  const dialog = useDialog();


  const update_identifiers =
    [{ subtotal: 'number' },
    { discount: 'number' },
    { tax: 'number' },
    { total: 'number' },
    { invoice_date: "string" },
    { serie: "string" },
    { folio: "string" },
    { name_business_partner: "string" },
    { rfc_business_partner: "string" },
    { tipodecomprobante: "string" },
    { username: "string" },
    { payment_method: "string" },
    { uuid_sat: "string" },
    { name_pos: "string" },
    { cancelation_status: 'string' },
    { ticket: 'string' },
    { forma_pago: 'string' },
    { amount_of_debt: 'string' },
    { amount_paid: 'string' },
    { paid: 'string' },
    { installment: 'string' },
    { currency: 'string' },
    { filename: 'string' },
    { filename_cancel: 'string' },
    { status: 'string' },
    { its_cancelable: 'string' },
    { cancelation_status: 'string' },
    { cancel: 'string' },
    { note: 'string' },
    { seriefolio: 'string' },
    { payment_method: "string" }
    ];

  const update_null_identifiers = (original_obj, identifiers_to_update) => {
    return original_obj.map((item) => {
      for (const identifier in item) {
        if (item.hasOwnProperty(identifier)) {
          const isToUpdate = identifiers_to_update.some((update) => update[identifier] !== undefined);
          if (isToUpdate && item[identifier] === null) {
            item[identifier] = typeof identifiers_to_update.find((update) => update[identifier]) === 'number' ? 0 : '';
          }
        }
      }
      return item;
    });
  };


  const handle_get_invoice = async (query_filters_flag, query_filters_data) => {
    setIsLoading(true);
    setData([]);

    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            var dt;
            console.log(query_filters_flag)
            if (query_filters_flag) {
              console.log(query_filters_data)
              setfi(dayjs(query_filters_data.di))
              setff(dayjs(query_filters_data.df))
              dt = await get_invoices(query_filters_data.di, query_filters_data.df, query_filters_data.rfc === "null" ? null : query_filters_data.rfc, query_filters_data.b_partner === "null" ? null : query_filters_data.b_partner, query_filters_data.type === "null" ? null : query_filters_data.type, cancel === "all" ? null : cancel);
            } else {
              setquery_filters({
                di: fi.format('YYYY-MM-DD'),
                df: ff.format('YYYY-MM-DD'),
                rfc: rfc || null,
                b_partner: sdn || null,
                type: tipodoc || null,
                cancel: cancel || null,
              })
              dt = await get_invoices(fi.format('YYYY-MM-DD'), ff.format('YYYY-MM-DD'), sessionStorage.getItem("rfcBusqueda"), null, null, false);

            }
            setg_total(dt.data?.map(obj => obj.total).filter(d => d !== null))
            setg_discount(dt.data?.map(obj => obj.discount).filter(d => d !== null))
            setg_subtotal(dt.data?.map(obj => obj.subtotal).filter(d => d !== null))
            setg_tax(dt.data?.map(obj => obj.tax).filter(d => d !== null))
            const list = Array.from(new Set(dt.data?.map(obj => obj.serie)));
            dt = dt.data.slice().sort((a, b) => a.invoice_date < b.invoice_date);
            var ndt = update_null_identifiers(dt, update_identifiers);
            setusStateList(list);
            setData(ndt);
            console.log(ndt);
          } catch (err) {
            setData([]);
          } finally {
            setIsLoading(false);

          }
        }, 1000);
      } else { }
    } catch (err) {
      console.log(err);
    }
  };

  const handleStateChange = (newState) => {
    const filteredRowsCount = newState.filteredRowModel
    settotal_rows(filteredRowsCount);
  };
  const handle_success_filter = (a, b) => {
    handle_get_invoice(a, b);
    notificationSystemRef.current.addNotification({
      message: <AlertResponse msj={"Filtro Aplicado"} view={true}></AlertResponse>,
      level: "success",
      position: "br",
      autoDismiss: 10,
    });

  }

  const handlehancelR = async () => {
    setmenssage("Cancelando factura...")
    modalT(true);
    modalGeneral(true);
    var data = {
      uuid: sessionStorage.getItem("rowUuid"),
      motivo: reason_cancel,
      cancellation_request_id: sessionStorage.getItem("faCancel"),
      relacionado: uuidRelation,
    };
    try {
      await cancel_invoice(id_cancel, data);
      alert("Exito se cancelo la factura")
      handleSubmit();
      modalT(false);
      modalGeneral(false);
      dialog.close(true);
    } catch (err) {
      catchErrorModul("Cancelar Factura", JSON.stringify(data), JSON.stringify(err?.response));
      alert(JSON.stringify(err.response.data));
      dialog.close(true);
      modalT(false);
      modalGeneral(false);
    }

  };

  const columns = useMemo(
    () => [
      {
        id: "join_invoice",
        header: "Relacionar",
        accessorKey: "join_invoice",
        enableFilters: false,
        enableEditing: false,
        enableRowOrdering: false,
        enableGrouping: false,
        enableColumnDragging: false,
        enableColumnActions: false,
        accessorFn: (d) => {
          function join_invoices_fn() {
            sessionStorage.setItem("aCancelar", d.uuid_sat);
            setuuidRelation(d.uuid_sat);
            setviewR(true);
          }
          return (<img
            alt=""
            src={Motivo}
            style={{ cursor: "pointer" }}
            onClick={async () => {
              const result = await Confirm(
                "Factura relacionada: " + d.uuid_sat,
                "ESTA USTED SEGURO DE RELACIONAR ESTA FACTURA, DE NO SER ASI DE AL BOTÓN DE CANCELAR PARA ESCOGER OTRA FACTURA"
              );
              if (result) {
                join_invoices_fn(result);
              } else {
              }
            }}
          ></img>)
        },
        size: 50,
        muiTableBodyCellProps: {
          align: 'center',
        },
      },
      {
        id: "invoice_date",
        header: "Fecha",
        accessorKey: "invoice_date",
        size: 145,
        muiTableBodyCellProps: {
          align: 'center',
        },
      },

      {
        header: "Serie",
        accessorKey: "serie",
        id: "serie",
        size: 140,
        filterVariant: 'multi-select',
        filterSelectOptions: usStateList,
        filterFn: custom_multiple_filter,
        muiTableBodyCellProps: {
          align: 'center',
        },
        enableColumnFilterModes: false

      },
      {
        id: "folio",
        header: "Folio",
        accessorKey: "folio",
        size: 140,
        muiTableBodyCellProps: {
          align: 'center',
        },

      },

      {
        id: "total",
        header: "Total",
        accessorKey: 'total',
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) : cell.getValue()?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },

      },

      {
        header: "UUID_SAT",
        accessorKey: "uuid_sat",
        id: "uuid_sat",

        className: "justify_data"
      },
    ],
    [g_discount, g_subtotal, g_tax, g_total, usStateList],
  );


  useEffect(() => {
    handle_get_invoice();
  }, []);

  return (
    <div style={{ width: "100%", margin: "0 auto" }} className='new_table_v2'>
      {/* <BREADCRUMBS niveles={
        [
          { label: "CONSULTAR FACTURAS", path: null },
        ]
      }
        cards_dashboard={true}
        total_rows={total_rows !== "" ? total_rows : 0}
        filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
        visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
        total={data?.length}
        old_version={true}
        modul="CONSULTAR_FACTURAS_V1"
        path="/consultaCFDI"
      ></BREADCRUMBS> */}

      <MODAL_TABLE
        open={true}
        message={menssage}
        modalGeneral={modalGeneral}
        modalT={modalT}
      ></MODAL_TABLE>
      <NotificationSystem ref={notificationSystemRef}></NotificationSystem>
      <Box sx={{ display: 'flex', justifyContent: 'center', width: '98%', margin: "0 auto", marginBottom: "10px" }}>
        <p
          className="contentMCancel"
          style={{ width: "100%" }}
          title="Seleeccionar otra factura"
          onClick={(e) => setviewR(false)}
        >
          Factura relacionada:{uuidRelation}
        </p>
      </Box>
      <div
        style={{ display: uuidRelation !== "" ? "" : "none" }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'center', width: '98%', margin: "0 auto", marginBottom: "10px" }}>

          <Button
            onClick={async () => {
              const result = await Confirm('¿Esta usted seguro de cancelar esta factura?', 'Cancelar Factura', 'Si', 'No');
              if (result) {
                handlehancelR();
              }
            }
            }
            sx={{ m: 2, width: "100%" }}
            variant="contained"
            style={{ background: "green" }}
          >
            CANCELAR FACTURA
          </Button>
          <Button
            onClick={() => {
              dialog.close();
            }}
            sx={{ m: 2, width: "100%" }}
            variant="contained"
            style={{ background: "red" }}
          >
            SALIR
          </Button>
        </Box>
      </div>
      <div style={{ display: viewR === true ? "none" : "" }}>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '98%', margin: "0 auto", marginBottom: "10px" }}>
          <MaterialReactTable
            columns={columns}
            data={data}
            enableGrouping
            enablePinning
            enableFacetedValues
            enableStickyHeader
            enableColumnFilterModes
            getRowId={(row) => row?.id}
            onRowSelectionChange={setRowSelection}
            enableTooltips={false}
            localization={MRT_Localization_ES}
            state={{ rowSelection, isLoading: isLoading }}
            enableColumnResizing
            enableColumnPinning
            enableColumnOrdering
            onStateChange={handleStateChange}
            enableExpandAll={false}
            initialState={{ showGlobalFilter: true }}
            muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
            muiSkeletonProps={{ animation: 'pulse', height: 28 }}
            rowVirtualizerInstanceRef
            rowVirtualizerOptions={{ overscan: 5 }}
            columnVirtualizerOptions={{ overscan: 2 }}
            muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
            positionToolbarAlertBanner="bottom"
            paginationDisplayMode='pages'
            rowPinningDisplayMode='sticky'
            layoutMode="grid"
            muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important", } }}
            muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
            muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
            displayColumnDefOptions={{
              'mrt-row-select': {
                enableHiding: true,
              }
            }}
            muiTableBodyRowDragHandleProps={({ table }) => ({
              onDragEnd: () => {
                const { draggingRow, hoveredRow } = table.getState();
                if (hoveredRow && draggingRow) {
                  data.splice(
                    hoveredRow.index,
                    0,
                    data.splice(draggingRow.index, 1)[0],
                  );
                  setData([...data]);
                }
              },
            })}
            muiTableBodyRowProps={
              ({ row, table }) => {
                settotal_rows(table);
                const { density } = table.getState();
                return {
                  sx: {
                    height: row.getIsPinned()
                      ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                      }px`
                      : undefined,
                  },
                };
              }
            }
            renderTopToolbarCustomActions={({ }) => (
              <SEARCH_FILTERS
                fi={fi}
                setfi={setfi}
                ff={ff}
                setff={setff}
                tipodoc={tipodoc}
                setTipoDoc={setTipoDoc}
                cancel={cancel}
                setCancel={setCancel}
                rfc={rfc}
                setRFC={setRFC}
                sdn={sdn}
                setSDN={setSDN}
                handle_get_invoice={handle_get_invoice}
                join_invoice={true}

              ></SEARCH_FILTERS>
            )}
            renderToolbarInternalActions={({ table, idenx }) => (
              <RENDER_TOOLBAR_INTERNAL_ACTIONS
                key={idenx}
                share_filter={true}
                query_filters={query_filters}
                settotal_rows={settotal_rows}
                handle_success_filter={handle_success_filter}
                total_rows={total_rows !== "" ? total_rows : 0}
                table={table} file_name={"FACTURAS DEL " + fi.format('YYYY-MM-DD') + " AL " + ff.format('YYYY-MM-DD')}
                update_identifiers={update_identifiers}
                general_public_report={false}
                zip={false}
              />
            )
            }
          />
        </Box>
      </div>

    </div >
  );
};

export default JOIN_INVOICE_CANCEL_2;