import React, { useState, useEffect, Suspense } from "react";
import axios from "axios";
import { alpha, styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Spinner from "../../../imgComponents/S.gif";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { CustomDialog, useDialog, Confirm } from "react-st-modal";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { update_policy } from "../../../services/expenses/expenses";

import "../../expense.css";

const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ValueType = [
  { value: "INGRESO", label: "INGRESO" },
  { value: "EGRESO", label: "EGRESO" },
];

const UPDATE_POLICY = ({ codCocept, codType, codId, codAction,codFecha}) => {
  const dialog = useDialog();
  const [open, setOpen] = useState(false);
  const [codTypePolicy, setcodTypePolicy] = useState(codType);
  const [codConceptPolicy, setcodConcepPolicy] = useState(codCocept);
  const [ff, setff] = useState(codFecha);

  const handelConcept = (event) => {
    setcodConcepPolicy(event?.target?.value);
  };
  const handelType = (event) => {
    setcodTypePolicy(event?.target?.value);
  };

  var data = {
    policy: {
      concept: codConceptPolicy,
      type: codTypePolicy,
      export_date: ff,
    },
  };

  console.log(data);

  const handleSubmit = async (event) => {
    event?.preventDefault();
    var data = {
      policy: {
        concept: codConceptPolicy,
        type: codTypePolicy,
        export_date: ff,
      },
    };

    console.log(data);

    setOpen(true);

      try {
        await update_policy(data,codId,codAction);
        setOpen(false);
        alert(codAction==="UPDATE"?"Poliza actualizada":"Poliza Inyecatada");
        dialog.close(true);
      } catch (err) {
        setOpen(false);
        var error = err?.response?.data?.errorMessage;
        alert("Error.," + error);
      }
  };

  return (
    <div>
      <div>
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style2}>
            <div className="centermodaldescarga">
              <Typography
                id="modal-modal-title"
                className=""
                variant="h6"
                component="h2"
              >
                <img alt="" src={Spinner}></img>
              </Typography>
              <Typography
                id="modal-modal-description"
                className=""
                sx={{ mt: 2 }}
              >
                {"Ejecutando proceso..."}
              </Typography>
            </div>
          </Box>
        </Modal>
      </div>
      <div>
        <form>
          <div style={{display: codAction==="UPDATE"?"":"none"}}> 
          

          <Box>
            <TextareaAutosize
              label="Descripciónn"
              variant="outlined"
              aria-label="empty textarea"
              value={codConceptPolicy}
              placeholder="Descripción"
              className="TexAreaAutosize"
              onChange={handelConcept}
            />
          </Box>

          <div className="generarExpense">
            <div className="expen-6">
              <input
                type="text"
                className="fExpense2"
                onFocus={(e) => {
                  e.currentTarget.type = "date";
                  e.currentTarget.focus();
                }}
                placeholder="Fecha"
                onChange={(event) => setff(event.target.value)}
              ></input>
            </div>
            <div className="expen-7">
              <TextField
                select
                label="Tipo"
                value={codTypePolicy}
                sx={{ m: 1.5, width: "94%" }}
                onChange={handelType}
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                SelectProps={{
                  native: true,
                }}
              >
                {ValueType.map((option) => (
                  <option
                    key={option.value}
                    value={option.value}
                    selected={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </div>
          </div>
          </div>

          <br></br>

          <Box>
            <Button
              variant="contained"
              sx={{ m: 1.5, width: "20ch" }}
              className="ButtonModal"
              onClick={handleSubmit}
            >
              {codAction==="UPDATE"?"ACTUALIZAR":"INYECTAR"}
            </Button>

            <Button
              variant="contained"
              sx={{ m: 1.5, width: "20ch" }}
              className="ButtonModal2"
              onClick={() => {
                dialog.close();
              }}
            >
              cancelar
            </Button>
          </Box>
        </form>
      </div>
    </div>
  );
};

export default UPDATE_POLICY;
