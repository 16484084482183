import axios from "axios";
import { catchErrorModul } from "../../main_components/catchErr/catchError";
import { headersdata } from "../../main_components/tokens/headers";
import { refresh_token } from "../../main_components/tokens/tokenrefresh";

var urlC = "https://mrck7bov1j.execute-api.us-east-1.amazonaws.com/development";
var urlUser="https://lxwr6pudd9.execute-api.us-east-1.amazonaws.com/development";

export const get_catalogs = async (tablename) => {
  refresh_token();
  const url = `${urlC}/catalogs/${tablename}`;

  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const post_catalogs = async (tablename, data) => {
  refresh_token();
  const url = `${urlC}/catalogs/${tablename}`;
  try {
    const response = await axios.post(url, data, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    catchErrorModul("Catalogs", JSON.stringify(data), JSON.stringify(error?.response===undefined?"Token expirado":error?.response));
    return Promise.reject(error);
  }
};

export const put_catalogs = async (tablename,data,id) => {
  refresh_token();
  const url = `${urlC}/catalogs/${tablename}/${id}`;
  try {
    const response = await axios.put(url, data, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    catchErrorModul("Catalogs", JSON.stringify(data), JSON.stringify(error?.response===undefined?"Token expirado":error?.response));
    return Promise.reject(error);
  }
};

export const delete_catalogs = async (tablename,id) => {
  refresh_token();
    const url = `${urlC}/catalogs/${tablename}/${id}`;
    try {
    const response = await axios.delete(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const get_users = async () => {
  refresh_token();
    const url = `${urlUser}/users/roles/ROLE_PROJECT_LIST?allowed=true`;
    try {
    const response = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
