//yarn add react-google-slides
import CountdownClock from "./countdownclock";
import "./dashboard.css";
import GoogleSlides from 'react-google-slides';
import c1 from "../imgComponents/iconos/icono-1.png";
import c2 from "../imgComponents/iconos/icono-2.png";
import c3 from "../imgComponents/iconos/icono-3.png";
import proximg from "../imgComponents/iconos/apps.png";
import imgpublicidad from "../imgComponents/iconos/AWS.png";
import { arrStorage } from "../main_components/menu/data";

export const DASHBOARD = () => {

    const tokenAcces = sessionStorage.getItem("getAccesTokenRefresh");    
    const expirationDateAcces = new Date(0);
    expirationDateAcces.setUTCSeconds(JSON.parse(atob(tokenAcces.split('.')[1])).exp);
    console.log(expirationDateAcces);


    const modulos =[
        {
            id: 1,
            modulo: "Deudas",
            des: "En este apartado se almacenan y se visualizan todas las deudas",
            icono: c1,
        },  
        {
            id: 2,
            modulo: "Asignación de Comisiones",
            des: "El registro diario permite reportar el personal asignado a una ruta de forma diaria",
            icono: c2
        },
        {
            id: 3,
            modulo: "Consultar Proveedores",
            des:"Se visualizan los socios de negocios configurados como proveedor o ambos",
            icono: c3
        }
    ]

    var r = localStorage.getItem("rolesfav");
    var u =localStorage.getItem("userIdfav");
    const arrLocalStorage= arrStorage(r, u);

    function handletour(){
        localStorage.setItem("tour", "true");
        window.location.reload();
    }
    function handlehi(){
        window.open("https://www.herramientasinformaticas.com", "_blank");
    }

return (
    <div className='container-dashboard'>
        <div className='welcome-container'>
        <div className='title-dashboard'>
            BIENVENIDO A OD2
        </div>
        </div>
        <div className='grid-dashboard'>
            <div className='seccion1' id="seccion1">
                <div className="seccion-noticias">
                    <div className="title-noticias">
                        Nuevas Actualizaciones
                    </div>
                    <div className="presentacion-noticias">
                        <GoogleSlides
                            width="100%"
                            height="310px"
                            slidesLink="https://docs.google.com/presentation/d/1c6r2Rikglg3QypfrJFmcjNUJ9NPn7LrioXYQPSXxXJs/edit#slide=id.p"
                            slideDuration={5}
                            position={1}
                            showControls
                            loop
                        /> 
                    </div>
                </div>
            </div>
            <div className='seccion2' id="seccion2">
                <div className="hi-seccion">
                    <div className="title-hi">
                        Herramientas Informáticas
                    </div>
                    <div className="publicidad-hi">
                        <img src={imgpublicidad} alt="" onClick={()=>handlehi()}/>
                    </div>

                </div>
            </div>
            <div className='seccion3'>
                <div className='token-container' id="token-container">
                    <div className='token-title'>
                        Tiempo de Sesión
                    </div>
                    <div className='token-time'>
                        Tiempo restante:<CountdownClock expirationDateAcces={expirationDateAcces} />
                    </div>
                </div>
                <div className="moduls-act" id="moduls-act">
                    <div className="title-moduls">
                        Nuevos Módulos
                    </div>
                    <div className="moduls-list">
                      {
                        modulos?.map(item=>(
                          <div className="cart-modulo">
                            <div className="cart-modulo-icon">
                                <img src={item.icono} alt=""/>
                            </div>
                            <div className="cart-modulo-title">
                              <p className="name-modu">{item.modulo}</p>
                              <p className="description-modu">{item.des}</p>
                            </div>
                          </div>
                        ))
                      }
                    </div>
                </div>
            </div>
            <div className="seccion4" id="seccion4">
                <div className="title-seccion4">
                    Módulos Favoritos
                </div>
                <div className="modulos-fav-star">
                    {
                        arrLocalStorage?.map((valor, index)=>{
                        if(valor.forStorage === null ||
                            valor.forStorage === undefined ||
                            valor.restriccion === "none" ||
                            valor.restriccion === "DENEGADO")
                        return "";
                        return (
                            <div className={`$carta${index}`}>
                                <a href={valor.ruta} className="cartas">
                                <div className="iconofav">
                                    {valor.icono}
                                </div>
                                <div className="desfav">
                                    {valor.name}
                                </div>
                                </a>
                            </div>
                        )
                    })
                    }
                </div>
            </div>
            <div className="seccion5" id="seccion5">
                <div className="title-moduls">
                   Proximos Módulos 
                </div>
                <div className="moduls-list-prox">
                    <div className="img-prox">
                        <img src={proximg} alt=""/>
                    </div>
                    <div className="title-prox">
                        Sin Información
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
