//PAQUETERIAS
import { useEffect, useState, useMemo, useRef, Fragment } from "react";
import NotificationSystem from 'react-notification-system';
import MODAL_TABLE from "../../../main_components/modal/modal_Table";
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from '@mui/material';

import dayjs from 'dayjs';

//COMPONENTES
import AlertResponse from "../../../main_components/alerts/alertResponse";
//IMAGENES/ICONOS

import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../../main_components/methods_v2/export";
import { date_current, previous_date } from "../../../main_components/date/date";
import { COUNT_CARDS } from "../../../main_components/pagination/countcards";
import EditFormDialog from "./form";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import "../file_uploader.css"
import { update_items_invoices } from "../../../services/portal/portal";
import ADD_DETAIL from "../../../imgComponents/create.png"
import FORM_PETITIONS_DETAILS from "./form_petitions_details";
import DELETE from "../../../imgComponents/borrar.png"
const customLocalization = {
  ...MRT_Localization_ES,
  toolbar: {
    ...MRT_Localization_ES.toolbar,
    resetOrder: 'Resetear Orden',
  },
};
const DETAILS_PETITIONS = (props) => {


  const notificationSystemRef = useRef();
  const [open, setopen] = useState(false);
  const [data, setData] = useState(props?.items.map((item, index) => { return { ...item, id: index + 1 }; }));
  const [id_flags, setid_flags] = useState(props?.items.map((item, index) => { return { ...item, id: index + 1 }; }));

  const [keys, setkeys] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [reconsult, setreconsult] = useState(false);
  const [modalT, setmodalT] = useState(false);
  const [menssage, setmenssage] = useState("");
  const [modalGeneral, setmodalGeneral] = useState(false);
  const [usStateList, setusStateList] = useState([]);
  const [total_rows, settotal_rows] = useState("");

  const [gvalor_en_mn, setgvalor_en_mn] = useState(0);

  const [porc, setporc] = useState(0.0);

  const [query_filters, setquery_filters] = useState({});
  const [query_filters_dt, setquery_filters_data] = useState("");

  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [currentRowData, setCurrentRowData] = useState(null);
  const [isNewEntry, setIsNewEntry] = useState(false);


  const handleOpenEditDialog = (rowData, isNew = false) => {
    setCurrentRowData(rowData);
    setIsNewEntry(isNew);
    setEditDialogOpen(true);
  };

  const handleCloseEditDialog = () => {
    setEditDialogOpen(false);
  };

  const handleSaveData = async (newData, isNew) => {
    console.log(newData)
    console.log(data)

    let updatedData;
    if (isNew) {
      updatedData = [...data, newData];
    } else {
      updatedData = data.map(row => row.id === newData.id ? newData : row);
    }

    var original_data = data;
    console.log(updatedData)

    setData(updatedData);
    try {
      setIsLoading(true);
      await update_items_invoices(props?.docID, props?.supplierCode, { "items": updatedData })
      props?.handle_cosult_invoice();
    } catch (error) {
      alert(JSON.stringify(error?.response?.data));
      setData(original_data);

    } finally {
      setIsLoading(false);
    }

  };

  const handleAddNewRow = () => {
    const newRow = {
      id: data.length + 1,
      "seller": "DEU",
      "igiPaymentMethodItem": "",
      "sequence": "",
      "unit": "",
      "origin": "",
      "parts": [],
      "vatPaymentMethodItem": "",
      "tariffFractionEntrySummary": ""
    };


    handleOpenEditDialog(newRow, true);
  };




  const update_identifiers =
    [
    ];

  //proceso de consultar todas las cuentas contables
  const handleSubmit = async (query_filters_flag, query_filters_data) => {

  };

  const handleStateChange = (newState) => {
    const filteredRowsCount = newState.filteredRowModel
    settotal_rows(filteredRowsCount);
  };



  const handle_success_filter = (a, b) => {
    handleSubmit(a, b);
    notificationSystemRef.current.addNotification({
      message: <AlertResponse msj={"Filtro Aplicado"} view={true}></AlertResponse>,
      level: "success",
      position: "br",
      autoDismiss: 10,
    });

  }



  const handleSaveRow = async ({ exitEditingMode, row, values }) => {
    const updatedData = data.map((d) =>
      d.id === row.id ? { ...d, ...values } : d
    );
    setData(updatedData);
    exitEditingMode(); // Salir del modo edición
  };


  const columns = useMemo(
    () => [

      {
        header: "Ac.",
        size: 60,
        Cell: ({ row }) => {

          const deleteitemdt = async (id) => {
            console.log(data)
            console.log(id)
            var original_data = data;
            var delete_item = data.filter((d) => d.id !== id);
            console.log(delete_item)

            setData(delete_item);
            try {
              setIsLoading(true);
              await update_items_invoices(props?.docID, props?.supplierCode, { "items": delete_item })
              props?.handle_cosult_invoice();
            } catch (error) {
              alert(JSON.stringify(error?.response?.data));
              setData(original_data);

            } finally {
              setIsLoading(false);
            }

          }

          return (
            <div>
              <EditIcon onClick={() => handleOpenEditDialog(row.original)}></EditIcon>
              <img src={DELETE} onClick={() => deleteitemdt(row.original.id)}></img>
            </div>
          )
        },
        id: "Acciones",
        enableFilters: false,
        enableEditing: false,
        enableRowOrdering: false,
        enableGrouping: false,
        enableColumnDragging: false,
        enableColumnActions: false,
        enableSorting: false,
        enableStickyFooter: false,
      },
      {
        header: "Secuencia",
        size: 150,
        accessorKey: "sequence",
        id: "sequence",
      },
      {
        header: "Fracción partida Pedimento",
        size: 200,
        accessorKey: "tariffFractionEntrySummary",
        id: "tariffFractionEntrySummary",
      },

      {
        header: "Unidad",
        size: 150,
        accessorKey: "unit",
        id: "unit",
      },
      {
        header: "Peso",
        size: 150,
        accessorKey: "itemWeight",
        id: "itemWeight",
      },
      {
        header: "Origen",
        size: 200,
        accessorKey: "origin",
        id: "origin",
      },
      {
        header: "Vendedor",
        size: 150,
        accessorKey: "seller",
        id: "seller",
      },
      {
        header: "Forma de Pago IVA Partida",
        size: 200,
        accessorKey: "vatPaymentMethodItem",
        id: "vatPaymentMethodItem",
      },
      {
        header: "Forma de Pago IGI",
        size: 220,
        accessorKey: "igiPaymentMethodItem",
        id: "igiPaymentMethodItem",
      }
    ],
    [data]
  );


  useEffect(() => {
    if (total_rows === "") {
    } else {
      const getCurrentPath = () => {
        const search = window.location.search;
        const pathWithoutQuestionMark = search.startsWith('?') ? search.slice(1) : search;
        return pathWithoutQuestionMark;
      };

      const pathWithoutQuestionMark = getCurrentPath();
      setquery_filters_data(pathWithoutQuestionMark)
    }
  }, [total_rows])








  return (
    <>

      <div className='new_table_v2 invoice_or_petitios'>
        <div >
          <NotificationSystem ref={notificationSystemRef} ></NotificationSystem>
          <MODAL_TABLE
            open={true}
            message={menssage}
            modalGeneral={modalGeneral}
            modalT={modalT}
          ></MODAL_TABLE>
          <>
            <COUNT_CARDS niveles={
              [
                { label: "", path: null },
              ]
            }
              // old_version={true}
              // modul="CONSULTAR_CUENTAS_CONTABLES_V1"
              // path="/consult_accounting_account"  
              cards_dashboard={true}
              total_rows={total_rows !== "" ? total_rows : 0}
              filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
              visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
              total={data?.length}
            ></COUNT_CARDS>
            <FORM_PETITIONS_DETAILS
              open={editDialogOpen}
              onClose={handleCloseEditDialog}
              data={currentRowData}
              onSave={handleSaveData}
              isNew={isNewEntry}
              original_data={data}
            />

            <MaterialReactTable

              columns={columns}
              data={data}
              enableGrouping
              enablePinning
              enableFacetedValues
              enableStickyHeader
              enableStickyFooter
              enableRowPinning
              enableColumnFilterModes
              onStateChange={handleStateChange}
              getRowId={(row) => row?.id}
              onEditingRowSave={handleSaveRow}
              onRowSelectionChange={setRowSelection}
              enableTooltips={false}
              localization={customLocalization}
              enableMultiRowSelection={false}
              state={{ rowSelection, isLoading: isLoading }}
              enableColumnResizing
              enableColumnPinning
              enableColumnOrdering
              enableExpandAll={false}
              initialState={
                {
                  showGlobalFilter: true,
                  density: 'compact'
                }}
              muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
              muiSkeletonProps={{ animation: 'pulse', height: 28 }}
              rowVirtualizerInstanceRef
              rowVirtualizerOptions={{ overscan: 5 }}
              columnVirtualizerOptions={{ overscan: 2 }}
              muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
              positionToolbarAlertBanner="top"
              paginationDisplayMode='pages'
              rowPinningDisplayMode='sticky'
              layoutMode="grid"
              muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important", } }}
              muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
              muiTableFooterCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
              muiPaginationProps={{
                color: 'primary', shape: 'rounded', variant: 'variant', rowsPerPageOptions: [10, 15, 30, 50, 100, 200, 1000],
              }}

              displayColumnDefOptions={{
                'mrt-row-pin': {
                  Header: () => {
                    return (<img style={{ cursor: "pointer" }} src={ADD_DETAIL} onClick={handleAddNewRow} />)
                  },
                  enableHiding: true,

                },
                'mrt-row-actions': {
                  enableHiding: true,
                },
                'mrt-row-expand': {
                  enableHiding: true,
                },
                'mrt-row-select': {
                  enableHiding: true,
                }
              }}

              muiTableBodyRowDragHandleProps={({ table }) => ({
                onDragEnd: () => {
                  const { draggingRow, hoveredRow } = table.getState();
                  if (hoveredRow && draggingRow) {
                    data.splice(
                      hoveredRow.index,
                      0,
                      data.splice(draggingRow.index, 1)[0],
                    );
                    setData([...data]);
                  }
                },
              })}

              muiTableBodyRowProps={
                ({ row, table }) => {
                  settotal_rows(table);
                  const { density } = table.getState();
                  return {
                    sx: {
                      height: row.getIsPinned()
                        ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                        }px`
                        : undefined,
                    },
                  };
                }
              }

              renderToolbarInternalActions={({ table, idenx }) => (
                <RENDER_TOOLBAR_INTERNAL_ACTIONS
                  table={table}
                  file_name={"DETALLES DEL PEDIMENTO " + props?.docID}
                  update_identifiers={update_identifiers}
                  porc={porc}
                  query_filters={query_filters}
                  query_filters_dt={query_filters_dt}
                  handle_get_consult={handleSubmit}
                  key={idenx}
                  isFullscreen={false}
                  settotal_rows={settotal_rows}
                  handle_success_filter={handle_success_filter}
                  share_filter={false}
                  total_rows={total_rows !== "" ? total_rows : 0}
                />
              )
              }
            />

          </>
        </div>
      </div>


    </>
  );
}

export default DETAILS_PETITIONS;
