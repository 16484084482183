import React, { useState, useEffect, Suspense,useMemo,useRef } from "react";
import axios from "axios";
import { alpha, styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Spinner from "../../imgComponents/S.gif";
import TableContainer from "../Table_Container_Addendas";
import TextField from "@mui/material/TextField";
import NotificationSystem from 'react-notification-system';
import Button from "@mui/material/Button";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { FileUploader } from "react-drag-drop-files";
import { CustomDialog, useDialog, Confirm } from "react-st-modal";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Relacionar from "./Relacionar";
import AddDocs from "./addDocs";
import Modal_Table from "../../main_components/modal/modal_Table";
import AlertResponse from "../../main_components/alerts/alertResponse";

const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ValidationTextField = styled(TextField)({
  "& input:valid + fieldset": { borderColor: "green", borderWidth: 2 },
  "& input:valid:focus + fieldset": {
    borderLeftWidth: 6,
    padding: "4px !important",
  },
});

const Addenda_PSV = () => {
  const [open, setOpen] = useState(false);
  const [modalT, setmodalT] = useState(false);
  const [serie, setSerie] = useState("");
  const [folio, setfolio] = useState("");
  const [type, settype] = useState("PSV");
  const [order, setorder] = useState("");
  const [destino, setdestino] = useState("");
  const [rem, setrem] = useState("");
  const [sNombre, setsNombre] = useState("");
  const [sCorreo, setsCorreo] = useState("");
  const [cCorreo, setcCorreo] = useState("eolvera@boellhoff.com");
  const [dataTable, setDataTable] = useState([]);
  const [dataTable2, setDataTable2] = useState([]);
  const [idInvoice, setidInvoice] = useState("");
  
  const [vtable, setvtable] = useState(false);
  const [actB, setactB] = useState(true);

  const notificationSystemRef = useRef();

  const handelOrder = (event) => {
    setorder(event?.target?.value);
  };
  const handelfolio = (event) => {
    setfolio(event?.target?.value);
  };
  const handelserie = (event) => {
    setSerie(event?.target?.value);
  };
  const handelType = (event) => {
    settype(event?.target?.value);
  };
  const handelDestino = (event) => {
    setdestino(event?.target?.value);
  };
  const handelRem = (event) => {
    setrem(event?.target?.value);
  };
  const handelsNombre = (event) => {
    setsNombre(event?.target?.value);
  };
  const handelsCorreo = (event) => {
    setsCorreo(event?.target?.value);
  };
  const handelcCorreo = (event) => {
    setcCorreo(event?.target?.value);
  };

  const handleSubmit3 = (event) => {
    event?.preventDefault();
    var jsonMandar=JSON.parse(sessionStorage.getItem("TikectconsulReturn"));
    var jsonDocs= JSON.parse(sessionStorage.getItem("doscTable"));
    console.log(jsonMandar);

    var data = {
      type: type,
      order: order,
      sNombre: sNombre,
      sCorreo:sCorreo,
      rem: rem,
      partes:jsonMandar,
      files:jsonDocs,
      cCorreo:cCorreo
    };

    setmodalT(true);
    setOpen(true);
    let axiosConfig = {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    };
    axios
      .post(
        `https://rfpx9okh43.execute-api.us-east-1.amazonaws.com/od/invoices/addendas/volkswagen/${idInvoice}?action=POST`,
        data,
        axiosConfig
      )
      .then((res) => {
        var data = res?.data?.document?.content;
        notificationSystemRef.current.addNotification({
          message: (
            <AlertResponse msj={"La addenda se generó con éxito y está disponible en el módulo de facturas."} view={true}></AlertResponse>
          ),
          level: "success",
          position: "br",
          autoDismiss: 10,
        });
        setOpen(false);
        setmodalT(false);
        settype("PSV");
        setorder("");
        setrem("");
        setdestino("");
        setSerie("");
        setfolio("");
        setidInvoice("");
        setvtable(true);
        setDataTable([]);
        setsCorreo("");
        setsNombre("");
        setcCorreo("eolvera@boellhoff.com");
        setactB(true);
        var blob = base64toBlobxml(data);
        const blobUrl = URL.createObjectURL(blob);
        const downloadLink = document.createElement("a");
        downloadLink.href = blobUrl;
        downloadLink.download = serie + folio + type + ".xml";
        downloadLink.click();
        setTimeout(() => {
          setvtable(false);
        }, 1000);
        
      })
      .catch((err) => {
        setmodalT(false);
        setOpen(false);
        console.log(err);
        var error = err?.response?.data?.errorMessage;

        notificationSystemRef.current.addNotification({
          message: (
            <AlertResponse
              msj={
                error === undefined
                  ? "Error 401:No autorizado intentelo nuevamente o recarge la pagina nuevamente oprimiendo la tecla 'CTRL + F5'"
                  : error
              }
              view={false}
            ></AlertResponse>
          ),
          level: "error",
          position: "br",
          autoDismiss: 60,
        });

      });


  };

  function base64toBlobxml(base64Data) {
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: "application/xml" });
  }

  const handleSubmit2 = (event) => {
    event?.preventDefault();
    var jsonMandar=JSON.parse(sessionStorage.getItem("TikectconsulReturn"));
    var jsonDocs= JSON.parse(sessionStorage.getItem("doscTable"));
    console.log(jsonMandar);

    var data = {
      type: type,
      order: order,
      sNombre: sNombre,
      sCorreo:sCorreo,
      rem: rem,
      partes:jsonMandar,
      files:jsonDocs,
      cCorreo:cCorreo

    };

    setmodalT(true);
    setOpen(true);
    let axiosConfig = {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    };
    axios
      .post(
        `https://rfpx9okh43.execute-api.us-east-1.amazonaws.com/od/invoices/addendas/volkswagen/${idInvoice}?action=GET`,
        data,
        axiosConfig
      )
      .then((res) => {
        setOpen(false);
        setmodalT(false);
        console.log(res?.data);
        const xmlString = res.data.addenda_xml;
        console.log(xmlString);
        const blob = new Blob([xmlString], { type: "text/xml" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "Ac"+serie+folio+type+".xml";
        link.click();
        var data=res?.data?.addenda_json?.partes.map((d,i)=>{
          var json={
              colum:i ,
              posicion: d.posicion,
              numeroMaterial: d.numeroMaterial,
              descripcionMaterial: d.descripcionMaterial,
              cantidadMaterial: d.cantidadMaterial,
              unidadMedida: d.unidadMedida,
              precioUnitario: d.precioUnitario,
              montoLinea: d.montoLinea
          
          }
          return json;
        });
        setDataTable(data);

        
      })
      .catch((err) => {
        setmodalT(false);
        setOpen(false);
        console.log(err);
        var error = err?.response?.data?.errorMessage;
        alert("Error.," + error);
      });


  };

  const handleSubmit = (event) => {
    event?.preventDefault();
    var data = {
      type: type,
      order: order,
      sNombre: sNombre,
      sCorreo:sCorreo,
      rem: rem,
      partes: dataTable,
      cCorreo:cCorreo
    };
    console.log(data);

    setmodalT(true);
    setOpen(true);
    let axiosConfig = {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    };
    axios
      .post(
        `https://rfpx9okh43.execute-api.us-east-1.amazonaws.com/od/invoices/addendas/volkswagen/${idInvoice}?action=GET`,
        data,
        axiosConfig
      )
      .then((res) => {
        setOpen(false);
        setmodalT(false);
        console.log(res?.data);
        const xmlString = res.data.addenda_xml;
        console.log(xmlString);
        const blob = new Blob([xmlString], { type: "text/xml" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "Vs"+serie+folio+type+".xml";
        link.click();
        var data=res?.data?.addenda_json?.partes.map((d,i)=>{
          var json={
              colum:i ,
              posicion: d.posicion,
              numeroMaterial: d.numeroMaterial,
              descripcionMaterial: d.descripcionMaterial,
              cantidadMaterial: d.cantidadMaterial,
              unidadMedida: d.unidadMedida,
              precioUnitario: d.precioUnitario,
              montoLinea: d.montoLinea
          
          }
          return json;
        });
        setDataTable(data);
        setactB(false);


        
      })
      .catch((err) => {
        setmodalT(false);
        setOpen(false);
        console.log(err);
        var error = err?.response?.data?.errorMessage;
        alert("Error.," + error);
      });
   
  };

  const handelAutoComp = (data) => {
      console.log(data);
    setDataTable([]);
    const json = data?.reference1===null?{}: data?.reference1===undefined?{}:JSON?.parse(data?.reference1);
    console.log(json);
//del json
    //del data
    setsNombre(json?.sNombre===undefined?"":json?.sNombre);
    setsCorreo(json?.sCorreo===undefined?"":json?.sCorreo);


     //del json
     setorder(json?.order === undefined ? "" : json?.order);
     setrem(json?.rem === undefined ? "" : json?.rem);
     setdestino(json?.destino === undefined ? "6020" : json?.destino);
     //del data
     setSerie(data?.serie === undefined ? "" : data?.serie);
     setfolio(data?.folio === undefined ? "" : data?.folio);
     setidInvoice(data?.id === undefined ? "" : data?.id);
     setDataTable(json?.partes === undefined ? [] : json?.partes);
     setactB(true);
 

  };


 useEffect(() => {
  setDataTable2(dataTable2)
}, [dataTable]);

  const [originalData] = React.useState(dataTable)
  const [skipPageReset, setSkipPageReset] = React.useState(false)
  const updateMyData = (rowIndex, columnId, value) => {
    setSkipPageReset(true)
    setDataTable(old =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          }
        }
        return row
      })
    )
  }
  React.useEffect(() => {
    setSkipPageReset(false)
  }, [dataTable])
  const resetData = () => dataTable(originalData)


  const handleSubmitValid = () => {
    var errorInvoce="Factura no seleccionada";
    var errorOrden="El campo 'Orden', debe contener una longitud de 10 caracteres";
    var errorNS="El campo 'Nombre del solicitante' no puede ir vacío";
    var errorCS="El campo 'Correo del solicitante' no puede ir vacío";
    var errorCIE="El campo 'Correo interno' no puede ir vacío";
    var errorRem="El campo 'Remisión' no puede ir vacío";

    //POSICIÒN TYPE ENTERO
    //DECRIOCION 39 CARACTERES    
    //1-3 UNIDAD MEDIDA
    //1-18  NUMERO DE MATERIAL
    //LOS OTROS NUMBER
    console.log(folio.length)
    if (folio?.length !== 0 && order?.length===10&&sNombre?.length!==0 &&sCorreo?.length!==0 && cCorreo?.length!==0 && rem?.length!==0) {
       handleSubmit();
    }else {
      notificationSystemRef.current.addNotification({
        message: (
          <AlertResponse
          msj={folio?.length===0?errorInvoce:order?.length!==10?errorOrden:sNombre?.length===0?errorNS:sCorreo?.length===0?errorCS:cCorreo?.length===0?errorCIE:rem?.length===0?errorRem:"Error"}
          view={false}
          ></AlertResponse>
        ),
        level: "error",
        position: "br",
        autoDismiss: 60,
      });
    }
  };


  const columns = useMemo(
    () => [
      {
        Header: "Posición",
        accessor: "posicion",
      },
      {
        Header: "Cantidad",
        accessor: "cantidadMaterial",
      },
      {
        Header: "Descripción",
        accessor: "descripcionMaterial",
      },

      {
        Header: "Monto",
        accessor: "montoLinea",
      },
      {
        Header: "Número Material",
        accessor: "numeroMaterial",
      },
      {
        Header: "Precio Unitario",
        accessor: "precioUnitario",
      },
      {
        Header: "Unidad de Medida",
        accessor: "unidadMedida",
      },
    ],
    []
  );
  

  return (
    <div>
            <Modal_Table modalT={modalT}></Modal_Table>
            <NotificationSystem ref={notificationSystemRef}></NotificationSystem>

      <div>

        <Box>
            <ValidationTextField
              label="Serie"
              variant="outlined"
              placeholder="Serie"
              sx={{ m: 2, width: "54ch" }}
              value={serie}
              InputLabelProps={{ shrink: true }}
            />
            <ValidationTextField
              label="Folio"
              variant="outlined"
              placeholder="Folio"
              sx={{ m: 2, width: "54ch" }}
              value={folio}
              InputLabelProps={{ shrink: true }}
            />
          </Box>

          <Box>
            <ValidationTextField
              label="Tipo"
              variant="outlined"
              placeholder="Tipo"
              sx={{ m: 2, width: "10ch" }}
              value={type}
              InputLabelProps={{ shrink: true }}
            />
            <ValidationTextField
              label="Orden"
              variant="outlined"
              inputProps={{ maxLength: 10, minLength:10}}
              placeholder="Orden"
              sx={{ m: 2, width: "20ch" }}
              onChange={handelOrder}
              value={order}
              InputLabelProps={{ shrink: true }}
            />
            <ValidationTextField
              label="Remisión"
              variant="outlined"
              placeholder="Remisión"
              sx={{ m: 2, width: "20ch" }}
              onChange={handelRem}
              value={rem}
              InputLabelProps={{ shrink: true }}
              inputProps={{ maxLength: 16, minLength:1}}

            />
             <ValidationTextField
              label="Nombre del solicitante"
              variant="outlined"
              placeholder="Nombre del solicitante"
              sx={{ m: 2, width: "50ch" }}
              onChange={handelsNombre}
              value={sNombre}
              InputLabelProps={{ shrink: true }}
              inputProps={{ maxLength: 80, minLength:1}}

            />
          </Box>


          <Box>
           
            <ValidationTextField
              label="Correo del solicitante"
              variant="outlined"
              placeholder="Correo del solicitante"
              sx={{ m: 2, width: "54ch" }}
              onChange={handelsCorreo}
              type="email"
              value={sCorreo}
              InputLabelProps={{ shrink: true }}
              inputProps={{ maxLength: 80, minLength:1}}

            />
            <ValidationTextField
              label="Correo interno de la empresa"
              variant="outlined"
              placeholder="Correo interno de la empresa"
              sx={{ m: 2, width: "54ch" }}
              type="email"
              value={cCorreo}
              InputLabelProps={{ shrink: true }}
              inputProps={{ maxLength: 80, minLength:1}}

            />
          </Box>
          <Box>

          <Button
            variant="contained"
            sx={{ m: 2, width: "54ch" }}
            className="ButtonModal2"
            onClick={async () => {
              const result = await CustomDialog(<Relacionar  RFC={"VME640813HF6"}></Relacionar>, {
                className: "ModalRelacionAdd",
                title: "Buscar Factura",
                isCanClose: false,
                showCloseIcon: true,

              });
              {
                handelAutoComp(result);
              }
            }}
          >
            Buscar Factura
          </Button>
          <Button
            variant="contained"
            sx={{ m: 2, width: "54ch" }}
            className="ButtonModalG"
            onClick={handleSubmitValid}
          >
            Visualizar Addenda
          </Button>
          </Box>
          <Box>
        <Button
            variant="contained"
            sx={{ m: 2, width: "54ch" }}
            className="ButtonModalA"
            onClick={handleSubmit2}
            disabled={actB}
          >
            Actualizar
          </Button>

          <Button
            variant="contained"
            sx={{ m: 2, width: "54ch" }}
            className="ButtonModal"
            disabled={actB}
            onClick={handleSubmit3}
          >
            Guardar Addenda
          </Button>
         
        </Box>
      </div>
      <div style={{display:actB===true?"none":""}}>
      <AddDocs vtable={vtable}></AddDocs> 
      </div>

     <div className="TableCOnsultaClientes TableConsultaAddenda">
        <TableContainer
          exportEx={true}
          columns={columns}
          data={dataTable}
          Gmodal={false}
          style={{ overflowx: 10 }}
          updateMyData={updateMyData}
          skipPageReset={skipPageReset}  
        />
      </div>
    </div>
  );
};

export default Addenda_PSV;
