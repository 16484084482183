import { useEffect, useState } from 'react';
import "./bot.css";
import SendIcon from '@mui/icons-material/Send';
import { HISTORY } from './tools/history';
import axios from "axios";
import TextField from "@mui/material/TextField";
import imgload from "../../imgComponents/iconos/loading.gif";
import { refresh_token } from '../../main_components/tokens/tokenrefresh';
import RefreshIcon from '@mui/icons-material/Refresh';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import img1 from "../../imgComponents/avatar.png";
import { general_categories, modulos, reports_categories } from './tools/moduls';
import CloseIcon from '@mui/icons-material/Close';

export const BOT_FC = (props) => {

const[data, setdata]=useState([
    {
      msj: "¡Hola! Bienvenido, me llamo Smit y estoy aquí para ayudarte con cualquier pregunta que tengas. No dudes en preguntar lo que necesites, estaré encantado de asistirte en lo que pueda. ¡Adelante!",
      hora: "",
      fecha: "",
      origen: "bot" 
    }
]);
  const[msj, setmsj]=useState("");
  
  const [select, setselect]=useState("");
  const [selectname, setselectname]=useState("");
  const [init, setinit]=useState(false);
  const [load, setload]=useState(false);
  const [loadmsj, setloadmsj]=useState("");
  const [smoduls, setsmoduls]=useState([]);
  const [icon_refresh, seticon_refresh]=useState(false);

  useEffect(() => {
    init === true ? create_threads() : console.log("");
  }, [init])


const handleKeyPress = (e) => {
  if (e.key === 'Enter') {
    if (!e.shiftKey) {  
      e.preventDefault();
      if(msj === "" || msj === null){
        console.log("No ha escrito mensaje");
      }else{
        add_message_thread();
      }
      
    }
  }
}
const create_threads = async() =>{
  const url = "https://gu9i687pl1.execute-api.us-east-1.amazonaws.com/prod/bot-openai"
  try{
    const rf_token = await refresh_token();
    if(rf_token){
      setTimeout(async() => {
        try{
          const d = await axios.post(url, {},{
            headers: {
              Authorization: sessionStorage.getItem("getIdTokenRefresh"),
              "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
              "Content-Type": "application/json",
            },
          });
          localStorage.setItem("id_thread_bot_ia", d?.data?.thread_id);
          console.log(localStorage.getItem("id_thread_bot_ia"))
        }catch(err){
          console.log(err);
        }
      }, 1000);
    }else{}
  }catch(err){
    console.log(err);
  }
}
const add_message_thread = async()=>{

setload(true);
setloadmsj("Enviando mensaje");

var data = {
  "message": msj,
  "thread": localStorage.getItem("id_thread_bot_ia")
}

const url = "https://gu9i687pl1.execute-api.us-east-1.amazonaws.com/prod/bot-openai"
  try{
    const rf_token = await refresh_token();
    if(rf_token){
      setTimeout(async() => {
        try{
          const d = await axios.put(url, data, {
            headers: {
              Authorization: sessionStorage.getItem("getIdTokenRefresh"),
              "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
              "Content-Type": "application/json",
            },
          });
          console.log(d);
          run_thead();
        }catch(err){
          console.log(err);
        }
      }, 1000);
    }else{}
  }catch(err){
    console.log(err);
  }

}
const run_thead = async()=>{
  setmsj("");
  setloadmsj("Analizando pregunta");
  // var error_system = "";
  //  if (localStorage.getItem("data_brands_bot_suport")=== "" || localStorage.getItem("data_brands_bot_suport") === null){
  //    error_system = ""
  //  }else{
  //    error_system = localStorage.getItem("data_brands_bot_suport");
  //  }



  // console.log(localStorage.getItem("data_brands_bot_suport"));
  
  var data = {
    "thread": localStorage.getItem("id_thread_bot_ia"),
    "select": select,
    // "error_system": localStorage.getItem("data_brands_bot_suport")
  }
  console.log(data);
  const url = "https://gu9i687pl1.execute-api.us-east-1.amazonaws.com/prod/bot-openai/run_execution";
  try{
    const rf_token = await refresh_token();
    if(rf_token){
      setTimeout(async() => {
        try{
          const d = await axios.post(url, data, {
            headers: {
              Authorization: sessionStorage.getItem("getIdTokenRefresh"),
              "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
              "Content-Type": "application/json",
            },
          });
          var run = d?.data;
          while (['queued', 'in_progress', 'cancelling'].includes(run.status)) {
                  await new Promise(resolve => setTimeout(resolve, 2000));
                  try{
                    const url2 = `https://gu9i687pl1.execute-api.us-east-1.amazonaws.com/prod/bot-openai/run_execution?thread=${run?.thread_id}&id_run=${run?.id}`;
                    const response2 = await axios.get(url2, 
                    {
                      headers: {
                        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
                        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
                        "Content-Type": "application/json",
                      }
                    });
                    run = response2.data;
                    
                  }catch(err){
                    console.log(err);
                    setload(false);
                    break;
                  }  
          }
          consult_threads();
        }catch(err){
          console.log(err);
        }
      }, 1000);
    }else{}
  }catch(err){
    console.log(err);
  }
} 
const consult_threads = async() =>{
  
  seticon_refresh(true);
  setloadmsj("Generando respuesta...");
  const id = localStorage.getItem("id_thread_bot_ia")
  const url = `https://gu9i687pl1.execute-api.us-east-1.amazonaws.com/prod/bot-openai?id=${id}`;
  try{
    const rf_token = await refresh_token();
    if(rf_token){
      setTimeout(async() => {
        try{
          const d = await axios.get(url, {
            headers: {
              Authorization: sessionStorage.getItem("getIdTokenRefresh"),
              "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
              "Content-Type": "application/json",
            },
          });
          setdata(d?.data);
          setload(false);
          seticon_refresh(false);
          setloadmsj("");
        }catch(err){
          console.log(err);
          seticon_refresh(false);
          setload(false);
          setloadmsj("");
        }
      }, 1500);
    }
  }catch(err){
    console.log(err);
  }
}

const handleselectmodul = (e)=>{  
  setselect("");
  e==="GENERAL"?setsmoduls(general_categories):
    e==="REPORTES"?setsmoduls(reports_categories):setsmoduls([]);
}

return (
    <>
      <div className='navbar-bot'>
        <div className='nav-bot-bot'>
          {
            init===true?
              <ArrowBackIcon onClick={()=>{setinit(false); setdata([])}}></ArrowBackIcon>:<></>
          }
          <p>Smit Bot</p>
          {
            init===true?
            <></>:
            <CloseIcon onClick={()=>props?.setopen(false)} className='close-modal-bot-conversation'></CloseIcon>
          }
          {
            init===true?
              icon_refresh===false?
                <RefreshIcon onClick={()=>consult_threads()}></RefreshIcon>:
              <></>:
            <></>
          }
        </div>
      </div>
      {
        init === false?
        <div className='cont-bot-opc'>
        
        <div className='bot-avatar-img'>  
          <img src={img1} alt="avatar"></img>

        </div>

        <div className='select-opc-bot'>
            <TextField
              select
              label="Modulo"
              //value={select}
              InputLabelProps={{shrink: true}}
              SelectProps={{native: true}}
              sx={{ m: 1.5, width: "100%"}}
              onChange={(e)=>handleselectmodul(e.target.value)}
            >
              {
                modulos?.map((item, index)=>(
                  <option key={index+2} value={item?.value}>{item?.label}</option>
                ))
              }
          </TextField>
        </div>
        <div className='select-opc-bot2'>
            <TextField
              select
              label="Submodulo"
              value={select}
              InputLabelProps={{shrink: true}}
              SelectProps={{native: true}}
              sx={{ m: 1.5, width: "100%"}}
              onChange={(event) => {
                setselect(event.target.value);
                setselectname(event.target.label);
              }}  
            >
             {
              smoduls?.map((item, index)=>(
                <option key={index} value={item?.value}>{item?.label}</option>
              ))
             }
          </TextField>
        </div>
        {
          select==="" || select === null || select === "000"?<></>:
          <div className='buton-action-bot'>
            <button onClick={()=>{setinit(true)}}>Iniciar</button>
          </div>
        }
        </div>
        :
        <>
        <div className='body-bot-container'>
          <HISTORY data={data} selectname={selectname} load={load} loadmsj={loadmsj}></HISTORY>
        </div>
        <div className='input-bot' style={load===true?{"display":"none"}:{"display":""}}>
          <input 
            className='input-bot-plac'
            placeholder='Escribe tú pregunta'
            onChange={(e)=>setmsj(e.target.value)}  
            value={msj}
            onKeyPress={(e)=>handleKeyPress(e)}
          ></input>

          <span className='span-input-icon'>
            <SendIcon 
              onClick={()=>add_message_thread()}></SendIcon>
          </span>
        </div>
        </>
      }
    </>
  )
}
