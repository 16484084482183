//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
import { CustomDialog, useDialog, Confirm } from "react-st-modal";
//COMPONENTES
import TableContainer from "../../../Table_Container_Tax";
import SubTable from "../../detail_expense/datail";
import CREATE_DETAIL_POLICY from "./tools/create_detail_policy";
import UPDATE_DETAIL_POLICY from "./tools/update_detail_policy";
import {
  get_expenses_groups_detail_policy,
  delete_expenses_groups_detail_policy,
} from "../../../../services/expenses/expenses";
import MODAL_TABLE from "../../../../main_components/modal/modal_Table";
import { refresh_token } from "../../../../main_components/tokens/tokenrefresh";
//ESTILOS
import "../../../expense.css";
//IMAGENES/ICONOS
import SAVE from "../../../../imgComponents/save.png";
import DELETE from "../../../../imgComponents/borrar.png";
import CREATE from "../../../../imgComponents/create.png";

import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../../../main_components/methods_v2/export";
import ACTIONS_SPECIALS_V2 from "./tools/actions_specials_v2";
import { Box } from "@mui/material";

const TableDetailGrupExpensePolicyDetail_v2 = ({
  idGrupExpense,
  codModificable,
}) => {
  console.log(codModificable);

  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [menssage, setMenssage] = useState("");
  const [equality, setEquality] = useState("");
  const [success_msg, setSuccess_msg] = useState("none");
  const [error_msg, setError_msg] = useState("none");
  const [Reconsulta, setReconsulta] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [reconsult, setreconsult] = useState(false);

  const handleSubmit = async (event) => {
    event?.preventDefault();

    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setMenssage("Consultando grupo de gastos...");
        console.log(idGrupExpense);
        setReconsulta(true);

        try {
          var dta = await get_expenses_groups_detail_policy(
            sessionStorage.getItem("IdGrupExpense"),
            sessionStorage.getItem("idPolicy")
          );
          const data = dta?.data;
          var datac = data.filter((d) => d.effect === "CARGO");
          var dataa = data.filter((d) => d.effect !== "CARGO");
          var data2 = datac.sort((a, b) =>
            a.id > b.id ? 1 : a.id < b.id ? -1 : 0
          );
          setData(data2.concat(dataa));
          setOpen(false);
          setMenssage("");
          const cargos = data.filter((item) => item.effect === "CARGO");
          const abonos = data.filter((item) => item.effect === "ABONO");
          const sumCargos = cargos
            .map((cargoSum) => parseFloat(cargoSum.ammount))
            .reduce((previous, current) => {
              return previous + current;
            }, 0);
          const sumAbonos = abonos
            .map((abonoSum) => parseFloat(abonoSum.ammount))
            .reduce((previous, current) => {
              return previous + current;
            }, 0);
          sessionStorage.setItem("totalPoliza", sumAbonos);
          if (sumCargos?.toFixed(2) - sumAbonos?.toFixed(2) === 0) {
            setEquality("Los datos cuadran");
            setSuccess_msg("");
            setError_msg("none");
          } else {
            setEquality("Los datos no cuadran");
            setError_msg("");
            setSuccess_msg("none");
          }
          setReconsulta(false);
        } catch (err) {
          setData([]);
          setOpen(false);
          setReconsulta(false);
          setMenssage("");
        }
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleReconsulta = (reconsultar) => {
    if (reconsultar === true) {
      handleSubmit();
    } else {
      console.log("no se reconsulto");
    }
  };

  const handle_delete_detail_policy = async (id) => {
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setOpen(true);
        try {
          await delete_expenses_groups_detail_policy(
            sessionStorage.getItem("IdGrupExpense"),
            sessionStorage.getItem("idPolicy"),
            id
          );
          setOpen(false);
          handleSubmit();
        } catch (err) {
          setOpen(false);
        }
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (idGrupExpense !== "") {
      handleSubmit();
    } else {
      setTimeout(() => {
        if (idGrupExpense !== "") {
          handleSubmit();
        } else {
          console.log("esta vacio");
        }
      }, 6000);
    }
  }, [idGrupExpense]);

  const columns = useMemo(
    () => [
      // {
      //   header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => {
      //     return (
      //       <img
      //         title="Crear Detalle"
      //         src={CREATE}
      //         alt=""
      //         onClick={async () => {
      //           const result = await CustomDialog(
      //             <CREATE_DETAIL_POLICY
      //               codIdPolicy={sessionStorage.getItem("idPolicy")}
      //             ></CREATE_DETAIL_POLICY>,
      //             {
      //               className: "modalTItle",
      //               title: "Crear Detale",
      //               showCloseIcon: true,
      //               isCanClose: false,
      //             }
      //           );
      //           // eslint-disable-next-line no-lone-blocks
      //           {
      //             handleReconsulta(result);
      //           }
      //         }}
      //       ></img>
      //     );
      //   },
      //   id: "Acciones",

      //   Cell: ({ valor, row }) => {
      //     return (
      //       <div>
      //         <img
      //           title="Editar Detalle"
      //           src={SAVE}
      //           alt=""
      //           onClick={async () => {
      //             const result = await CustomDialog(
      //               <UPDATE_DETAIL_POLICY
      //                 codIdPolicy={sessionStorage.getItem("idPolicy")}
      //                 id_detail={row.original.id}
      //                 codaccounts={row.original.accounting_account}
      //                 codconcept={row.original.concept}
      //                 codamount={row.original.ammount}
      //                 codeffect={row.original.effect}
      //                 codidConcept={row.original.id_account}
      //                 codIdDetail={row.original.id}
      //               ></UPDATE_DETAIL_POLICY>,
      //               {
      //                 title: "Editar detalle del gasto",
      //                 className: "modalTItle",
      //                 showCloseIcon: true,
      //                 isCanClose: false,
      //               }
      //             );
      //             // eslint-disable-next-line no-lone-blocks
      //             {
      //               handleReconsulta(result);
      //             }
      //           }}
      //           className="centerText, taxes"
      //         ></img>

      //         <img
      //           title="Eliminar Detalle"
      //           src={DELETE}
      //           alt=""
      //           className="centerText, taxes"
      //           onClick={async () => {
      //             const result = await Confirm(
      //               "¿Esta usted seguro de eliminar este detalle?",
      //               "Eliminar Detalle",
      //               "Si",
      //               "No"
      //             );
      //             if (result) {
      //               handle_delete_detail_policy(row.original.id);
      //             } else {
      //             }
      //           }}
      //         ></img>
      //       </div>
      //     );
      //   },
      // },

      {
        id: "gasto",
        header: "Gasto",
        accessorKey: "gasto",
        muiTableBodyCellProps: {
          align: 'center',
        },
        size:150
      },
      {
        id: "concept",
        header: "Concepto",
        accessorKey: "concept",
        muiTableBodyCellProps: {
          align: 'justify',
        },
      },
      {
        id: "accounting_account",
        header: "Cuenta",
        accessorKey: "accounting_account",
        muiTableBodyCellProps: {
          align: 'center',
        },
        size:150
      },
      {
        id: "ammount",
        header: "Importe",
        accessorKey: "ammount",
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '20ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()?.toLocaleString?.('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
        muiTableBodyCellProps: {
          align: 'right',
        },
      },
      {
        id: "effect",
        header: "Efecto",
        accessorKey: "effect",
        muiTableBodyCellProps: {
          align: 'center',
        },
        size:150
      },
      {
        id: "rfc",
        header: "RFC",
        accessorKey: "rfc",
        muiTableBodyCellProps: {
          align: 'center',
        },
        size:150
      },
      {
        id: "uuid",
        header: "UUID",
        accessorKey: "uuid",
        muiTableBodyCellProps: {
          align: 'justify',
        },
        size:400
      },
    ],
    []
  );


  const update_identifiers =
    [
      { gasto: 'string' },
      { concept: 'string' },
      { accounting_account: 'String' },
      { ammount: 'string' },
      { effect: 'string' },
      { rfc: 'string' },
      { uuid: 'string' }
    ];


  if (Reconsulta === false) {
    return (
      <div >
        <MODAL_TABLE open={true} modalT={open}></MODAL_TABLE>

        <div>
          <h3>DETALLES DE POLIZA</h3>
        </div>
        <br></br>

        <div className="TableConsultarTodo2">
          <div class="success-msg" style={{ display: success_msg }}>
            <h2>
              <i class="fa fa-check"></i>
              {equality}
            </h2>
          </div>
          <div class="error-msg" style={{ display: error_msg }}>
            <h2>
              <i class="fa fa-times-circle"></i>
              {equality}
            </h2>
          </div>
        </div>
        <div className="new_table_v2" style={{ "width": "95%", "margin": "0 auto" }}>
          <MaterialReactTable
            columns={columns}
            data={data}
            enableGrouping
            enablePinning
            enableFacetedValues
            enableStickyHeader
            enableStickyFooter
            enableRowPinning
            enableColumnFilterModes
            enableRowActions
            enableRowSelection
            getRowId={(row) => row?.id}
            onRowSelectionChange={setRowSelection}
            enableTooltips={false}
            localization={MRT_Localization_ES}
            state={{ rowSelection, isLoading: isLoading }}
            enableColumnResizing
            enableColumnPinning
            enableColumnOrdering
            enableExpandAll={false}
            initialState={{ showGlobalFilter: true }}
            muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
            muiSkeletonProps={{ animation: 'pulse', height: 28 }}
            rowVirtualizerInstanceRef
            rowVirtualizerOptions={{ overscan: 5 }}
            columnVirtualizerOptions={{ overscan: 2 }}
            muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
            positionToolbarAlertBanner="bottom"
            paginationDisplayMode='pages'
            rowPinningDisplayMode='sticky'
            layoutMode="grid"
            muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
            muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
            muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
            displayColumnDefOptions={{
              'mrt-row-pin': {
                Header: () => (
                  <div>
                    <img
                      style={{ "cursor": "pointer" }}
                      title="Crear Detalle"
                      src={CREATE}
                      alt=""
                      onClick={async () => {
                        await CustomDialog(
                          <CREATE_DETAIL_POLICY
                            codIdPolicy={sessionStorage.getItem("idPolicy")}
                            handleSubmit2={handleSubmit}
                          ></CREATE_DETAIL_POLICY>,
                          {
                            className: "modalTItle",
                            title: "Crear Detale",
                            showCloseIcon: true,
                            isCanClose: false,
                          }
                        );
                      }}
                    ></img>
                  </div>
                ),
                enableHiding: true,
              },
              'mrt-row-actions': {
                enableHiding: true,
              },
              'mrt-row-expand': {
                enableHiding: true,
              },
              'mrt-row-select': {
                enableHiding: true,
              }
            }}
            muiTableBodyRowDragHandleProps={({ table }) => ({
              onDragEnd: () => {
                const { draggingRow, hoveredRow } = table.getState();
                if (hoveredRow && draggingRow) {
                  data.splice(
                    hoveredRow.index,
                    0,
                    data.splice(draggingRow.index, 1)[0],
                  );
                  setData([...data]);
                }
              },
            })}
            muiTableBodyRowProps={
              ({ row, table }) => {
                const { density } = table.getState();
                return {
                  sx: {
                    height: row.getIsPinned()
                      ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                      }px`
                      : undefined,
                  },
                };
              }
            }
            renderRowActionMenuItems={({ row, closeMenu }) => [
              <ACTIONS_SPECIALS_V2
                closeMenu={closeMenu}
                setmodalT={setOpen}
                setmodalGeneral={false}
                row={row}
                handleSubmit={handleSubmit}
              //alertas={alertas}
              />
            ]}
            renderToolbarInternalActions={({ table }) => (
              <RENDER_TOOLBAR_INTERNAL_ACTIONS
                table={table}
                file_name={"Reporte de Gastos Agrupados"}
                update_identifiers={update_identifiers}
              />
            )
            }
          />

        </div>

        {/* <div className="TableReport DetailPolicy">
          <div style={{ overflowX: "auto" }}>
            <TableContainer
              exportEx={true}
              columns={columns}
              visible={false}
              data={data}
              style={{ overflowx: 10 }}
              renderRowSubComponent={(row) => {
                return (
                  <div style={{ padding: "20px" }}>
                    <SubTable verData={true} />
                  </div>
                );
              }}
            />
          </div>
        </div> */}
      </div>
    );
  } else {
    return <div className="loader"></div>;
  }
};

export default TableDetailGrupExpensePolicyDetail_v2;
