import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ValidationTextField } from '../../../main_components/lable/labe';
import Grid from '@mui/material/Grid';

function EditFormDialog({ open, onClose, data, onSave, isNew, original_data }) {
  const [formData, setFormData] = useState(data);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setFormData(data);
  }, [data, open]);

  useEffect(() => {
    // Limpiar errores cuando se cierra el diálogo
    if (!open) {
      setErrors({});
    }
  }, [open]);

  const handleChange = (e) => {

    const { name, value } = e.target;

    let processedValue = value;

    // Convertir a número si el campo es numérico
    if (['quantity', 'price', 'total', 'line'].includes(name)) {
      processedValue = Number(value);
    }

    setFormData(prev => ({ ...prev, [name]: processedValue }));

    // Limpiar errores cuando se cambia un campo
    setErrors(prev => ({ ...prev, [name]: '' }));

  };



  const handleSubmit = () => {
    // Validaciones
    const validationErrors = {};

    if (!formData.line) {
      validationErrors.line = 'El número de partida es obligatorio';
    } else if (formData?.line > original_data?.length+1 ) {
      validationErrors.line = 'El número de partida no puede ser mayor al numero de detalles + 1';
    }


    if (!formData.quantity) {
      validationErrors.quantity = 'La cantidad es obligatoria';
    } else if (isNaN(formData.quantity)) {
      validationErrors.quantity = 'La cantidad debe ser numérica';
    }

    if (!formData.price) {
      validationErrors.price = 'El precio unitario es obligatorio';
    } else if (isNaN(formData.price)) {
      validationErrors.price = 'El precio unitario debe ser numérico';
    }

    if (!formData.total) {
      validationErrors.total = 'El monto total es obligatorio';
    } else if (isNaN(formData.total)) {
      validationErrors.total = 'El monto total debe ser numérico';
    }
    if (!formData.description) {
      validationErrors.description = 'El número de parte es obligatorio';
    }


    if (Object.keys(validationErrors).length === 0) {
      onSave(formData, isNew);
      onClose();
    } else {
      setErrors(validationErrors);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{isNew ? 'Agregar Detalle' : 'Editar Detalle'}</DialogTitle>
      <DialogContent style={{ zIndex: 2000 }}>
        <Grid container spacing={2}>
          <Grid item xs={4} style={{ paddingTop: "22px" }}>
            <ValidationTextField
              label="Número de Partida"
              required
              placeholder="Número de Partida"
              fullWidth
              variant="outlined"
              name="line"
              value={formData?.line || ''}
              onChange={handleChange}
              error={!!errors.line}
              helperText={errors.line}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={8} style={{ paddingTop: "22px" }}>
            <ValidationTextField
              label="Número de Parte"
              required
              placeholder="Número de Parte"
              fullWidth
              variant="outlined"
              name="description"
              value={formData?.description || ''}
              onChange={handleChange}
              error={!!errors.description}
              helperText={errors.description}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <ValidationTextField
              label="Cantidad"
              required
              placeholder="Cantidad"
              type="number"
              fullWidth
              variant="outlined"
              name="quantity"
              value={formData?.quantity || ''}
              onChange={handleChange}
              error={!!errors.quantity}
              helperText={errors.quantity}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <ValidationTextField
              label="Precio Unitario"
              required
              placeholder="Precio Unitario"
              type="number"
              fullWidth
              variant="outlined"
              name="price"
              value={formData?.price || ''}
              onChange={handleChange}
              error={!!errors.price}
              helperText={errors.price}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <ValidationTextField
              label="Monto Total"
              required
              placeholder="Monto Total"
              type="number"
              fullWidth
              variant="outlined"
              name="total"
              value={formData?.total || ''}
              onChange={handleChange}
              error={!!errors.total}
              helperText={errors.total}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          sx={{ m: 1, width: "100%" }}
          variant="contained"
          style={{ background: "red" }}
        >
          CANCELAR
        </Button>
        <Button
          onClick={handleSubmit}
          sx={{ m: 1, width: "100%" }}
          variant="contained"
          style={{ background: "green" }}
        >
          {isNew ? 'CREAR' : 'ACTUALIZAR'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditFormDialog;
