//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import { CustomDialog, useDialog, Confirm } from "react-st-modal";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
//COMPONENTES
import { SelectColumnFilter } from "../../filters";
import Set_tax from "./Set_Taxes";
import SubTable from "./Get_Configure_Tax";
//ESTILOS
import "../../../components_cfdi/estilo.css";
import "./TableTax.css";
import "../../../components_cfdi/modal.css";
//IMAGENES/ICONOS
import SAVE from "../../../imgComponents/save.png";
import Spinner from "../../../imgComponents/S.gif";
import { catchErrorModul } from "../../../main_components/catchErr/catchError";
import { refresh_token  } from "../../../main_components/tokens/tokenrefresh";
import { get_taxes_configs, put_taxes_configs } from "../../../services/invoices/invoice";
import TableContainer from "../../../main_components/Table/TableContainer";
import { BREADCRUMBS } from "../../../main_components/pagination/breadcrumbs";

const style2 = {position: 'absolute' ,top: '50%',left: '50%',transform: 'translate(-50%, -50%)',width: '100%',height: '100%', bgcolor: 'background.paper',border: '2px solid #000',boxShadow: 24,p: 4,};

const TableConfigureTaxes = () => {
  const [mensajeDescarga, setmensajeDescarga] = useState("");
  const [data, setData] = useState([]);
  const [cancel, setCancel] = useState(null);
  const [open, setOpen] = useState(true);
  const [menssage, setMenssage] = useState(true);

  

  const handleSubmit = async (event) => {
    event?.preventDefault();
    setOpen(true);
    setMenssage("Consultando configuraciones");
    try{
      var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try{
            const d = await get_taxes_configs();
            const data = d === null ? []: d.data;
            setData(data);
            setOpen(false);
            setMenssage("");
          }catch(err){
            console.log("error", err);
            setData([]);
            setMenssage("")
          }
        }, 1000);
      }else{}
    }catch(err){
      console.log(err);
    }
    /*
    axios
      .get(`https://inxmul26u8.execute-api.us-east-1.amazonaws.com/od/taxes/configs?`, {headers: {Authorization: sessionStorage.getItem("getIdTokenRefresh"),"access-token": sessionStorage.getItem("getAccesTokenRefresh"),"Content-Type": "application/json"},})
      .then((response) => {
        const data = response.data;
        setData(data);
        setOpen(false);
        setMenssage("");
      })
      .catch((err) => {
        console.log("error", err);
        setData([]);
        setMenssage("")
      });
      */
  };

  
  const handleSubmit2 = () => {
    window.location="/consult_taxes";
  } 



  useEffect(() => {
    handleSubmit();
  }, []);


  const columns = useMemo(
    () => [
      
      {
        Header: "Acciones",
        id: "Acciones",

        Cell: ({ valor, row }) => {

          const setDefault = async()=> {
            setMenssage("Estableciendo configuracion por default...")
            setOpen(true);

            var data = {};
            try{
              var rf_token = await refresh_token();
              if(rf_token===true){
                setTimeout(async() => {
                  try{
                    const d = await put_taxes_configs(row.original.id, data);
                    alert("Exito, Impuesto establecido como default")
                    setOpen(false);
                    window.location="/consult_configure_taxes"
                    setMenssage("")
                  }catch(err){
                    console.log("error", err);
                    catchErrorModul("Impuestos", JSON.stringify(data), JSON.stringify(err?.response===undefined?"Token expirado":err?.response));
                    setOpen(false);
                    setMenssage("")
                    console.log()
                    alert("Error.,"+ err?.response?.data?.errorMessage); 
                  }
                }, 1000);
              }else{}
            }catch(err){

            }



            /*
            refresh_token ();
            let axiosConfig = {
              headers: {
                Authorization: sessionStorage.getItem("getIdTokenRefresh"),
                "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
                "Content-Type": "application/json",
              },
            };
            axios
                .put(`https://inxmul26u8.execute-api.us-east-1.amazonaws.com/od/taxes/10/config/${row.original.id}`,
                    data,
                    axiosConfig
                  )
                  .then((response) => {
                    alert("Exito, Impuesto establecido como default")
                    setOpen(false);
                    window.location="/consult_configure_taxes"
                    setMenssage("")
                  })
                  .catch((err) => {
                    console.log("error", err);
                    catchErrorModul("Impuestos", JSON.stringify(data), JSON.stringify(err?.response===undefined?"Token expirado":err?.response));
 
                    setOpen(false);
                    setMenssage("")
                    console.log()
                    alert("Error.,"+ err?.response?.data?.errorMessage); 
                  });
                  */

          }

          return (
            <div>
              <img
                title="Asignar por default al impuesto"
                src={SAVE}
                onClick={async () => {
                  const result = await Confirm(
                    "¿Esta usted seguro de establecer como default este impuesto?",
                    "Establecer por default",
                    "Si",
                    "No"
                  );
                  if (result) {
                    setDefault();
                  } else {
                  }
                }}

                
              ></img>   
            </div>
          );
        },
      },

      {
        Header: "Default",
        accessor: (d) => {
          return d.default===true?"Si":"NO"
            
        },
        
      },
      {
        Header: "Tarifa",
        accessor: "rate",
      },
      {
        Header: "Tipo",
        accessor: "type",
      },
      {
        Header: "Tipo Factor",
        accessor: "c_tipo_factor",
      },

      {
        Header: "Código",
        accessor: "c_impuesto",
      },
      {
        Header: "Impuesto",
        accessor: "tax_name",
      },
    
    ],
    []
  );

  return (
    <div >
      <div>
           <Modal
             open={open}
             onClose={open}
             aria-labelledby="modal-modal-title"
             aria-describedby="modal-modal-description"
             >
               <Box sx={style2} >
                 <div className="centermodaldescarga">
                   <Typography id="modal-modal-title"    variant="h6" component="h2">
                     <img src={Spinner}></img>
                   </Typography>
                   <Typography id="modal-modal-description"  sx={{ mt: 2 }}>  
                     {menssage}
                   </Typography>
                   </div>
                 </Box>
            </Modal>
       </div>
       <BREADCRUMBS niveles={
                [
                  {label: "CONSULTAR IMPUESTOS", path: "consult_taxes"},
                  {label: "CONFIGURACIONES DE IMPUESTOS", path: null}
                ]
              }
      ></BREADCRUMBS>

      <div className="TableTaxes">
        <div >
              <TableContainer
                columns={columns}
                data={data}
                style={{ overflowx: 10 }}
              />
          </div>
      
      </div>
    </div>
  );
};

export default TableConfigureTaxes;
