//PAQUETERIAS
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import React, { useState, useEffect, useRef } from 'react';

//COMPONENTES
import AnimatedText from "./animated_text";
//ESTILOS
import "../../components_cfdi/estilo.css";
import "../../components_cfdi/modal.css";
import "../../components_cfdi/table.css";
import "../../components_buys/expense.css";
import "./modal.css";
import GIF from "./img.gif"
//IMAGENES
import Spinner from "../../imgComponents/S.gif";

const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  opacity: "1 !important",
};

function MODAL_FORECAST({ open, message, message2, modalGeneral, modalT, progress }) {
  const [showMessage, setShowMessage] = useState(true); // Estado para controlar la visibilidad del mensaje

  useEffect(() => {
    // Cada vez que el mensaje cambie, ejecuta la transición
    setShowMessage(false); // Primero oculta el mensaje
    const timer = setTimeout(() => setShowMessage(true), 500); // Luego lo muestra de nuevo después de la transición
    return () => clearTimeout(timer);
  }, [message]); // Dependencia del efecto: el mensaje
  console.log(progress)

  return (
    <div>
      <div style={{ display: modalGeneral === true ? "" : "none" }}>
        <Modal
          open={modalGeneral}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style2}>
            <div className="modal_forecast">
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
              >
                <img className="" src={GIF} style={{ width: '600px', margin: '0 auto' }}></img>
                <div style={{ width: '50%', backgroundColor: '#ddd', margin: '0 auto' }}>
                  <div style={{ height: '24px', width: `${progress}%`, backgroundColor: 'blue' }}>
                    <Typography style={{color:"white",fontWeight:"bold"}} variant="subtitle1"> {Math.round(progress)+"%" }</Typography>
                  </div>
                </div>
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}  >
                <AnimatedText message={message} /> {/* Usa el componente AnimatedText para el mensaje */}
              </Typography>
            </div>
          </Box>
        </Modal>
      </div>

      <div
        style={{
          display:
            modalT === true ? (modalGeneral === false ? "" : "none") : "none",
        }}
      >
        <Modal
          open={modalT}
          className="modal2Carga"
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style1}>
            <div >
              <Typography
                id="modal-modal-title"

                variant="h6"
                component="h2"
              ></Typography>
              <Typography
                id="modal-modal-description"

                sx={{ mt: 2 }}
              >
                {message2}
              </Typography>
            </div>
          </Box>
        </Modal>
      </div>
    </div>
  );
}

export default MODAL_FORECAST;
