//PAQUETERIAS
import React, { useEffect, useState, useMemo, useRef} from "react";
import axios from "axios";
import { StickyTable, Row, Cell } from "react-sticky-table";
import LoadingScreen from "react-loading-screen";
import {CustomDialog, Confirm} from "react-st-modal";
import NotificationSystem from 'react-notification-system';
import Modal_Table from "../../main_components/modal/modal_Table";
//COMPONENTES

import AlertResponse from "../../main_components/alerts/alertResponse";
import TableContainer from "../../componentsUpdateBusnes/TableContainerBusnes";
//ESTILOS
import "../../componentsCFDI/estilo.css";
import "../../componentsCFDI/modal.css";
//IMAGENES/ICONOS
import Spinner from "../../componentsPago/S.gif";
import Delete from "../../imgComponents/borrar.png";
import CREATE from "../../imgComponents/create.png";
import Edit from "../../imgComponents/save.png";
import { refresh_token } from "../../main_components/tokens/tokenrefresh";
import EditExpenseConcept from "./EditExpenseConcept";
import CreateConceptC from "./CreateConceptC";
import EditConceptC from "./EditConceptC";
import { SelectColumnFilter } from "../../componentsCFDI/filters";
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";

const TableAssignedConcepts= () => {
  console.log(sessionStorage.getItem("id_catalogo"));
  console.log(sessionStorage.getItem("nombre_catalogo"));

  const notificationSystemRef = useRef();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [MensajeBusqueda, setMensajeBusqueda] = useState("");
  const [open, setopen]=useState(false);
  var entrada=0;
  const [modalT, setmodalT] = useState(false);
  const [menssage, setmenssage] = useState("");
  const [modalGeneral, setmodalGeneral] = useState(false);
  const URL_BASE="https://7t0suvusg7.execute-api.us-east-1.amazonaws.com";

  


  useEffect(()=>{
    handleSubmit();
  },[]);

  
function alertas(msj,status){

  if(status==true){
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={true}></AlertResponse>,
      level: 'success',
      position: 'br', 
      autoDismiss: 10, 
    });  
  }else{
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={false}></AlertResponse>,
      level: 'error',
      position: 'br', 
      autoDismiss: 60, 
    });
  }  
}
const reconsultar=(n)=>{
  if(n==true){
    setmodalT(true);  
  }else{
    setmodalT(false);
  } 
}


//proceso de consultar el catalogo de conceptos de Gastos
const handleSubmit = () => {
    console.log("entra a consultar los conceptos asignados al catalogo");
    refresh_token();

    if(entrada==0){
      entrada=entrada+1;
      setmenssage("Consultando Conceptos de Catálogo...");
      setmodalGeneral(true);
      setmodalT(true); 
    }else{
      setmodalT(true);
    }

    setopen(true);
    setData([]);
    

    setTimeout(() => {
      axios
        .get(
          `${URL_BASE}/od/expense-concepts/${sessionStorage.getItem("id_catalogo")}/expense-concepts-list`,
          {
            headers: {
              Authorization: sessionStorage.getItem("getIdTokenRefresh"),
              "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          const data = response.data.concepts;
          setData(data);
          setopen(false);
          setmodalGeneral(false);
          setmodalT(false);
         })
        .catch((err) => {
          console.log("error", err);
          setData([]);
          setopen(false);
          setmodalGeneral(false);
          setmodalT(false);
          });
    }, 2000);
};

const columns = useMemo(
  () => [
    {
      Header:()=>(
        <div>
          <img
            id="crearRol"
            src={CREATE}
            onClick={async () => {
              const result = await CustomDialog(               
             <div>
                <CreateConceptC handleSubmit={handleSubmit} alertas={alertas} reconsultar={reconsultar}></CreateConceptC>
             </div>,
           {
             className: "modalTItle",
             title:"Agregar Concepto",
             showCloseIcon: true,
             isCanClose:false,
           }
       );
     }}
            title="Agregar Concepto"
            className="cancel"
          ></img>
        </div>
      ),
      
      id: "Acciones",
      Cell: ({ valor, row }) => {

      function DeleteSector(){  
      
      setmodalT(true);
      refresh_token();
      setData([]);
      setopen(true);
    
      setTimeout(() => {
        var url = `${URL_BASE}/od/expense-concepts/${sessionStorage.getItem("id_catalogo")}/expense-concepts-list/${row.original.id}`;
        var axiosConfig = {
          headers: {
            Authorization: sessionStorage.getItem("getIdTokenRefresh"),
            "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
            "Content-Type": "application/json",
          },
        }
        axios
        .delete(url,axiosConfig)
        .then((response) => {
          console.log(response);
          alertas("Concepto Eliminado con éxito",true);
          handleSubmit();
        })
        .catch((err) => {
          console.log("error", err);
          setopen(false);
          alertas("Error, no se pudo eliminar el concepto", false);
          handleSubmit();
        });
       }, 2000);
      }
        return (
          <div>
            <img
              src={Delete}
              onClick={async() =>{
                  const result = await Confirm('¿Esta usted seguro de eliminar el concepto?','Eliminar Concepto','Si','No');
                  if(result){
                    DeleteSector()
                    }
                  }            
                }
              className="centerText, cancel"
              title="Eliminar Concepto"
            ></img>
            
            <img
              src={Edit}
              onClick={
                async () => {
                  const result = await CustomDialog(               
                 <div>
                    <EditConceptC handleSubmit={handleSubmit} row={row} reconsultar={reconsultar} alertas={alertas}></EditConceptC>
                 </div>,
               {
                 className: "modalTItle",
                 title:"Editar Concepto",
                 showCloseIcon: true,
                 isCanClose:false,
               }
           ); 
         }
        }
              className="centerText, cancel"
              title="Editar Concepto"
            ></img>
          </div>
        );
      },
    },
    {
      Header: "Concepto",
      accessor:"concept"
    },
    {
      Filter: SelectColumnFilter,
      Header:"Estado",
      accessor:(d)=>{
        return d.enable==true?"Habilitado":"Deshabilitado"
      }
    },
    {
      Header: "Cuenta",
      accessor: "account"
    },
    {
      Header:"Fecha de Registro",
      accessor:"date_register"
    }
    
    ],
    []
);
  return (
    <>


<BREADCRUMBS niveles={
                [
                  {label: "CONSULTAR CATÁLOGO DE CONCEPTOS DE GASTO", path: "/consult_expense_concept"},
                  {label: `CATÁLOGO DE CONCEPTOS: ${sessionStorage.getItem("nombre_catalogo")}`, path: null},

                ]
              }
      ></BREADCRUMBS>

<div className="TableCatalogoGastosConceptos">
<NotificationSystem  ref={notificationSystemRef} ></NotificationSystem>
<Modal_Table
    open={true}
    message={menssage}
    modalGeneral={modalGeneral}
    modalT={modalT}
  ></Modal_Table>
  <div className="">         
    
          <>
         
          <StickyTable>
            <TableContainer
              paginado={"Catálogo de Gastos"}
              consultas={"Catálogos de Gastos Consultados"}
              exportar={true}
              columns={columns}
              data={data}
              style={{ overflowx: 10 }}
              Gmodal={open}
            />
          </StickyTable>
          </>   
           
      
          </div>
        </div>
    </>
  );
}

export default TableAssignedConcepts;
