//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import { StickyTable, Row, Cell } from "react-sticky-table";
import { CustomDialog, useDialog, Confirm } from "react-st-modal";
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Box from "@mui/material/Box";
//COMPONENTES
import SubTableContainer from "./SubTableContainer";
import Put_Tax_Concept from "./Put_Tax_Concept";

//ESTILOS
import "../../estilo.css";
import "../../modal.css";
import "../../table.css";

//IMAGENES
import DELETE from "../../../imgComponents/borrar.png";
import Spinner from "../../../imgComponents/S.gif";
import SAVE from "../../../imgComponents/save.png";
import { refresh_token } from "../../../main_components/tokens/tokenrefresh";
import { delete_concepts_taxes, get_concepts_taxes } from "../../../services/invoices/invoice";



const style2 = {position: 'absolute' ,top: '50%',left: '50%',transform: 'translate(-50%, -50%)',width: '100%',height: '100%', bgcolor: 'background.paper',border: '2px solid #000',boxShadow: 24,p: 4,};



function SubTable ({recarga}) {

  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);

  const handleSubmit = async(event) => {
    event?.preventDefault();
    try{
      var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try{
            const d = await get_concepts_taxes(sessionStorage.getItem("id_concept_subtable"));
            const data = d===null?[]:d.data;
            setData(data);
          }catch(err){
            setData([]);
          }
        }, 1000);
      }else{}
    }catch(err){
      console.log(err);
    }
    /*
    axios
      .get(`https://inxmul26u8.execute-api.us-east-1.amazonaws.com/od/concepts/${sessionStorage.getItem("id_concept_subtable")}/taxes`,{headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const data = response.data;
        setData(data);
      })
      .catch((err) => {
        setData([]);
      });*/
  };
  

  useEffect(() => {
    handleSubmit();
    if(recarga===true){
      handleSubmit();
    }
  }, []);



  const columns = useMemo(
    () => [

      {
        Header: "Acciones",
        id: "Acciones",

        Cell: ({ valor, row }) => {
          function Redireccion() { 
          }
          const DeleteTax = async () => {
            setOpen(true);
            try{
              var rf_token=await refresh_token();
              if(rf_token===true){
                setTimeout(async() => {
                  try{
                    const d = await delete_concepts_taxes(sessionStorage.getItem("id_concept_subtable"),row.original.id);
                    alert("Impuesto eliminado correctamente")
                    setOpen(false);
                    window.location="/consult_concept"
                  }catch(err){
                    console.log("error", err);
                    setOpen(false);
                  }
                }, 1000);
              }else{}
            }catch(err){  
              console.log(err);
            }

            /*
            axios
              .delete(`https://inxmul26u8.execute-api.us-east-1.amazonaws.com/od/concepts/${sessionStorage.getItem("id_concept_subtable")}/taxes/${row.original.id}?`, {
                headers: {
                  Authorization: sessionStorage.getItem("getIdTokenRefresh"),
                  "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
                  "Content-Type": "application/json",
                },
              })
              .then((response) => {
                alert("Impuesto eliminado correctamente")
                setOpen(false);
                window.location="/consult_concept"
              })
              .catch((err) => {
                console.log("error", err);
                setOpen(false);
              });
            */
          }

          return (
            <div>

                <img
                title="Editar impuesto"
                src={SAVE}
                onClick={async () => {
                  
                  const result = await CustomDialog(
                      <Put_Tax_Concept 
                      base={row.original.base}
                      tasa_o_cuotan={row.original.tasa_o_cuota}
                      tipo_factorn={row.original.tipo_factor}
                      impueston={row.original.impuesto}
                      person_typen={row.original.person_type}
                      tipon={row.original.tipo}
                      id_Impueston={row.original.id}
                      id_tax_config_fk={row.original.id_tax_config_fk}
        
                      ></Put_Tax_Concept>,
                    {
                      className: "modalTItle",
                      title:
                        "Editar impuesto del concepto",
                      showCloseIcon: true,
                      isCanClose:false,
                    }
                  );
                
              }}
                className="centerText, taxes"
              ></img>
              
              <img
                title="Eliminar impuesto"
                src={DELETE}
                className="centerText, taxes"
                onClick={async () => {
                  const result = await Confirm(
                    "¿Esta usted seguro de eliminar este impuesto del concepto?",
                    "Eliminar Impuesto",
                    "Si",
                    "No"
                  );
                  if (result) {
                    DeleteTax();
                  } else {
                  }
                }}
              ></img>
            </div>
          );
        },
      },

      {
        Header: "Base",
        accessor: "base",

      },
      {
        Header: "Impuesto",
        accessor: "impuesto",
      },
      {
        Header: "Tipo",
        accessor: "tipo",
      },
      {
        Header: "Tipo factor",
        accessor: "tipo_factor",
      },

      {
        Header: "Tipo persona",
        accessor: "person_type",
      },
      
      {
        Header: "Tasa/Cuota",
        accessor: "tasa_o_cuota",
      },

      {
        Header: "Importe",
        accessor: "importe",
      },

    ],
    []
  );



  return (

    <div>
      <div>
           <Modal
             open={open}
             onClose={open}
             aria-labelledby="modal-modal-title"
             aria-describedby="modal-modal-description"
             >
               <Box sx={style2} >
                 <div className="centermodaldescarga">
                   <Typography id="modal-modal-title"    variant="h6" component="h2">
                     <img src={Spinner}></img>
                   </Typography>
                   <Typography id="modal-modal-description"  sx={{ mt: 2 }}>  
                     {"Eliminando impuestos..."}
                   </Typography>
                   </div>
                 </Box>
            </Modal>
       </div>

        <div className="TableConsultarTodo2">
          
                <StickyTable>
          

                  <SubTableContainer
                    columns={ columns}
                    data={data}
                    style={{ overflowx: 10 }}
                  />

                  
                </StickyTable>
        </div>
    </div>

  );
};

export default SubTable;
