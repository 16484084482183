import TableContainer from "../../main_components/Table/TableContainer";
import { StickyTable } from "react-sticky-table";
import { useEffect, useMemo, useRef, useState } from "react";
import './buscarcasos.css'
//import '../CreateCase/createcase.css'
import '../comments/comments.css'
import CREATE from "../../imgComponents/create.png";
import { CustomDialog, useDialog } from "react-st-modal";
import SupportTicketModal from "../comments/Comments";
import { get_supports } from "../../services/supports/supports";
import SupportCaseModal from "../createCase/CreateCase";
import AlertResponse from "../../main_components/alerts/alertResponse";
import NotificationSystem from 'react-notification-system';
import { SelectColumnFilter } from "../../components_cfdi/filters";
import { CircularProgress, FormControlLabel, Icon, TextField } from "@material-ui/core";
import { MaterialUISwitch } from "../../main_components/material_switch/switch";
import { COLUMS_SHOW } from "../../main_components/Table/ColumsShow";
import { SpinnerComments } from "../comments/SpinnerComments";
import { Description, Forum, Replay } from "@material-ui/icons";
import { refresh_token } from "../../main_components/tokens/tokenrefresh";
import { DESCRIPTION_CASE } from "./descriptionCases/Description";
import dataprueba from "./dataprueba.json";
import "../../main_components/menu/tour.css";
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";

export const ViewCases = () => {
  const [dataoriginal, setDataoriginal] = useState([])
  const [datafilter, setDatafilter] = useState([])
  const [opencreate, setOpencreate] = useState(false);
  const [statusticket, setStatusTicket] = useState("OPEN");
  const [statusticket2, setStatusTicket2]=useState("PROCESS");
  const [isloading, setIsloading ] = useState(false);
  const notificationSystemRef = useRef();
  const [viewuid, setviewuid]=useState(localStorage.getItem("viewuid") === null? true: localStorage.getItem("viewuid") === "true"? true:false)
  const [filtersColumsView, setfiltersColumsView] = useState(true);
  const [viewfolio, setviewfolio] = useState(localStorage.getItem("viewfolio") === null? true: localStorage.getItem("viewfolio") === "true"? true:false);
  const [viewmodulo, setviewmodulo] = useState(localStorage.getItem("viewmodulo") === null? true: localStorage.getItem("viewmodulo") === "true"? true:false);
  const [viewsubmodule, setviewsubmodule] = useState(localStorage.getItem("viewsubmodule") === null? true: localStorage.getItem("viewsubmodule") === "true"? true:false);
  const [viewseveridad, setviewseveridad] = useState(localStorage.getItem("viewseveridad") === null? true: localStorage.getItem("viewseveridad") === "true"? true:false);
  const [viewtipo, setviewtipo] = useState(localStorage.getItem("viewtipo") === null? true: localStorage.getItem("viewtipo") === "true"? true:false);
  const [viewestado, setviewestado] = useState(localStorage.getItem("viewestado") === null? true: localStorage.getItem("viewestado") === "true"? true:false);
  const [viewcompany_rfc, setviewcompany_rfc] = useState(localStorage.getItem("viewcompany_rfc") === null? true: localStorage.getItem("viewcompany_rfc") === "true"? true:false);
  const [viewemail, setviewemail] = useState(localStorage.getItem("viewemail") === null? true: localStorage.getItem("viewemail") === "true"? true:false);
  const [viewasunto, setviewasunto] = useState(localStorage.getItem("viewasunto") === null? true: localStorage.getItem("viewasunto") === "true"? true:false );
  const [viewfecha, setviewfecha] = useState(localStorage.getItem("viewfecha") === null? true: localStorage.getItem("viewfecha") === "true"? true:false );
  const [refreshing, setRefreshing] = useState(false);
  const dialog = useDialog();
  console.log(dataprueba);
  


  const handleCloseCreate = () => {
    setOpencreate(false);
  };
  const handleFilterData = (event) => {     
    if(event.target.value==="CLOSE"){
      setStatusTicket(event.target.value);
      setStatusTicket2(event.target.value);
    }else{
      setStatusTicket(event.target.value);
      setStatusTicket2("PROCESS");
    }
  }

  function alertas(msj,status){
    if(status===true){
      notificationSystemRef.current.addNotification({
        message:<AlertResponse msj={msj} view={true}></AlertResponse>,
        level: 'success',
        position: 'br', 
        autoDismiss: 20, 
      });  
      handleLoad()
    }else{
      notificationSystemRef.current.addNotification({
        message:<AlertResponse msj={msj} view={false}></AlertResponse>,
        level: 'error',
        position: 'br', 
        autoDismiss: 20, 
      });
    }  
  }

  const handleLoad = async () => {    
       
    try {      
      const refresh = await refresh_token();
      if(refresh){
        console.log(refresh)
        setIsloading(true)
        setDataoriginal([]);
        try {
          let data = await get_supports();
          console.log(data)
          setDatafilter(data?.data?.filter(val => val.status === statusticket || val.status === statusticket2))
          setDataoriginal(data.data); 
        } catch (err) {
          setDataoriginal([]);
        } 
      }
    }
    catch(error){
      console.log("Error al refrescar", error)
    }
    finally{
      console.log("finally")
      }
  };

    const columns = useMemo(
        () => [
            {
                id: "expander",
                Header: () => {
                  return(
                    <div className="adjust">
                      <Icon onClick={ async() => { 
                        setRefreshing(true);
                        const handle = await handleLoad()
                       
                          setRefreshing(false)
                          console.log(handle)
                        
                      }}>
                    
                        <Replay 
                        titleAccess="REFRESCAR TABLA"
                        className={refreshing ? 'rotating' : ""}
                        style={{
                          cursor: "pointer", 
                          color: "#E8F125", 
                          filter: "drop-shadow(1px 1px 0px #000000)"}}
                        
                      />
                      </Icon>
                        
                      <br/>
                      
                      <img 
                        className="paso1tour"
                        id="paso1tour"
                        width="max-content"
                        style={{cursor: "pointer"}}
                        src={CREATE} 
                        alt="Crear un nuevo caso"
                        title="CREAR NUEVO CASO"
                        onClick={    async () => {

                                 const result = await CustomDialog(
                                  <SupportCaseModal isOpen={opencreate} onClose={handleCloseCreate} alertas={alertas}/>,
                                    {
                                      title: "CREAR NUEVO CASO DE SOPORTE",
                                      className: 'modal-create-case',
                                      showCloseIcon: true,
                                      isCanClose: false,
                                    } 
                                  ); 
                                }}
                             />
                    </div>
                  )
                } ,
                Cell: ({row}) => {
                    return(
                      <>
                        <Forum  
                          className="ticket_seg_tour"
                          id="ticket_seg_tour"
                          titleAccess="VER SEGUIMIENTO"
                          fontSize= 'medium' 
                          style={{ cursor: "pointer", color: "#0042FE", filter: "drop-shadow(1px 1px 0px #000000)"}} 
                          onClick={                          
                            async () => {         
                              
                              const result = await CustomDialog(
                              <SupportTicketModal 
                              key={1} 
                              handleLoad={handleLoad} 
                              alertas={alertas} {...row.original} 
                              
                              />,

                                {
                                className: "support-ticket-modal",
                                  title: `TICKET: ${row?.original?.ticket}`,
                                  showCloseIcon: true,                                
                                } 
                              );
                            }
                          }/>  
                          <Description  
                          titleAccess="VER DESCRIPCIÓN"
                          fontSize= 'medium'                       
                          style={{ cursor: "pointer", color: "#E5A812", filter: "drop-shadow(1px 1px 0px #000000)"}} 
                          onClick={                          
                            async () => {   
                              
                              const result = await CustomDialog(
                              <DESCRIPTION_CASE 
                              user_name={row?.original?.user_name}
                                subject={row?.original?.subject}
                                description={row?.original?.description}
                              />,
                                {
                                  className: "description-container",
                                  title: `DESCRIPCIÓN DEL CASO: ${row?.original?.ticket}`,
                                  showCloseIcon: true,                                
                                } 
                              );
                            }
                          }
                          />
                      </>
                    )
                },
          },
          {
            Header:"Fecha",
            show: viewfecha,
            id: "fecha",
            accessor:"date",
            Cell: ({value})=>{
              const [datePart, timePart] = value.split('.')[0].split(' ');
            return <>{datePart}<br />{timePart}</>;
            },
          },        
          {
            Header:"Asunto",
            accessor:"subject",
            Cell:({value}) => {
            return (
              <div className="toLeft">
                {value}
              </div>)
            },
            show: viewasunto,
            id: "asunto" 
          },
          {
            Filter: SelectColumnFilter,
            Header:"Severidad",
            accessor:"severity",
            show: viewseveridad,
            id: "severidad" 
          },
          {
            Header:"Folio",
            accessor:"folio",
            show: viewfolio,
            id: "folio"
          },
          {
            Header:"UID",
            accessor:"ticket",
            show: viewuid,
            id: "uid"
          },
          {
            Filter: SelectColumnFilter,
            Header:"Tipo",
            accessor:"type",
            show: viewtipo,
            id: "tipo" 
          },
          {
            Filter: SelectColumnFilter,
            Header:"Estado",
            accessor:"status",
            show: viewestado,
            id: "estado",
            Cell: ({ value }) => {
              return(
                <div className="toCenter">
                  {value==="OPEN"?"ABIERTO":value==="PROCESS"?"EN REVISIÓN":"CERRADO"}
                </div>
              )
            }         
          },
          {
            Header:"RFC de la Compañía",
            accessor:"company_rfc",
            Cell:({value})=>{
             return(
              <div className="toLeft">
                {value}
              </div>)
            } ,
            show: viewcompany_rfc,
            id: "company_rfc" 
          },
          {
            Header:"Email",
            accessor:"user_email",
            Cell:({value}) => {
              return (
                <div className="toLeft">
                  {value}
              </div>)
            },
            show: viewemail,
            id: "email" 
          },
          {
            Header:"Sub-Módulo",
            accessor:"submodule",
            Cell:({value}) =>{
              return(
                <div className="toLeft">
                  {value}
              </div>)
            },
            show: viewsubmodule,
            id: "submodule" 
          },
          {
            Header:"Módulo",
            accessor: "module",
            Cell: ({ value }) => (
              <div className="toLeft">
                {value}
              </div>
            ),
            show: viewmodulo,
            id: "modulo" 
          },
          ],
          // eslint-disable-next-line react-hooks/exhaustive-deps
          [
            viewfolio,
            viewuid,
            viewmodulo,
            viewsubmodule,
            viewasunto,
            viewcompany_rfc,
            viewemail,
            viewestado,
            viewfecha,
            viewtipo,
            viewseveridad,
            refreshing,
          ]
      );

      const datacolum =[
        {
          view: viewfolio,
          setview: setviewfolio,
          label:"Folio"
        },
        {
          view: viewuid,
          setview: setviewuid,
          label: "UID"
        },
        {
          view: viewmodulo,
          setview: setviewmodulo,
          label:"Módulo"
        },
        {
          view: viewsubmodule,
          setview: setviewsubmodule,
          label:"Submódulo"
        },
        {
          view: viewseveridad,
          setview: setviewseveridad,
          label:"Severidad"
        },
        {
          view: viewtipo,
          setview: setviewtipo,
          label:"Tipo"
        },
        {
          view: viewestado,
          setview: setviewestado,
          label:"Estado"
        },
        {
          view: viewcompany_rfc,
          setview: setviewcompany_rfc,
          label:"RFC compañía"
        },
        {
          view: viewemail,
          setview: setviewemail,
          label:"Email"
        },
        {
          view: viewasunto,
          setview: setviewasunto,
          label:"Asunto"
        },
        {
          view: viewfecha,
          setview: setviewfecha,
          label:"Fecha"
        }
      
      ]

      const handlechecked = (event) => {
        setfiltersColumsView(event.target.checked);
      };

      useEffect(() => {
      
        handleLoad()        
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [])
      useEffect(() => {
        setIsloading(true)
        if(statusticket === "TODOS"){
        setDatafilter(dataoriginal);
        }
        else {
           setDatafilter(dataoriginal.filter(val => val.status === statusticket || val.status === statusticket2));  
        }
         setIsloading(false)
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [statusticket])
      

      useEffect(() => {
        localStorage.setItem("viewfolio",viewfolio);
        localStorage.setItem("viewuid",viewuid);
        localStorage.setItem("viewmodulo",viewmodulo);
        localStorage.setItem("viewsubmodule",viewsubmodule);
        localStorage.setItem("viewseveridad",viewseveridad);
        localStorage.setItem("viewtipo",viewtipo);
        localStorage.setItem("viewestado",viewestado);
        localStorage.setItem("viewcompany_rfc",viewcompany_rfc);
        localStorage.setItem("viewemail",viewemail);
        localStorage.setItem("viewasunto",viewasunto);
        localStorage.setItem("viewfecha",viewfecha);        
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [
        viewfolio,
        viewuid,
        viewmodulo,
        viewsubmodule,
        viewasunto,
        viewcompany_rfc,
        viewemail,
        viewestado,
        viewfecha,
        viewtipo
      ] 
      )
  return (
    <div className="TableConsultCases">    
      <div className="container-support-bol">
          
      <BREADCRUMBS niveles={
                [
                  {label: "CONSULTAR HISTORIAL DE CASOS", path: null},
                ]
              }
          new_version={true}
          modul="Consultar historial de casos v2"
          path="/help_center_v2"        
      ></BREADCRUMBS>

          <COLUMS_SHOW 
              filtersColumsView={filtersColumsView}
              datacolums={datacolum}>
            </COLUMS_SHOW>

         
          <div className="items-consult-cases">
            <div className="input-filter" >
              <TextField      
                fullWidth               
                variant="outlined"            
                select
                label="Estado del ticket"
                defaultValue={""}
                InputLabelProps={{shrink: true}}
                SelectProps={{native: true}}
                onChange={(event) => handleFilterData(event)}
              >
                <option key={1} value={"OPEN"}>
                    {"ABIERTO"}
                </option>
                <option key={2} value={"CLOSE"}>
                    {"CERRADO"}
                </option>
                <option key={3} value={"TODOS"}>
                    {"TODOS"}
                </option>
            </TextField>
            </div>
            
            <div className="switch-filter">
                <FormControlLabel                  
                    control={
                      <MaterialUISwitch
                        sx={{ m: 1 }}
                        onChange={handlechecked}
                        checked={filtersColumsView}
                      />
                    }
                    label={
                      filtersColumsView === true
                        ? "Activar Filtros"
                        : "Desactivar Filtros"
                    }
                  />
            </div>
              
          </div>
        <div className="table-container table-container-tour" id="table-container-tour">
          <StickyTable>
                <TableContainer
                  paginado={"Tickets"}
                  consultas={"Tickets consultados"}
                  nametable={"Tickets"}
                  exportar={true}
                  columns={columns}
                  data={datafilter}
                />
            </StickyTable>
        </div>  
          
           
        <NotificationSystem  ref={notificationSystemRef} >
         { isloading && 
                ( <SpinnerComments 
                      backgroundColor="white" 
                      color="red" 
                      display={isloading}
                      height="100%" 
                      message="Cargando Tickets ..." 
                      width="100%"/>
                  )} 

         </NotificationSystem>  
         </div> 
   </div> 
  )
}