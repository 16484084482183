//PAQUETERIAS
import React, { useEffect, useState, useMemo, useRef } from "react";
import axios from "axios";
import { StickyTable} from "react-sticky-table";
import {CustomDialog, Confirm} from "react-st-modal";
import NotificationSystem from 'react-notification-system';
//COMPONENTES
import AlertResponse from "../../main_components/alerts/alertResponse";
import Modal_Table from "../../main_components/modal/modal_Table";
import TableContainer from "../../main_components/Table/TableContainer";
//ESTILOS
import "./users.css";

//IMAGENES/ICONOS

import Delete from "../../imgComponents/borrar.png";
import CREATE from "../../imgComponents/create.png";
import Edit from "../../imgComponents/save.png";
import { refresh_token } from "../../main_components/tokens/tokenrefresh";
import CreateUser from "./CreateUser";
import EditUser from "./EditUser";
const URL_BASE = "https://lxwr6pudd9.execute-api.us-east-1.amazonaws.com";


const TableUsers= () => {

  const notificationSystemRef = useRef();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [MensajeBusqueda, setMensajeBusqueda] = React.useState("");
  const [open, setopen]=useState(false);
  var entrada=0;
  const [modalT, setmodalT] = useState(false);
  const [menssage, setmenssage] = useState("");
  const [modalGeneral, setmodalGeneral] = useState(false);


  useEffect(()=>{
    handleSubmit();
  },[]);


function alertas(msj,status){

  if(status==true){
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={true}></AlertResponse>,
      level: 'success',
      position: 'br', 
      autoDismiss: 10, 
    });  
  }else{
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={false}></AlertResponse>,
      level: 'error',
      position: 'br', 
      autoDismiss: 60, 
    });
  }  
}
const reconsultar=(n)=>{
  if(n==true){
    setmodalT(true);  
  }else{
    setmodalT(false);
  } 
}
//proceso de consultar todos los Uusarios
const handleSubmit = () => {
  console.log("entra a consultar todos los usuarios");
  refresh_token();

    if(entrada==0){
      entrada=entrada+1;
      setmenssage("Consultando Usuarios...");
      setmodalGeneral(true);
      setmodalT(true);
    }else{
      setmodalT(true);
    }
    setData([]);
    setopen(true);
    
    setTimeout(() => {
      axios
        .get(
          `${URL_BASE}/development/users`,
          {
            headers: {
              Authorization: sessionStorage.getItem("getIdTokenRefresh"),
              "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          const data = response.data.users;
          setData(data);
          setopen(false);
          setmodalGeneral(false);
          setmodalT(false);
         })
        .catch((err) => {
          console.log("error", err);
          setData([]);
          setopen(false);
          setmodalGeneral(false);
          setmodalT(false);
        });
    }, 2000);
};
const columns = useMemo(
  () => [
    {
      Header:()=>(
        <div>
          <img
            id="crearUser"
            src={CREATE}
            onClick={async () => {
              const result = await CustomDialog(               
             <div>
                <CreateUser handleSubmit={handleSubmit} alertas={alertas} reconsultar={reconsultar}></CreateUser>
             </div>,
           {
             className: "modalpuntosventa",
             title:"Crear nuevo Usuario",
             showCloseIcon: true,
             isCanClose:false,
           }
       );
     }}
            title="Crear Usuario"
            className="cancel"
          ></img>
        </div>
      ),
      
      id: "Acciones",
      Cell: ({ valor, row }) => {
      function DeleteSector(){  
      
      setmodalT(true);
      refresh_token();
      setopen(true);
      setData([]);

      setTimeout(() => {
        var url = `${URL_BASE}/development/users/${row.original.id}`;
        var axiosConfig = {
          headers: {
            Authorization: sessionStorage.getItem("getIdTokenRefresh"),
            "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
            "Content-Type": "application/json",
          },
        }
        axios
        .delete(url,axiosConfig)
        .then((response) => {
          console.log(response);
           alertas("Usuario Eliminado", true);
           handleSubmit();
        })
        .catch((err) => {
          setopen(false);
          console.log("error", err);
          alertas("Error, no se pudo eliminar el Usuario", false);
          handleSubmit();
        });
       }, 2000);
      }
        return (
          <div>
            <img
              src={Delete}
              onClick={async() =>{
                  const result = await Confirm('¿Esta usted seguro de eliminar el Usuario?','Eliminar Usuario','Si','No');
                  if(result){
                    DeleteSector()
                    }
                  }            
                }
              className="centerText, cancel"
              title="Eliminar Usuario"
            ></img>
              &nbsp;
            <img
              src={Edit}
              onClick={
                async () => {
                  const result = await CustomDialog(               
                 <div>
                    <EditUser row={row} handleSubmit={handleSubmit} alertas={alertas} reconsultar={reconsultar}></EditUser>
                 </div>,
               {
                 className: "modalpuntosventa",
                 title:"Editar Usuario",
                 showCloseIcon: true,
                 isCanClose:false,
               }
           ); 
         }
        }
              className="centerText, cancel"
              title="Editar Usuario"
            ></img>
          </div>
        );
      },
    },
    {
      Header: "Habilitado",
      accessor: (d)=>{
      return d.user_enable==true?"SI":"NO"
      }
    },
    {
      Header: "Bloqueado",
      accessor: (d)=>{
        return d.accountnonlocked==true?"NO":"SI"
      }
    },
    {
      Header: "Usuario",
      accessor:"username"
    },
    {
      Header:"Nombre",
      accessor:"fullusername"
    },
  
    {
      Header:"Email",
      accessor:"email"
    },
    {
      Header:"Teléfono",
      accessor:"phone"
    },
    {
      Header:"Puesto",
      accessor:"job"
    },
    {
      Header:"Último ingreso",
      accessor:"last_login_time"
    }
    
    ],
    []
);
  return (
    <>
<div className="TableUsers">
  
  <div className="">         
    <NotificationSystem  ref={notificationSystemRef} ></NotificationSystem>
  <Modal_Table
    open={true}
    message={menssage}
    modalGeneral={modalGeneral}
    modalT={modalT}
  ></Modal_Table>
          <>
          <div className="titletable">
            <br/>
          <h1>Datos de Usuarios</h1>
          

          </div>
          <StickyTable>
            <TableContainer
              paginado={"Usuarios"}
              consultas={"Usuarios Consultados"}
              exportar={true}
              columns={columns}
              data={data}
              style={{ overflowx: 10 }}
              Gmodal={open}
            
            />
          </StickyTable>
          </>   
          </div>
        </div>
    </>
  );
}

export default TableUsers;
