import React, { useState, useEffect } from 'react';

function AnimatedText({ message }) {
  const [displayedMessage, setDisplayedMessage] = useState('');

  useEffect(() => {
    setDisplayedMessage(''); // Reinicia el mensaje animado cuando el mensaje cambia
    const maxDelay = 25; // Máximo retraso en ms entre cada letra
    let delay = 0;

    message.split('').forEach((char, index) => {
      delay += index * maxDelay;
      setTimeout(() => {
        setDisplayedMessage((prev) => prev + char);
      }, delay);
    });

    return () => clearTimeout();
  }, [message]);

  return <span style={{fontWeight:"bold"}}>{displayedMessage}</span>;
}


export default AnimatedText