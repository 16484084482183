import React, { useState, useEffect, Suspense } from "react";
import axios from "axios";
import { alpha, styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Spinner from "../../../imgComponents/S.gif";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { ReactSearchAutocomplete } from "react-search-autocomplete";

import { CustomDialog, useDialog, Confirm } from "react-st-modal";
import { get_expense_paymentaccounts } from "../../../services/expenses/expenses";
import EFECTO from "../../../dataComponets/efect.json"
import { update_policy_detail } from "../../../services/expenses/expenses";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ValidationTextField = styled(TextField)({
  "& input:valid + fieldset": { borderColor: "green", borderWidth: 2 },
  "& input:valid:focus + fieldset": {
    borderLeftWidth: 6,
    padding: "4px !important",
  },
});


const UPDATE_DETAIL_POLICY = ({
  id_detail,
  codaccounts,
  codconcept,
  codamount,
  codeffect,
  codidConcept,
  id_policy
}) => {
  const dialog = useDialog();

  const [open, setOpen] = React.useState(false);
  const [Concepto, setConcepto] = useState([]);
  const [idConceptUpdate, setidConceptUpdate] = useState(codidConcept);
  const [ConceptUpdate, setConceptUpdate] = useState(codconcept);
  const [effectUpdate, seteffectUpdate] = useState(codeffect);
  const [accountUpdate, setaccountUpdate] = useState(codaccounts);
  const [importe, setImporte] = useState(codamount);
  const [efecto, setefecto] = React.useState(codeffect);

  const [pro_servData, setpro_servData] = useState([]);


  const handleOnSearch = (string, results) => {};
  

  const handleOnHover = (result) => {};
  const handleOnSelect = (item) => {
    setConcepto(item);
    setidConceptUpdate(item.id);
    setConceptUpdate(item.name);
    seteffectUpdate(item.efect);
    setaccountUpdate(item.code);
  };

  const handleOnFocus = () => {
    console.log("Focused");
  };

  const handleOnClear = () => {
    console.log("Cleared");
  };

  

  const handelImporte = (event) => {
    setImporte(event?.target?.value);
  };
  const handelImportOnBurl = (event) => {
    setImporte(Number(importe).toFixed(2));
  };

  const handelEfect = (event) => {
    setefecto(event?.target?.value);
  };

  var data = {
      id_account: idConceptUpdate,
      amount: Number(importe),
      effect: efecto

  };

  console.log(data);

  const handleSubmit = async (event) => {
    event?.preventDefault();
    var data = {
        id_account: idConceptUpdate,
        amount: Number(importe),
        effect: efecto

    };
    setOpen(true);    
      try {
        await update_policy_detail(data,id_policy,id_detail)
        setOpen(false);
        dialog.close(true);
      } catch (err) {
        setOpen(false);
        var error=  err?.response?.data?.errorMessage;
        alert("Error.," + error);
      }
  };

 
  const handleprod_concept_list = async () => {
    try {
      var dt = await get_expense_paymentaccounts();
      const data = dt.data;
      const dataStatic = {
        id_account: "9090909090909",
        concept: "SELECCIONE UNA OPCIÓN",
        account: "SELECCIONE UNA OPCIÓN",
      };
      data[data.length] = dataStatic;
      setpro_servData(data);
    } catch (error) {
      setpro_servData([]);
    }
  };

  useEffect(() => {
    handleprod_concept_list();
  }, []);

  return (
    <div>
      <div>
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style2}>
            <div className="centermodaldescarga">
              <Typography
                id="modal-modal-title"
                className=""
                variant="h6"
                component="h2"
              >
                <img alt="" src={Spinner}></img>
              </Typography>
              <Typography
                id="modal-modal-description"
                className=""
                sx={{ mt: 2 }}
              >
                {"Actualizando detalle..."}
              </Typography>
            </div>
          </Box>
        </Modal>
      </div>
      <div>
        <form>
          <Box>
            <div style={{ width: "90%", margin: "0 auto" }}>
              <ReactSearchAutocomplete
                items={pro_servData}
                fuseOptions={{ keys: ["id", "name_code"] }}
                resultStringKeyName={"name_code"}
                onSearch={handleOnSearch}
                onHover={handleOnHover}
                onSelect={handleOnSelect}
                onFocus={handleOnFocus}
                onClear={handleOnClear}
                showIcon={true}
                maxResults={30}
                placeholder="Buscar concepto..."
                styling={{
                  height: "34px",
                  border: "1px solid darkgreen",
                  borderRadius: "4px",
                  backgroundColor: "white",
                  boxShadow: "none",
                  hoverBackgroundColor: "lightgreen",
                  color: "black",
                  iconColor: "black",
                  lineColor: "black",
                  placeholderColor: "black",
                  clearIconMargin: "3px 8px 0 0",
                  zIndex: 10,
                }}
              />
            </div>
          </Box>

          <Box className="">
            <ValidationTextField
              label="Concepto"
              variant="outlined"
              placeholder="Cuenta"
              sx={{ m: 2, width: "50%" }}
              value={ConceptUpdate}
              InputLabelProps={{ shrink: true }}
            />
            <ValidationTextField
              label="Cuenta"
              variant="outlined"
              placeholder="Cuenta"
              sx={{ m: 2, width: "34%" }}
              value={accountUpdate}
              InputLabelProps={{ shrink: true }}
            />
          </Box>

          <Box>
          <TextField
              select
              label="Efecto"
              value={efecto}
              sx={{ m: 1.5, width: "44%" }}
              onChange={handelEfect}
              size="small"
              name="taxes"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
            >
              {EFECTO.map((option) => (
                <option
                  key={option.value}
                  value={option.value}
                  selected={option.value}
                >
                  {option.label}
                </option>
              ))}
            </TextField>
            <ValidationTextField
              label="Importe"
              variant="outlined"
              placeholder="Importe"
              type="number"
              sx={{ m: 2, width: "40%" }}
              onChange={handelImporte}
              onBlur={handelImportOnBurl}
              value={importe}
              InputLabelProps={{ shrink: true }}
            />
          </Box>

          <Box>
            <Button
              variant="contained"
              sx={{ m: 1.5, width: "20ch" }}
              className="ButtonModal"
              onClick={handleSubmit}
            >
              Actualizar
            </Button>

            <Button
              variant="contained"
              sx={{ m: 1.5, width: "20ch" }}
              className="ButtonModal2"
              onClick={() => {
                dialog.close();
              }}
            >
              cancelar
            </Button>
          </Box>
        </form>
      </div>
    </div>
  );
};

export default UPDATE_DETAIL_POLICY;
