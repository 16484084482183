//PAQUETERIAS
import { useEffect, useState, useMemo, useRef, Fragment } from "react";
import MODAL_TABLE from "../../main_components/modal/modal_Table";
import { CustomDialog, Confirm } from "react-st-modal";

//COMPONENTES
//IMAGENES/ICONOS

import { Box, Button } from "@mui/material";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import INVOICE from "./invoice";
import PETITIONS from "./petitions";
import { process_documents } from "../../services/portal/portal";
import ExpandIcon from '@mui/icons-material/Expand';
const INVOICE_OR_PETITIONS = () => {
  const [petitions_docts, setpetitions_docts] = useState([]);
  const [invoice_docts, setinvoice_docts] = useState([]);
  const [modalT, setmodalT] = useState(false);
  const [flag_relation, setflag_relation] = useState(true);
  const [fi_original_pedimentos, setfi_original_pedimentos] = useState(false);
  const [fi_original_facturas, setfi_original_facturas] = useState(false);
  const [ff_original_facturas, setff_original_facturas] = useState(false);
  const [ff_original_pedimentos, setff_original_pedimentos] = useState(false);
  const [flag_reconsult, setflag_reconsult] = useState(0);




  const handle_join_documentss = async () => {
    const join_docts = { "documents": invoice_docts.concat(petitions_docts) }
    console.log(flag_reconsult)
    setmodalT(true)

    try {

      await process_documents(join_docts)
      alert("Documentos relacionados correctamente")
      setflag_reconsult(flag_reconsult + 1)
    } catch (error) {
      alert("Error." + JSON.stringify(error.response.data))

    }
    finally {
      setTimeout(() => {
        setmodalT(false)

      }, 200);


    }
  }


  // console.log(petitions_docts?.related) //["32011231","112324214","4124124"]
  // console.log(invoice_docts?.docID)

  // var d = invoice_docts.map(dato => dato.docID);

  // console.log(d) //[   "32012115","32011231"]



  const checkRelated = invoice_docts?.map(dato => dato.docID)?.some(docID => petitions_docts?.related?.includes(docID));
  console.log(checkRelated)


  return (

    <>
      <MODAL_TABLE
        open={true}
        message={"PROCESANDO DOCUMENTOS"}
        modalGeneral={modalT}
        modalT={modalT}
      ></MODAL_TABLE>
      <Box className="" sx={{ display: 'flex', gap: '5px', p: '2px', zIndex: "10", alignItems: 'flex-start' }}>
        <Button
          style={{ "backgroundColor": "#f39c12" }}
          sx={{ height: '35px', width: "210px", color: "white", fontSize: "12px" }}
          onClick={() => setflag_relation(!flag_relation)}
        >
          <ExpandIcon /> {flag_relation ? "VISTA RESUMIDA" : "VISTA COMPLETA"}
        </Button>
        <Button
          style={{ "backgroundColor": "green" }}
          sx={{ height: '35px', width: "280px", color: "white", fontSize: "12px" }}
          // onClick={() => handle_join_documentss()}
          onClick={async () => {

            const result = await Confirm(
              `${checkRelated ? `¿Esta usted seguro de volver a relacionar estos documentos? '${invoice_docts?.map(dato => dato.docID).filter(docID => petitions_docts?.related?.includes(docID))}'` : "¿Esta usted seguro de relacionar estos documentos?"}`,
              "Relacionar Documentos",
              "Si",
              "No"
            );
            if (result) {
              handle_join_documentss();
            } else {
            }

          }}
        >
          <UploadFileIcon></UploadFileIcon>
          GENERAR RELACIÓN DOCUMENTOS
        </Button>

      </Box>
      <br></br>

      <Box
        sx={{
          display: 'grid',
          width: "100%",
          gridTemplateColumns: { xs: 'auto', lg: flag_relation ? '100%' : '50% 50%' },
          gap: '1rem',
          p: '4px',
        }}
      >
        <div >
          <INVOICE key_table={`invoice-${flag_relation}`} setinvoice_docts={setinvoice_docts} flag_relation={flag_relation} flag_reconsult={flag_reconsult}></INVOICE>
        </div>
        <div >
          <PETITIONS key_table={`petitions-${flag_relation}`} setpetitions_docts={setpetitions_docts} flag_relation={flag_relation} flag_reconsult={flag_reconsult}></PETITIONS>
        </div>
      </Box>


    </>
  );
}

export default INVOICE_OR_PETITIONS;
