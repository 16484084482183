export function calculate_total(n) {
  try {
    return n?.reduce((acumulado, actual) => acumulado + actual, 0);
  } catch (error) {
    return 0
  }
}


export const custom_multiple_filter = (row, columnId, filterValue, column) => {
  console.log(filterValue)
  try {
    if (filterValue?.length === 0) {
      console.log(filterValue)
      return true;
    }
    if (!Array.isArray(filterValue)) {
      console.log(columnId)
      console.log(row?.original?.hasOwnProperty(columnId))
      return false;
    }
    if (!row.original.hasOwnProperty(columnId)) {
      console.log(columnId)

      const cleanColumnId = columnId.replace('-list', '');

      console.log(row?.original?.hasOwnProperty(cleanColumnId))
      return filterValue.includes(row.original[cleanColumnId]);
    }

    return filterValue.includes(row.original[columnId]);
  } catch (e) {
    console.log(e)

  }
};



export function format_year_month_day(fecha) {
  if (fecha !== undefined) {
    console.log(fecha)
    const partes = fecha.split('/');

    // Crear un objeto de fecha con el formato 'DD/MM/YYYY'
    const fechaObj = new Date(`${partes[2]}-${partes[1]}-${partes[0]}T00:00:00`);
    console.log(fechaObj)

    // Obtener el año, mes y día del objeto de fecha
    const year = fechaObj.getFullYear();
    const month = String(fechaObj.getMonth() + 1).padStart(2, '0'); // Los meses comienzan en 0
    const day = String(fechaObj.getDate()).padStart(2, '0');
    console.log(`${year}/${month}/${day}`)

    // Devolver la fecha en el nuevo formato 'YYYY/MM/DD'
    return `${year}/${month}/${day}`;
  }
}


