import axios from "axios";
import { catchErrorModul } from "../../main_components/catchErr/catchError";
import { headersdata } from "../../main_components/tokens/headers";
import { refresh_token } from "../../main_components/tokens/tokenrefresh";

var urll = "https://mz2b94va5l.execute-api.us-east-1.amazonaws.com/dev";

export const get_layout = async (dated) => {
    const url = `${urll}/petitions`;
    try {
      const response = await axios.get(url, {
        params:{
            date: dated
        },
        headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        //   "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  

  export const get_layout_download = async (dated) => {
    const url = `${urll}/petitions/${dated}`;
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          // "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };


  export const post_layout = async (datad) => {
    const url = `${urll}/petitions`;
    try {
      const response = await axios.post(url, datad, {
        headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          // "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };


  export const put_layout = async (id, datad, date) => {
    const url = `${urll}/petitions/${date}/${id}`;
    try {
      const response = await axios.put(url, datad, {
        headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          // "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };


  
  export const delete_layout = async (id, date) => {
    const url = `${urll}/petitions/${date}/${id}`;
    try {
      const response = await axios.delete(url,{
        headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          // "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };