//PAQUETERIAS
import { useEffect, useState, useMemo, useRef } from "react";
import NotificationSystem from 'react-notification-system';
import MODAL_TABLE from "../main_components/modal/modal_Table";
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';

import dayjs from 'dayjs';

//COMPONENTES
import AlertResponse from "../main_components/alerts/alertResponse";
//IMAGENES/ICONOS
import { refresh_token } from "../main_components/tokens/tokenrefresh";
import { BREADCRUMBS } from "../main_components/pagination/breadcrumbs";
import { Box } from "@mui/material";
import { calculate_total } from "../main_components/methods_v2/auxiliary_functions";
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../main_components/methods_v2/export";
import FILTERS_FACTOR from "./tools/filters";
import "./factor.css";
import { get_factor_importation } from "../services/portal/portal";
import { date_current, previous_date } from "../main_components/date/date";
const customLocalization = {
  ...MRT_Localization_ES,
  toolbar: {
    ...MRT_Localization_ES.toolbar,
    resetOrder: 'Resetear Orden',
  },
};
const FACTOR_IMPORTATION = () => {

  const notificationSystemRef = useRef();
  const [open, setopen] = useState(false);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [rowSelection, setRowSelection] = useState({});
  const [reconsult, setreconsult] = useState(false);
  var consulta = 0;
  const [modalT, setmodalT] = useState(false);
  const [menssage, setmenssage] = useState("");
  const [modalGeneral, setmodalGeneral] = useState(false);
  const [usStateList, setusStateList] = useState([]);
  const [total_rows, settotal_rows] = useState("");

  const [gvalor_en_mn, setgvalor_en_mn] = useState(0);
  const [giva, setgiva] = useState("");
  const [ghonorarios, setghonorarios] = useState("");
  const [gfletes, setgfletes] = useState("");
  const [gdta, setgdta] = useState("");
  const [gprv, setgprv] = useState("");
  const [gcnt, setgcnt] = useState("");
  const [gigi, setgigi] = useState("");
  const [ggts, setggts] = useState("");
  const [gtotalg, setgtotalg] = useState("");
  const [gcostot, setgcostot] = useState("");
  const [porc, setporc] = useState(0.0);

  const [ff, setff] = useState(dayjs(date_current));
  const [fi, setfi] = useState(dayjs(previous_date));
  const [suplier, setsuplier] = useState(null);
  const [warehouse, setwarehouse] = useState(null);
  const [filter, setfilter] = useState("Dates");
  const [orderpg, setorderpg] = useState([]);
  const [option_aux, setoption_aux] = useState("FECHA");

  

  const [query_filters, setquery_filters] = useState({});
  const [query_filters_dt, setquery_filters_data] = useState("");
  // useEffect(() => {
  //   handleSubmit();
  // }, []);



  const update_identifiers =
    [
      { docEntry: "number" },
      { docNum: "number" },
      { PROVEEDOR: "string" },
      { CODE_PROVEEDOR: "number" },
      { VALOR_EN_MN: "number" },
      { IVA: "number" },
      { HONORARIOS: "number" },
      { FLETES: "number" },
      { DTA: "number" },
      { PRV: "number" },
      { CNT: "number" },
      { IGI: "number" },
      { GTS_GRL_AD: "number" },
      { TOTAL_GASTOS: "number" },
      { COSTO_TOTAL: "number" },
      { IMP: "number" },
      { FI: "number" },
      { PEDIMENTO: "string" },
      { FECHA: "string" },
      { PERIODO: "string" },
      { VALOR_ADUANA: "number" },
      { COMENTARIOS: "string" },
      { FECHA_PEDIMENTO: "string" },
    ];




  const update_null_identifiers = (original_obj, identifiers_to_update) => {
    return original_obj.map((item) => {
      for (const identifier in item) {
        if (item.hasOwnProperty(identifier)) {
          const isToUpdate = identifiers_to_update.some((update) => update[identifier] !== undefined);
          if (isToUpdate && item[identifier] === null) {
            item[identifier] = typeof identifiers_to_update.find((update) => update[identifier]) === 'number' ? 0 : '';
          }
        }
      }
      return item;
    });
  };

  function alertas(msj, status) {

    if (status === true) {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={true}></AlertResponse>,
        level: 'success',
        position: 'br',
        autoDismiss: 10,
      });
    } else {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={false}></AlertResponse>,
        level: 'error',
        position: 'br',
        autoDismiss: 60,
      });
    }
  }
  //proceso de consultar todas las cuentas contables
  const handleSubmit = async (query_filters_flag, query_filters_data) => {

    setmodalT(true);
    setIsLoading(true);

    var data;

    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {

            var d;
            console.log(query_filters_flag)
            if (query_filters_flag) {
              console.log(query_filters_data)
              setfi(dayjs(query_filters_data.datei))
              setff(dayjs(query_filters_data.datef))
              setoption_aux(query_filters_data.option_aux);
              setsuplier(query_filters_data.supplier);
              setwarehouse(query_filters_data.whscode);
              d = await get_factor_importation(query_filters_data);

              setquery_filters(query_filters_data)
            } else {

              data = {
                filtro: filter,
                datei: filter === "Dates" || filter === "SupplierAndDates" || filter === "WarehouseAndDates" ? fi?.format('YYYY-MM-DD') : null,
                datef: filter === "Dates" || filter === "SupplierAndDates" || filter === "WarehouseAndDates" ? ff?.format('YYYY-MM-DD') : null,
                supplier: suplier,
                whscode: warehouse,
                option_aux:option_aux
              }

              setquery_filters(data);

              d = await get_factor_importation(data);
            }

            // const d = await get_factor_importation(data);

            console.log(d.data);
            const datajson = (d === null ? [] : d.data);



            setData(update_null_identifiers(datajson, update_identifiers));
            setgvalor_en_mn(datajson?.map(obj => obj.VALOR_EN_MN).filter(d => d !== null && d !== ""))
            setgiva(datajson?.map(obj => obj.IVA).filter(d => d !== null && d !== ""));
            setghonorarios(datajson?.map(obj => obj.HONORARIOS).filter(d => d !== null && d !== ""));
            setgfletes(datajson?.map(obj => obj.FLETES).filter(d => d !== null && d !== ""));
            setgdta(datajson?.map(obj => obj.DTA).filter(d => d !== null && d !== ""));
            setgprv(datajson?.map(obj => obj.PRV).filter(d => d !== null && d !== ""));
            setgcnt(datajson?.map(obj => obj.CNT).filter(d => d !== null && d !== ""));
            setgigi(datajson?.map(obj => obj.IGI).filter(d => d !== null && d !== ""));
            setggts(datajson?.map(obj => obj.GTS_GRL_AD).filter(d => d !== null && d !== ""));
            setgtotalg(datajson?.map(obj => obj.TOTAL_GASTOS).filter(d => d !== null && d !== ""));
            setgcostot(datajson?.map(obj => obj.COSTO_TOTAL).filter(d => d !== null && d !== ""));

            var d1 = calculate_total(datajson?.map(obj => obj.VALOR_EN_MN).filter(d => d !== null && d !== ""));
            var d2 = calculate_total(datajson?.map(obj => obj.TOTAL_GASTOS).filter(d => d !== null && d !== ""))
            calcularporcentaje(d1, d2);

            const dataLength = datajson?.length || 0;

            const baseOptions = [5, 10, 20, 50, 100];

            const filteredOptions = baseOptions.filter(option => option <= dataLength);

            if (dataLength > 0 && !filteredOptions.includes(dataLength)) {
              filteredOptions.push(dataLength);
            }
            const sortedRowsPerPageOptions = filteredOptions.sort((a, b) => a - b);

            setorderpg(sortedRowsPerPageOptions);
            setopen(false);
            setmodalGeneral(false);
            setmodalT(false);
            setIsLoading(false);
          } catch (err) {
            console.log("error", err);
            setData([]);
            setopen(false);
            setmodalGeneral(false);
            setmodalT(false);
            setIsLoading(false);
          }
        }, 1000);
      } else { }
    } catch (err) {
      console.log(err);
    }
  };

  const handleStateChange = (newState) => {
    const filteredRowsCount = newState.filteredRowModel
    console.log(filteredRowsCount)
    settotal_rows(filteredRowsCount);
  };
  

  const calcularporcentaje = (d1, d2) => {
    var d = ((d2 / d1) * 100).toFixed(2);
    setporc(d);
  }

  const handle_success_filter = (a, b) => {
    handleSubmit(a, b);
    notificationSystemRef.current.addNotification({
      message: <AlertResponse msj={"Filtro Aplicado"} view={true}></AlertResponse>,
      level: "success",
      position: "br",
      autoDismiss: 10,
    });

  }


  const columns = useMemo(
    () => [
      {
        id: "PROVEEDOR",
        header: "PROVEEDOR",
        size: 200,
        accessorKey: "PROVEEDOR",
        muiTableBodyCellProps: {
          align: "justify"
        },
        Footer: () => (
          <div style={{ fontSize: "14px" }}>
            <div>SubTotal:</div>
            <div>Gran Total: </div>
            <div>IMPO:</div>
          </div>
        ),
      },
      {
        id: "VALOR_EN_MN",
        header: "VALOR EN M.N.",
        accessorKey: "VALOR_EN_MN",
        size: 230,
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              // backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              // color: '#fff',
              maxWidth: '12ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue() === 0 || cell.getValue() === null || cell.getValue() === "" ?

              (0.0).toLocaleString?.('es-MX', {
                style: 'currency',
                currency: 'MXN',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
              : cell.getValue()?.toLocaleString?.('es-MX', {
                style: 'currency',
                currency: 'MXN',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
          </Box>
        ),
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.VALOR_EN_MN).filter(d => d !== "")
          return (
            <div style={{ fontSize: "14px", textAlign: "right" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(gvalor_en_mn))}</div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(gvalor_en_mn))}</div>
            </div>
          )
        },
      },
      {
        id: 'IVA',
        size: 140,
        header: "IVA",
        accessorKey: "IVA",
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              // backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              // color: '#fff',
              maxWidth: '12ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue() === 0 || cell.getValue() === null || cell.getValue() === "" ?

              (0.0).toLocaleString?.('es-MX', {
                style: 'currency',
                currency: 'MXN',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
              : cell.getValue()?.toLocaleString?.('es-MX', {
                style: 'currency',
                currency: 'MXN',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
          </Box>
        ),
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.IVA).filter(d => d !== "")
          return (
            <div style={{ fontSize: "14px", textAlign: "right" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}
              </div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(giva))}</div>
            </div>
          )
        },
      },
      {
        id: 'HONORARIOS',
        header: "HONORARIOS",
        size: 210,
        accessorKey: "HONORARIOS",
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              // backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              // color: '#fff',
              maxWidth: '12ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue() === 0 || cell.getValue() === null || cell.getValue() === "" ?

              (0.0).toLocaleString?.('es-MX', {
                style: 'currency',
                currency: 'MXN',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
              : cell.getValue()?.toLocaleString?.('es-MX', {
                style: 'currency',
                currency: 'MXN',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
          </Box>
        ),
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.HONORARIOS).filter(d => d !== "")
          return (
            <div style={{ fontSize: "14px", textAlign: "right" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}
              </div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(ghonorarios))}</div>
            </div>
          )
        },
      },
      {
        id: 'FLETES',
        header: "FLETES",
        size: 160,
        accessorKey: "FLETES",
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              // backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              // color: '#fff',
              maxWidth: '12ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue() === 0 || cell.getValue() === null || cell.getValue() === "" ?

              (0.0).toLocaleString?.('es-MX', {
                style: 'currency',
                currency: 'MXN',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
              :
              cell.getValue()?.toLocaleString?.('es-MX', {
                style: 'currency',
                currency: 'MXN',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
          </Box>
        ),
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.FLETES).filter(d => d !== "")
          return (
            <div style={{ fontSize: "14px", textAlign: "right" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}
              </div> <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(gfletes))}</div>
            </div>
          )
        },
      },
      {
        id: "DTA",
        header: "DTA",
        size: 140,
        accessorKey: "DTA",
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              // backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              // color: '#fff',
              maxWidth: '12ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue() === 0 || cell.getValue() === null || cell.getValue() === "" ?

              (0.0).toLocaleString?.('es-MX', {
                style: 'currency',
                currency: 'MXN',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
              : cell.getValue()?.toLocaleString?.('es-MX', {
                style: 'currency',
                currency: 'MXN',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
          </Box>
        ),
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.DTA).filter(d => d !== "")
          return (
            <div style={{ fontSize: "14px", textAlign: "right" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}
              </div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(gdta))}</div>
            </div>
          )
        },
      },
      {
        header: "PRV",
        size: 140,
        accessorKey: "PRV",
        id: "PRV",
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              // backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              // color: '#fff',
              maxWidth: '12ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue() === 0 || cell.getValue() === null || cell.getValue() === "" ?

              (0.0).toLocaleString?.('es-MX', {
                style: 'currency',
                currency: 'MXN',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
              : cell.getValue()?.toLocaleString?.('es-MX', {
                style: 'currency',
                currency: 'MXN',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
          </Box>
        ),
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.PRV).filter(d => d !== "")
          return (
            <div style={{ fontSize: "14px", textAlign: "right" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}
              </div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(gprv))}</div>
            </div>
          )
        },
      },
      {
        header: "CNT",
        size: 140,
        accessorKey: "CNT",
        id: "CNT",
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              // backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              // color: '#fff',
              maxWidth: '12ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue() === 0 || cell.getValue() === null || cell.getValue() === "" ?

              (0.0).toLocaleString?.('es-MX', {
                style: 'currency',
                currency: 'MXN',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
              : cell.getValue()?.toLocaleString?.('es-MX', {
                style: 'currency',
                currency: 'MXN',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
          </Box>
        ),
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.CNT).filter(d => d !== "")
          return (
            <div style={{ fontSize: "14px", textAlign: "right" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}
              </div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(gcnt))}</div>
            </div>
          )
        },
      },
      {
        header: "IGI",
        accessorKey: "IGI",
        size: 140,
        id: "IGI",
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              // backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              // color: '#fff',
              maxWidth: '12ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue() === 0 || cell.getValue() === null || cell.getValue() === "" ?

              (0.0).toLocaleString?.('es-MX', {
                style: 'currency',
                currency: 'MXN',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
              : cell.getValue()?.toLocaleString?.('es-MX', {
                style: 'currency',
                currency: 'MXN',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
          </Box>
        ),
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.IGI).filter(d => d !== "")
          return (
            <div style={{ fontSize: "14px", textAlign: "right" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}
              </div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(gigi))}</div>
              <div>{porc}%</div>
            </div>
          )
        },
      },
      {
        header: "GTS GRL AD",
        size: 200,
        accessorKey: "GTS_GRL_AD",
        id: "GTS_GRL_AD",
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              // backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              // color: '#fff',
              maxWidth: '12ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue() === 0 || cell.getValue() === null || cell.getValue() === "" ?

              (0.0).toLocaleString?.('es-MX', {
                style: 'currency',
                currency: 'MXN',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
              : cell.getValue()?.toLocaleString?.('es-MX', {
                style: 'currency',
                currency: 'MXN',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
          </Box>
        ),
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.GTS_GRL_AD).filter(d => d !== "")
          return (
            <div style={{ fontSize: "14px", textAlign: "right" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}
              </div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(ggts))}</div>
            </div>
          )
        },
      },
      {
        header: "TOTAL GASTOS",
        size: 220,
        accessorKey: "TOTAL_GASTOS",
        id: "TOTAL_GASTOS",
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              // backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              // color: '#fff',
              maxWidth: '12ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue() === 0 || cell.getValue() === null || cell.getValue() === "" ?

              (0.0).toLocaleString?.('es-MX', {
                style: 'currency',
                currency: 'MXN',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
              : cell.getValue()?.toLocaleString?.('es-MX', {
                style: 'currency',
                currency: 'MXN',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
          </Box>
        ),
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.TOTAL_GASTOS).filter(d => d !== "")
          return (
            <div style={{ fontSize: "14px", textAlign: "right" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}
              </div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(gtotalg))}</div>
            </div>
          )
        },
      },
      {
        header: "COSTO TOTAL",
        accessorKey: "COSTO_TOTAL",
        size: 220,
        id: "COSTO_TOTAL",
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              // backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              // color: '#fff',
              maxWidth: '12ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue() === 0 || cell.getValue() === null || cell.getValue() === "" ?

              (0.0).toLocaleString?.('es-MX', {
                style: 'currency',
                currency: 'MXN',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
              : cell.getValue()?.toLocaleString?.('es-MX', {
                style: 'currency',
                currency: 'MXN',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
          </Box>
        ),
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.COSTO_TOTAL).filter(d => d !== "")
          return (
            <div style={{ fontSize: "14px", textAlign: "right" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}
              </div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(gcostot))}</div>
            </div>
          )
        },
      },
      {
        header: "#IMP",
        accessorKey: "IMP",
        size: 150,
        id: "IMP",
        muiTableBodyCellProps: {
          align: "right"
        }
      },
      {
        header: "FI",
        accessorKey: "FI",
        size: 140,
        id: "FI",
        Cell: ({ cell }) => (
          <Box
            component="span"
          >
            {cell.getValue()}%
          </Box>
        ),
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
      },
      {
        header: "PEDIMENTO",
        size: 230,
        accessorKey: "PEDIMENTO",
        id: "PEDIMENTO",
      },
      {
        header: "FECHA",
        size: 160,
        accessorKey: "FECHA",
        id: "FECHA",
      },
      {
        header: "VALOR ADUANA",
        size: 230,
        accessorKey: "VALOR_ADUANA",
        id: "VALOR_ADUANA",
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              //  backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              //  color: '#fff',
              maxWidth: '12ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        }
      },
      {
        header: "PERIODO",
        accessorKey: "PERIODO",
        id: "PERIODO",
      },
      {
        header: "COMENTARIOS",
        size: 220,
        accessorKey: "COMENTARIOS",
        id: "COMENTARIOS",
      },


    ],
    [
      gvalor_en_mn,
      giva,
      ghonorarios,
      gfletes,
      gdta,
      gprv,
      gcnt,
      gigi,
      ggts,
      gtotalg,
      gcostot,
      porc,
      
    ]
  );
  useEffect(() => {
    if (total_rows === "") {
    } else {
      const getCurrentPath = () => {
        const search = window.location.search;
        const pathWithoutQuestionMark = search.startsWith('?') ? search.slice(1) : search;
        return pathWithoutQuestionMark;
      };

      const pathWithoutQuestionMark = getCurrentPath();
      setquery_filters_data(pathWithoutQuestionMark)
    }
  }, [total_rows])
  console.log(total_rows)



  return (
    <>
      <BREADCRUMBS niveles={
        [
          { label: "FACTOR DE IMPORTACIÓN", path: null },
        ]
      }
        // old_version={true}
        // modul="CONSULTAR_CUENTAS_CONTABLES_V1"
        // path="/consult_accounting_account"  
        cards_dashboard={true}
        total_rows={total_rows !== "" ? total_rows : 0}
        filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
        visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
        total={data?.length}
      ></BREADCRUMBS>
      <div className='new_table_v2'>
        <div >
          <NotificationSystem ref={notificationSystemRef} ></NotificationSystem>
          <MODAL_TABLE
            open={true}
            message={menssage}
            modalGeneral={modalGeneral}
            modalT={modalT}
          ></MODAL_TABLE>
          <>
            <MaterialReactTable
              columns={columns}
              data={data}
              enableGrouping
              enablePinning
              enableFacetedValues
              enableStickyHeader
              enableStickyFooter
              enableRowPinning
              enableColumnFilterModes
              enableRowActions
              onStateChange={handleStateChange}
              enableRowSelection
              getRowId={(row) => row?.id}
              onRowSelectionChange={setRowSelection}
              enableTooltips={false}
              localization={customLocalization}
              state={{ rowSelection, isLoading: isLoading }}
              enableColumnResizing
              enableColumnPinning
              enableColumnOrdering
              enableExpandAll={false}
              initialState={
                {
                  showGlobalFilter: true,
                  columnVisibility: {
                    IMP: false,
                    FI: false,
                    PEDIMENTO: false,
                    FECHA: false,
                    VALOR_ADUANA: false,
                    PERIODO: false,
                    COMENTARIOS: false
                  },
                }}
              muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
              muiSkeletonProps={{ animation: 'pulse', height: 28 }}
              rowVirtualizerInstanceRef
              rowVirtualizerOptions={{ overscan: 5 }}
              columnVirtualizerOptions={{ overscan: 2 }}
              muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
              positionToolbarAlertBanner="bottom"
              paginationDisplayMode='pages'
              rowPinningDisplayMode='sticky'
              layoutMode="grid"
              muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important", } }}
              muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
              muiTableFooterCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
              muiPaginationProps={{
                color: 'primary', shape: 'rounded', variant: 'variant', rowsPerPageOptions: orderpg,
              }}
              
              displayColumnDefOptions={{
                'mrt-row-pin': {
                  enableHiding: true,
                },
                'mrt-row-actions': {
                  enableHiding: true,
                },
                'mrt-row-expand': {
                  enableHiding: true,
                },
                'mrt-row-select': {
                  enableHiding: true,
                }
              }}
              muiTableBodyRowDragHandleProps={({ table }) => ({
                onDragEnd: () => {
                  const { draggingRow, hoveredRow } = table.getState();
                  if (hoveredRow && draggingRow) {
                    data.splice(
                      hoveredRow.index,
                      0,
                      data.splice(draggingRow.index, 1)[0],
                    );
                    setData([...data]);
                  }
                },
              })}
              muiTableBodyRowProps={
                ({ row, table }) => {
                  settotal_rows(table);
                  const { density } = table.getState();
                  return {
                    sx: {
                      height: row.getIsPinned()
                        ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                        }px`
                        : undefined,
                    },
                  };
                }
              }
              renderTopToolbarCustomActions={({ }) => (
                <FILTERS_FACTOR
                  fi={fi}
                  ff={ff}
                  setfi={setfi}
                  setff={setff}
                  suplier={suplier}
                  setsuplier={setsuplier}
                  warehouse={warehouse}
                  setwarehouse={setwarehouse}
                  handleSubmit={handleSubmit}
                  setfilter={setfilter}
                  setoption_aux={setoption_aux}
                  option_aux={option_aux}
                />
              )}
              renderToolbarInternalActions={({ table,idenx }) => (
                <RENDER_TOOLBAR_INTERNAL_ACTIONS
                  table={table}
                  file_name={"Factor de Importación"}
                  update_identifiers={update_identifiers}
                  gvalor_en_mn={gvalor_en_mn}
                  porc={porc}
                  factImp={true}
                  query_filters={query_filters}
                  query_filters_dt={query_filters_dt}
                  handle_get_consult={handleSubmit}
                  key={idenx}
                  settotal_rows={settotal_rows}
                  handle_success_filter={handle_success_filter}
                  share_filter={true}
                  total_rows={total_rows !== "" ? total_rows : 0}
                />
              )
              }
            />

          </>
        </div>
      </div>
    </>
  );
}

export default FACTOR_IMPORTATION;
