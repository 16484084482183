//PAQUETERIAS

import { useEffect, useState, useMemo, useRef } from "react";
import { CustomDialog } from "react-st-modal";

import { Box } from "@mui/material";
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import dayjs from 'dayjs';
import NotificationSystem from "react-notification-system";
//COMPONENTES Y FUNCIONES
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../../main_components/methods_v2/export";
import MODAL_TABLE from "../../../main_components/modal/modal_Table";
import SEARCH_FILTERS from "../../../main_components/methods_v2/shearch_filter";
import { BREADCRUMBS } from "../../../main_components/pagination/breadcrumbs";
import { refresh_token } from "../../../main_components/tokens/tokenrefresh";
import { date_current } from "../../../main_components/date/date";
import { previous_date } from "../../../main_components/date/date";
import JSON_HEADER from "../json/headers.json";
import DEBETS_PAYMENTS_DETAIL from "./debts_payments_detail";
import { calculate_total } from "../../../main_components/methods_v2/auxiliary_functions";
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import Button from "@mui/material/Button";

//ESTILOS
//IMAGENES/ICONOS

import VIEW from "../../../imgComponents/lupa.png"
import CREATE from "../../../imgComponents/create.png";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { GRAPH } from "../tools/graph";

const DEBETS_PAYMENTS = (props) => {
    const [data, setData] = useState([]);
    const [modalGeneral, setmodalGeneral] = useState(true);
    const [modalT, setmodalT] = useState(false);
    const [ff, setff] = useState(dayjs(date_current));
    const [fi, setfi] = useState(dayjs(previous_date));
    const notificationSystemRef = useRef();
    const [isLoading, setIsLoading] = useState(false);
    const [rowSelection, setRowSelection] = useState({});
    const [total_rows, settotal_rows] = useState("");

    const [id_client, set_id_client] = useState(null);

    const [enable_detail, set_enable_detail] = useState(false);


    const update_identifiers =
        [
            { name: 'string' },
            { proyectado: 'number' },
            { promesa: 'number' },
            { description: 'number' },
            { diferencia: 'number' },
            { currency: 'string' },
        ];



    const handleSubmit = async () => {
        setIsLoading(true)
        try {
            setTimeout(() => {
                setData(JSON_HEADER);
                setIsLoading(false)
            }, 3000);
        } catch (err) {
            console.log(err);
        }
    };


    const handle_set_id_client = (id) => {
        set_id_client(id);
        set_enable_detail(true);
    }

    const columns_header = useMemo(
        () => [
            {
                header: "Name",
                accessorKey: "name",
                size: 400,
                muiTableBodyCellProps: {
                    align: 'justify',
                },
                Cell: ({ cell }) => {
                    return (
                        <Box
                            component="span"
                            className="link-style"
                            onClick={() => handle_set_id_client(cell.row.original.name)}
                        >
                            {cell.getValue()}
                        </Box>
                    );
                },
            },
            {
                header: "Proyectado",
                accessorKey: "proyectado",
                Cell: ({ cell }) => {
                    return (
                        <Box
                            component="span"
                            sx={(theme) => ({
                                backgroundColor: theme.palette.success.dark,
                                borderRadius: '0.25rem',
                                color: '#fff',
                                maxWidth: '16ch',
                                p: '0.25rem',
                            })}
                        >
                            {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
                                style: 'currency',
                                currency: 'MXN',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }) : cell.getValue()?.toLocaleString?.('es-MX', {
                                style: 'currency',
                                currency: 'MXN',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}
                        </Box>
                    )
                },
                muiTableBodyCellProps: {
                    align: 'right',
                },
                muiTableFooterCellProps: {
                    align: 'right',
                },
                size: 250,
                Footer: (d) => {
                    const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => isNaN(obj.proyectado) ? 0 : obj.proyectado)
                    const total = data.map(obj => isNaN(obj.proyectado) ? 0 : obj.proyectado)
                    return (
                        <div >
                            <div>{calculate_total(subtotal).toLocaleString('es-Mx', {
                                style: 'currency',
                                currency: 'MXN',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}</div>
                            <div>{calculate_total(total).toLocaleString('es-Mx', {
                                style: 'currency',
                                currency: 'MXN',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}</div>
                        </div>
                    )
                },
            },
            {
                header: "Promesa",
                accessorKey: "promesa",
                Cell: ({ cell }) => {
                    return (
                        <Box
                            component="span"
                            sx={(theme) => ({
                                backgroundColor: theme.palette.success.dark,
                                borderRadius: '0.25rem',
                                color: '#fff',
                                maxWidth: '16ch',
                                p: '0.25rem',
                            })}
                        >
                            {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
                                style: 'currency',
                                currency: 'MXN',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }) : cell.getValue()?.toLocaleString?.('es-MX', {
                                style: 'currency',
                                currency: 'MXN',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}
                        </Box>
                    )
                },
                muiTableBodyCellProps: {
                    align: 'right',
                },
                muiTableFooterCellProps: {
                    align: 'right',
                },
                size: 250,
                Footer: (d) => {
                    const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => isNaN(obj.promesa) ? 0 : obj.promesa)
                    const total = data.map(obj => isNaN(obj.promesa) ? 0 : obj.promesa)
                    return (
                        <div >
                            <div>{calculate_total(subtotal).toLocaleString('es-Mx', {
                                style: 'currency',
                                currency: 'MXN',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}</div>
                            <div>{calculate_total(total).toLocaleString('es-Mx', {
                                style: 'currency',
                                currency: 'MXN',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}</div>
                        </div>
                    )
                },
            },
            {
                header: "Diferencia",
                accessorKey: "diferencia",
                size: 250,
                Cell: ({ cell }) => {
                    return (
                        <Box
                            component="span"
                            sx={(theme) => ({
                                backgroundColor: theme.palette.success.dark,
                                borderRadius: '0.25rem',
                                color: '#fff',
                                maxWidth: '16ch',
                                p: '0.25rem',
                            })}
                        >
                            {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
                                style: 'currency',
                                currency: 'MXN',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }) : cell.getValue()?.toLocaleString?.('es-MX', {
                                style: 'currency',
                                currency: 'MXN',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}
                        </Box>
                    )
                },
                muiTableBodyCellProps: {
                    align: 'right',
                },
                muiTableFooterCellProps: {
                    align: 'right',
                },
                Footer: (d) => {
                    const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => isNaN(obj.diferencia) ? 0 : obj.diferencia)
                    const total = data.map(obj => isNaN(obj.diferencia) ? 0 : obj.diferencia)
                    return (
                        <div >
                            <div>{calculate_total(subtotal).toLocaleString('es-Mx', {
                                style: 'currency',
                                currency: 'MXN',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}</div>
                            <div>{calculate_total(total).toLocaleString('es-Mx', {
                                style: 'currency',
                                currency: 'MXN',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}</div>
                        </div>
                    )
                },
            },
            {
                header: "Moneda",
                accessorKey: "currency",
                Cell: ({ cell }) => {
                    return (
                        <Box
                            component="span"
                            sx={(theme) => ({
                                backgroundColor: 'rgb(245, 124, 0)',
                                borderRadius: '0.25rem',
                                color: '#fff',
                                maxWidth: '16ch',
                                p: '0.25rem',
                            })}
                        >
                            {cell.getValue() === undefined ? 0?.toLocaleString?.('es-MX', {
                                style: 'currency',
                                currency: 'MXN',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }) : cell.getValue()?.toLocaleString?.('es-MX', {
                                style: 'currency',
                                currency: 'MXN',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}
                        </Box>
                    )
                },
                muiTableBodyCellProps: {
                    align: 'right',
                },
                muiTableFooterCellProps: {
                    align: 'right',
                },
                filterVariant: 'select',
                enableEditing: false,
                enableRowOrdering: false,
                enableColumnDragging: false,
                enableColumnActions: false,
                enableFilterMatchHighlighting: false,
                enableColumnFilterModes: false,
            },

        ],
        [data]
    );


    const handleStateChange = (newState) => {
        const filteredRowsCount = newState.filteredRowModel
        settotal_rows(filteredRowsCount);
    };

    useEffect(() => { handleSubmit() }, []);

    return (
        <div >
            <MODAL_TABLE open={true} modalT={modalT}></MODAL_TABLE>
            <NotificationSystem ref={notificationSystemRef}></NotificationSystem>
            <div style={{ display: enable_detail ? "none" : "" }}>
                <BREADCRUMBS
                    niveles={[
                        {
                            label: "REPORTE DE PAGOS Y DEUDAS",
                            path: null,
                        },
                    ]}
                    cards_dashboard={true}
                    total_rows={total_rows !== "" ? total_rows : 0}
                    filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
                    visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
                    total={data?.length}
                ></BREADCRUMBS>
            </div>
            <div style={{ display: enable_detail ? "none" : "" }}>
                <div className="new_table_v2">
                    <MaterialReactTable
                        columns={columns_header}
                        data={data}
                        enableGrouping
                        enablePinning
                        enableFacetedValues
                        enableStickyHeader
                        enableStickyFooter
                        enableRowPinning
                        enableColumnFilterModes
                        onStateChange={handleStateChange}
                        enableRowSelection
                        getRowId={(row) => row?.id}
                        onRowSelectionChange={setRowSelection}
                        enableTooltips={false}
                        localization={MRT_Localization_ES}
                        state={{ rowSelection, isLoading: isLoading }}
                        enableColumnResizing
                        enableColumnPinning
                        enableColumnOrdering
                        enableExpandAll={false}
                        enableColumnDragging={false}
                        initialState={{ showGlobalFilter: true, showColumnFilters: true }}
                        muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
                        muiSkeletonProps={{ animation: 'pulse', height: 28 }}
                        rowVirtualizerInstanceRef
                        rowVirtualizerOptions={{ overscan: 5 }}
                        columnVirtualizerOptions={{ overscan: 2 }}
                        muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
                        positionToolbarAlertBanner="bottom"
                        paginationDisplayMode='pages'
                        rowPinningDisplayMode='sticky'
                        layoutMode="grid"
                        muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
                        muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
                        muiTableFooterCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

                        muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
                        displayColumnDefOptions={{
                            'mrt-row-pin': {
                                Header: () => (
                                    <div title="Re-consultar">
                                        <RestartAltIcon
                                            style={{ cursor: "pointer" }}
                                            onClick={() => handleSubmit()}
                                        ></RestartAltIcon>
                                    </div>
                                ),
                                enableHiding: true,
                            },
                            'mrt-row-actions': {
                                enableHiding: true,
                            },
                            'mrt-row-expand': {
                                enableHiding: true,
                            },
                            'mrt-row-select': {
                                enableHiding: true,
                            }
                        }}
                        muiTableBodyRowDragHandleProps={({ table }) => ({
                            onDragEnd: () => {
                                const { draggingRow, hoveredRow } = table.getState();
                                if (hoveredRow && draggingRow) {
                                    data.splice(
                                        hoveredRow.index,
                                        0,
                                        data.splice(draggingRow.index, 1)[0],
                                    );
                                    setData([...data]);
                                }
                            },
                        })}
                        muiTableBodyRowProps={
                            ({ row, table }) => {
                                settotal_rows(table)
                                const { density } = table.getState();
                                return {
                                    sx: {
                                        height: row.getIsPinned()
                                            ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                                            }px`
                                            : undefined,
                                    },
                                };
                            }
                        }
                        renderTopToolbarCustomActions={({ }) => (
                            <Box sx={{ display: 'flex', gap: '5px', p: '2px', zIndex: "10", alignItems: 'flex-start' }}>
                                <Button
                                    onClick={async () => {
                                        const result = await CustomDialog(
                                            <div className="graficas-demo">
                                                <GRAPH data={data}></GRAPH>
                                            </div>,
                                            {
                                                className: "custom-modal-3",
                                                title: "Graficas",
                                                showCloseIcon: true,
                                                isCanClose: false,
                                            }
                                        );
                                    }}
                                >
                                    <span title='' >Generar Grafica </span><LeaderboardIcon></LeaderboardIcon>
                                </Button>
                            </Box>

                        )}

                        renderToolbarInternalActions={({ table }) => (
                            <RENDER_TOOLBAR_INTERNAL_ACTIONS
                                table={table}
                                file_name={"Reporte de pagos y deudas"}
                                update_identifiers={update_identifiers}
                                settotal_rows={settotal_rows}
                            />
                        )}

                        renderDetailPanel={({ row }) => {
                            if (row.getIsExpanded() === true) {
                                sessionStorage.setItem("dataSuntablefiltro", row.original.uuid_sat);
                                sessionStorage.setItem("dataSuntablefiltroID", row.original.id);
                            }
                            return (
                                <div style={{width:'80%'}}>
                                    <DEBETS_PAYMENTS_DETAIL set_enable_detail={set_enable_detail} id_client={row.original.name} set_id_client={set_id_client}></DEBETS_PAYMENTS_DETAIL>
                                </div>)
                        }}

                    />
                </div>
            </div>

            <div style={{ display: enable_detail ? "" : "none" }}>
                <DEBETS_PAYMENTS_DETAIL set_enable_detail={set_enable_detail} id_client={id_client} set_id_client={set_id_client}></DEBETS_PAYMENTS_DETAIL>
            </div>

        </div >
    );
};

export default DEBETS_PAYMENTS;
