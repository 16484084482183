import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import HailIcon from '@mui/icons-material/Hail';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import BuildIcon from '@mui/icons-material/Build';
import Groups2Icon from '@mui/icons-material/Groups2';
import AssignmentIcon from '@mui/icons-material/Assignment';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import AddCardIcon from '@mui/icons-material/AddCard';
//subcatalogo
import BrandingWatermarkIcon from '@mui/icons-material/BrandingWatermark';
import CarCrashIcon from '@mui/icons-material/CarCrash';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import EngineeringIcon from '@mui/icons-material/Engineering';
import AirlineSeatReclineNormalIcon from '@mui/icons-material/AirlineSeatReclineNormal';
import PercentIcon from '@mui/icons-material/Percent';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import StoreIcon from '@mui/icons-material/Store';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import ReduceCapacityIcon from '@mui/icons-material/ReduceCapacity';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import PublicIcon from '@mui/icons-material/Public';
import TrafficIcon from '@mui/icons-material/Traffic';
import SummarizeIcon from '@mui/icons-material/Summarize';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import BorderAllIcon from '@mui/icons-material/BorderAll';
import GavelIcon from '@mui/icons-material/Gavel';
import BallotIcon from '@mui/icons-material/Ballot';
import FlightIcon from '@mui/icons-material/Flight';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import RequestPageIcon from '@mui/icons-material/RequestPage';
//imagenes
import WarehouseIcon from '@mui/icons-material/Warehouse';
import ApartmentIcon from '@mui/icons-material/Apartment';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ForkLeftIcon from '@mui/icons-material/ForkLeft';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange'
import FeedIcon from '@mui/icons-material/Feed';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import InventoryIcon from '@mui/icons-material/Inventory';
import IcecreamIcon from '@mui/icons-material/Icecream';
import FormatLineSpacingIcon from '@mui/icons-material/FormatLineSpacing';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import BadgeIcon from '@mui/icons-material/Badge';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import ListAltIcon from '@mui/icons-material/ListAlt';

export const data = [
    
   
  
    {
        id: 104,
        name: 'FINANZAS',
        icon: <PriceChangeIcon className='icon' style={{color: "white"}}/>,
        restriccion:"",
        sections: [
          {
            id: 104101,
            name: 'CUENTAS CONTABLES',
            icon: <PointOfSaleIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
            videos: [
              {
                code: 10301,
                titlevideo: "VIDEO", 
                description: 'En este video encontrarás una guía paso a paso para poder crear un nuevo vehìculo.', 
                url: '', 
                img: 'https://web-corporativa-documents.s3.amazonaws.com/caratulas/proximamente.jpg',
              }
            ]
          },
        ]
    },
    
   
    {
        id: 106,
        name: 'COMPRAS',
        icon: <ShoppingCartIcon className='icon' style={{color: "white"}}/>,
        restriccion:"",
        sections: [
          {
            id: 106101,
            name: 'CONSULTAR DOCUMENTOS ENVÍADOS',
            icon: <ContentPasteSearchIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
            videos: [
              {
                code: 10301,
                titlevideo: "VIDEO", 
                description: 'En este video encontrarás una guía paso a paso para poder crear un nuevo vehìculo.', 
                url: '', 
                img: 'https://web-corporativa-documents.s3.amazonaws.com/caratulas/proximamente.jpg',
              }
            ]
          },
          {
            id: 106102,
            name: 'ENVÍO DE DOCUMENTOS',
            icon: <UploadFileIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
            videos: [
              {
                code: 10301,
                titlevideo: "VIDEO", 
                description: 'En este video encontrarás una guía paso a paso para poder crear un nuevo vehìculo.', 
                url: '', 
                img: 'https://web-corporativa-documents.s3.amazonaws.com/caratulas/proximamente.jpg',
              }
            ]
          },
          {
            id: 106108,
            name: 'CATALOGO DE GASTOS',
            icon: <BorderAllIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
            videos: [
              {
                code: 10301,
                titlevideo: "VIDEO", 
                description: 'En este video encontrarás una guía paso a paso para poder crear un nuevo vehìculo.', 
                url: '', 
                img: 'https://web-corporativa-documents.s3.amazonaws.com/caratulas/proximamente.jpg',
              }
            ]
          },
          {
            id: 106109,
            name: 'REGLA DE GASTOS',
            icon: <GavelIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
            videos: [
              {
                code: 10301,
                titlevideo: "VIDEO", 
                description: 'En este video encontrarás una guía paso a paso para poder crear un nuevo vehìculo.', 
                url: '', 
                img: 'https://web-corporativa-documents.s3.amazonaws.com/caratulas/proximamente.jpg',
              }
            ]
          },
          {
            id: 106103,
            name: 'GASTOS NO DEDUCIBLES',
            icon: <BallotIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
            videos: [
              {
                code: 10301,
                titlevideo: "VIDEO", 
                description: 'En este video encontrarás una guía paso a paso para poder crear un nuevo vehìculo.', 
                url: '', 
                img: 'https://web-corporativa-documents.s3.amazonaws.com/caratulas/proximamente.jpg',
              }
            ]
          },
          {
            id: 106104,
            name: 'GASTOS DEL EXTRANJERO',
            icon: <FlightIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
            videos: [
              {
                code: 10301,
                titlevideo: "VIDEO", 
                description: 'En este video encontrarás una guía paso a paso para poder crear un nuevo vehìculo.', 
                url: '', 
                img: 'https://web-corporativa-documents.s3.amazonaws.com/caratulas/proximamente.jpg',
              }
            ]
          },
          {
            id: 106105,
            name: 'REPORTE DE GASTOS',
            icon: <ReceiptLongIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
            videos: [
              {
                code: 10301,
                titlevideo: "VIDEO", 
                description: 'En este video encontrarás una guía paso a paso para poder crear un nuevo vehìculo.', 
                url: '', 
                img: 'https://web-corporativa-documents.s3.amazonaws.com/caratulas/proximamente.jpg',
              }
            ]
          },
          {
            id: 106106,
            name: 'REPORTE DE GASTOS AGRUPADOS',
            icon: <RecentActorsIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
            videos: [
              {
                code: 10301,
                titlevideo: "VIDEO", 
                description: 'En este video encontrarás una guía paso a paso para poder crear un nuevo vehìculo.', 
                url: '', 
                img: 'https://web-corporativa-documents.s3.amazonaws.com/caratulas/proximamente.jpg',
              }
            ]
          },
          {
            id: 106107,
            name: 'POLIZAS',
            icon: <RequestPageIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
            videos: [
              {
                code: 10301,
                titlevideo: "VIDEO", 
                description: 'En este video encontrarás una guía paso a paso para poder crear un nuevo vehìculo.', 
                url: '', 
                img: 'https://web-corporativa-documents.s3.amazonaws.com/caratulas/proximamente.jpg',
              }
            ]
          },
          {
            id: 106110,
            name: 'CONSULTAR PROVEEDORES',
            icon: <ContactMailIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
            videos: [
              {
                code: 10601,
                titlevideo: "VIDEO", 
                description: 'En este video encontrarás una guía paso a paso para poder crear un nuevo vehìculo.', 
                url: '', 
                img: 'https://web-corporativa-documents.s3.amazonaws.com/caratulas/proximamente.jpg',
              }
            ]
          },
        ]
    },
    {
        id: 107,
        name: 'FACTURAS',
        icon: <LibraryBooksIcon className='icon' style={{color: "white"}}/>,
        restriccion:"",
        sections: [
          {
            id: 107102,
            name: "CONSULTAR CONCEPTOS",
            icon: <CreditScoreIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
            videos: [
              {
                code: 10301,
                titlevideo: "VIDEO", 
                description: 'En este video encontrarás una guía paso a paso para poder crear un nuevo vehìculo.', 
                url: '', 
                img: 'https://web-corporativa-documents.s3.amazonaws.com/caratulas/proximamente.jpg',
              }
            ]
          },
          {
            id: 107103,
            name: "CONSULTAR FACTURAS",
            icon: <RequestQuoteIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
            videos: [
              {
                code: 10301,
                titlevideo: "VIDEO", 
                description: 'En este video encontrarás una guía paso a paso para poder crear un nuevo vehìculo.', 
                url: '', 
                img: 'https://web-corporativa-documents.s3.amazonaws.com/caratulas/proximamente.jpg',
              }
            ]
          },
          {
            id: 107104,
            name: "CONSULTAR FACTURAS PPD",
            icon: <RequestQuoteIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
            videos: [
              {
                code: 10301,
                titlevideo: "VIDEO", 
                description: 'En este video encontrarás una guía paso a paso para poder crear un nuevo vehìculo.', 
                url: '', 
                img: 'https://web-corporativa-documents.s3.amazonaws.com/caratulas/proximamente.jpg',
              }
            ]
          },
          {
            id: 107105,
            name: "CONSULTAR IMPUESTOS",
            icon: <MoneyOffIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
            videos: [
              {
                code: 10301,
                titlevideo: "VIDEO", 
                description: 'En este video encontrarás una guía paso a paso para poder crear un nuevo vehìculo.', 
                url: '', 
                img: 'https://web-corporativa-documents.s3.amazonaws.com/caratulas/proximamente.jpg',
              }
            ]
          },
          {
            id: 107101,
            name: "REALIZAR CARTA PORTE",
            icon: <ContentPasteGoIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
            videos: [
              {
                code: 10301,
                titlevideo: "VIDEO", 
                description: 'En este video encontrarás una guía paso a paso para poder crear un nuevo vehìculo.', 
                url: '', 
                img: 'https://web-corporativa-documents.s3.amazonaws.com/caratulas/proximamente.jpg',
              }
            ]
          },
          {
            id: 107108,
            name: "REALIZAR COMPLEMENTO DE PAGO",
            icon: <CurrencyExchangeIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
            videos: [
              {
                code: 10301,
                titlevideo: "VIDEO", 
                description: 'En este video encontrarás una guía paso a paso para poder crear un nuevo vehìculo.', 
                url: '', 
                img: 'https://web-corporativa-documents.s3.amazonaws.com/caratulas/proximamente.jpg',
              }
            ]
          },
          {
            id: 107107,
            name: "REALIZAR FACTURA",
            icon: <FeedIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
            videos: [
              {
                code: 10301,
                titlevideo: "VIDEO", 
                description: 'En este video encontrarás una guía paso a paso para poder crear un nuevo vehìculo.', 
                url: '', 
                img: 'https://web-corporativa-documents.s3.amazonaws.com/caratulas/proximamente.jpg',
              }
            ]
          },
        ]
    },
    {
        id: 108,
        name: 'SOCIOS DE NEGOCIOS',
        icon: <Groups2Icon className='icon' style={{color: "white"}}/>,
        restriccion:"",
        sections: [
         
          {
            id: 108105,
            name: 'SOCIO DE NEGOCIOS',
            icon: <HailIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
            videos: [
              {
                code: 10301,
                titlevideo: "VIDEO", 
                description: 'En este video encontrarás una guía paso a paso para poder crear un nuevo vehìculo.', 
                url: '', 
                img: 'https://web-corporativa-documents.s3.amazonaws.com/caratulas/proximamente.jpg',
              }
            ]
          },
         
        
        ]
    },
   
    {
        id: 110,
        name: 'CONFIGURACIÓN',
        icon: <BuildIcon className='icon' style={{color: "white"}}/>,
        restriccion:"",
        sections: [
      
          {
            id: 110102,
            name: "EMPRESA",
            icon: <ApartmentIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
            videos: [
              {
                code: 10301,
                titlevideo: "VIDEO", 
                description: 'En este video encontrarás una guía paso a paso para poder crear un nuevo vehìculo.', 
                url: '', 
                img: 'https://web-corporativa-documents.s3.amazonaws.com/caratulas/proximamente.jpg',
              }
            ]
          },
          {
            id: 110103,
            name: "ROLES",
            icon: <AccountTreeIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
            videos: [
              {
                code: 10301,
                titlevideo: "VIDEO", 
                description: 'En este video encontrarás una guía paso a paso para poder crear un nuevo vehìculo.', 
                url: '', 
                img: 'https://web-corporativa-documents.s3.amazonaws.com/caratulas/proximamente.jpg',
              }
            ]
          },
         
          {
            id: 110105,
            name: "USUARIOS",
            icon: <PersonSearchIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
            videos: [
              {
                code: 10301,
                titlevideo: "VIDEO", 
                description: 'En este video encontrarás una guía paso a paso para poder crear un nuevo vehìculo.', 
                url: '', 
                img: 'https://web-corporativa-documents.s3.amazonaws.com/caratulas/proximamente.jpg',
              }
            ]
          },
        ]
    },
]